import react from 'react';
import { useBoundTransactionsStore } from '../../../../store/ReportsTransactionsStore/useBoundTransactionsStore';
import { currencyFormatter, formatterNumber } from '../../../../helper/currencyFormatter';
import { CURRENCY } from '../../../../helper/status_transactions';
import Skeleton from 'react-loading-skeleton';

export const TransactionsProducts = () => {
    const { data, loading,totalPoints,totalPrices, errors} = useBoundTransactionsStore( state => {
        const { loadings, data, errors:errorsState } = state;
        return {
            loading: loadings.products,
            data: data.products,
            errors: errorsState.products,
            totalPoints : data.products.reduce( (a,b) => a + b.total * b.points, 0),
            totalPrices : data.products.reduce( (a,b) => a + b.total * b.price, 0),

        }
    });

    if(loading){
        return <Skeleton height={300} />
    }else{
        if(data.length == 0){ return <div className="mt-2 alert alert-danger">{errors.message}</div> }
    }

    return ( 
        <div className="mt-3 card">
            <div className="card-header"><strong>Productos</strong></div>
            <div className="card-body table-responsive">
            <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Total</th>
                            <th>En proceso%</th>
                            <th>Entregados%</th>
                            <th>Rechazados%</th>
                            <th>Total puntos</th>
                            <th>Total Precio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((product, index) => {
                                return ( 
                                    <tr key={ index }>
                                        <th>{product.name}</th>
                                        <th>{ product.total }</th>
                                        <th>{((product.accepted / product.total)*100).toFixed(2) }%</th>
                                        <th>{((product.delivered / product.total)*100).toFixed(2) }%</th>
                                        <th>{((product.underReview / product.total)*100).toFixed(2) }%</th>
                                        <th>{ formatterNumber(product.points * product.total) }</th>
                                        <th>{ currencyFormatter({
                                            value: product.total * product.price,
                                            currency: CURRENCY[product.country]
                                        }) }</th>
                                    </tr>
                                )
                            })
                        }
                         <tr style={{ border: 'none' }}>
                                <td style={{ border:'none' }}></td>
                                <td style={{ border:'none' }}></td>
                                <td style={{ border:'none' }}></td>
                                <td style={{ border:'none' }}></td>
                                <td style={{ border:'none' }}></td>
                                <td style={{ border:'none', backgroundColor:'#FFE0B2', color:'#F57C00', textAlign:'right' }}><strong>{ formatterNumber(totalPoints) }</strong></td>
                                <td style={{ border:'none', backgroundColor:'#C8E6C9', color:'#689F38', textAlign:'right' }}><strong>{ currencyFormatter({
                                    value: totalPrices,
                                    currency: CURRENCY[data[0].country]
                                }) 
                                }</strong></td>
                        </tr>
                    </tbody>
            </table>
            </div>
        </div>
    )
}