import React from 'react';

export const ButtonAction = ({ versionLime }) => {
    return (
        <>
            <div className="content-button-action">
                <a  className="btn btn-warning" href={`/limesurvey/editar/${ versionLime.id }`}>Editar</a>
            </div>
        </>
    );
}