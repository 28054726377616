import { axiosApi } from "../axiosApi";

export const getGeographiesUser =  async () => {
    const { data } = await axiosApi.get(`/api/geo/paises`);
    return data;
}

export const getTagGeographies = async (country = null) => {
    let url = `/api/geo/tags/`;
    if (country) { url = url + country }
    try {
        const response = await axiosApi.get(url);
        return response;
    } catch (error) {
        const { response } = error;
        throw  response;
    }
}

export const getGeographiesWithLevel = async (data) => {
    try{
        const response = await axiosApi.get(`/api/geo/level/${ data.value }`);
        return response;
    }catch(error){
        const { response } = error;
        throw  response;
    }
}

export const getCountries = async() => {
    try{
        const response = await axiosApi.get('/api/geo/countries');
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const getLevelsGeo = async () => {
    try{
        const response = await axiosApi.get('/api/geo/niveles');
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}