import {MaterialReactTable, useMaterialReactTable} from 'material-react-table';
import React, { useMemo  } from 'react';
import { formatterNumber } from '../../../../helper/currencyFormatter';
import { useBoundMktStore } from '../../../../store/ReportsCampaignMkt/useBoundMktStore';
import { profilesStatus } from '../../../../helper/projectTransform';

export const CampaignForProfilesTable = () => {
    const { loading, errors, data, totalRegister } =  useBoundMktStore( state => {
        return {
            loading: state.loadings.profiles,
            errors: state.errors.profiles,
            data: state.data.profiles,
            totalRegister: state.totalRegister
        }
    });
    const columns = useMemo(() => [
        {
            accessorKey: 'title',
            header:'Nombre'
        },
        {
            accessorKey: 'status',
            header:'Estado',
            Cell:({ row }) => profilesStatus[row.original.status]
        },{
            accessorKey:'total',
            header: 'Total',
            Cell:({ row }) => formatterNumber(row.original.total)
        },{
            header: 'Total %',
            Cell:({ row }) => {
                return  ((row.original.total / totalRegister)*100).toFixed(2) + '%';
            }
        }
    ],[totalRegister]);

    const table = useMaterialReactTable({
        columns: columns,
        data:data,
        muiTablePaperProps: {
            sx: { boxShadow:'none !important', border:'none' }
        },
        muiToolbarAlertBannerProps: errors.status ? { color: 'error', children: errors.message } : undefined,
        // enablePinning:true,
        state:{
            isLoading:loading,
            showAlertBanner: errors.status,
            density:"compact"
        },
        enablePagination:false,
        enableSorting:true,
        enableFullScreenToggle:false,
        enableDensityToggle:false,
        enableFilters:true,
        enableHiding:false,
        enableBottomToolbar:false,
        enableTopToolbar:true,
        enableColumnActions:true,
    });
    return (
        <div>
            <MaterialReactTable table={ table } />
        </div>
    )
}