export const projectStatus = {
    "actived": "En campo",
    "expired": "Cerrado",
    "testmode": "Modo Prueba",
    "paused": "Pausado"
};

export const programmingType = {
    "internal": "Interna",
    "external": "Externa"
};

export const profilesStatus = {
    'enabled': 'En campo',
    'disabled': 'Cerrado'
}