import {MaterialReactTable} from 'material-react-table';
import { useMemo } from 'react';
import { STATUS_PANELIST_PROJECT } from '../../../../helper/project';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import  formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { es } from 'date-fns/locale';
import { isValid, parseISO, isBefore } from 'date-fns';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';
import { deleteProjectPanelistTest, getPanelists, resetProjectPanelistTest, updatePanelistsExpiredAtByPanel } from '../../../../api/sio/project';
import { ExportToCsv } from 'export-to-csv';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { useMutation } from 'react-query';
import { usePanels } from '../../../../hooks/useProject';
import { RenderRowActionListPanelists } from '../form/RenderRowActionListPanelists';
import { UpdateExpiredAtModal } from '../modals/UpdateExpiredAtModal';

export const PanelistsTableProject = ({ projectId,
    panelists,
    pagination,
    setPagination,
    rowCount,
    columnFilters,
    setColumnFilters,
    url,
    setUrl,
    isError,
    setIsError,
    isLoading,
    refetch,
    seeModal,
    setSeeModal,
    setToken }) => {

    const { panels, getPanels,selectPanel,setSelectPanel }= usePanels(projectId);
    const columns = useMemo( () => [
        {
            accessorKey: "status",
            header: "Estado",
            size:5,
            filterVariant:'select',
            filterSelectOptions: Object.entries(STATUS_PANELIST_PROJECT).map(([clave, valor]) => ({text:valor, value:clave})),
            Cell: ({ cell }) => {
                return STATUS_PANELIST_PROJECT[cell.getValue()];
            },
        },{
            accessorKey: "panel",
            header: "Panel",
        },{
            accessorKey: "email",
            header: "Email",
        },{
            accessorKey: "token",
            header: "Token",
        },{
            accessorKey: "pid",
            header: "PID",
        },{
            accessorKey: "external_token",
            header: "Token Externo",
        },{
            accessorKey: "expired_at",
            header: "Expira en",
            enableColumnFilter:false,
            Cell: ({ cell }) => {
                if(isValid(parseISO(cell.row.original.expired_at))){
                return formatDistanceToNow (parseISO(cell.row.original.expired_at), { addSuffix: true, locale:es })
                }
                return '-'
            }
        },{
            accessorKey: "linkUrl",
            header: "Link",
            enableColumnFilter: false,
            Cell: ({ cell }) => {
                let isDisabled = ["completed", "filtered"].includes(cell.row.original.status) || isBefore(parseISO(cell.row.original.expired_at), new Date())  ? "disabled" : "";
                return (
                    <a
                        className={`btn btn-primary-oo ${ isDisabled }`}
                        href={cell.row.original.linkUrl}
                        style={{ cursor: "pointer" }}
                        target="_blank"
                    >
                        <i className="fa fa-rocket" style={{ fontSize: "14px" }}></i>
                    </a>
                )
            }
        },
    ],[]);

    const handleExportData = async (event, type) => {
        setIsError({
            status:false,
            message: ""
        })
        var _url = new URL(`${import.meta.env.VITE_REACT_APP_URL}/proyecto/${projectId}/panelistas`);
        _url.searchParams.set('csv', 'download');
        if(type == 'panel'){
        _url.searchParams.set('panel', event.value);
        }
        setUrl(_url);
        try{
            let response = await getPanelists(_url);
            const { data,status } = response;
            if(status == 204){
                setIsError({
                    status:true,
                    message:"No hay datos disponibles para la descarga"
                })
                return;
            }
            const csvOptions = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                useBom: true,
                headers: columns.map((c) => c.header),
                filename: uuidv4()
            };
            const csvExporter = new ExportToCsv(csvOptions);
            let calc = data.map( (row) => (
                {
                    'Estado' : STATUS_PANELIST_PROJECT[row.status],
                    'Email':  row.email,
                    'Token': row.token,
                    'PID': row.pid,
                    'Token Externo': row.external_token,
                    'Expira en': row.expired_at,
                    'Link': row.linkUrl,
                    'Panel': row.panel
                }
            ))
            csvExporter.generateCsv( calc.map( row => row));
            getPanels();
        }catch(error){
            const  { data,status } = error;
            if(status == 422){
                setIsError({
                    status:true,
                    message: data.message
                })
            }else{
                setIsError({
                    status:true,
                    message: "Algo ocurrio al intentar descargar los datos, intenta nuevamente."
                })
            }
        }
    }

    /**
     * 
     * @param {*} e
     * Acciones asociadas a los links de pruebas como reiniciar y eliminar links
     */
    const handleLinkTest = (e) => {
        if(e.value == 'reset'){
            handleResetLinksTest();
        }
        if(e.value == 'delete'){
            handleRemoveLinksTest();
        }
    }

    /**
     * Reiniciar links de pruebas
     */
    const {  mutate: mResetProjectPanelistTest, error: errorResetProjectPanelistTest, isError: isErrorResetProjectPanelistTest } = useMutation(resetProjectPanelistTest, {
        onSuccess: () => {
            Swal.fire(
                "Reiniciar encuestas de prueba",
                "Se han reiniciado todas las encuestas de prueba",
                "success"
            ).then(() => {
                refetch()
                getPanels();
            });
        },onError: (res) => {
            Swal.fire(
                "Reiniciar encuestas de prueba",
                `${res.data.error ? res.data.error : ""}`,
                "error"
            );
        },
    });
    const handleResetLinksTest = () => {
        Swal.fire({
            title: "¿Quieres reiniciar la encuesta de todos los panelistas de prueba?",
            showCancelButton: true,
            confirmButtonText: "Reiniciar",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((res) => {
            if (res.isConfirmed) {
                mResetProjectPanelistTest(projectId);
            } else {
                Swal.fire(
                    "Reiniciar encuestas de prueba",
                    `Las encuestas de prueba no se han reiniciado ${
                        isErrorResetProjectPanelistTest
                            ? errorResetProjectPanelistTest
                            : ""
                    }`,
                    "warning"
                );
            }
        });
    };

    /**
     * Eliminar links de pruebas
     */
    const handleRemoveLinksTest = () => {
        Swal.fire({
            title: "¿Quieres eliminar todos los panelistas de prueba y sus respuestas?",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((res) => {
            if (res.isConfirmed) {
                mDeleteProjectPanelistTest(projectId);
            } else {
                Swal.fire(
                    "Eliminar Panelistas de prueba",
                    `No se han eliminado los panelistas de prueba`,
                    "warning"
                );
            }
        });
    }
    const { mutate: mDeleteProjectPanelistTest } = useMutation(deleteProjectPanelistTest,{
        onSuccess: () => {
            Swal.fire(
                "Eliminar Panelistas de prueba",
                "Se han eliminado los panelistas de prueba y sus respuestas",
                "success"
            ).then(() => {
                refetch();
                getPanels();
            });
        },onError: (res) => {
            Swal.fire(
                "Eliminar Panelistas de prueba",
                `${res.data.error ? res.data.error : ""}`,
                "error"
            ).then(() =>  {
                refetch();
                getPanels();
            } );
        },
        }
    );

    return (
        <>
                <div className="content_button_action_table">
                    {

                        isLoading ? (
                            <>
                                <Skeleton width={100} height={20}  />
                                <Skeleton width={100} height={20}  />
                                <Skeleton width={100} height={20}  />
                            </>
                        ) : (
                            <>
                                {
                                    panels.length > 0 && (
                                        panels.filter( x => x.value == 'TEST').length > 0 && (
                                            <Select
                                                key={2}
                                                isClearable
                                                placeholder='Links de prueba'
                                                options={[{value:'reset', label:'📝Reiniciar'}, {value:'delete',label:'🗑️ Eliminar'}]}
                                                styles={ {
                                                    menu: provided => ({ ...provided, zIndex: 100, }),
                                                    control: (provided, state) => ({ ...provided, zIndex: 100, border:'solid #86b7fe 1px', borderRadius:'1rem', color: '#86b7fe'}),
                                                    placeholder: (provided, state) => ({...provided,  color: '#86b7fe'  })
                                                }}
                                                value={ selectPanel }
                                                onChange = { e => handleLinkTest(e, 'panel') }
                                            />
                                        )
                                    )
                                }
                                {
                                    panels.length > 0 && (
                                        <>
                                        <Select
                                            key={1}
                                            isClearable
                                            placeholder='Exportar'
                                            options = { panels }
                                            styles={ {
                                                menu: provided => ({ ...provided, zIndex: 100, }),
                                                control: (provided, state) => ({ ...provided, zIndex: 100, border:'solid #86b7fe 1px', borderRadius:'1rem', color: '#86b7fe'}),
                                                placeholder: (provided, state) => ({...provided,  color: '#86b7fe'  })
                                            }}
                                            value={ selectPanel }
                                            onChange = { e => handleExportData(e, 'panel') }
                                        />
                                        <button
                                            className='btn btn-primary-oo-outline-blue'
                                            style={{ borderRadius:'1rem' }}
                                            onClick={() => setSeeModal({...seeModal,updateExpiredAt: !seeModal.updateExpiredAt})}>
                                            Cambiar fecha de expiración
                                        </button>
                                        {seeModal.updateExpiredAt &&
                                            <UpdateExpiredAtModal
                                                projectId={projectId}
                                                setSeeModal={setSeeModal}
                                                seeModal={seeModal}
                                                refetchPanelists={refetch}
                                            />
                                        }
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </div>

                <MaterialReactTable
                    isClearable
                    columns={columns}
                    data={panelists}
                    manualFiltering
                    manualPagination
                    rowCount={rowCount}
                    onColumnFiltersChange={setColumnFilters}
                    onPaginationChange={setPagination}
                    enableGlobalFilter={false}
                    enableColumnActions={true}
                    enableColumnFilters={true}
                    enableBottomToolbar={true}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableHiding={true}
                    localization={MRT_Localization_ES}
                    initialState={{ density:'compact', showColumnFilters: true, }}
                    muiTablePaginationProps={{ rowsPerPageOptions: [10,50,100,150,200] }}
                    muiToolbarAlertBannerProps={ isError.status ? { color: 'error', children: isError.message } : undefined }
                    muiTablePaperProps={{ sx: { boxShadow: 'none !important', border: 'none' } }}
                    state={{
                        isLoading,
                        pagination,
                        columnFilters,
                        showAlertBanner: isError.status,
                    }}
                    enableRowActions
                    positionActionsColumn="last"
                    displayColumnDefOptions={{ "mrt-row-actions": { header: "Acciones", } }}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                        <RenderRowActionListPanelists
                            key='rnd'
                            closeMenu= { closeMenu }
                            row = { row }
                            id= {projectId}
                            setToken={setToken}
                            seeModal={ seeModal }
                            setSeeModal = { setSeeModal }
                            refetch =  { refetch }/>
                    ]}
                />
        </>

    )
}