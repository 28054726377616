import React, { useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import {MaterialReactTable} from 'material-react-table';
import Skeleton from '@mui/material/Skeleton';
import { App } from '../../../App';
import { ButtonAction } from './form/ButtonAction';
import { useGetProjects } from '../../../hooks/useProject';
import { projectStatus,programmingType } from '../../../helper/projectTransform.js';


export const Projects = () => {
    // const { isLoading, data } = useQuery('projects', getProjects);
    const { projects, isLoading:isLoadingProject, isError:isErrorProjects, error:errorProjects } = useGetProjects();
    const columns = useMemo(
        () => [
          {
            accessorKey: 'status', //access nested data with dot notation
            header: 'Estado',
            Cell: ({ cell }) => {
              return <strong>{projectStatus[cell.getValue()]}</strong>
            },
            size: 5,
          },
          {
            accessorKey: 'name',
            header: 'Titulo',
          },{
            accessorKey:'client',
            header:'Cliente'
          },
          {
            accessorKey: 'loi',
            header: 'Tiempo promedio',
            size:1
          },
          {
            accessorKey: 'ir',
            header: 'Tasa incidencia',
            size:1
          },
          {
            accessorKey: 'sample',
            header: 'Muestra',
          },
          {
            accessorKey: 'programming',
            header: 'Tipo programación',
            Cell: ({ cell }) => {
              return programmingType[cell.getValue()]
            },
          },
          {
            header: 'Acciones',
            Cell: ( { cell, row }) => <ButtonAction project={ cell.row.original }/>
          },
        ],
        [],
      );
    return ( 
        <>
            {
                isLoadingProject  ? ( <Skeleton height={400}/>)
                : (
                    <div className="card">
                        <div className="card-header">
                          <div className="content-head-card">
                            <label>Mostrar proyectos</label>
                            <a className="btn btn-primary-oo" href="/proyecto/crear">Crear proyecto</a>
                          </div>
                        </div>
                        <div className="card-body">
                            <MaterialReactTable 
                                columns={columns} 
                                data={projects} 
                                initialState={{ density: 'compact' }}
                                muiTablePaperProps={{
                                    //customize paper styles
                                    sx: {
                                        boxShadow: 'none !important',
                                        border: 'none'
                                    },
                                }}
                            />
                        </div>
                    </div>

                )
            }
        </>
    );

}

if (document.getElementById('jsx-projects')) {
    const el = document.getElementById("jsx-projects");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
          <Projects { ...props } />
      </App>
  )
}