import { axiosApi } from "../axiosApi";

export const getRefenceNse = async () => {
    try {
        const  response = await axiosApi.get('/api/nse/referencias');
        return response;
    } catch (error) {
        const { response } = error;
        throw  response;
    }
}

export const getTypesNse = async() => {
    try{
        const response = await axiosApi.get('/api/nse/tipos');
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}