import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { getGeographiesWithLevel } from "../api/dashboard/geo";
import { useLevelsGeo } from "./useGeography";

export const useGeographiesWithLevelM = (filter, type) => {
    const [geographies, setGeographies] = useState([]);
    const { isLoading:isLoadingM, mutate } = useMutation(getGeographiesWithLevel,{ 
        onSuccess : (response) => {
            const { data } = response;
            setGeographies(data || []);
    }, onError:({response}) => setGeographies([])});

    useEffect( () => {
        mutate(filter);
        setGeographies([])
    }, [filter]);

    return {isLoadingM, geographies, setGeographies,mutate}
}