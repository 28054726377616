import React, { useState } from 'react';
import { getRolesUser, updateUser } from '../../../../api/sio/user';
import { getGeographiesUser } from '../../../../api/sip/geography';

import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import Swal from 'sweetalert2';

const options = [
    { value: 'active', label: 'Activar' },
    { value: 'inactive', label: 'Desactivar'}
];

export const EditUserForm = ({ user, setUser }) => {
    const [ roles, setRoles ] = useState(null);
    const [ geographies, setGeographies] = useState(null);
    const { isLoading:isLoadingRoles, error:errorRoles, isError:isErrorRoles } = useQuery( ['roles'], () => getRolesUser(user.id), { 
        onSuccess: (data) => {
            setRoles(data);
        }

    } );

    const { isLoading:isLoadingGeo, error:errorGeo, isError:isErrorGeo } = useQuery( ['geographies'], () => getGeographiesUser(), { 
        onSuccess: (data) => {
            let geographies = data.map(x => ({ value: x.country, label: x.country }));
            setGeographies(geographies);
        }
    } );

    const { mutate, error, isLoading:isLoadingEdit, isError:isErrorUpdate, isSuccess:isSuccessUpdate} = useMutation(updateUser,{
        onSuccess: () => Swal.fire('Datos del usuario modificados', '', 'success'),
        onError: () => Swal.fire('No se pudo modificar los datos del usuario, intenta nuevamente', '', 'error')
    });

    const handleUpdateData = (e) => {
        e.preventDefault();
        Swal.fire({
            title: '¿Quiere actualizar la información?',
            showCancelButton: true,
            confirmButtonText: 'Editar',
            cancelButtonText: 'Cancelar',
            icon:'info'
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              mutate(user);
            } else if (result.isDenied) {
               Swal.fire('No se pudo modificar los datos del usuario, intenta nuevamente', '', 'error') 
            }
          })
    }

    return (
        <>

            <form onSubmit={ handleUpdateData }>
                <div className="form-group">
                    <label className="my-1 fw-bold">Nombre usuario</label>
                    <input type="text" name="name" className="form-control" id="name-input" value={ user.name } onChange={ (e) => setUser({ ...user, name:e.target.value })}/>
                    {
                        isErrorUpdate && (
                            <p className="text text-danger">{error.response.data.errors.name?.map( x => x)}</p>
                        )
                    }
                </div>
                <div className="form-group">
                    <label className="my-1 fw-bold">Correo electrónico</label>
                    <input type="text" name="email" className="form-control" id="email-input" value={ user.email } onChange={ (e) => setUser({ ...user, email:e.target.value })}/>
                    {
                        isErrorUpdate && (
                            <p className="text text-danger">{error.response.data.errors.email?.map( x => x)}</p>
                        )
                    }

                </div>
                <div className="form-group">
                    <label className="my-1 fw-bold">Nickname</label>
                    <input type="text" name="nickname" className="form-control" id="nickname-input" value={ user.nickname } onChange={ (e) => setUser({ ...user, nickname:e.target.value })}/>
                    {
                        isErrorUpdate && (
                            <p className="text text-danger">{error.response.data.errors.nickname?.map( x => x)}</p>
                        )
                    }
                </div>
                <div className="form-group">
                    <label className="my-1 fw-bold">Estado</label>
                    <Select
                        key={1} 
                        className="baisc-single"
                        classNamePrefix="select-status-user"
                        name="status"
                        defaultValue={ options.filter( x => x.value == user.status ) }
                        options={ options }
                        onChange={ (e)  => setUser({...user, status: e.value }) }
                    />
                    {
                        isErrorUpdate && (
                            <p className="text text-danger">{error.response.data.errors.status?.map( x => x)}</p>
                        )
                    }

                </div>
                {
                    isLoadingRoles ? ( <i>Cargando roles...</i>):(
                        <div className="form-group">
                        <label className="my-1 fw-bold">Tipo usuario</label>
                        <Select 
                            key={2}
                            className="baisc-single"
                            classNamePrefix="select-type-user"
                            name="type"
                            defaultValue={
                                () => {
                                    let r = roles.filter( x => x.id == user.roles[0].id);
                                    return r.map(x => ({ value: x.id, label: x.name }));
                                }
                            }
                            options={ roles.map(x => ({ value: x.id, label: x.name })) }
                            onChange={ (e)  => setUser({...user, roles: roles.filter( r => r.id == e.value) }) }
                        />
                        {
                            isErrorUpdate && (
                                <p className="text text-danger">{error.response.data.errors.roles?.map( x => x)}</p>
                            )
                        }
                    </div>
                    )
                }
                {
                    isLoadingGeo ? (<i>Cargando paises...</i>) : (
                        <div className="form-group">
                            <label className="my-1 fw-bold">Pais</label>
                            <Select 
                                key={3}
                                className="baisc-single"
                                classNamePrefix="select-dashboardByCountry-user"
                                name="type"
                                defaultValue={ geographies.filter( x => x.value == user.dashboardByCountry) }
                                options={ geographies }
                                onChange={ (e)  => setUser({...user, dashboardByCountry: e.value }) }
                            />
                            {
                            isErrorUpdate && (
                                <p className="text text-danger">{error.response.data.errors.dashboardByCountry?.map( x => x)}</p>
                            )
                        }
                        </div>
                    )
                }
                <div className="form-group mt-2">
                    {
                        isLoadingEdit ? (<button className="btn btn-primary-oo">Cargando...</button>)
                        : (<button className="btn btn-primary-oo">Editar usuario</button>)
                    }
                </div>
            </form>
        </>
    )
}