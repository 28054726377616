import React from "react";
import ReactDOM from "react-dom/client";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "react-query";
import Swal from "sweetalert2";
import { updatePanelExternal } from "../../../api/sio/panelExternal";
import { App } from "../../../App";
import { usePanelExternal } from "../../../hooks/usePanelExternal";

export const EditPanelExternal = ({ id }) => {
    const { isLoading, panelExternal, setPanelExternal } = usePanelExternal(id);

    const {
        mutate: mEditPanelExternal,
        isLoading: isLoadingEdit,
        error,
        isError,
    } = useMutation(updatePanelExternal, {
        onSuccess: () => {
            Swal.fire(
                "Editar panel",
                "Se ha editado este panel",
                "success"
            ).then((result) => location.replace("/panel-externo"));
        },
        onError: (e) => {
            Swal.fire("Editar panel", "No se pudo editar este panel", "error");
        },
    });

    const handleEditPanelExternal = (e) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Quieres editar este panel?",
            showCancelButton: true,
            confirmButtonText: "Editar",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                mEditPanelExternal(panelExternal);
            } else if (result.isDenied) {
                Swal.fire(
                    "Editar panel",
                    "No se pudo editar el panel",
                    "error"
                );
            }
        });
    };

    return isLoading ? (
        <Skeleton height={200} />
    ) : (
        <div className="card">
            <div className="card-header">
                <div className="content-head-card">
                    <label>Editar panel</label>
                </div>
            </div>
            <div className="card-body">
                <form onSubmit={handleEditPanelExternal}>
                    <div className="form-group">
                        <label className="my-1 fw-bold">Nombre panel</label>
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="name"
                            value={panelExternal.name}
                            onChange={(e) =>
                                setPanelExternal({
                                    ...panelExternal,
                                    name: e.target.value,
                                })
                            }
                        />
                        {isError && (
                            <p className="text text-danger">
                                {error.response.data.errors.name?.map((x) => x)}
                            </p>
                        )}
                    </div>
                    <div className="form-group mt-2">
                        {isLoading ? (
                            <button className="btn btn-primary-oo">
                                Cargando...
                            </button>
                        ) : (
                            <button className="btn btn-primary-oo">
                                Editar panel
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

if (document.getElementById("jsx-edit-panelexternal")) {
    const el = document.getElementById("jsx-edit-panelexternal");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <EditPanelExternal {...props} />
        </App>
    );
}
