import axios from "axios";
import { axiosApi } from "../axiosApi";
export const getVersionsLime =  async () => {
    const { data } = await axiosApi.get(`/api/limesurvey`);
    return data;
}

export const createRefenceLime = async (lime) => {
    const { data } = await axiosApi.post('/api/limesurvey/crear', lime);
    return data;
}

export const editRefenceLime = async(lime) => {
    const { data } = await axiosApi.put('/api/limesurvey/editar', lime);
    return data;
}

export const getRefenceLime = async (id) => {
    const { data } = await axiosApi.get(`/api/limesurvey/version/${ id }`);
    return data;
}

export const getSurveys  = async () => {
    try {
        const response = await axiosApi.get('/api/limesurvey/encuestas');
        return response;
    } catch (error) {
        const { response } = error;
        throw  response;
    }
}

export const getSurvey  = async (project_id) => {
    try {
        const response = await axiosApi.get(`/api/limesurvey/encuesta/detalle/${ project_id }`);
        return response;
    } catch (error) {
        const { response } = error;
        throw  response;
    }
}