import { formatterNumber } from '../../../../helper/currencyFormatter';

export const QuarterTable = ({ responseQuarter }) => {

    return ( 
        <div className="table-responsive">
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>Trimestre/año</th>
                        <th>Panelistas</th>
                        <th>Completas</th>
                        <th style={{ width:'250px' }}>Tasa de completas</th>
                        <th>Filtradas</th>
                        <th>Parciales</th>
                        <th>Tasa base total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        responseQuarter.map((quarter,keyquarter) => {
                          const _sum = ((parseInt(quarter.completed) + parseInt(quarter.filtered) + parseInt(quarter.partial)) /  (parseInt(quarter.completed_total) + parseInt(quarter.filtered_total) + parseInt(quarter.totalLastPartial))*100).toFixed(2);
                            return (
                                <tr key={keyquarter}>
                                    <td>{ quarter.quarterName }</td>
                                    <td>{ formatterNumber(parseInt(quarter.completed)) }</td>
                                    <td>{ formatterNumber(parseInt(quarter.completed_total)) }</td>
                                    <td>{ ((parseInt(quarter.completed) / parseInt(quarter.completed_total))*100).toFixed(2)}%</td>
                                    <td>{ formatterNumber(parseInt(quarter.filtered)) }</td>
                                    <td>{ formatterNumber(parseInt(quarter.partial)) }</td>
                                    <td>{ _sum }%</td>
                                </tr>  
                           ) 
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

