import React  from 'react';
import ReactDOM from "react-dom/client";
import { App } from "../../../App";
import { AgesBarChart } from './charts/AgesBarChart';
import { GenderPieChart } from './charts/GenderPieChart';
import { RegisterLineChart } from './charts/RegisterLineChart';
import { GeoBarChart } from './charts/GeoBarChart';
import { NseBarChart } from './charts/NseBarChart';

export const DashboardMkt = ({ id }) => {
    return (
        <div className="card mt-2">
            <div className="card card-body">
                <div className='row'>
                    <div className="col-md-12" style={{ backgroundColor:'white', marginTop:'20px'}}>
                        <RegisterLineChart />
                    </div>
                    <div className="col-md-6" style={{ backgroundColor:'white', marginTop:'20px'}}>
                        <AgesBarChart id={id} />
                    </div>
                    <div className="col-md-6" style={{ backgroundColor:'white', marginTop:'20px'}}>
                        <GenderPieChart />
                    </div>
                    <div className="col-md-12" style={{ backgroundColor:'white', marginTop:'20px'}}>
                        <GeoBarChart />
                    </div>

                    <div className="col-md-12" style={{ backgroundColor:'white', marginTop:'20px'}}>
                        <NseBarChart />
                    </div>
                </div>
            </div>
        </div>
    )
}


if (document.getElementById("jsx-reports-mkt-dashbaord-campaigns-for-day")) {
    const el = document.getElementById("jsx-reports-mkt-dashbaord-campaigns-for-day");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <DashboardMkt {...props} />
        </App>
    );
}