import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAnswers } from '../../../../hooks/useAnswer';
import { MultipleOptionsQuestionBarChart } from '../question/charts/MultipleOptionsQuestionBarChart';
import { RankingQuestionStackedChart } from '../question/charts/RankingQuestionStackedChart';
import { TextQuestion } from '../question/charts/TextQuestion';
import { UniqueQuestionPieChart } from '../question/charts/UniqueQuestion';



export const AnswersModal = ({ id, hash, question, seeModal, setSeeModal }) => {
    const { isLoading, answers, typeQuestion  } = useAnswers({...question, projectId:id, hash:hash });

    return (
        <>
        {
            isLoading ? (<Skeleton height={300} />) 
            : (
                <div className="modal modal-question">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{question.question.length == 0 ? question.title : question.question}</h5>
                                <button  className="close close-modal-btn" data-dismiss="modal" aria-label="Close" onClick={ () => setSeeModal(!seeModal)} >
                                    <i className="fa fa-times-circle"></i>
                                </button>
                            </div>
                            <div className="modal-body" style={{ height:'100vh'}}>
                                { ["uniqueQuestion"].includes(typeQuestion) && ( <UniqueQuestionPieChart data = { answers } /> ) }
                                { ["multipleOptionsQuestion"].includes(typeQuestion) && (<MultipleOptionsQuestionBarChart data = { answers } />)}
                                { ["rankingQuestion"].includes(typeQuestion) && (<RankingQuestionStackedChart data = { answers } />)}
                                { ["textQuestion"].includes(typeQuestion) && (<TextQuestion data = { answers } />)}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        </>

    )
}