import React, { useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import { getUsers } from '../../../api/sio/user';
import { App } from '../../../App';

import { useQuery} from 'react-query';
import {MaterialReactTable} from 'material-react-table';
import { ButtonAction } from './form/ButtonAction';
import Skeleton from 'react-loading-skeleton';


export const User = () =>  {
    const  { isLoading, data } = useQuery( 'users', getUsers);
    const columns = useMemo(
        () => [
          {
            accessorKey: 'name', //access nested data with dot notation
            header: 'Nombre',
          },
          {
            accessorKey: 'email',
            header: 'Correo',
          },
          {
                accessorKey: 'roles',
                header: 'Tipo de usuario',
                Cell: ({ cell }) => cell.getValue().map(x => <span key={x.id}>{x.name}</span>)
          },
          {
            accessorKey: 'status',
            header: 'Estado',
            Cell: ( { cell }) => {
                return cell.getValue() == 'active' ? 'Activo' : 'Desactivado'; 
            }
          },
          {
            header: 'Acciones',
            Cell: ( { cell, row }) => <ButtonAction user =  { cell.row.original } /> 
          },
        ],
        [],
      );
    
    

    return (
        <>
            {
                isLoading  ? ( <Skeleton height={400}/>)
                : (

                    <div className="card">
                        <div className="card-header">
                          <div className="content-head-card">
                            <label>Listar usuarios</label>
                            <a className="btn btn-primary-oo" href="/usuario/crear">Crear usuario</a>
                          </div>
                        </div>
                        <div className="card-body">
                             <MaterialReactTable 
                                columns={columns} 
                                data={data} 
                                muiTablePaperProps={{
                                    //customize paper styles
                                    sx: {
                                        boxShadow: 'none !important',
                                        border: 'none'
                                    },
                                  }}
                                />
                        </div>
                    </div>

                )
            }
        </>
    );
}

if (document.getElementById('jsx-user')) {
    const el = document.getElementById("jsx-user");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
          <User { ...props } />
      </App>
  )
}
