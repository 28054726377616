import React, { useContext, useState } from 'react';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DashboardContext } from '../../../context/DashboardContext';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { enGB } from 'date-fns/locale';


export const DatePickerFilter = () => {
    const [fromTo, setFromTo] = useState({
        date: {
            from: startOfMonth(new Date(),'yyyy-MM-dd'),
            to: new Date()
        },
    });
    const { filter, setFilter } = useContext(DashboardContext);
    const handleChangeDate = (type, value) => {
        if(type == 'from'){
            let _d = { ...fromTo.date, from: value }
            setFromTo({...fromTo, date: _d });
        }
        if(type == 'to'){
            let _d = {  ...fromTo.date, to: value }
            setFromTo({...fromTo, date: _d });
        }
    }

    const handleFilterData = (e) => {
            e.preventDefault();
            setFilter(fromTo);
    }
    console.log(fromTo);
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    gap: '20px',
                    flexWrap: 'wrap',
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Desde"
                        inputFormat="dd/MM/yyyy"
                        value={fromTo.date.from}
                        onChange={ e => handleChangeDate('from', e) }
                        renderInput={(params) => <TextField {...params} />}
                        />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns}> 
                    <DesktopDatePicker
                        label="Hasta"
                        inputFormat="dd/MM/yyyy"
                        value={fromTo.date.to}
                        onChange={ e => handleChangeDate('to', e) }
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <button className="btn btn-primary-oo text-light" onClick={ handleFilterData }>Filtrar</button> 
            </div>
        </>
    )
}