import { useCallback, useEffect, useMemo, useState } from "react";
import { ModalLayout } from "../../../ui/modal/ModalLayout";
import Select from 'react-select';
import { POINTS_STATUS_OPTIONS } from "../../../../helper/panelists";
import { usePanelistsStore } from "../../../../store/PanelistsInsight/usePanelistsStore";
import Swal from "sweetalert2";

export const AddPoints = ({ setSeeModal, panelist }) => {

    const [currentPoints, setCurrentPoints] = useState(0);

    const [selectStatus, setSelectStatus] = useState({
        options: POINTS_STATUS_OPTIONS,
        selected: POINTS_STATUS_OPTIONS.find(x => x.value == 'other')
    });

    const [addPoints, setAddPoints] = useState({
        token: panelist.token,
        points: 1,
        description: "",
        status: 'other',
    });

    const {
        fetchPanelistPoints,
        fetchAddPointsToPanelist,
        isErrorAddPoints,
        isLoadingGetPoints
    } = usePanelistsStore(state => state);

    const handleStatusChange = useCallback((e) => {
        setSelectStatus(prevSelectStatus => ({ ...prevSelectStatus, selected: e }));
        setAddPoints(prevAddPoints => ({ ...prevAddPoints, status: e.value }));
    }, []);

    const handlePointsChange = useCallback((e) => {
        setAddPoints(prevAddPoints => ({ ...prevAddPoints, points: e.target.value }));
    }, []);

    const handleDescriptionChange = useCallback((e) => {
        setAddPoints(prevAddPoints => ({ ...prevAddPoints, description: e.target.value }));
    }, []);

    const handleGetPanelistPoints = useCallback(async () => {
        fetchPanelistPoints(panelist.token)
            .then((points) => {
                setCurrentPoints(points);
            })
            .catch(() => {
                setCurrentPoints(null);
            });
    }, [panelist.token]);

    const handleSubmitForm = useCallback(async (e, addPoints) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Añadir puntos?",
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar"
        }).then((res) => {
            if (res.isConfirmed) {
                fetchAddPointsToPanelist(addPoints)
                    .then((resp) => {
                        if (resp && resp.status == 200) {
                            Swal.fire({
                                title: "Puntos añadidos",
                                icon: 'success'
                            });
                            setSeeModal(prevSeeModal => ({
                                ...prevSeeModal,
                                addPoints: !prevSeeModal.addPoints,
                            }));
                        }
                    });
            }
        });
    }, [panelist]);

    //Obtener puntos actuales del panelista
    useEffect(() => { handleGetPanelistPoints(); }, [panelist.token]);

    const AddPointsForm = useMemo(() => {
        return (
            <form onSubmit={(e) => handleSubmitForm(e, addPoints)}>
                <div className="row form-group my-3">
                    <div className="col-12 col-md-5">
                        <label htmlFor="name">Nombre</label>
                        <input
                            className="form-control"
                            name="name"
                            type="text"
                            defaultValue={panelist.name}
                            disabled
                        />
                    </div>
                    <div className="col-12 col-md-5">
                        <label htmlFor="email">Email</label>
                        <input
                            className="form-control"
                            name="email"
                            type="email"
                            defaultValue={panelist.email}
                            disabled
                        />
                    </div>
                    <div className="col-12 col-md-2">
                        <label htmlFor="email">Puntos</label>
                        <input
                            className="form-control"
                            name="points"
                            type="text"
                            value={isLoadingGetPoints ? '...' : currentPoints}
                            disabled
                        />
                    </div>
                </div>
                <div className="row form-group my-3">
                    <div className="col-12 col-md-6">
                        <label htmlFor="type">Categoría</label>
                        <Select
                            name="type"
                            options={selectStatus.options}
                            value={selectStatus.selected}
                            onChange={handleStatusChange}
                        />
                        {
                            isErrorAddPoints.status && (
                                <p className="text text-danger">{isErrorAddPoints.message.errors?.status?.map(x => x)}</p>
                            )
                        }
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="type">Añadir puntos</label>
                        <input
                            className="form-control"
                            name="points"
                            type="number"
                            min={1}
                            max={30000}
                            value={addPoints.points}
                            onChange={handlePointsChange}
                        />
                        {
                            isErrorAddPoints.status && (
                                <p className="text text-danger">{isErrorAddPoints.message.errors?.points?.map(x => x)}</p>
                            )
                        }
                    </div>
                </div>
                <div className="row form-group my-3">
                    <div className="col-12">
                        <label htmlFor="type">Descripción</label>
                        <input
                            className="form-control"
                            type="text"
                            name="description"
                            value={addPoints.description}
                            onChange={handleDescriptionChange}
                        />
                        {
                            isErrorAddPoints.status && (
                                <p className="text text-danger">{isErrorAddPoints.message.errors?.description?.map(x => x)}</p>
                            )
                        }
                    </div>
                </div>
                <div className="row form-group my-3">
                    <div className="col-12 d-flex justify-content-end">
                        <button
                            className="btn btn-primary-oo"
                        >
                            Añadir puntos
                        </button>
                    </div>
                </div>
            </form>
        );
    }, [
        panelist,
        addPoints,
        selectStatus,
        currentPoints,
        isLoadingGetPoints,
        isErrorAddPoints
    ]);

    return (
        <ModalLayout
            closeFunction={() => {
                setSeeModal(prevSeeModal => ({
                    ...prevSeeModal,
                    addPoints: !prevSeeModal.addPoints,
                }));
            }}
            title={"Añadir puntos"}
            header={""}
            body={AddPointsForm}
        />
    )
}