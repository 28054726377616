import { formatterNumber } from '../../../../helper/currencyFormatter';

export const MonthTable = ({ responseMonth }) => {

    return ( 
        <div className="table-responsive">
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>Mes/año</th>
                        <th>Panelistas</th>
                        <th>Completas</th>
                        <th style={{ width:'250px' }}>Tasa de completas</th>
                        <th>Filtradas</th>
                        <th>Parciales</th>
                        <th>Tasa base total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        responseMonth.map((month,keyMonth) => {
                          const _sum = ((parseInt(month.completed) + parseInt(month.filtered) + parseInt(month.partial)) /  (parseInt(month.completed_total) + parseInt(month.filtered_total) + parseInt(month.totalLastPartial))*100).toFixed(2);
                           return (
                                <tr key={keyMonth}>
                                    <td>{ month.monthName }</td>
                                    <td>{ formatterNumber(parseInt(month.completed)) }</td>
                                    <td>{ formatterNumber(parseInt(month.completed_total)) }</td>
                                    <td>{ ((parseInt(month.completed) / parseInt(month.completed_total))*100).toFixed(2)}%</td>
                                    <td>{ formatterNumber(parseInt(month.filtered)) }</td>
                                    <td>{ formatterNumber(parseInt(month.partial)) }</td>
                                    <td>{ _sum }%</td>
                                </tr>  
                           ) 
                        })
                    }
                </tbody>
                    
            </table>
        </div>
    )
}

