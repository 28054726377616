export const geo  = [
    { id: 1, name: 'Tarapacá', value: 2539 },
    { id: 2, name: 'Antofagasta', value: 4551 },
    { id: 3, name: 'Atacama', value: 2057 },
    { id: 4, name: 'Coquimbo', value: 5662 },
    { id: 5, name: 'Valparaíso', value: 14792 },
    { id: 6, name: 'Libertador General Bernardo OHiggins', value: 6476 },
    { id: 7, name: 'Maule', value: 7105 },
    { id: 8, name: 'Biobío', value: 11502 },
    { id: 9, name: 'La Araucanía', value: 6304 },
    { id: 10, name: 'Los Lagos', value: 5854 },
    { id: 11, name: 'Aysén del General Carlos Ibañez del Campo', value: 619 },
    { id: 12, name: 'Magallanes y de la Antártica Chilena', value: 983 },
    { id: 13, name: 'Metropolitana de Santiago', value: 120901 },
    { id: 14, name: 'Los Ríos', value: 3072 },
    { id: 15, name: 'Arica y Parinacota', value: 1771 },
    { id: 16, name: 'Ñuble', value: 2814 },
]