import React, { useEffect, useId, useState } from "react";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { usePanelExternalsByProject } from "../../../../hooks/usePanelExternal";
import TextField from "@mui/material/TextField";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/de";
import moment from "moment";
import { editPanelist } from "../../../../api/sio/project";
import { useMutation } from "react-query";
import Swal from "sweetalert2";
import {
    useProjectPanelist,
} from "../../../../hooks/useProject";

export const PanelistEdit = ({ seeModal, setSeeModal, project, token, refetch }) => {
    const keySelect = useId();
    const { isLoadingProjectPanelist, projectPanelist, setProjectPanelist } = useProjectPanelist(project.id, token);

    //Cargamos los paneles disponibles para configuración y seteamos opciones del Select
    const { isLoadingPanelExternalConfigs, panelExternalConfigs } = usePanelExternalsByProject(project.id);


    //create panelist
    const {
        mutate: mEditPanelist,
        isError: isErrorEditPanelist,
        error: errorEditPanelist,
    } = useMutation(editPanelist, {
        onSuccess: () => {
            Swal.fire(
                "Editar Panelista",
                `Se ha actualizado la información del panelista`,
                "success"
            ).then(() => {
                setSeeModal({
                    ...seeModal,
                    edit: !seeModal.edit,
                });
                refetch()
            });
        },
        onError: ({ data }) => {
            Swal.fire(
                "Editar Panelista",
                `Ha ocurrido un error, no se actualizó la información del panelista`,
                "error"
            );
        },
    });

    //OnSubmit handler
    const handleOnSubmit = (e) => {
        e.preventDefault();
        Swal.fire({
            title: `¿Quieres editar el panelista?`,
            showCancelButton: true,
            confirmButtonText: "Editar",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((result) => {
            result.isConfirmed
                ? mEditPanelist({...projectPanelist, project_id:project.id })
                : Swal.fire(
                      "Editar Panelista",
                      "No se ha editado el panelista",
                      "warning"
                  );

        });
    };

    return (
        <>
            <div className="modal modal-segmentation">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Editar panelista</h5>
                            <button
                                className="close close-modal-btn"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setSeeModal({
                                        ...seeModal,
                                        edit: !seeModal.edit,
                                    });
                                }}
                            >
                                <i className="fa fa-times-circle"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            {isLoadingProjectPanelist ? (
                                <Skeleton height={400} />
                            ) : (
                                <form onSubmit={(e) => handleOnSubmit(e)}>
                                    <div className="row mb-3">
                                        <div className="form-group col-md-6">
                                            <label className="my-1 fw-bold">
                                                Seleccionar un panel
                                            </label>
                                            {
                                                isLoadingPanelExternalConfigs ? (<Skeleton height={100}/>) : (
                                                    <>
                                                        <Select
                                                            key={ keySelect }
                                                            name="panel"
                                                            className="basic-select"
                                                            options={ [
                                                                ...panelExternalConfigs.map( x => ({ value:x.name, label:x.name})),
                                                            {
                                                                value: "TEST",
                                                                label: "PRUEBA",
                                                            },{
                                                                value: "OPINANDO",
                                                                label: "OPINANDO"
                                                            }]}
                                                            value= {  { value: projectPanelist.panel, label: projectPanelist.panel  == 'TEST' ? 'PRUEBA' : projectPanelist.panel }  }
                                                            onChange={(e) => {
                                                                setProjectPanelist({
                                                                    ...projectPanelist,
                                                                    panel: e.value,
                                                                });
                                                            }}
                                                        />
                                                        {isErrorEditPanelist && (
                                                            <p className="text text-danger">
                                                                {errorEditPanelist.data.errors?.panel?.map(
                                                                    (x) => x
                                                                )}
                                                            </p>
                                                        )}
                                                    </>
                                                )
                                            }
                                            
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="my-1 fw-bold">
                                                Fecha de expiración
                                            </label>
                                            <LocalizationProvider
                                                dateAdapter={AdapterMoment}
                                                adapterLocale="de"
                                            >
                                                <DateTimePicker
                                                    ampm={false}
                                                    className="form-control"
                                                    inputFormat="YYYY-MM-DD HH:mm"
                                                    value={projectPanelist.expired_at}
                                                    onChange={(e) => {
                                                        setProjectPanelist({
                                                            ...projectPanelist,
                                                            expired_at: moment(
                                                                e
                                                            )
                                                                .utc(true)
                                                                .format(
                                                                    "YYYY-MM-DD HH:mm"
                                                                ),
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            size="small"
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            {isErrorEditPanelist && (
                                                <p className="text text-danger">
                                                    {errorEditPanelist.data.errors?.expired_at?.map(
                                                        (x) => x
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-group col-md-12">
                                        <button
                                            type="submit"
                                            className="btn btn-primary-oo"
                                        >
                                            Editar panelista
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
