import { create }  from 'zustand';
import { fetchQuestions, fetchSearchData } from '../../api/dashboard/profiles';

const LOADING = false;
const ERRORS = { questions: [] };
export const  DATA_SELECT = [];
export const RESPONSE_DATA = {
    data: [],
    totals: 0,
};
export const useBoundProfile = create( (set, get) => {
    return {
        dataSelect: DATA_SELECT,
        responseData: RESPONSE_DATA,
        loading: LOADING,
        errors: ERRORS,
        questions: [],
        setter: (setter) => set(setter),
        // obtenemos las preguntas
        fetchQuestions: async () => {
            try{
                const response = await fetchQuestions();
                const { data } = response;

                const groupingData = data.reduce((a, b) => {
                    const profile =  b.profile_id || 0; 
                    if( !a[profile] ){
                        a[profile] = {
                            "profile_id": b.profile_id,
                            "profile_title": b.profile_title,
                            "profile_country": b.profile_country,
                            "profile_status": b.profile_status,
                            "profile_type": b.profile_type,
                            "profile_survey_id": b.profile_survey_id,
                            "profile_versionLime_id": b.profile_versionLime_id,
                            "profile_platform": b.profile_platform,
                            "profile_refenceDB": b.profile_refenceDB,
                            "profile_status_refence": b.profile_status_refence,
                            label: b.profile_title, 
                            options: []
                        }
                    }
                    let _options = { ...b };
                    // acá cuando la data demográfica sea aplicada, se debe gestionar esta información en el selector
                    if(b.type == 'demografico'){
                        _options = { 
                            ..._options,  
                            'label': `${ b.question }`,
                            'value': `${b.value}`,
                        }
                    }else{
                        _options = {
                            ..._options,
                            'label': ` [${ b.type }] - ${ b.question } ${b.answer.length == 0  ? '' : `(${b.answer})`}`,
                            'value': `${b.column}`,
                            'questionKey': b.profile_id,
                            'versionLime': b.versionLime
                        };
                    }
                   
                    a[profile].options.push({ ..._options });
                    return a;
                },[]);
                set({ 
                    questions: groupingData
                })
            }catch(error){  
                console.log('error', error);
            }   
        },
        // generar busqueda
        fetchSearchData : async () => {
            set({  responseData:RESPONSE_DATA, loading:true });
            try{
               const response = await fetchSearchData(get().dataSelect);
               const { data } = response;
               let _data = {
                    data, 
                    totals: data.reduce((a, r) => a + r.count, 0),
                    firstSegmentation: get().dataSelect[0].value
               }
               set({ responseData: { ..._data }, loading:false });
            }catch(error){  
                const response = error;
                set({ responseData:RESPONSE_DATA, loading:false });
            }   
        }
    }
})