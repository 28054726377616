import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from '../../../App';
import {CreateUserForm} from './form/CreateUserForm';

export const CreateUser = () => {

    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-lg-12 mt-2">
                    <div className="card">
                        <div className='card-header'>
                            <div className="content-head-card">
                                <label>Crear usuario</label>
                            </div>
                        </div>
                        <div className='card-body'>
                            <CreateUserForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

if (document.getElementById('jsx-create-user')) {
    const el = document.getElementById("jsx-create-user");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
          <CreateUser { ...props } />
      </App>
  )
}
