import { formatterNumber } from '../../../../helper/currencyFormatter';

export const SemesterTable = ({ responseSemester }) => {

    return ( 
        <div className="table-responsive">
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>Semestre/año</th>
                        <th>Panelistas</th>
                        <th>Completas</th>
                        <th style={{ width:'250px' }}>Tasa de completas</th>
                        <th>Filtradas</th>
                        <th>Parciales</th>
                        <th>Tasa base total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        responseSemester.map((semester,keysemester) => {
                            const _sum = ((parseInt(semester.completed) + parseInt(semester.filtered) + parseInt(semester.partial)) /  (parseInt(semester.completed_total) + parseInt(semester.filtered_total) + parseInt(semester.totalLastPartial))*100).toFixed(2);
                            return (
                                <tr key={keysemester}>
                                    <td>{ semester.semesterName }</td>
                                    <td>{ formatterNumber(parseInt(semester.completed)) }</td>
                                    <td>{ formatterNumber(parseInt(semester.completed_total)) }</td>
                                    <td>{ ((parseInt(semester.completed) / parseInt(semester.completed_total))*100).toFixed(2)}%</td>
                                    <td>{ formatterNumber(parseInt(semester.filtered)) }</td>
                                    <td>{ formatterNumber(parseInt(semester.partial)) }</td>
                                    <td>{ _sum }%</td>
                                </tr>  
                           ) 
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

