import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { ExportToCsv } from "export-to-csv";
import {MaterialReactTable} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { DateRangePicker } from "./DateRangePicker";
import { useFilterPotentialBase } from "../../../hooks/useFilterPotentialBase";
import Skeleton from "react-loading-skeleton";
import { useState } from "react";
import { createRoot } from "react-dom/client";
import { App } from "../../../App";
import {
    endOfWeek,
    intlFormatDistance,
    startOfMonth,
    startOfWeek,
    sub,
} from "date-fns";

const initialRangeState = {
    from: null,
    to: null,
};

export const PotentialBase = () => {
    const [range, setRange] = useState(initialRangeState);
    const [isFiltering, setIsFiltering] = useState(false);
    const today = new Date();
    const {
        panelist,
        isLoading: isLoadingPotentialBase,
        isError: isErrorPotentialBase,
        error: errorPotentialBase,
        getPotentialBase,
    } = useFilterPotentialBase(range, isFiltering);

    const columns = useMemo(
        () => [
            {
                accessorKey: "nombre", //access nested data with dot notation
                header: "Nombre",
            },
            {
                accessorKey: "email",
                header: "Email",
            },
            {
                accessorKey: "token",
                header: "Token",
            },
            {
                accessorKey: "last_interaction",
                header: "Última interacción",
                Cell: ({ cell }) =>
                    intlFormatDistance(new Date(cell.getValue()), new Date()),
            },
            {
                accessorKey: "pais",
                header: "País",
            },
        ],
        []
    );

    const configCsvOptions = () => {
        const csvOptions = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: uuidv4(),
        };
        const csvExporter = new ExportToCsv(csvOptions);
        return csvExporter;
    };

    const handleExportData = () => {
        configCsvOptions().generateCsv(panelist);
    };

    const handleExportRows = (rows) => {
        configCsvOptions().generateCsv(rows.map((row) => row.original));
    };

    const handleChangeRange = (e) => {
        setRange(e);
    };

    const handleRangePresets = (preset) => {
        switch (preset) {
            case "reset":
                setRange({ from: null, to: null });
                break;
            case "today":
                setRange({
                    from: today,
                    to: today,
                });
                break;
            case "thisWeek":
                setRange({
                    from: startOfWeek(today, { weekStartsOn: 1 }),
                    to: endOfWeek(today, { weekStartsOn: 1 }),
                });
                break;
            case "lastWeek":
                setRange({
                    from: sub(startOfWeek(today, { weekStartsOn: 1 }), {
                        weeks: 1,
                    }),
                    to: sub(endOfWeek(today, { weekStartsOn: 1 }), {
                        weeks: 1,
                    }),
                });
                break;
            case "last7":
                setRange({ from: sub(today, { days: 6 }), to: today });
                break;
            case "thisMonth":
                setRange({ from: startOfMonth(today), to: today });
                break;
            case "active":
                setRange({ from: sub(today, { years: 1 }), to: today });
                break;
            case "semiactive":
                setRange({
                    from: sub(today, { years: 2 }),
                    to: sub(today, { years: 1, days: 1 }),
                });
                break;
            default:
                setRange({ from: null, to: null });
                break;
        }
    };

    const handleIsFiltering = () => {
        setIsFiltering(!isFiltering);
    };

    return (
        <div className="card">
            <div className="card-header">
                <div className="content-head-card">
                    <label>Base potencial</label>
                </div>
            </div>
            <div className="card-body">
                {isErrorPotentialBase && (
                    <div className="alert alert-danger mx-2" role="alert">
                        Error: {errorPotentialBase}
                    </div>
                )}
                {isLoadingPotentialBase ? (
                    <Skeleton height={200} />
                ) : (
                    <MaterialReactTable
                        localization={MRT_Localization_ES}
                        enableRowSelection
                        columns={columns}
                        data={isErrorPotentialBase ? [] : [...panelist]}
                        initialState={{ density: "compact" }}
                        enableDensityToggle={false}
                        enableColumnActions={false}
                        enableColumnFilters={true}
                        enableSorting={true}
                        enableTopToolbar={true}
                        muiTableBodyRowProps={{ hover: true }}
                        positionToolbarAlertBanner="bottom"
                        renderTopToolbarCustomActions={({ table }) => {
                            return (
                                <>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        <button
                                            className="btn btn-primary-oo text-light"
                                            onClick={handleExportData}
                                            disabled={panelist == 0}
                                        >
                                            <i className=" p-1 fas fa-download"></i>
                                            Descargar base
                                        </button>
                                        <button
                                            className="btn btn-primary-oo text-light"
                                            onClick={() =>
                                                handleExportRows(
                                                    table.getSelectedRowModel()
                                                        .rows
                                                )
                                            }
                                            disabled={
                                                !table.getIsSomeRowsSelected() &&
                                                !table.getIsAllRowsSelected()
                                            }
                                        >
                                            <i className=" p-1 fas fa-download"></i>
                                            Descargar base seleccionada
                                        </button>
                                        <DateRangePicker
                                            getPotentialBase={getPotentialBase}
                                            handleChangeRange={
                                                handleChangeRange
                                            }
                                            handleRangePresets={
                                                handleRangePresets
                                            }
                                            range={range}
                                            handleIsFiltering={
                                                handleIsFiltering
                                            }
                                            errorMessage={errorPotentialBase}
                                            isError={isErrorPotentialBase}
                                        ></DateRangePicker>
                                    </div>
                                </>
                            );
                        }}
                    />
                )}
            </div>
        </div>
    );
};

if (document.getElementById("jsx-potentialbase-index")) {
    const el = document.getElementById("jsx-potentialbase-index");
    const root = createRoot(el);
    const props = Object.assign({}, el.dataset);
    root.render(
        <App>
            <PotentialBase {...props} />
        </App>
    );
}