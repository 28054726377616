import React, { useState }  from 'react';
import { useGetTotalPanel } from '../../../hooks/usePanel';
import Skeleton from 'react-loading-skeleton';

export const TotalRegister = ({ registers }) => {

    const [totalRegisteredDate, setTotalRegisteredDate] = useState( registers.reduce((accumulator,currentValue) => accumulator + currentValue.registered, 0));
    const [totalProfiledDate, setTotalProfiledDate] = useState( registers.reduce((accumulator,currentValue) => accumulator + currentValue.profiled, 0));
    const { isLoading, panel, setPanel } = useGetTotalPanel();

    return ( 
            <div className="card card-body">
                <div className="total_panel" >
                    {
                        isLoading ? ( <Skeleton height={200} />):(
                            <div className="total_panel__item" style={{
                                backgroundColor:"rgb(129, 212, 250)"
                            }}>
                                <div>
                                    <p>Registrados</p>
                                    <p>{totalRegisteredDate}/{panel.totalRegister}</p>
                                </div>
                                <div>
                                    <i className="fa-solid fa-users"></i>
                                </div>
                            </div>
                        )
                    }
                    {
                        isLoading ? ( <Skeleton height={200} />) : (
                            <div className="total_panel__item" style={{
                                backgroundColor:"rgb(165, 214, 167)"
                            }}>
                                <div>
                                    <p>Perfilados</p>
                                    <p>{totalProfiledDate}/{panel.totalProfiled}</p>
                                </div>
                                <div>
                                    <i className="fa-solid fa-users-viewfinder"></i>
                                </div>
                            </div>
                        )
                    }
                    {
                        isLoading ? ( <Skeleton height={200} />) : (
                            <div className="total_panel__item" style={{
                                backgroundColor:"rgb(244, 143, 177)"
                            }}>
                                <div>
                                    <p>Tasa de perfilados</p>
                                    <p>{Math.round((totalProfiledDate/totalRegisteredDate)*100,2)}%</p>
                                </div>
                                <div>
                                    <i className="fa-solid fa-percent"></i>
                                </div>
                            </div>
                        )
                    }

                    
                </div>
            </div>
    )
}