import React  from 'react';
import { useContext } from 'react';
import {Bar, BarChart, CartesianGrid, Cell, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { geo } from '../../../api/data/geo';
import { COLORS } from '../../../helper/COLOR';
import { DashboardContext } from '../../../context/DashboardContext';
import { useGeographiesWithLevelM } from '../../../hooks/useGeoDashboard';
import Skeleton from 'react-loading-skeleton';


const CustomBar = (props) => {
    const { x, y, width, height, fill, payload } = props;
    return (
        <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        stroke="#e2e2e2" // Color del borde
        strokeWidth={1} // Ancho del borde
        radius={[10,10,0,0]}
        />
    );
}; 

const CustomTooltip = ({ active, payload, label,totals }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
            backgroundColor:"#FFF",
            padding:"10px",
            borderRadius:"10px",
            // boxShadow:'1px 1px 3px 1px #7E57C2',
            boxShadow:  '#CFD8DC 0px 1px 7px 0px',
            width:'300px'
            }}>
            <p style={{ color:"#3d3d3d", textAlign:'center'}}><strong>{ label }</strong></p>
            <hr></hr>
            <p style={{ color:`${payload[0].color}`}}>{ payload[0].name }: {`${payload[0].value} (${((payload[0].value/totals)*100).toFixed(2)}%)`}</p>
        </div>
      );
    }
  
    return null;
  };
export const GeoBarChart = () => {
    const {filter} = useContext(DashboardContext);
    const { isLoadingM, geographies, mutate  } = useGeographiesWithLevelM(filter,undefined);
    const maxValue = Math.max(
        ...geographies.map(item => Math.max(parseInt(item.total)))
    );
    return (
        <>
        {
            isLoadingM && !geographies.length ? ( <Skeleton height={400}/> ):(
                !geographies.length  ? ( <div className="alert__not-data">No se pudo encontrar la información <strong>GEOGRÁFICA</strong>, por favor intenta nuevamente modificando la fecha o refrescando la página</div>)
                   : ( <>
                        {/* <div style={{
                            display:'flex',
                            justifyContent:'end',
                            gap: '10px',
                            fontSize:'12px',
                            alignItems:'center',
                            margin: '0.2rem'
                        }}>
                            <a><i className="fas fa-filter"></i></a>
                        </div>   */}
                    <ResponsiveContainer  width="100%" height={500}>
                        <BarChart
                        width={500}
                        height={300}
                        data={geographies}
                        >
                        <CartesianGrid strokeDasharray="3 3" stroke='transparent' />
                            <XAxis  
                            dataKey="name" 
                            textAnchor= "end" 
                            sclaeToFit="true" 
                            verticalAnchor= "start"  
                            interval={0} 
                            angle= "-40"  
                            height={200} 
                            style={{fontSize:'12px'}} 
                            axisLine={false} // Elimina la línea del eje Y
                            tickLine={false} 
                            />
                            <YAxis 
                            type="number"
                            domain={[0, dataMax => maxValue * 1.5]} tickCount={10} 
                            allowDataOverflow={true}    
                            axisLine={false} // Elimina la línea del eje Y
                            tickLine={false} // Elimina las líneas de las marcas en el eje Y 
                            />
                            {/* <Tooltip cursor={{fill: 'transparent'}}/> */}
                            <Tooltip cursor={{fill: 'transparent'}} content={(props) => <CustomTooltip {...props} totals={geographies.reduce((a,c)=> a  + c.total,0)}/>}/>
                            <Legend iconType='circle' wrapperStyle={{paddingTop: "3.2rem"}} />
                            <Bar  dataKey="total" fill="rgb(33, 150, 243)" name={geographies[0]?.label} barSize={40} shape={<CustomBar />}>
                                {geo.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % geographies.length]}/>))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </>)
            )
        }
        </>
    )
}