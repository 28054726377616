import { format } from "date-fns";
import { axiosApi } from "../axiosApi";

export const getProductionsReport = async (data) => {

    let url = new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/proyectos`);
    url.searchParams.set('from_at', format(data.from,'yyyy-MM-dd'));
    url.searchParams.set('to_at', format(data.to,'yyyy-MM-dd'));
    url.searchParams.set('status', data.status);
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response }= error;
        throw response;
    }
}

export const getProductionOfMonth = async (preset,status) => {
    let _s = status.map(m => m.value).toString();
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/proyectos/produccion/mes`);
    url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
    url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    url.searchParams.set('status', _s);
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const getProductionOfQuarter = async (preset,status) => {
    let _s = status.map(m => m.value).toString();
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/proyectos/produccion/trimestre`);
    url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
    url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    url.searchParams.set('status', _s);
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const getProductionOfSemester = async (preset,status) => {
    let _s = status.map(m => m.value).toString();
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/proyectos/produccion/semestre`);
    url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
    url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    url.searchParams.set('status', _s);
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const getProductionOfYear = async (preset,status) => {
    let _s = status.map(m => m.value).toString();
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/proyectos/produccion/anho`);
    url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
    url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    url.searchParams.set('status', _s);
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}