import { useEffect, useState } from "react";

export const ModalLayout = ({ closeFunction, title = "", body, header, modalClass = 'modal-segmentation' }) => {
    const [hideBodyOverflow,setHideBodyOverflow] = useState(true);
    const handleHideBodyOverFlow = () => {
        setHideBodyOverflow(!hideBodyOverflow)
    }
    useEffect(() => {
        if (hideBodyOverflow) {
            document.body.style.overflow = 'hidden'; // Deshabilitar el scroll al abrir el modal
        } else {
            document.body.style.overflow = 'auto'; // Habilitar el scroll al cerrar el modal
        }
        return () => {
            document.body.style.overflow = 'auto'; // Restaurar el scroll si el componente es desmontado
        };
    }, [hideBodyOverflow]);
    return (
        <>
            <div className={`modal ${modalClass}`}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header justify-content-between">
                            <h5 className="modal-title">{title}{header}</h5>
                            <button
                                className="close close-modal-btn"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    closeFunction();
                                    handleHideBodyOverFlow();
                                }}
                            >
                                <i className="fa fa-times-circle"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            {body}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}