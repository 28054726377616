import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { checkAttribute14, getAssociatedPanels, getDailyProject, getItemsProject, getPanelExternalProject, getPanelist, getPanelists, getProject, getProjects, getProjectsOrder, getQuotasProject } from "../api/sio/project";


export const useGetProjectsOrder = () => {
    const [ projects, setProjects] = useState([]);
    const { isLoading, isError, error, fetch } = useQuery(['getProjectsOrder'],() => getProjectsOrder(), {
        onSuccess: (response) => {
            const { data } = response;
            setProjects(data);
        }
    });
    return {
        isLoading, isError, error, projects,setProjects, fetch
    }
}


export const useGetProjects = () => {
    const [ projects, setProjects] = useState([]);
    const { isLoading, isError, error, fetch } = useQuery(['getProjects'],() => getProjects(), {
        onSuccess: (response) => {
            const { data } = response;
            setProjects(data);
        }
    });
    return {
        isLoading, isError, error, projects,setProjects, fetch
    }
}

export const useItemsProject = (id, hash) => {
    const [ items, setItems ] = useState([]);
    const { isLoading, isError, error, refetch } = useQuery(['itemsProject', id], () => getItemsProject(id, hash),{
        onSuccess: (response) => {
            const { data } = response;
            setItems(data);
        }
    })
    return { isLoading, isError, error, refetch, items, setItems };
};

export const useDailyProject = (id, hash ) => {
    const [ daily, setDaily ] = useState([]);
    const { isLoading, isError, error, refetch } = useQuery(['dailyProject', id], () => getDailyProject(id, hash),{
        onSuccess: (response) => {
            const { data } = response;
            console.log(data);
            setDaily(data);
        }
    });
    return { isLoading, isError, error, refetch, daily, setDaily };
};

export const usePanelExternalProject = (id, hash) => {
    const [panel, setPanel] = useState([]);
    const { isLoading, isError, error, refetch} = useQuery(['panelExternalProject', id], () => getPanelExternalProject(id, hash), {
        onSuccess: (response) => {
            const { data } = response;
            setPanel(data);
        }
    });
    return { isLoading, isError, error, refetch, panel, setPanel };
};

export const useQuotasProject = (id, hash) => {
    const [quota, setQuota] = useState([]);
    const { isLoading, isError, error, refetch} = useQuery(['quotasProject', id], () => getQuotasProject(id, hash), {
        onSuccess: (response) => {
            const { data } = response;
            setQuota(data);
        }
    });
    return { isLoading, isError, error, refetch, quota, setQuota };
};

export const useProject = (id) => {
    const [project, setProject] = useState([]);
    const { isLoading, isError, error, refetch } = useQuery(["getProject"],() => getProject(id),{
            onSuccess: ({ data }) => setProject(data),
    });
    return { isLoading, isError, error, refetch, project, setProject };
};

export const useProjectPanelists = (projectId, enabled = true) => {
    const {
        isLoading,
        refetch,
        data,
        isError,
        error,
        isFetching
    } = useQuery([`getProjectPanelists`, projectId], () => getPanelists(projectId), { enabled: enabled })
    return {
        isLoading,
        isErrorProjectPanelists:isError,
        ErrorProjectPanelists:error,
        refetch,
        isFetching,
        projectPanelists:data,
    };
};

export const useProjectPanelist = (projectId, token) => {
    const [projectPanelist, setProjectPanelist] = useState({});
    const {
        isLoading: isLoadingProjectPanelist,
        refetch,
        isError: isErrorProjectPanelist,
        error: ErrorProjectPanelist,
    } = useQuery(["getPanelist",projectId], () => getPanelist(projectId, token), {
        onSuccess: (data) => setProjectPanelist(data),
    });
    return {
        isLoadingProjectPanelist,
        isErrorProjectPanelist,
        ErrorProjectPanelist,
        refetch,
        projectPanelist,
        setProjectPanelist
    };
};


export const usePanels  = (projectId) => {
    const [ panels, setPanels ] = useState([]);
    const [ selectPanel, setSelectPanel ] = useState(undefined);
    const getPanels = useCallback( async () => {
        try{
            const response = await getAssociatedPanels(projectId);
            const { data }  =  response;
            let _panels = data.map(panel => ({value: panel.panel, label: panel.panel}));
            _panels = [{ value:'ALL', label: 'TODO'}, ..._panels];
            setPanels(_panels);
        }catch( error){
            const { response } = error;
            console.warn(response);
        }
    },[projectId]);
    
    useEffect( () => {
        getPanels();
    },[]);

    return { panels, setPanels, getPanels,selectPanel,setSelectPanel}
}

export const useCheckAttr14 = (id) => {
    const [checkAttr14, setCheckAttr14] = useState(false);
    const { isLoading, isError, error, refetch } = useQuery(["checkAttribute14",id],() => checkAttribute14(id),{
            onSuccess: ({ data }) => { data.status == "ok" ? setCheckAttr14(true) : setCheckAttr14(false) },
    });
    return { isLoading, isError, error, refetch, checkAttr14 };
};
