import React, { useContext }  from 'react';
import {Bar, BarChart, CartesianGrid, Cell, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { COLORS } from '../../../helper/COLOR';
import { DashboardContext } from '../../../context/DashboardContext';
import Skeleton from 'react-loading-skeleton';
import { useAgesM } from '../../../hooks/useAgeDashboard';


const CustomBar = (props) => {
    const { x, y, width, height, fill, payload } = props;
    return (
        <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        stroke="#e2e2e2" // Color del borde
        strokeWidth={1} // Ancho del borde
        radius={[0,10,10,0]}
        />
    );
}

const CustomTooltip = ({ active, payload, label,totals }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
            backgroundColor:"#FFF",
            padding:"10px",
            borderRadius:"10px",
            // boxShadow:'1px 1px 3px 1px #7E57C2',
            boxShadow:  '#CFD8DC 0px 1px 7px 0px',
            width:'300px'
            }}>
            <p style={{ color:"#3d3d3d", textAlign:'center'}}><strong>{ label }</strong></p>
            <hr></hr>
            <p style={{ color:`${payload[0].color}`}}>{ payload[0].name }: {`${payload[0].value} (${((payload[0].value/totals)*100).toFixed(2)}%)`}</p>
        </div>
      );
    }
  
    return null;
};


export const AgesBarChart = () => {
    const {filter} = useContext(DashboardContext);
    const { isLoading, ages } = useAgesM(filter);

    const maxValue = Math.max(
        ...ages.map(item => Math.max(parseInt(item.total)))
    );
    
    return (
        <>
            {
                isLoading && !ages.length ? (<Skeleton height={400}/>) : (
                    !ages.length ? (<div className="alert__not-data">No se pudo encontrar la información <strong>EDADES</strong>, por favor intenta nuevamente modificando la fecha o refrescando la página</div>) :(
                        <ResponsiveContainer  width="100%" height={300}>
                            <BarChart
                            width={500}
                            height={300}
                            data={ages}
                            layout='vertical'
                            >
                            <CartesianGrid strokeDasharray="3 3"  stroke='transparent'/>
                                {/* <XAxis dataKey="total"  type="number"/>
                                <YAxis dataKey="ages"  type="category" /> */}

                                <XAxis dataKey="total"  type="number"
                                    allowDataOverflow={true}    
                                    axisLine={false} // Elimina la línea del eje Y
                                    tickLine={false} 
                                />
                                <YAxis dataKey="ages"  type="category" 
                                    domain={[0, dataMax => maxValue * 1.5]} tickCount={10} 
                                    allowDataOverflow={true}    
                                    axisLine={false} // Elimina la línea del eje Y
                                    tickLine={false} 
                                
                                />
                                {/* <Tooltip cursor={{fill: 'transparent'}}/> */}
                                <Tooltip cursor={{fill: 'transparent'}} content={(props) => <CustomTooltip {...props} totals={ages.reduce((a,c)=> a  + c.total,0)}/>}/>
                                <Legend iconType='circle'/>
                                <Bar 
                                    dataKey="total" 
                                    // fill="#42A5F5" 
                                    fill="#8884d8"
                                    name="Tramos de edad"
                                    shape={<CustomBar />}
                                    >
                                    {ages.map((entry, index) => (
                                        <Cell 
                                            key={`cell-${index}`} 
                                            // fill="#81D4FA"
                                            fill={COLORS[index % ages.length]} 
                                        />))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    )
                )
            }
        </>

    )
}