import { axiosApi } from "../axiosApi";

export const getFriendPoints = async () => {
    try {
        const response = await axiosApi.get(`/api/marketing/puntos-referidos`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const createFriendPoint = async (friendPoint) => {
    try {
        const { data } = await axiosApi.post("/api/marketing/puntos-referidos/crear", friendPoint);
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const updateFriendPoint = async (friendPoint) => {
    try {
        const { data } = await axiosApi.put("/api/marketing/puntos-referidos/editar", friendPoint);
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};