import { axiosApi } from "../axiosApi";

// obtener las preguntas 

export const fetchQuestions = async () => {
    try {
        const response = await axiosApi.get(`${import.meta.env.VITE_REACT_APP_URL}/dashboard/perfiles/preguntas`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
}

export const fetchSearchData = async (dataSelect) => {
    try{
        const response = await axiosApi.post(`/api/dashboard/perfiles/resultados`, { ...dataSelect })
        return response;
    }catch ( error ){
        const { response } = error;
        throw response;
    }
}