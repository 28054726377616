import { getCampaigns, getCampaign, getCampaignsForDay, registerCostCampaign} from "../../api/reports/campaigns";

const errorsConst = { status:false, message:'' }
export const useBoundCampaignReportStore = (set,get) => {
    return {
        // Obtenemos todas las campañas
        fetchCampaigns: async (preset = null) => {
            set( {
                loadings: { ...get().loadings, campaigns: true  },
                errors: { ...get().errors,  campaigns: errorsConst }
            });
            try{
                const { data, status } = await getCampaigns(get().preset);
                if(status == 204){
                    set( {
                        errors:  { status: true, message: 'No hay datos disponibles para la visulización'},
                        data: { ...get().data, campaigns: [] }
                    })
                }else{
                    set({ data: { ...get().data, campaigns: data }});
                }
                set({ loadings: { ...get().loadings, campaigns: false  }})
            }catch(error){
                console.log(error);
            }
        },

        // Obtenemos una camapaña
        fetchCampaign: async (id) => {
            set( {
                loadings: { ...get().loadings, campaign: true  },
                errors: { ...get().errors,  campaign: errorsConst }
            });
            try{
                const { data, status } = await getCampaign(id);
                if(status == 204){
                    set( {
                        errors:  { status: true, message: 'No hay datos disponibles para la visulización'},
                        data: { ...get().data, campaign: [] }
                    })
                }else{
                    set({ 
                        data: { ...get().data, campaign: data}, 
                        preset:{ 
                            from: new Date(data.min_at), 
                            to:get().preset.to 
                        }
                    });
                    get().fetchCampaignsForDay({ from: new Date(data.min_at), to:get().preset.to }, id);
                    get().fetchAges({
                        id: id,
                        preset : { 
                            from: new Date(data.min_at), 
                            to:get().preset.to 
                        }
                    });
                    get().fetchGenders({
                        id: id,
                        preset : { 
                            from: new Date(data.min_at), 
                            to:get().preset.to 
                        }
                    });
                    get().fetchFirstgeo({
                        id: id,
                        preset : { 
                            from: new Date(data.min_at), 
                            to:get().preset.to 
                        }
                    });
                    get().fetchProfiles({
                        id: id,
                        preset : { 
                            from: new Date(data.min_at), 
                            to:get().preset.to 
                        }
                    });
                    get().fetchSurveysCampaign({
                        id: id,
                        preset : { 
                            from: new Date(data.min_at), 
                            to:get().preset.to 
                        }
                    });
                    get().fetchNsesCampaign({
                        id: id,
                        preset : { 
                            from: new Date(data.min_at), 
                            to:get().preset.to 
                        }
                    });
                }
            set({ loadings: { ...get().loadings, campaign: false  }})
            }catch(error){
                console.log(error);
            }
        },

        // Obtenemos la información diaria de una campaña
        fetchCampaignsForDay: async (preset = null, id) => {
            set( {
                loadings: { ...get().loadings, daily: true  },
                errors: { ...get().errors,  daily: errorsConst }
            });
            try{
                const { data, status } = await getCampaignsForDay(preset != null ? preset : get().preset,  id);
                let totalRegister= data.reduce((a,b) => a + b.total,0);
                if(status == 204){
                    set( {
                        errors:  { status: true, message: 'No hay datos disponibles para la visulización'},
                        data: { ...get().data, daily: [], totalRegister:0 }
                    })
                }else{
                    set({ data: { ...get().data, daily: data}, totalRegister:totalRegister });
                }
            set({ loadings: { ...get().loadings, daily: false  }})
            }catch(error){
                console.log(error);
            }
        },

        // Registramos el costo de una campaña diaria.
        fetchRegisterCostCampaign: async(payload, table= null ) => {
            set( {
                loadings: { ...get().loadings, registerCost: true  },
                errors: { ...get().errors,  registerCost: errorsConst }
            });
            try{
                const { data, status } = await registerCostCampaign(payload);
                if(status == 204){
                    set( { errors:  { status: true, message: 'No hay datos disponibles para la visulización'} })
                }else{
                    table.setEditingRow(null);
                    get().fetchCampaignsForDay(null,payload.id);
                }
            set({ loadings: { ...get().loadings, registerCost: false  }})
            }catch(error){
                console.log(error);
            }
        }
    }
};