import React, { useContext, useState, useEffect} from 'react';
import Skeleton from 'react-loading-skeleton';
import { ItemsResultSurveyContext } from '../../../../context/ItemsResultSurveyContext';

export const ItemProgress = ({id}) => {

    const [ checkPerformance, setCheckPerformance] = useState(false);
    // const  { isLoading,items  } = useItemsProject(id);
    const {isLoading, items, hash } = useContext(ItemsResultSurveyContext);
    useEffect( () => {
        let params = new URL(window.location.href);
        if(params.searchParams.has('performance')){
            setCheckPerformance(params.searchParams.get('performance') == 'ok' ? true : false);
        }
    },[]);
    return ( 
        <div className="col-md-12 mt-4">
            <div className="card card-body">
                <div className="card-detail-project">
                    {
                        isLoading ? ( <Skeleton height={150} />):(
                            !hash && ( 
                                <div className="detail-project">
                                    <p><i className="fa-solid fa-database"></i></p>
                                    <div>
                                        <label>Base potencial</label>
                                        <p>{ items.totalBase }</p>
                                    </div>
                                </div>

                            )
                        )
                    }
                    {
                        isLoading ? ( <Skeleton height={150} />):(
                            <div className="detail-project">
                                <p><i className="fa-solid fa-ranking-star"></i></p>
                                    <div>
                                        <label>Completas</label>
                                        <p>{ items.completed }</p>
                                    </div>
                            </div>
                        )
                    }
                    {
                        isLoading ? ( <Skeleton height={150} />):(
                            <div className="detail-project">
                                <p><i className="fa-solid fa-filter-circle-xmark"></i></p>
                                    <div>
                                        <label>Filtradas</label>
                                        <p>{ items.filtered }</p>
                                    </div>
                            </div>
                        )
                    }
                    {
                        isLoading ? ( <Skeleton height={150} />):(
                            <div className="detail-project">
                                <p><i className="fa-regular fa-star-half-stroke"></i></p>
                                    <div>
                                        <label>Parciales</label>
                                        <p>{ items.parcial }</p>
                                    </div>
                            </div>
                        )
                    }
                    {
                        isLoading ? ( <Skeleton height={150} />):(
                            <div className="detail-project">
                                <p><i className="fa-solid fa-info"></i></p>
                                    <div>
                                        <label>Tasa de incidencia</label>
                                        <p>{ items.incidenceRate }%</p>
                                    </div>
                            </div>
                        )
                    }
                    {
                        isLoading ? ( <Skeleton height={150} />):(
                            (!hash || checkPerformance) && (
                                <div className="detail-project">
                                    <p><i className="fa-regular fa-registered"></i></p>
                                        <div>
                                            <label>Tasa de respuesta</label>
                                            <p>{ items.responseRate }%</p>
                                        </div>
                                </div>
                            )
                        )
                    }
                    {
                        isLoading ? ( <Skeleton height={150} />):(
                            (!hash || checkPerformance) && (
                                <div className="detail-project">
                                    <p><i className="fa-regular fa-clock"></i></p>
                                        <div>
                                            <label>T. promedio</label>
                                            <p>{ items.avg.toFixed(2) } min.</p>
                                        </div>
                                </div>
                            )
                        )
                    }
                    {
                        isLoading ? ( <Skeleton height={150} />):(
                            (!hash || checkPerformance) && (
                                <div className="detail-project">
                                    <p><i className="fa-regular fa-clock"></i></p>
                                        <div>
                                            <label>T. mediana</label>
                                            <p>{ items.median.toFixed(2) } min.</p>
                                        </div>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </div>

    )
}

