
import { Skeleton } from "@mui/material"
import { format, parseISO } from "date-fns"
import { useEffect, useState } from "react"
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts"
import { formatterNumber } from "../../../../helper/currencyFormatter"

export const KpiCardTwoAreaChart = ({ isLoading, kpiKey, data, dataKeys = ['a', 'b'], customColors = {} }) => {
    return isLoading ?
        <Skeleton variant="rounded" width={'90%'} height={130} sx={{ margin: '10px' }} />
        : <ResponsiveContainer className="mt-2" width="100%" height={130} >
            <AreaChart key={'two-area-chart' + kpiKey} data={data}>
                <defs>
                    <linearGradient key={dataKeys[0]} id={`color${dataKeys[0]}`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={'#8884d8'} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={'#8884d8'} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient key={dataKeys[1]} id={`color${dataKeys[1]}`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={'#d88488'} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={'#d88488'} stopOpacity={0} />
                    </linearGradient>
                </defs>
                <Tooltip
                    labelFormatter={(label, payload) => {
                        let newLabel = payload[0]?.payload?.label;
                        if (newLabel) {
                            newLabel = format(parseISO(newLabel), 'dd-MM-yyy');
                        }
                        return newLabel
                    }}
                    formatter={(value, name, entry) => {
                        let formattedName = name === 'completedSurveyPoints' ? 'Puntos encuestas completas' : 'Puntos registrados válidos';
                        return [formatterNumber(value || 0), formattedName];
                    }}
                />
                <Area
                    key={dataKeys[0]}
                    type="monotone"
                    dataKey={dataKeys[0]}
                    stroke={'#8884d8'}
                    fillOpacity={1}
                    fill={`url(#color${dataKeys[0]})`}
                />
                <Area
                    key={dataKeys[1]}
                    type="monotone"
                    dataKey={dataKeys[1]}
                    stroke={'#d88488'}
                    fillOpacity={1}
                    fill={`url(#color${dataKeys[1]})`}
                />
            </AreaChart >
        </ResponsiveContainer>
}