import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import Skeleton from 'react-loading-skeleton';
import { useMutation, useQuery } from 'react-query';
import Swal from 'sweetalert2';
import Select from 'react-select';

import { editRefenceLime, getRefenceLime } from '../../../api/sip/limesurvey';
import { App } from '../../../App';

const statusSelect = [{ value: 'enabled', label: 'Habilidata' }, { value: 'disabled', label: 'Deshabilidata'}];
export const EditLimeSurvey = ({ id }) => {
    const [lime, setLime] = useState({
        id: '',
        platform: '',
        version: '',
        refenceDB: '',
        status: '',
        url:''
    });

    const { isLoading, isError } = useQuery( ['user'], () => getRefenceLime(id),{ onSuccess:  ( data  ) => setLime(data) });
    const { mutate, error, isLoading:isLoadingEdit, isError:isErrorEdit} = useMutation( editRefenceLime ,{
        onSuccess: () => {
            Swal.fire('Editar referencia Limesurvey', 'Referencia editada', 'success').then( result => window.location.replace('/limesurvey'));
        },
        onError: () => Swal.fire('Error al editar referencia Limesurvey', 'No se pudo crear la referencia, por favor intenta más tarde o comunicate con el administrador', 'error')
    });


    const handleEditLimeSurvey = (e) => {
        e.preventDefault();
        Swal.fire({
            title: '¿Quieres editar esta referencia de Limesurvey?',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar',
            icon:'info'
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              mutate(lime);
            } else if (result.isDenied) {
               Swal.fire('No se pudo modificar los datos del usuario, intenta nuevamente', '', 'error') 
            }
          })
    }

    return (
        <div className="card">
        <div className="card-header">
        <div className="content-head-card">
            <label>Editar versión limesurvey</label>
        </div>
        </div>
        <div className="card-body">

            {
                isLoading || isError ?( <Skeleton height={400}/> ) :
                (
                    <form onSubmit={ handleEditLimeSurvey }>
                        <div className="form-group">
                            <label className="my-1 fw-bold">Nombre plataforma</label>
                            <input type="text" name="platform" className="form-control" id="platform-input" value={ lime.platform } onChange={ (e) => setLime({ ...lime, platform :e.target.value })}/>
                            {
                                isErrorEdit && (
                                    <p className="text text-danger">{error.response.data.errors.platform?.map( x => x)}</p>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label className="my-1 fw-bold">Versión plataforma</label>
                            <input type="text" name="version" className="form-control" id="version-input" value={ lime.version } onChange={ (e) => setLime({ ...lime, version :e.target.value })}/>
                            {
                                isErrorEdit && (
                                    <p className="text text-danger">{error.response.data.errors.version?.map( x => x)}</p>
                                )
                            }
                        </div>    
                        <div className="form-group">
                            <label className="my-1 fw-bold">Referencia base de datos</label>
                            <input type="text" name="refenceDB" className="form-control" id="refenceDB-input" value={ lime.refenceDB } onChange={ (e) => setLime({ ...lime, refenceDB :e.target.value })}/>
                            {
                                isErrorEdit && (
                                    <p className="text text-danger">{error.response.data.errors.refenceDB?.map( x => x)}</p>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label className="my-1 fw-bold">Url</label>
                            <input type="text" name="url" className="form-control" id="url-input" value={ lime.url } onChange={ (e) => setLime({ ...lime, url :e.target.value })}/>
                            {
                                isErrorEdit && (
                                    <p className="text text-danger">{error.response.data.errors.url?.map( x => x)}</p>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label className="my-1 fw-bold">Estado</label>
                            <Select 
                                key={1}
                                className="baisc-single"
                                classNamePrefix="select-status-version"
                                name="type"
                                defaultValue={ statusSelect.filter(x => x.value == lime.status) }
                                options={ statusSelect }
                                onChange={ (e)  => setLime({...lime, status: e.value }) }
                            />
                            {
                                isErrorEdit && (
                                    <p className="text text-danger">{error.response.data.errors.status?.map( x => x)}</p>
                                )
                            }
                        </div>
                        <div className="form-group mt-2">
                            {
                                isLoadingEdit ? (<button className="btn btn-primary-oo">Cargando...</button>)
                                : (<button className="btn btn-primary-oo">Editar referencia</button>)
                            }
                        </div>
                    </form>
                )
            }
        </div>
    </div>

    );
}

if (document.getElementById('jsx-edit-version-lime')) {
    const el = document.getElementById("jsx-edit-version-lime");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
          <EditLimeSurvey { ...props } />
      </App>
  )
}
