import React from "react";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";

export const LoadingAvailables = ({toLoad,availables,progress}) => {
    return (
        <>
            <div>
                <p className="m-0 me-3">
                    Cargando {toLoad} de {availables} panelistas...
                </p>
            </div>
            <div>
                <CircularProgressWithLabel
                    value={progress}
                    style={{ color: "#552C75" }}
                />
            </div>
        </>
    );
};
