import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { getTypePanelistAjax, postRecordByDateAjax } from "../api/dashboard/registers";

export const useRegistersM = (filter) => {
    const [registers, setRegisters] = useState([]);
    const filterRef = useRef(filter);
    const { isLoading, mutate, error, isError } = useMutation(postRecordByDateAjax,{ 
        onSuccess : (response) => {
            const { data } = response;
            setRegisters(data);
        }, onError: (response) => setRegisters([])
    });
    useEffect( () => {
        mutate(filter);
        setRegisters([])
    },[filter]);
    return {
        registers, setRegisters,isLoading
    }
}


export const useTypePanelistM = (filter) => {
    const [typePanelist, setTypePanelist] = useState([]);
    const { isLoading, mutate, error, isError } = useMutation(getTypePanelistAjax,{ 
        onSuccess : (response) => {
            const { data } = response;
            setTypePanelist(data);
    },onError:({response}) => setTypePanelist([])});
    useEffect( () => {
        mutate(filter);
        setTypePanelist([])
    },[filter]);
    return {
        typePanelist, setTypePanelist,isLoading
    }
}
