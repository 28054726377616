import React from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { COLORS } from '../../../../../helper/COLOR';
import { DescriptionUniqueQuestion } from '../table/DescriptionUniqueQuestion';


const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, payload }) => {
    let progress = (payload.total/payload.totalAnswer)*100;
    progress = parseFloat(progress.toFixed(2));
    progress = isNaN(progress) ? 0 : progress;
    return `${payload.total}  (${progress}%)`    
};
export const UniqueMatrizQuestionPieChart = ({ data, title }) => {
    return (
        <div className="legendChart__content">
            <div className='legendChart__content-chart'>
                <ResponsiveContainer width="100%" height={800} >
                    <PieChart width={450} height={300} >
                        <Pie 
                            data={data} 
                            dataKey="total" 
                            nameKey="answer" 
                            labelLine={true}
                            label={renderCustomizedLabel} >
                            {data.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index]} />))}
                        </Pie>
                        <Legend layout='horizontal' divisor={200} content={ (payload) =>  <DescriptionUniqueQuestion { ...payload } title={title} /> }  height={400}/>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}