import React  from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { useBoundMktStore } from '../../../../store/ReportsCampaignMkt/useBoundMktStore';
import Skeleton from 'react-loading-skeleton';

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, payload }, totals) => {
    let progress = (payload.total/totals)*100;
    progress = parseFloat(progress.toFixed(2));
    progress = isNaN(progress) ? 0 : progress;
    return `${payload.total}  (${progress}%)`    
};

export const GenderPieChart = () => {
    const { loading, genders, errors } = useBoundMktStore ((state) => {
        const { loadings, data, errors } = state;
        return {
            loading: loadings.genders,
            genders: data.genders,
            errors: errors.genders
        }
    })
    return (
        loading ? ( <Skeleton height={ 300 }/> ) : (
             genders.length > 0  && (
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart width={730} height={250}>
                        <Legend payload={ 
                            genders.map( (v,k) => {
                                return ({ ...v, name: v.panelist_gender, value:v.panelist_gender, color:v.panelist_gender == 'Mujer' ? '#9FA8DA' : '#90CAF9' })
                            })
                        }/>
                        <Pie data={genders} dataKey="total" nameKey="panelist_gender"  labelLine={true} label={(props) => renderCustomizedLabel({...props}, genders.reduce((a,b) => a + b.total,0))} >
                            {genders.map((entry, index) => (<Cell key={`cell-${index}`} fill={ entry.panelist_gender == 'Mujer' ? '#9FA8DA' : '#90CAF9'} />))}
                        </Pie>
                        {/* <Tooltip content={<CustomTooltip />} cursor={{fill: 'transparent'}}/> */}
                    </PieChart>
                </ResponsiveContainer>
    
             )
        )
    )
}