import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "react-query";
import Select from "react-select";
import Swal from "sweetalert2";
import { App } from "../../../App";
import { useGetProjects } from "../../../hooks/useProject";
import TextField from '@mui/material/TextField';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/de';
import moment from 'moment'
import { createMonitor } from "../../../api/sio/monitor";

export const CreateMonitor = () => {
    const [monitor, setMonitor] = useState({
        name: "",
        projects: [],
        expired_at: moment().utc(true).format("YYYY-MM-DD HH:mm")
    });
    const { projects, isLoading:isLoadingProject, isError:isErrorProjects, error:errorProjects } = useGetProjects();
    const {
        mutate: mCreateMonitor,
        isLoading:isLoadingC,
        error,
        isError,
    } = useMutation(createMonitor, {
        onSuccess: () => {
            Swal.fire("Generar monitor", "Monitor generado", "success").then(
                (result) => location.replace("/monitor")
            );
        },
    });
    const handleCreateMonitor = (e) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Quieres generar un monitor?",
            showCancelButton: true,
            confirmButtonText: "Crear",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed) {
                mCreateMonitor(monitor);
            } else if (result.isDenied) {
                Swal.fire(
                    "No se pudo generar el monitor, intenta nuevamente.",
                    "",
                    "error"
                );
            }
        });
    };

    return (
        <div className="card">
            <div className="card-header">
                <div className="content-head-card">
                    <label>Crear monitor para cliente</label>
                </div>
            </div>
            <div className="card-body">
                <form onSubmit={handleCreateMonitor}>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label className="my-1 ">Titulo</label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="name-input"
                                value={monitor.name}
                                onChange={(e) =>
                                    setMonitor({
                                        ...monitor,
                                        name: e.target.value,
                                    })
                                }
                            />
                            {isError && (
                                <p className="text text-danger">
                                    {error.data.errors.name?.map((x) => x)}
                                </p>
                            )}
                        </div>
                        <div className="col-md-12 mb-3">
                            {
                                (isLoadingProject) ? (<Skeleton height={50}/>) : 
                                (
                                    <>
                                    {
                                        projects.length == 0 ? (<label className="my-1 fw-bold text-danger">No se encuentran los proyectos</label>)  : (
                                            <>
                                            <label className="my-1">Asociar proyectos</label>
                                            <Select 
                                                isClearable
                                                isMulti
                                                className="basic-select"
                                                options= {projects.map( x => ({ value: x.id, label: x.name })) }
                                                onChange = { (e) => {
                                                    setMonitor({...monitor, projects: e.map(item => item.value).join()})
                                                }}
                                            />
                                             {isError && (
                                                <p className="text text-danger">
                                                    {error.data.errors.projects?.map((x) => x)}
                                                </p>
                                            )}
                                            </>
                                        )
                                    }
                                    </>
                                )
                            }
                        </div>
                        <div className="col-md-12">
                        <label htmlFor="" className="form-label">Fecha expiración</label>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
                                <DateTimePicker
                                    ampm={false}
                                    className="form-control"
                                    inputFormat="YYYY-MM-DD HH:mm"
                                    value={ monitor.expired_at }
                                    onChange={ (e) => {
                                        setMonitor({...monitor, expired_at: moment(e).utc(true).format('YYYY-MM-DD HH:mm:ss')}) 
                                    }}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                />
                            </LocalizationProvider>
                                {
                                    isError && (
                                            <p className="text text-danger">{error.data.errors?.expired_at?.map( x => x)}</p>
                                    )
                                }
                        </div>
                        <div className="form-group col-md-12 mt-2">
                            {isLoadingC ? (
                                <button className="btn btn-primary-oo">
                                    Cargando...
                                </button>
                            ) : (
                                <button className="btn btn-primary-oo">
                                    Crear monitor
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

if (document.getElementById("jsx-create-monitor")) {
    const el = document.getElementById("jsx-create-monitor");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <CreateMonitor {...props} />
        </App>
    );
}

