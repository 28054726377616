import React, { useEffect, useState }    from 'react';
import {Bar, BarChart, CartesianGrid, Cell, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Skeleton from 'react-loading-skeleton';
import { useBoundTransactionsStore } from '../../../../store/ReportsTransactionsStore/useBoundTransactionsStore';
import { formatterNumber } from '../../../../helper/currencyFormatter';


const CustomBar = (props) => {
    const { x, y, width, height, fill, payload } = props;
    return (
        <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        stroke="#e2e2e2" // Color del borde
        strokeWidth={1} // Ancho del borde
        radius={[10,10,0,0]}
        />
    );
}

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
            backgroundColor:"#FFF",
            padding:"10px",
            borderRadius:"10px",
            // boxShadow:'1px 1px 3px 1px #7E57C2',
            boxShadow:  '#CFD8DC 0px 1px 7px 0px',
            width:'300px'
            }}>
            <p style={{ color:"#3d3d3d", textAlign:'center'}}><strong>{ label }</strong></p>
            <p>Total: {payload[0].payload.total}</p>
            <p>Total puntos: { formatterNumber(payload[0].payload.points )}</p>
            <p>Total precios: { formatterNumber(payload[0].payload.price )}</p>
            <hr></hr>
        </div>
      );
    }
  
    return null;
  };


export const DateTransactions = ({ id }) => {
    const { data, loading, errors} = useBoundTransactionsStore( state => {
        const { loadings, data, errors:errorsState } = state;
        return {
            loading: loadings.productsForDate,
            data: data.productsForDate,
            errors: errorsState.productsForDate,
        }
    });
    
    
    const [interval, setInterval] = useState([]);
    useEffect( () => {
        if(data.length){ return }
        console.log(data);
        setInterval(Math.floor(data.length/(20*2)));
    },[])


    if(loading){
        return <Skeleton height={300} />
    }else{
        if(data.length == 0){ return <div className="mt-2 alert alert-danger">{errors.message}</div> }
    }

    const maxValue = Math.max(
        ...data.map(item => Math.max(parseInt(item.total)))
    )

    
    return (
        <div className="mt-3 card">
            <div className="card-header"><strong>Canjes diarios</strong></div>
            <div className="card-body">
                <ResponsiveContainer  width="100%" height={500}>
                    <BarChart
                    width={500}
                    height={300}
                    data={data}
                    >
                    <CartesianGrid  stroke='none'/>
                        <XAxis  
                            dataKey="tag" 
                            textAnchor= "end" 
                            sclaeToFit="true" 
                            verticalAnchor= "start"  
                            interval={interval} 
                            angle= "-40"  
                            height={200} 
                            style={{fontSize:'12px'}} 
                            axisLine={false} // Elimina la línea del eje Y
                            tickLine={false} 

                        />
                        <YAxis
                            type="number"
                            domain={[0, dataMax => maxValue * 1.5]} tickCount={10} 
                            allowDataOverflow={true}    
                            axisLine={false} // Elimina la línea del eje Y
                            tickLine={false} // Elimina las líneas de las marcas en el eje Y 
                        />
                        <Tooltip cursor={{fill: 'transparent'}} content={(props) => <CustomTooltip {...props} />}/>
                        <Legend iconType='circle'/>
                        <Bar 
                            dataKey="total" 
                            fill="#42A5F5" 
                            name="Canjes diarios"
                            shape={<CustomBar />}
                            >
                            {data.map((entry, index) => (
                                <Cell 
                                    key={`cell-${index}`} 
                                    fill="#81D4FA"
                                />))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>

    )
}