import { create } from "zustand"
import { getProductionOfMonth } from "../../api/reports/productions";

const errors= { status:false, message: '' };
const defaultTypeChart = {
    options: [{value:'barchart', label:'Barra' } , {value:'linechart', label:'Lineal' }, { value:'table', label:'Tabla' }],
    selected: {value:'barchart', label:'Barra' }
};
export const useProductionOfMonthStore = (set,get) => {
    return {
        loadingMonth:false,
        errorsMonths:errors,
        responseMonth: [],
        typeChartMonth: defaultTypeChart,
        setTypeChartMonth: (type) => set((state) => ({ ...state, typeChartMonth: { ...state.typeChartMonth, selected: type }})),
        setErrors: (status, message) => set((state) => ({...state, errors:{ status, message }})),

        fetchProductionOfMonth: async () => {
            set({ loadingMonth: true });
            set({errorsMonths:{status:false,message:''}});
            get().setErrors(false,'');
            try {
                const  {data, status } = await getProductionOfMonth(get().preset,get().defaultStatusProject);
                if(status == 204){
                    set({ errorsMonths: { status:true, message:'No hay data disponible para este filtro generado.'} })
                }else{
                    set({ errorsMonths: errors, responseMonth: data });
                }
            } catch (error) {
                if(error.status = 422){
                    get().setErrors(true, error.data.message);
                }
            }
            set({ loadingMonth: false });
        }
    }
};