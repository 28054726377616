import { create } from "zustand";

export const useLoadExternalTokens = create((set) => ({
    file: null,
    isProcessingFile: false,
    setFile: (newFile) => {
        set({ file: newFile });
    },
    setIsProcessingFile: (newIsProcessingFile) => set({ isProcessingFile: newIsProcessingFile }),
    resetFile: () => set({ file: null }),
    isValid: file => {
        const validTypes = ['text/csv','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-excel'];
        if (validTypes.includes(file.type)){
            return true;
        }
        return false;
    },
    addFileToFormData: (file,formState) => {
        if(file){
            const formData = new FormData();
            formData.append('tokens_file',file);
            let payload = JSON.stringify({ ...formState });
            formData.append('payload',payload);
            return formData;
        }
        return null;
    }
}));