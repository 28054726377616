import { useRef } from 'react';
import { useBoundProfile } from '../store/Profiles/useBoundProfiles';

export const useDebounceFetchProfileData = () => {
    const { fetchSearchData } = useBoundProfile(state => state);
    const debouncedRef = useRef(1000);

    function fetchData(){
        if(debouncedRef != undefined){
            clearTimeout(debouncedRef.current);
        }
        debouncedRef.current = setTimeout( () => {
            fetchSearchData();
        },1200);
    }

    return {
        fetchData
    }
}