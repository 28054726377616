import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { COLORS } from '../../../../../helper/COLOR';
import { DescriptionRankingQuestion } from '../table/DescriptionRankingQuestion';



export const RankingQuestionStackedChart = ({ data }) => {
    const df = data.map( function(item, key){
        return ({answer: item.answer, ...item.orders });
    });
    const maxValue = Math.max(
        ...data.map(item => Math.max(parseInt(item.totalAnswer)))
    );


    const renderTooltip = ({ active, payload, label, totalAnswer }) => {
        if (active && payload && payload.length) {
            
            return (<div style={{
                backgroundColor:"#FFF",
                padding:"10px",
                borderRadius:"10px",
                border:"solid 1px #d3d3d3"
            }}>
                <p><strong>{ payload[0].payload.answer }</strong></p>
                {
                    payload.map( (value,key) => {
                        let progress = (value.value/totalAnswer)*100;
                        progress = parseFloat(progress.toFixed(2));
                        progress = isNaN(progress) ? 0 : progress;

                        return (
                            <p key={key} style={{ color:value.fill }}>
                                { `${value.name}: ${value.value}  (${progress}%)`}
                            </p>
                        )
                    })
                }
            </div>)
        }
    
        return null;
    };
    return (
        <div className="legendChart__content">
            <div className='legendChart__content-chart'>
                {
                    data.length == 0 ? ( <Skeleton height={300} />)
                    :(
                        <ResponsiveContainer width="100%" height={800}>
                            <BarChart width={450} height={400} data={ df } >
                                <XAxis dataKey="answer" textAnchor= "end" sclaeToFit="true" verticalAnchor= "start"  angle= "-25"  height={100}  tickFormatter ={ (value) => `${value.slice(0,30)}...`} style={{fontSize:'12px'}} />
                                <YAxis 
                                    domain={[0, dataMax => maxValue*1.3]} tickCount={10} 
                                />
                                <Tooltip cursor={{fill: 'transparent'}} content={(props) => renderTooltip({...props, totalAnswer:data[0].totalAnswer})}/>
                                { <Legend wrapperStyle={{paddingTop: "3.2rem"}}  divisor={200} content={ (payload) => <DescriptionRankingQuestion { ...payload } title={data[0].title} descriptionData={df} totalAnswer={ data[0].totalAnswer } /> } height={400} /> }
                                    {
                                        Object.keys(df[0]).map((v,k) => {
                                            if(v != "answer"){
                                                return (<Bar key={k} dataKey={v} stackId="a"  fill={COLORS[k]}  />)
                                            }
                                        })
                                    }
                            </BarChart>
                        </ResponsiveContainer>                        
                    )
                }

            </div>
        </div>
    )
}