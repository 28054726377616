import React, { useMemo  } from 'react';
import {  useMaterialReactTable, MaterialReactTable } from 'material-react-table';
import { Box  } from '@mui/material';
import { formatterNumber } from '../../helper/currencyFormatter';
import { useBoundProfile } from '../../store/Profiles/useBoundProfiles';

export const SearchData = () => {
    const { responseData, dataSelect } = useBoundProfile(state => state);
    const columns = useMemo( () => {
        let _columns = dataSelect.map((item,key) => ({
            accessorKey: item.value,
            header: item.question,
        }));
        return [..._columns, {
                    accessorKey: 'count',
                    header: 'Total',
                    AggregatedCell: ({ cell, table }) => {
                        var colors = '';
                        if(dataSelect.length == 1){
                            colors = 'black';
                        }
                        console.log(dataSelect, cell);

                        return (
        
                            <>
                                <Box
                                sx={{ color:colors, display: 'inline', fontWeight: 'bold' }}
                                >
                                {formatterNumber(cell.getValue())}
                                </Box>
                            </>
                        )
                    },
                    Footer: () => {
                        return (
                            <div style={{ color:'var(--bs-teal)'}}>Total: { responseData.totals }</div>
                        )
                    }
                },{
                    accessorFn: ( row ) => row.count,
                    header: 'Porcentaje',
                    AggregatedCell: ({ cell, table }) => {
                        let parentId = cell.row.parentId === undefined ? cell.row.id : cell.row.parentId;
                        let parentElement = table.getRow(parentId);
                        let totalParent =  cell.row.parentId === undefined ? responseData.totals : parentElement._groupingValuesCache['count']
                        return (
        
                            <>
                                <Box
                                sx={{  display: 'inline', fontWeight: 'bold' }}
                                >
                                { ((cell.getValue() / totalParent) * 100).toFixed(2) }%
                                </Box>
                            </>
                        )
                    },
                    Footer: () => {
                        return (
                            <div style={{ color:'var(--bs-yellow)'}}>Total: { ((responseData.totals  / responseData.totals)* 100).toFixed(0) }%</div>
                        )
                    }
                }
        ];
    },[dataSelect]);
    
    const table = useMaterialReactTable({
        columns,
        data: responseData.data,
        enableGrouping: true,
        enableColumnDragging:false,
        groupedColumnMode:false,
        enableStickyHeader: true,
        positionToolbarAlertBanner:false,
        enablePagination:false,
        rowCount: false,
        enableBottomToolbar:false,
        enableHiding:false,
        enableDensityToggle:false,
        enableFilters:true,
        initialState: {
            density: 'compact',
            expanded: false, //expand all groups by default
            grouping: dataSelect.map(x => x.value), //an array of columns to group by by default (can be multiple)
          },
        muiTablePaperProps: {
            elevation: 0, //change the mui box shadow
            //customize paper styles
            sx: {
              borderRadius: '0',
              border: '1px dashed #e0e0e0',
            },
          },
          muiTableBodyCellProps:{
            sx: {
                border: '1px dashed #e0e0e0',
              },
          },
    });

    return ( 
        <div className="card p-2">
            <MaterialReactTable table={ table } />
        </div>
    )
}