import { axiosApi } from "../axiosApi";

export const getTransactions = async (url) => {
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const updateTransaction = async (transaction) => {
    try{
        const response = await axiosApi.put(`${import.meta.env.VITE_REACT_APP_URL}/tienda/canjes/actualizar`,transaction);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}