import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "react-query";
import Select from "react-select";
import Swal from "sweetalert2";
import { createPanelExternalConfig } from "../../../../api/sio/project";
import { usePanelExternals } from "../../../../hooks/usePanelExternal";
import { ParametersList } from "../form/ParametersList";

export const ConfigPanelCreate = ({ projectId, showModal, setShowModal }) => {
    //Cargamos los paneles disponibles para configuración
    const { panelExternals, isLoading: isLoadingPanelExternals } =
        usePanelExternals();
    //Guardar configuración de panel
    const {
        mutate: mCreatePanelExternalConfig,
        error: errorCreate,
        isError: isErrorCreate,
    } = useMutation(createPanelExternalConfig, {
        onSuccess: () => {
            Swal.fire(
                "Configurar Panel Externo",
                "Se a configurado un nuevo panel externo",
                "success"
            ).then(() =>
                window.location.replace(`/proyecto/${projectId}/panel-externo`)
            );
        },
        onError: ({ data }) => {
            const _errors = Object.values(data.errors);
            Swal.fire(
                "Configurar Panel Externo",
                `El panel no se ha configurado correctamente: ${_errors.map(
                    (x) => x.map((y) => `<br> ${y}`)
                )}`,
                "error"
            );
        },
    });
    //radio button change active/inactive
    const [isChecked, setIsChecked] = useState(true);
    //Form state
    const [formState, setFormState] = useState({
        project_id: projectId,
        status: "active",
        panelexternal_id: null,
        parameters: [
            {
                id: 0,
                slug: "",
                qcode: null,
            },
        ],
        COMPLETED_URL: "",
        FILTERED_URL: "",
        QUOTAFULL_URL: "",
    });
    //Paneles externos disponibles
    const panelOptions = panelExternals.map((x) => {
        return {
            value: x.id,
            label: x.name,
        };
    });
    //Input change handlers
    const handleInputTextChange = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...formState,
            [name]: value,
        });
    };

    const handleParameterChange = (index, parameter) => {
        const newParameters = [...formState.parameters];
        newParameters[index] = parameter;
        setFormState({
            ...formState,
            parameters: newParameters,
        });
    };

    //Add/remove parameters handlers
    const handleAddParameter = () => {
        setFormState({
            ...formState,
            parameters: [
                ...formState.parameters,
                {
                    id: formState.parameters.length,
                    slug: "",
                    qcode: null,
                },
            ],
        });
    };

    const handleRemoveParameter = (index) => {
        const newParameters = [...formState.parameters];
        newParameters.splice(index, 1);
        setFormState({
            ...formState,
            parameters: newParameters,
        });
    };

    //Funcion para limpiar estado de la configuración al cerrar el modal sin guardar
    const handleCleanFormState = () => {
        setFormState({
            project_id: projectId,
            status: "active",
            panelexternal_id: 0,
            parameters: [],
            COMPLETED_URL: "",
            FILTERED_URL: "",
            QUOTAFULL_URL: "",
        });
    };

    //OnSubmit handler
    const handleOnSubmit = (e) => {
        e.preventDefault();
        let _formState = { ...formState };
        let _p = [..._formState.parameters];
        _p = _p.filter((x) => x.slug != "");
        _formState = { ..._formState, parameters: _p };
        mCreatePanelExternalConfig(_formState);
    };

    //Al renderizar o cambiar el project, se setea el project_id, y status en la config.
    //Default status = active.
    useEffect(() => {
        setFormState({
            ...formState,
            project_id: projectId,
            status: "active",
        });
    }, [projectId]);

    return (
        <>
            <div className="modal modal-segmentation">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Configurar panel</h5>
                            <button
                                className="close close-modal-btn"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setShowModal({
                                        ...showModal,
                                        create: false,
                                    });
                                    handleCleanFormState();
                                }}
                            >
                                <i className="fa fa-times-circle"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            {isLoadingPanelExternals ? (
                                <Skeleton height={400} />
                            ) : panelExternals.length > 0 ? (
                                <form onSubmit={(e) => handleOnSubmit(e)}>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="my-1 fw-bold">
                                                Seleccionar un panel
                                            </label>
                                            <Select
                                                name="panel"
                                                isMulti={false}
                                                isClearable
                                                className="basic-multi-select"
                                                options={panelOptions}
                                                onChange={(e) =>
                                                    setFormState({
                                                        ...formState,
                                                        panelexternal_id:
                                                            e.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <ParametersList
                                            projectId={projectId}
                                            parameters={formState.parameters}
                                            onParameterChange={
                                                handleParameterChange
                                            }
                                            onAddParameter={handleAddParameter}
                                            onRemoveParameter={
                                                handleRemoveParameter
                                            }
                                        />
                                    </div>
                                    <hr></hr>
                                    <div className="row">
                                        <div className="form-group col-md-12 mb-3">
                                            <label className="my-1 fw-bold">
                                                URL COMPLETED
                                            </label>
                                            <input
                                                name="COMPLETED_URL"
                                                type={"text"}
                                                placeholder="URL 'Completas' panel externo"
                                                className="form-control"
                                                onChange={(e) =>
                                                    handleInputTextChange(e)
                                                }
                                            />
                                            {isErrorCreate && (
                                                <p className="text text-danger">
                                                    {errorCreate.data.errors?.COMPLETED_URL?.map(
                                                        (x) => x
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                        <div className="form-group col-md-12 mb-3">
                                            <label className="my-1 fw-bold">
                                                URL FILTERED
                                            </label>
                                            <input
                                                name="FILTERED_URL"
                                                type={"text"}
                                                placeholder="URL 'Filtradas' panel externo"
                                                className="form-control"
                                                onChange={(e) =>
                                                    handleInputTextChange(e)
                                                }
                                            />
                                            {isErrorCreate && (
                                                <p className="text text-danger">
                                                    {errorCreate.data.errors?.FILTERED_URL?.map(
                                                        (x) => x
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                        <div className="form-group col-md-12 mb-3">
                                            <label className="my-1 fw-bold">
                                                URL QUOTA FULL
                                            </label>
                                            <input
                                                name="QUOTAFULL_URL"
                                                type={"text"}
                                                placeholder="URL 'Cuota llena' panel externo"
                                                className="form-control"
                                                onChange={(e) =>
                                                    handleInputTextChange(e)
                                                }
                                            />
                                            {isErrorCreate && (
                                                <p className="text text-danger">
                                                    {errorCreate.data.errors?.QUOTAFULL_URL?.map(
                                                        (x) => x
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-12 mb-3">
                                            <p className="fw-bold">Estado</p>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="statusActive"
                                                    value={"active"}
                                                    onChange={(e) => {
                                                        setFormState({
                                                            ...formState,
                                                            status: e.target
                                                                .value,
                                                        });
                                                        setIsChecked(
                                                            !isChecked
                                                        );
                                                    }}
                                                    checked={isChecked}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="statusActive"
                                                >
                                                    Activo
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="statusInactive"
                                                    value={"inactive"}
                                                    onChange={(e) => {
                                                        setFormState({
                                                            ...formState,
                                                            status: e.target
                                                                .value,
                                                        });
                                                        setIsChecked(
                                                            !isChecked
                                                        );
                                                    }}
                                                    checked={!isChecked}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="statusInactive"
                                                >
                                                    Inactivo
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="form-group col-md-12">
                                        <button
                                            type="submit"
                                            className="btn btn-primary-oo"
                                        >
                                            Guardar configuración
                                        </button>
                                    </div>
                                </form>
                            ) : (
                                <div className="text-center">
                                    <h5>¡No existen paneles externos en el sistema!</h5>
                                    <a className="btn btn-primary-oo" href="/panel-externo/crear">Añadir Panel Externo</a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
