import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { getNsesDahsboardAjax } from "../api/dashboard/nse";
import { useTypesNse } from "./useNse";

export const useNseM = (filter, type) => {
    const [nses, setNses] = useState([]);
    const refType = useRef(type);
    
    const { isLoading, selectTypes, setSelectTypes } = useTypesNse();
    const { isLoading:isLoadingM, mutate  } = useMutation(getNsesDahsboardAjax,{ 
        onSuccess : (response) => {
            const { data } = response;
            setNses(data || []);
    }, onError: ({ response }) => {
        setNses([])
    }
});
    useEffect( () => {
        setNses([]);
        if(selectTypes.length == 0){ return }
        mutate(filter, selectTypes.selected.value);
    }, [selectTypes.length, filter])
    return {isLoadingM, nses, setNses,selectTypes, setSelectTypes,mutate}
}
