import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import Select from "react-select";
import { getSurveyAvailableParameters } from "../../../../api/sio/project";

export const ParameterItem = ({
    projectId,
    index,
    parameter,
    onParameterChange,
    onRemoveParameter,
    showRemoveButton,
}) => {
    //Qcodes
    const [qcodes, setQcodes] = useState({
        value: "",
        label: "",
    });
    const {
        isLoading: isLoadingQcodes,
        data: dataQCodes,
        isSuccess: isQcodesSuccess,
    } = useQuery(
        "getSurveyAvailableParameters",
        () => getSurveyAvailableParameters(projectId),
        {
            onSuccess: ({ data }) => {
                setQcodes(data);
            },
        }
    );
    //Input handlers
    const handleSlugChange = (event) => {
        const newParameter = {
            ...parameter,
            slug: event.target.value,
        };
        onParameterChange(index, newParameter);
    };

    const handleQCodeChange = (selectedOption) => {
        const newParameter = {
            ...parameter,
            qcode: selectedOption,
        };
        onParameterChange(index, newParameter);
    };
    //Remove parameters
    const handleRemoveClick = () => {
        onRemoveParameter(index);
    };

    return (
        <>
            <div className="row my-2 py-3 bg-light border rounded">
                <div className="col-md-6">
                    <label htmlFor="parameters-slug">Parámetro</label>
                    <input
                        type="text"
                        id={`slug${index}`}
                        name={`slug${index}`}
                        className="form-control"
                        value={
                            parameter.slug === null || parameter.slug === ""
                                ? ""
                                : parameter.slug
                        }
                        onChange={handleSlugChange}
                    />
                </div>
                <div className="col-md-6">
                    <label htmlFor="parameters-slug">Código de pregunta</label>
                    {isLoadingQcodes ? (
                        <Skeleton height={30} />
                    ) : (
                        <Select
                            id={`code${index}`}
                            name={`code${index}`}
                            value={parameter.qcode}
                            onChange={handleQCodeChange}
                            isMulti={false}
                            isClearable
                            className="basic-select"
                            options={Array.from(qcodes)}
                        />
                    )}
                </div>
                {showRemoveButton && (
                    <div>
                        <button
                            type="button"
                            className="btn btn-outline-danger mt-2"
                            onClick={handleRemoveClick}
                        >
                            Eliminar
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};
