import { useEffect, useState } from "react";
import { useBoundPanelistsReportStore } from "../../../store/ReportsPanelistsStore/useBoundPanelistsReportStore";
import { SemesterBar } from "./bar/SemesterBar";
import Select from 'react-select';
import { SemesterLine } from "./line/SemesterLine";
import { SemesterTable } from "./table/SemesterTable";

export const DashboardPanelistsSemester = () => {

    const { loadingSemester, responseSemester, errorsSemester,defaultRepresentation,fetchPanelistsOfSemester,preset,defaultStatusProject,typeChartSemester, setTypeChartSemester } = useBoundPanelistsReportStore( state => state );

    useEffect( () => {
        let search = defaultRepresentation.filter(f => f.value == 'semester');
        if(search.length > 0){
            fetchPanelistsOfSemester();
        }
    },[defaultRepresentation,preset,defaultStatusProject]);
    return (
        <div className="card mt-5">
        <div className="card-header">
            <div style={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center'
            }}>
                <label>
                    Semestral
                </label>
                <Select
                      key="selected-2"
                      defaultValue={ typeChartSemester.selected }
                      name="typeChartSemester"
                      options={ typeChartSemester.options }
                      onChange={ (e) => {
                        setTypeChartSemester(e);
                      } }
                  />
            </div>
        </div>
        <div className="card-body">
                {
                    errorsSemester.status && ( errorsSemester.message )
                }
                {
                    loadingSemester ? ( 'cargando...') : (
                        responseSemester.length > 0 && (
                            <>
                            {typeChartSemester.selected.value == 'barchart' && ( <SemesterBar responseSemester={responseSemester}/> )}
                            {typeChartSemester.selected.value == 'linechart' && (<SemesterLine responseSemester={responseSemester}/>)}
                            {typeChartSemester.selected.value == 'table' && (<SemesterTable responseSemester={responseSemester}/>)}
                        </>
                        )
                    )
                }
        </div>
    </div>
    )
}