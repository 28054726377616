import React, { useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';

import moment from 'moment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import styles from './styles.module.css';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getTransactions } from '../../../api/sis/transaction';
import { App } from '../../../App';
import { TransactionsTable } from './TransactionsTable';
import { v4 as uuidv4 } from 'uuid';


const defaultFilter  = {
    date: {
        from:  moment(new Date(`${moment().year()}.${moment().month()+1}-01`)).utc(true).format('YYYY-MM-DD'),
        to: moment(new Date()).utc(true).format('YYYY-MM-DD'),
    },
}
export const Transactions = () => {

    const debouncedRef = useRef(1000);
    const [dataFilter, setDataFilter] = useState({...defaultFilter});
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState("");
    const [isError, setIsError] = useState({
        status: false,
        message: ""
    });
    const [transactions, setTransactions] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [pagination, setPagination] = useState({
            pageIndex: 0,
            pageSize: 10,
    });
    const [rowCount,setRowCount] = useState();
    const [csvOptions, setCsvOptions] = useState({
        filename: uuidv4(),
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        //headers : columns.map(x => x.accessorKey),
    });
    const handleChangeDate = (type, value) => {
        if(type == 'from'){
            setDataFilter({
                ...dataFilter,
                date:{
                    ...dataFilter.date,
                    from: moment(value).utc(true).format('YYYY-MM-DD'),
                }
            })
        }
        if(type == 'to'){
            setDataFilter({
                ...dataFilter,
                date:{
                    ...dataFilter.date,
                    to: moment(value).utc(true).format('YYYY-MM-DD'),
                }
            })
        }
    }

    const handleTransactions =  useCallback( async () => {
        setIsLoading(true);
        var _url = new URL(`${import.meta.env.VITE_REACT_APP_URL}/tienda/canjes`);
        _url.searchParams.set('from_at', `${dataFilter.date.from}`);
        _url.searchParams.set('to_at', `${dataFilter.date.to}`);
        _url.searchParams.set('perpage', `${pagination.pageSize}`);
        _url.searchParams.set('page', pagination.pageIndex  + 1);
        _url.searchParams.set('filters', columnFilters.length > 0  ? JSON.stringify(columnFilters) : '');
        setUrl(_url);
        try{
            let response = await getTransactions(_url);
            const { data,status } = response;
            if(status == 204){ 
                setIsError({
                    status:true,
                    message: "No hay datos para este filtro"
                })
                setTransactions([]);
            }else{
                setTransactions(data.data);
                setCsvOptions({...csvOptions, headers:Object.keys(data.data[0])})
            }
            let _links = data.links;
            _links.shift();
            _links.pop();
            setRowCount(data.total);
            setIsError({
                status: false,
                message: ""
            })
        }catch(error){
            const  { data,status } = error;
            if(status == 422){
                setIsError({
                    status:true,
                    message: data.message
                })
            }else{
                setIsError({
                    status:true,
                    message: "Error al cargar los datos"
                })
            }
        }
        setIsLoading(false);
    },[dataFilter,transactions,pagination,columnFilters]);
    const handleFilter = () => {
        handleTransactions();
    }
    
    useEffect( () => {
        if(columnFilters.length > 0){
                if(debouncedRef != undefined){
                    clearTimeout(debouncedRef.current);
                }

                debouncedRef.current = setTimeout( () => {
                    handleTransactions()
                },1500);
        }else{
            handleTransactions();
        }
    },[
        pagination.pageIndex, pagination.pageSize,columnFilters
    ]);

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12">
                <div className="card mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <div className="content-head-card">
                            <label htmlFor="">
                                <a href="/tienda/proyectos/categorias"><strong>Historial de canjes</strong></a>
                            </label>
                        </div>
                    </div>
                    <div className="card-body mt-4">
                    {
                        !isLoading  ? (
                                <div className={styles.filter_data}>
                                <LocalizationProvider dateAdapter={AdapterMoment} > 
                                    <DesktopDatePicker
                                        label="Desde"
                                        inputFormat="DD/MM/YYYY"
                                        value={dataFilter.date.from}
                                        onChange={ e => handleChangeDate('from', e) }
                                        renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        label="Hasta"
                                        inputFormat="DD/MM/YYYY"
                                        value={dataFilter.date.to}
                                        onChange={ e => handleChangeDate('to', e) }
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                <button className="btn btn-primary-oo text-light" onClick={ handleFilter }>Filtrar</button> 
                            </div>
                        ): (<Skeleton count={1} />)
                    }
                    </div>
                    <div className="card-body">
                        {
                            <TransactionsTable 
                                handleTransactions={ handleTransactions }
                                pagination={ pagination }
                                setPagination={ setPagination }
                                rowCount={ rowCount }
                                transactions={ transactions }
                                isLoading= { isLoading }
                                isError= { isError }
                                setIsError= {setIsError }
                                columnFilters={columnFilters}
                                setColumnFilters={setColumnFilters}
                                url={url}
                                csvOptions={csvOptions}
                                setCsvOptions={setCsvOptions}    
                            />

                        }
                    </div>
                 </div>
            </div>
        </div>
    )
}

if (document.getElementById('jsx-list-transactions')) {
    const el = document.getElementById("jsx-list-transactions");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
          <Transactions { ...props } />
      </App>
  )
}