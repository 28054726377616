import { axiosApi } from "../axiosApi";

export const getPanelists = async (url) => {
    try {
        const response = await axiosApi.get(url);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getPanelistPoints = async (token) => {
    try {
        const response = await axiosApi.get(`/api/marketing/panelists/points/${token}`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const updatePanelist = async (panelist) => {
    try {
        const response = await axiosApi.put('/api/marketing/panelists/update', panelist);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const addPointsToPanelist = async (addPoints) => {
    try {
        const response = await axiosApi.post('/api/marketing/panelists/points/add', addPoints);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};