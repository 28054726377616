import React from 'react';
import {Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { COLORS } from '../../../../../helper/COLOR';
import { DescriptionMultipleOptionsQuestion } from '../table/DescriptionMultipleOptionsQuestion';

export const MultipleOptionsQuestionBarChart = ({ data }) => {

    const maxValue = Math.max(
        ...data.map(item => Math.max(parseInt(item.total)))
    );

    const renderCustomLabel = ({x,y,width,fill,value,totalAnswer}) => {
        let progress = (value/totalAnswer)*100;
        progress = parseFloat(progress.toFixed(2));
        progress = isNaN(progress) ? 0 : progress;
        // const { x, y, width, value,fill } = props;
        return (
          <text
            x={x + width / 1}
            y={y - 20}
            textAnchor="middle"
            fontSize="12"
            fill={"#000"}
            transform={`rotate(-45 ${x + width / 2} ${y - 5})`}
          >
            { `${value}  (${progress}%)` }
          </text>
        );
      };
    
      const renderTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const { payload:data,fill } = payload[0];
            let progress = (data.total/data.totalAnswer)*100;
            progress = parseFloat(progress.toFixed(2));
            progress = isNaN(progress) ? 0 : progress;
            return (
            <div style={{
                backgroundColor:"#FFF",
                padding:"10px",
                borderRadius:"10px",
                border:"solid 1px #d3d3d3"
            }}>
                <p><strong>{ data.question }</strong></p>
                <p style={{ color: "#000" }}>
                    { `${data.total}  (${progress}%)`}
                </p>
            </div>
            );
    }
    
        return null;
    };
    return (
        <div className="legendChart__content">
            <div className='legendChart__content-chart'>
                <ResponsiveContainer  width="100%" height={800}>
                    <BarChart width={450} height={300} data={data}  >
                    <CartesianGrid  stroke="transparent"/>
                        <XAxis dataKey="question"  textAnchor= "end" rticalAnchor= "start"  angle= "-25"  height={100} style={{fontSize:'12px'}}  />
                         <YAxis 
                            domain={[0, dataMax => maxValue*2]} tickCount={10} 
                        />
                        <Tooltip cursor={{fill: 'transparent'}} content={ renderTooltip }/>
                        <Legend wrapperStyle={{paddingTop: "10rem" }} fontSize={2}  divisor={200} content={ (payload) => <DescriptionMultipleOptionsQuestion { ...payload } title={data[0].title}  /> } height={400} />
                        <Bar 
                            dataKey="total" 
                            name={data[0].title}
                            label={{ content:(props) => renderCustomLabel({...props, totalAnswer: data[0].totalAnswer}), }}
                            >
                             {
                                data.map((entry, index) => { 
                                    return (
                                        <Cell payload={entry} key={`cell-${index}`} fill={COLORS[index % data.length]}/>
                                    )
                                    // return 
                                })
                            }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
                
            </div>
        </div>
    )
}