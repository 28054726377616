import Select from 'react-select';
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import { useSurveyPageStore } from '../../../../../store/SurveyPage/useSurveyPageStore';
import { v4 as uuid } from 'uuid';
import { BG_POSITION_OPTIONS } from '../../../../../helper/surveyPage';

export const WelcomeInputs = ({ setImage,isError,error, isEdit = false }) => {
    const [ pathImage,setPathImage ] = useState(null);
    const [ bgPosition,setBgPosition ] = useState({value: 'center', label: 'Centro'});
    const [ positionY,setPositionY ] = useState(65);
    const { pageData,setPageData } = useSurveyPageStore((state) => state);
    const [ mounted,setMounted ] = useState(false);

    // Función para convertir la imagen a base64
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
        });
    };
    // Función para guardar la imagen en LocalStorage
    const saveImageToLocalStorage = async (file) => {
        const base64Image = await getBase64(file);
        try {
            localStorage.setItem('banner_image', base64Image);
            setPathImage(base64Image);
        } catch (error) {
            //Si supera el tamaño permitido por el LocalStorage, mostramos un alert error.
            Swal.fire('Error', 'La imagen exede el tamaño permitido (Max: 500KB)','error');
        }
    };

    const handleOnDropAccepted = (acceptedFiles) => {
        setImage(acceptedFiles[0]);
        saveImageToLocalStorage(acceptedFiles[0]);
        setPageData({ ...pageData, banner_image:  uuid() });
    };

    const handleOnDropRejected = (rejectedFiles) => {
        Swal.fire('Error', 'La imagen exede el tamaño permitido (Max: 500KB)','error');
    };

    const handleSelectBgPosition = (e) => {
        setBgPosition({ value: e.value, label: e.label });
        setPageData({ ...pageData, bg_position: e.value });
    }
    const handleSelectPercentY = (e) => {
        setPositionY(e.target.value);
        setPageData({ ...pageData, position_y: e.target.value });
    }

    if (isEdit) {
        useEffect(()=>{
            //mounted es para que setPathImage solo se haga la primera vez que .
            if(!mounted){
                setPathImage(`'https://nadmin.opinandoonline.com/img/banners/project/${pageData.project_id}/${pageData.banner_image}'`);
                setBgPosition(
                    BG_POSITION_OPTIONS.find(x => x.value == pageData.bg_position) ?? {...bgPosition}
                );
                setPositionY( pageData.position_y ?? 65 );
            }
            setMounted(true);
        },[]);
    }

    return (
        <div className="form-group col-12">
            <label htmlFor="banner-image" className="my-2 fw-bold">Imágen del banner</label>
            <Dropzone onDropAccepted={handleOnDropAccepted} onDropRejected={handleOnDropRejected} multiple={false} maxSize={512000}>
                {({ getRootProps, getInputProps }) => (
                    <section className="d-flex align-items-center justify-content-center" style={{ border: "2px dashed #d3d3d3", }}>
                        <div className="py-4 w-100 text-center" {...getRootProps()} >
                            <input {...getInputProps()} name="image" id="image"/>
                            <p className="m-0 text-muted">Arrastra y suelta una nueva imágen aquí (.jpg,.png,.jpeg)</p>
                            <p className="m-0 fw-bold" style={{ color: "#46166C", }}>o haz click para importar un archivo</p>
                            <small style={{ fontSize: "12px", }} >La importación tiene un límite de 500KB</small>
                        </div>
                    </section>
                )}
            </Dropzone>
            {pathImage && pageData.banner_image && (
                <div className="mt-2 d-flex flex-column">
                    <p className="my-2 fw-bold">Opciones de imagen</p>
                    <div className="row">
                        <div className='col-12 col-md-3 d-flex align-items-center'>
                            <label htmlFor="image-position" className="fw-bold">Posición</label>
                            <Select
                                className="basic-select ms-2 w-100"
                                name='image-position'
                                options= {BG_POSITION_OPTIONS}
                                defaultValue={BG_POSITION_OPTIONS.find(x => x.value == pageData.bg_position) ?? null}
                                onChange = { handleSelectBgPosition }
                            />
                        </div>
                        {bgPosition.value == 'percent' &&
                        <div className='col-12 col-md-3 d-flex align-items-center'>
                                <label className='me-2 fw-bold' htmlFor='position-y'>Subir/Bajar</label>
                                <input onChange={handleSelectPercentY}
                                    type='number' className='form-control'
                                    name='position-y' min={0} max={100} defaultValue={pageData.position_y ?? 50}
                                />
                                <span className='ms-2 fw-bolder'>%</span>
                        </div>
                        }
                    </div>
                    <div id="oo-preview-image" className="mt-2" style={{
                        backgroundImage: `url(${pathImage})`,
                        backgroundPosition: bgPosition.value == 'percent' ? `center ${positionY}%` : bgPosition.value,
                    }}>
                    </div>
                </div>
            )}
            {isError && (<p className="text text-danger">{error.data.errors?.image?.map((x) => x)}</p>)}
            {isError && (<p className="text text-danger">{error.data.errors?.bg_position?.map((x) => x)}</p>)}
            {isError && (<p className="text text-danger">{error.data.errors?.position_y?.map((x) => x)}</p>)}
        </div>
    );
};
