import { create } from "zustand";
import { persist } from "zustand/middleware";
import { getSurveyPage, getSurveyPages } from "../../api/sio/surveyPage";

export const useSurveyPageStore = create(
    persist(
        (set) => ({
            surveyPages: [],
            pageData: {
                type: "",
                project_id: "",
                status: "enabled",
                title: "",
                description: "",
                button_text: "",
                button_url: "",
                bg_position: "",
                position_y: 50,
                pageId: "",
                banner_image: ""
            },
            setPageData: (newData) => set({ pageData: { ...newData } }),
            resetPageData: () =>
                set({
                    pageData: {
                        type: "",
                        project_id: "",
                        status: "enabled",
                        title: "",
                        description: "",
                        button_text: "",
                        button_url: "",
                        bg_position: "",
                        position_y: 50,
                        pageId: "",
                        banner_image: ""
                    },
                }),
            isLoadingList: true,
            isError: false,
            error: '',
            fetchSurveyPages: async (projectId) => {
                const response = await getSurveyPages(projectId);
                if (response.status == 200){
                    set({ isLoadingList: false, surveyPages: response.data })
                }else {
                    set({ isLoadingList: false, isError: true, error: response.error })
                }
            },
            fetchSurveyPage: async (pageId) => {
                const response = await getSurveyPage(pageId);
                if (response.status == 200){
                    set({ pageData: { ...response.data[0], pageId: response.data[0].id } })
                }else {
                    set({ isError: true, error: response.error })
                }
            },
        }),
        {
            name: "pageData",
            partialize: (state) => ({
                pageData: state.pageData,
            }),
        }
    )
);
