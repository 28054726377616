import React from 'react';
import ReactDOM from "react-dom/client";
import { App } from "../../../App";
import { DateFilter } from './DateFilter';
import { CampaignsForDayTable } from './table/CampaignsForDayTable';
import { DashboardMkt } from './DashbaordMkt';
import { useBoundMktStore } from '../../../store/ReportsCampaignMkt/useBoundMktStore';
import { ItemsData } from './charts/ItemsData';
import { CampaignForProfilesTable } from './table/CampaignForProfilesTable';
import { CampaignForSurveyTable } from './table/CampaignForSurveyTable';

export const CampaignForDay = ({ id }) => {
    const { errors, data } =  useBoundMktStore( state => state );
    const { campaign:errorsC} = errors;
    const { campaign } = data
    return (
        <>
            <div className="card mb-2">
                <div className="card card-header text-center"><strong>{ campaign.name?.toUpperCase() }</strong></div>
                <div className="card card-header"><strong>Filtro de datos</strong></div>
                <div className="card card-body"><DateFilter campaign_id={ id } /></div>
                <div className="card card-body">
                    <ItemsData/>
                </div>
            </div>
            <div className="accordion" id="accordionID">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingDaily">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRegisterDaily" aria-expanded="false" aria-controls="collapseRegisterDaily">
                        <strong>Registros diarios de la campaña</strong>
                    </button>
                    </h2>
                    <div id="collapseRegisterDaily" className="accordion-collapse collapse" aria-labelledby="collapseRegisterDaily" data-bs-parent="#accordionID">
                    <div className="accordion-body">
                        {
                            errorsC.status ? ( 
                                <div>
                                    <div className="alert alert-danger"><span>Este campaña no se encuentra registrada en nuestra base de datos</span></div>
                                </div>
                            ) : ( 
                                <CampaignsForDayTable />
                            )
                        }
                    </div>
                    </div>
                </div>
                <div className="accordion-item mt-2">
                    <h2 className="accordion-header" id="headingRegister">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRegister" aria-expanded="false" aria-controls="collapseRegister">
                        <strong>Perfilamientos respondidos</strong>
                    </button>
                    </h2>
                    <div id="collapseRegister" className="accordion-collapse collapse" aria-labelledby="headingRegister" data-bs-parent="#accordionID">
                    <div className="accordion-body">
                        {
                            errorsC.status ? ( 
                                <div>
                                    <div className="alert alert-danger"><span>Este campaña no se encuentra registrada en nuestra base de datos</span></div>
                                </div>
                            ) : ( 
                                <>
                                    <CampaignForProfilesTable />
                                </>
                            )
                        }
                    </div>
                    </div>
                </div>
                <div className="accordion-item mt-2">
                    <h2 className="accordion-header" id="headingSurveyResponse">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSurveyResponse" aria-expanded="false" aria-controls="collapseSurveyResponse">
                        <strong>Encuestas respondidos</strong>
                    </button>
                    </h2>
                    <div id="collapseSurveyResponse" className="accordion-collapse collapse" aria-labelledby="headingSurveyResponse" data-bs-parent="#accordionID">
                    <div className="accordion-body">
                        {
                            errorsC.status ? ( 
                                <div>
                                    <div className="alert alert-danger"><span>Este campaña no se encuentra registrada en nuestra base de datos</span></div>
                                </div>
                            ) : ( 
                                <>
                                    <CampaignForSurveyTable />
                                </>
                            )
                        }
                    </div>
                    </div>
                </div>
            </div>
            <DashboardMkt  id={id}/>
        </>
    )
}


if (document.getElementById("jsx-reports-mkt-campaigns-for-day")) {
    const el = document.getElementById("jsx-reports-mkt-campaigns-for-day");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <CampaignForDay {...props} />
        </App>
    );
}