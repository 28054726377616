import { create } from "zustand";
import {
    createFriendPoint,
    getFriendPoints,
    updateFriendPoint,
} from "../../api/sip/friend-points";

const INITIAL_FRIEND_POINT = {
    description: "",
    points: 0,
    payload: {
        gender: [],
        geographyLevel: "",
        geographyData: [],
        age: { min: 0, max: 0 },
    },
    platformMail: { platformMail: "MAILJET", idPlatformMail: "5812562" },
    country: "",
};

export const useFriendPointsStore = create((set, get) => {
    return {
        friendPoints: [],
        isLoading: true,
        isError: false,
        error: "",
        fetchFriendPoints: async () => {
            try {
                const response = await getFriendPoints();
                set({
                    friendPoints: response.data,
                    isLoading: false,
                });
            } catch (error) {
                set({
                    friendPoints: [],
                    isLoading: false,
                    isError: true,
                    error: response.data,
                });
            }
        },
    };
});

export const useFriendPointStore = create((set, get) => {
    return {
        friendPoint: INITIAL_FRIEND_POINT,
        //friendPointId: null,
        //setFriendPointId: (newFriendPointId) => set({ friendPointId: newFriendPointId }),
        setFriendPoint: (newFriendPoint) => set({ friendPoint: { ...newFriendPoint } }),
        resetFriendPoint: () => set({ friendPoint: INITIAL_FRIEND_POINT, isError: false, error: [] }),
        isLoading: true,
        isError: false,
        error: [],
        createFriendPoint: async (friendPoint) => {
            set({ isLoading: true });
            try {
                const response = await createFriendPoint(friendPoint);
                set({ isLoading: false });
                return response.status;
            } catch (errors) {
                set({
                    isLoading: false,
                    isError: true,
                    error: errors,
                });
                return errors.status;
            }
        },
        updateFriendPoint: async (friendPoint) => {
            set({ isLoading: true });
            try {
                const response = await updateFriendPoint(friendPoint);
                set({ isLoading: false });
                return response.status;
            } catch (errors) {
                set({
                    isLoading: false,
                    isError: true,
                    error: errors,
                });
                return errors.status;
            }
        },
    };
});
