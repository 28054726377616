import { useState } from "react";
import Select from "react-select";

export const TypeSubsetSelect = ({ segmentSubset, setSegmentSubset }) => {
    const [type, setType] = useState({
        options: [
            { value: "lastinteraction", label: "Última actividad" },
            { value: "portion", label: "Cantidad %" },
        ],
        selected:
            segmentSubset.type == "lastinteraction"
                ? { value: "lastinteraction", label: "Última actividad" }
                : { value: "portion", label: "Cantidad %" },
    });
    return (
        <>
            <label htmlFor="last-interaction">Seleccionar por</label>
            <Select
                key={"type-asdas234"}
                className="basic-select"
                options={type.options}
                value={type.selected}
                onChange={(e) => {
                    setType({
                        ...type,
                        selected: { value: e.value, label: e.label },
                    });
                    setSegmentSubset({
                        ...segmentSubset,
                        type: e.value,
                    });
                }}
            />
        </>
    );
};
