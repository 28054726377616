import { format } from "date-fns";
import { axiosApi } from "../axiosApi";


export const getPanelistsOfMonth = async (preset,status) => {
    let _s = status.map(m => m.value).toString();
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/panelistas/encuestas/mes`);
    url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
    url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    url.searchParams.set('status', _s);
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const getPanelistsOfQuarter = async (preset,status) => {
    let _s = status.map(m => m.value).toString();
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/panelistas/encuestas/trimestre`);
    url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
    url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    url.searchParams.set('status', _s);
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const getPanelistsOfSemester = async (preset,status) => {
    let _s = status.map(m => m.value).toString();
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/panelistas/encuestas/semestre`);
    url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
    url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    url.searchParams.set('status', _s);
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const getPanelistsOfYear = async (preset,status) => {
    let _s = status.map(m => m.value).toString();
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/panelistas/encuestas/anho`);
    url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
    url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    url.searchParams.set('status', _s);
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}