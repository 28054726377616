import React, { useMemo }  from 'react';
import {MaterialReactTable} from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';
import { v4 as uuidv4 } from 'uuid';


export const DailyProgressTable = ({ daily }) => {

    const columns = useMemo( () =>[
        {
            accessorKey:'datestamp',
            header: 'Fecha',
        },
        {
            header:'IR',
            Cell:({ row }) => {
                let ir = row.original.completed > 0
                ? Math.round((row.original.completed / (row.original.completed + row.original.filtered))*100) 
                : "0";
                return `${ ir }%`
            }
        },
        {
            accessorKey:'completed',
            header:'Completas'
        },
        {
            accessorKey:'filtered',
            header:'Filtradas',
        },
        {
            accessorKey:'parcial',
            header:'Parciales',
        }
    ],[])

    const exportDailyProgress = (rows) => {
        const calc  =  rows.map( row => {
            const ir = row.original.completed > 0 ? Math.round((row.original.completed / (row.original.completed + row.original.filtered))*100)  : 0;
            return {
                'Fecha': row.original.datestamp, 
                'IR':  ir, 
                'Completas': row.original.completed,
                'Filtradas': row.original.filtered,
                'Parciales': row.original.parcial
            }
        })
        const csvOptions = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            headers: columns.map((c) => c.header),
            filename: uuidv4()
        };
        const csvExporter = new ExportToCsv(csvOptions); 
        csvExporter.generateCsv( calc.map( (row) => row ));
    }

    return (
        <>
            <MaterialReactTable 
                columns={columns} 
                data={daily} 
                muiTablePaperProps={{
                    //customize paper styles
                    sx: {
                        boxShadow: 'none !important',
                        border: 'none',
                    },
                }}
                enableColumnActions={false}
                enableColumnFilters={true}
                enablePagination={true}
                enableSorting={true}
                enableBottomToolbar={true}
                enableTopToolbar={true}
                enableFullScreenToggle={true}
                enableDensityToggle={false}
                enableFilters={false}
                enableHiding={false}
                renderTopToolbarCustomActions= {
                    ({table}) => (
                        <div style={{ display:'flex', gap:'5px' }}>
                            <a 
                                style={{ backgroundColor: 'var(--bs-cyan)'}}
                                onClick={() => exportDailyProgress(table.getPrePaginationRowModel().rows)}
                                className="btn btn-default">
                                <i className="fas fa-download m-1"></i>Descargar
                            </a>

                        </div>
                    )
                }
            />
        </>
    )
}