export const initialStateFilter = {
    id: 0,
    gender: [],
    geographyLevel: "",
    geographyData: [],
    age: { min: 0, max: 0 },
    inQuarantine: false,
    projectsInQuarantine: [],
    typeNse: "sin_nse",
    nse: [],
    project_id: 0,
};