import React from 'react';
import ReactDOM from 'react-dom/client';
import { GenderPieChart } from './charts/GenderPieChart';
import { RegisterLineChart } from './charts/RegisterLineChart';
import { App } from '../../App';
import { AgesBarChart } from './charts/AgesBarChart';
import { NseBarChart } from './charts/NseBarChart';
import { GeoBarChart } from './charts/GeoBarChart';
import { StatusPanelistPieChart } from './charts/StatusPanelistPieChart';
import { DatePickerFilter } from './filters/Datepicker';
import { DashboardProvider } from '../../providers/DashboardProvider';

export const Dashboard = () => {

    return (
        <>
            <div className="row">
                <div className='col-md-12'>
                    <div className="card card-body">
                        <DatePickerFilter />
                    </div>
                </div>
                <div className='col-md-12 mt-4'>
                    <div className="card card-body">
                        <RegisterLineChart />
                    </div>
                </div>
                <div className="col-md-6 mt-4">
                    <div className="card card-body">
                        <GenderPieChart />
                    </div>
                </div>
                <div className="col-md-6 mt-4">
                    <div className=" card card-body">
                        <AgesBarChart />
                    </div>
                </div>
                <div className="col-md-6 mt-4">
                    <div className=" card card-body">
                        <NseBarChart />
                    </div>
                </div>
                <div className="col-md-6 mt-4">
                    <div className="card card-body">
                        <StatusPanelistPieChart />
                    </div>
                </div>
                <div className="col-md-12 mt-4">
                    <div className=" card card-body">
                        <GeoBarChart />
                    </div>
                </div>
            </div>
        </>
    )
}
if (document.getElementById('jsx-dashboard-initial')) {
    const el = document.getElementById("jsx-dashboard-initial");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
        <DashboardProvider>
              <Dashboard { ...props } />
        </DashboardProvider>
      </App>
  )
}