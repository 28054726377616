import {MaterialReactTable, useMaterialReactTable} from 'material-react-table';
import React, { useMemo, useState } from 'react';
import { currencyFormatter } from '../../../../helper/currencyFormatter';
import { useBoundMktStore } from '../../../../store/ReportsCampaignMkt/useBoundMktStore';

export const CampaignsForDayTable = () => {
    const { loading, errors, data, fetchRegisterCostCampaign,registerL } =  useBoundMktStore( state => {
        return {
            loading: state.loadings.daily,
            registerL: state.loadings.registerCost,
            errors: state.errors.daily,
            data: state.data.daily,
            fetchRegisterCostCampaign: state.fetchRegisterCostCampaign,
        }
    });
    const [ payload, setPayload ] = useState({});
    const columns = useMemo(() => [
        {
            header:'Acciones',
            Cell:( { row, table}) => {
                return (
                    <div style={{ display: 'flex',flexWrap:'wrap',gap:'10px',justifyContent:'center', alignItems:'center',cursor:'pointer' }}>
                        <a style={{ fontSize:'20px', color:'var(--bs-gray-800)'}}><i className="fas fa-edit" onClick={ () => {
                            setPayload({ ...row.original });
                            table.setEditingRow(row);
                        } }></i></a>
                    {/* <a style={{ fontSize:'20px'}}><i className="fas fa-chart-area"></i></a> */}
                    </div>
                )}
        },{
            accessorKey: 'total',
            header:'Total'
        },
        {
            accessorKey: 'registration_at',
            header:'Fecha registro'
        },{
            accessorKey:'daily_cost',
            header: 'Monto diario',
            Cell:({ row }) => ( currencyFormatter({
                value:row.original.daily_cost,
                currency:'CLP'
            }))
        },{
            
            header:'Costo por registro',
            accessorFn: (row) => {
                const { total, daily_cost } = row;
                let _calc = 0;
                if(daily_cost > 0 && total == 0){
                    _calc = daily_cost;
                }
                return currencyFormatter({
                    value: _calc == 0 ? 0 : (_calc / total).toFixed(2),
                    currency: 'CLP'
                })
            },
            Cell:({row}) => {
                const { total, daily_cost } = row.original;
                let value = 0;
                value = (daily_cost / total).toFixed(2);
                if(daily_cost > 0 && total == 0){
                    value = daily_cost;
                }
                if(daily_cost == 0){
                    value = 0;
                }
                return currencyFormatter({
                    value,
                    currency: 'CLP'
                })
            }
        }
    ],[]);

    const handleUpdate = ( e, table ) => {
        e.preventDefault();
        fetchRegisterCostCampaign(payload, table);
    }


    const renderEditData = ({ table, row  }) => {
            return (
                <div className="p-3">
                    <div style={{ display:'flex', justifyContent: 'center', margin:'10px 0' }}>
                        <label htmlFor=""><strong>{ payload.utm_source }</strong></label>
                    </div>
                    <form onSubmit={ (e) => handleUpdate(e, table)  }>
                    <div className="mb-3">
                        <label className="form-label">Fecha registro:</label>
                        <input hidden type="text" name="id" className="form-control" id="id-id" defaultValue={ payload.id } />
                        <input disabled type="text" name="registration_at" className="form-control" id="registration_at-id" defaultValue={ payload.registration_at } />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Monto diario:</label>
                        <input type="number" name="daily_cost"   className="form-control" id="daily_cost-id" value={ payload.daily_cost  } onChange={ (e) => setPayload({ ...payload, daily_cost: e.target.value}) } />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Total de registro:</label>
                        <input disabled type="text" name="total"  className="form-control" id="total-id" value={ payload.total } />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Costo por registro:</label>
                        <input disabled type="text" name="cost_per_registration"  className="form-control" id="cost_per_registration-id" value={ currencyFormatter({
                            value: ((payload.daily_cost) / payload.total).toFixed(4),
                            currency: 'CLP' 
                        }) } />
                    </div>
                    <div style={{ display:'flex', justifyContent:'space-between', gap:20}}>
                        {
                            registerL ? (
                                <button className="btn btn-primary-oo" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Registrando información...
                                </button>
                            ):(
                                <button className="btn btn-sm btn-primary-oo text-light flex-fill">Guardar</button>
                            )
                        }
                        <a className="btn btn-sm btn-light text-dark flex-fill" onClick={ () => table.setEditingRow(null) }>Cancelar</a>
                    </div>
                    </form>
                </div>
            )
    }

    const table = useMaterialReactTable({
        columns: columns,
        data:data,
        editingRow:true,
        muiTablePaperProps: {
            sx: { boxShadow:'none !important', border:'none' }
        },
        muiToolbarAlertBannerProps: errors.status ? { color: 'error', children: errors.message } : undefined,
        // enablePinning:true,
        
        state:{
            isLoading:loading,
            showAlertBanner: errors.status,
            density:"compact"
        },
        enablePagination:false,
        enableSorting:true,
        enableFullScreenToggle:false,
        enableDensityToggle:false,
        enableFilters:true,
        enableHiding:false,
        enableBottomToolbar:false,
        enableTopToolbar:true,
        enableColumnActions:true,
        renderEditRowDialogContent: ({ table, row }) => renderEditData({ table, row })
    });
    return (
        <div>
            <MaterialReactTable table={ table } />
        </div>
    )
}