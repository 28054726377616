import { axiosApi } from "../axiosApi";

export const getAnswers = async (question) => {
    var url = `/api/respuesta/referencia/${question.projectId}/sid/${question.sid}/gid/${question.gid}/qid/${question.qid}/tipo/${question.type}/parent-type/${question.parentType}`;
    if(question.hash){
        url = `/api/cliente/monitor/h/${question.hash}/p/${question.projectId}/respuesta/referencia/${question.projectId}/sid/${question.sid}/gid/${question.gid}/qid/${question.qid}/tipo/${question.type}/parent-type/${question.parentType}`;
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    } 
}

export const getSubAnswers = async(question) => {
    var url = `/api/respuesta/referencia/${question.projectId}/sid/${question.sid}/gid/${question.gid}/qid/${question.qid}/codigo/${question.code}/tipo/${question.type}/parent-type/${question.parentType}/parent-qid/${question.parent_qid}/sub-respuestas`;
    if(question.hash){
        url = `/api/cliente/monitor/h/${question.hash}/p/${question.projectId}/respuesta/referencia/${question.projectId}/sid/${question.sid}/gid/${question.gid}/qid/${question.qid}/codigo/${question.code}/tipo/${question.type}/parent-type/${question.parentType}/parent-qid/${question.parent_qid}/sub-respuestas`
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response }= error;
        throw response;
    }
}