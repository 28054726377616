import { useCallback } from "react";
import { ListItemIcon, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { usePanelistsStore } from "../../../../store/PanelistsInsight/usePanelistsStore";
import { useAmbassadorStore } from "../../../../store/Ambassador/useAmbassadorStore";
import Swal from "sweetalert2";

export const PanelistsTableRowActionMenuItems = ({ closeMenu, seeModal, setSeeModal, setPanelist, row }) => {

    const { fetchPanelists } = usePanelistsStore((state) => state);

    const {
        fetchAcceptAmbassadorCandidate,
        fetchRejectAmbassadorCandidate,
        fetchCancelAmbassador,
    } = useAmbassadorStore((state) => state);

    const handleAcceptAmbassadorCandidate = useCallback(async (candidateToken) => {
        Swal.fire({
            title: "¿Aceptar candidato a embajador?",
            text: "Esta acción convertirá al panelista en Embajador",
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar"
        }).then((res) => {
            if (res.isConfirmed) {
                const _candidate = {
                    token: candidateToken,
                };
                fetchAcceptAmbassadorCandidate(_candidate)
                    .then((res) => {
                        if (res.status == 200) {
                            Swal.fire({
                                title: '¡Listo!',
                                text: res.message,
                                icon: 'success'
                            });
                            fetchPanelists();
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: res.message,
                                icon: 'warning'
                            });
                        }
                    })
                    .catch((resp) => {
                        Swal.fire({
                            title: '¡Error!',
                            html: `<ul>${resp.errors.errors.token.map(error => `<li>${error}</li>`)}${resp.errors.errors.type.map(error => `<li>${error}</li>`)}</ul>`,
                            icon: 'error'
                        });
                    });
            }
        });
    }, [row]);

    const handleRejectAmbassadorCandidate = useCallback(async (candidateToken) => {
        Swal.fire({
            title: "¿Rechazar postulación?",
            text: "Esta acción rechazará al candidato y lo dejará como panelista común",
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar"
        }).then((res) => {
            if (res.isConfirmed) {
                const _candidate = {
                    token: candidateToken,
                };
                fetchRejectAmbassadorCandidate(_candidate)
                    .then((res) => {
                        if (res.status == 200) {
                            Swal.fire({
                                title: '¡Listo!',
                                text: res.message,
                                icon: 'success'
                            });
                            fetchPanelists();
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: res.message,
                                icon: 'warning'
                            });
                        }
                    })
                    .catch((resp) => {
                        Swal.fire({
                            title: '¡Error!',
                            html: `<ul>${resp.errors.errors.token.map(error => `<li>${error}</li>`)}${resp.errors.errors.type.map(error => `<li>${error}</li>`)}</ul>`,
                            icon: 'error'
                        });
                    });
            }
        });
    }, [row]);

    const handleCancelAmbassador = useCallback(async (ambassadorToken) => {
        Swal.fire({
            title: "¿Quitar rol de Embajador?",
            text: "Esta acción convertirá al embajador en un panelista común y ya no recibirá más recompensas de embajador",
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar"
        }).then((res) => {
            if (res.isConfirmed) {
                const _ambassador = {
                    token: ambassadorToken,
                };
                fetchCancelAmbassador(_ambassador)
                    .then((res) => {
                        if (res.status == 200) {
                            Swal.fire({
                                title: '¡Listo!',
                                text: res.message,
                                icon: 'success'
                            });
                            fetchPanelists();
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: res.message,
                                icon: 'warning'
                            });
                        }
                    })
                    .catch((resp) => {
                        Swal.fire({
                            title: '¡Error!',
                            html: `<ul>${resp.errors.errors.token.map(error => `<li>${error}</li>`)}${resp.errors.errors.type.map(error => `<li>${error}</li>`)}</ul>`,
                            icon: 'error'
                        });
                    });
            }
        });
    }, [row]);

    return (
        <>
            <MenuItem
                key={row.original.token + Math.random()}
                onClick={() => {
                    closeMenu();
                    setPanelist({ ...row.original });
                    setSeeModal({ ...seeModal, edit: true });
                }}
            >
                <ListItemIcon sx={{ margin: 0, padding: 0 }}><EditIcon fontSize="18px" /></ListItemIcon>
                Ver y editar
            </MenuItem>
            <MenuItem
                key={row.original.token + Math.random()}
                onClick={() => {
                    closeMenu();
                    setPanelist({ ...row.original });
                    setSeeModal({ ...seeModal, addPoints: true });
                }}
            >
                <ListItemIcon sx={{ margin: 0, padding: 0 }}><AddIcon fontSize="18px" /></ListItemIcon>
                Añadir puntos
            </MenuItem>
            {row.original.type === 'ambassador_candidate' && (
                <>
                    <MenuItem
                        key={row.original.token + Math.random()}
                        onClick={() => {
                            closeMenu();
                            setPanelist({ ...row.original });
                            handleAcceptAmbassadorCandidate(row.original.token);
                        }}
                    >
                        <ListItemIcon sx={{ margin: 0, padding: 0 }}><ThumbUpIcon fontSize="18px" /></ListItemIcon>
                        Aceptar candidato a embajador
                    </MenuItem>
                    <MenuItem
                        key={row.original.token + Math.random()}
                        onClick={() => {
                            closeMenu();
                            setPanelist({ ...row.original });
                            handleRejectAmbassadorCandidate(row.original.token);
                        }}
                    >
                        <ListItemIcon sx={{ margin: 0, padding: 0 }}><ThumbDownIcon fontSize="18px" /></ListItemIcon>
                        Rechazar candidato a embajador
                    </MenuItem>
                </>
            )}
            {row.original.type === 'ambassador' && (
                <>
                    <MenuItem
                        key={row.original.token + Math.random()}
                        onClick={() => {
                            closeMenu();
                            setPanelist({ ...row.original });
                            setSeeModal({ ...seeModal, ambassadorResume: true });
                            // handleCancelAmbassador(row.original.token);
                        }}
                    >
                        <ListItemIcon sx={{ margin: 0, padding: 0 }}><AccountBoxIcon fontSize="18px" /></ListItemIcon>
                        Ver ficha de embajador
                    </MenuItem>
                    <MenuItem
                        key={row.original.token + Math.random()}
                        onClick={() => {
                            closeMenu();
                            setPanelist({ ...row.original });
                            handleCancelAmbassador(row.original.token);
                        }}
                    >
                        <ListItemIcon sx={{ margin: 0, padding: 0 }}><CancelIcon fontSize="18px" /></ListItemIcon>
                        Quitar rol de Embajador
                    </MenuItem>
                </>
            )}
        </>
    );
}