import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "react-query";
import Select from "react-select";
import Swal from "sweetalert2";
import { App } from "../../../App";
import { useGetProjects } from "../../../hooks/useProject";
import TextField from '@mui/material/TextField';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/de';
import moment from 'moment'
import { useGetMonitor } from "../../../hooks/useMonitor";
import { editMonitor } from "../../../api/sio/monitor";

export const EditMonitor = ({ id }) => {

    const { monitor, setMonitor, isLoading, refetch:rGetMonitor } = useGetMonitor(id);
    const { projects, isLoading:isLoadingProject, isError:isErrorProjects, error:errorProjects } = useGetProjects();
    const {
        mutate: mEditMonitor, isLoading:isLoadingC,error,isError} = useMutation(editMonitor, {
        onSuccess: () => {
            Swal.fire("Editar monitor", "Monitor editado", "success").then(
                (result) => location.replace("/monitor/")
            );
        },
    });


    const handleEditMonitor = (e) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Quieres editar el monitor?",
            showCancelButton: true,
            confirmButtonText: "Editar",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed) {
                mEditMonitor(monitor);
            } else if (result.isDenied) {
                Swal.fire(
                    "No se pudo editar el monitor, intenta nuevamente.",
                    "",
                    "error"
                );
            }
        });
    };

    return (
        <>
            {
                isLoading ? (<Skeleton height={400} />) : (
                    <div className="card">
                        <div className="card-header">
                            <div className="content-head-card">
                                <label>Editar monitor para cliente</label>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleEditMonitor}>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label className="my-1 ">Titulo</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            id="name-input"
                                            value={monitor.name}
                                            onChange={(e) =>
                                                setMonitor({
                                                    ...monitor,
                                                    name: e.target.value,
                                                })
                                            }
                                        />
                                        {isError && (
                                            <p className="text text-danger">
                                                {error.data.errors.name?.map((x) => x)}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        {
                                            (isLoadingProject) ? (<Skeleton height={50}/>) : 
                                            (
                                                <>
                                                {
                                                    projects.length == 0 ? (<label className="my-1 fw-bold text-danger">No se encuentran los proyectos</label>)  : (
                                                        <>
                                                        <label className="my-1">Asociar proyectos</label>
                                                        <Select 
                                                            isClearable
                                                            isMulti
                                                            className="basic-select"
                                                            defaultValue={ monitor.selectedProjects.map(item => ({ value:item.id, label: item.name }))}
                                                            options= {projects.map( x => ({ value: x.id, label: x.name })) }
                                                            onChange = { (e) => {
                                                                setMonitor({...monitor, projects: e.map(item => item.value).join()})
                                                            }}
                                                        />
                                                        {isError && (
                                                            <p className="text text-danger">
                                                                {error.data.errors.projects?.map((x) => x)}
                                                            </p>
                                                        )}
                                                        </>
                                                    )
                                                }
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className="col-md-12">
                                    <label htmlFor="" className="form-label">Fecha expiración</label>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
                                            <DateTimePicker
                                                ampm={false}
                                                className="form-control"
                                                inputFormat="YYYY-MM-DD HH:mm"
                                                value={ monitor.expired_at }
                                                onChange={ (e) => {
                                                    setMonitor({...monitor, expired_at: moment(e).utc(true).format('YYYY-MM-DD HH:mm:ss')}) 
                                                }}
                                                renderInput={(params) => <TextField size="small" {...params} />}
                                            />
                                        </LocalizationProvider>
                                            {
                                                isError && (
                                                        <p className="text text-danger">{error.data.errors?.expired_at?.map( x => x)}</p>
                                                )
                                            }
                                    </div>
                                    <div className="form-group col-md-12 mt-2">
                                        {isLoadingC ? (
                                            <button className="btn btn-primary-oo">
                                                Cargando...
                                            </button>
                                        ) : (
                                            <button className="btn btn-primary-oo">
                                                Editar monitor
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            }
        
        </>

    );
};

if (document.getElementById("jsx-edit-monitor")) {
    const el = document.getElementById("jsx-edit-monitor");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <EditMonitor {...props} />
        </App>
    );
}

