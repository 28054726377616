import { useCallback, useEffect, useMemo, useState } from "react";
import { ModalLayout } from "../../../ui/modal/ModalLayout";
import Select from 'react-select';
import { PANELIST_STATUS_OPTIONS, PANELIST_GENDER_VALUES, PANELIST_SINO_VALUES, PANELIST_TYPE_OPTIONS, PANELIST_TYPE_VALUES } from "../../../../helper/panelists";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { usePanelistsStore } from "../../../../store/PanelistsInsight/usePanelistsStore";
import Swal from "sweetalert2";

export const EditPanelist = ({ setSeeModal, panelist, pagination, columnFilters }) => {

    const [currentPoints, setCurrentPoints] = useState(0);

    const [selectStatus, setSelectStatus] = useState({
        options: PANELIST_STATUS_OPTIONS,
        selected: PANELIST_STATUS_OPTIONS.find(x => x.value == panelist.status)
    });

    const [editablePanelist, setEditablePanelist] = useState({
        token: panelist.token,
    });

    const [enableEdit, setEnableEdit] = useState(false);

    const {
        fetchPanelists,
        isErrorUpdate,
        fetchUpdatePanelists,
        isLoadingGetPoints,
        fetchPanelistPoints
    } = usePanelistsStore(state => state);

    const handleEnableEdit = useCallback(() => {
        setEnableEdit(prevEnableEdit => !prevEnableEdit);
    }, []);

    const handleKeyUpPhoneValidation = useCallback((e) => {
        const { value } = e.target;
        let regex = /^\+?[0-9]{0,15}$/;
        if (!regex.test(value)) {
            setEditablePanelist(prevEditablePanelist => ({
                ...prevEditablePanelist,
                phone: ''
            }));
        }
    }, []);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setEditablePanelist(prevEditablePanelist => ({
            ...prevEditablePanelist,
            [name]: value
        }));
    }, []);

    const handleStatusChange = useCallback((e) => {
        setSelectStatus(prevSelectStatus => ({ ...prevSelectStatus, selected: e }));
        setEditablePanelist(prevEditablePanelist => ({ ...prevEditablePanelist, status: e.value }));
    }, []);

    const handleGetPanelistPoints = useCallback(async () => {
        fetchPanelistPoints(panelist.token)
            .then((points) => {
                setCurrentPoints(points);
            })
            .catch(() => {
                setCurrentPoints(null);
            });
    }, [panelist.token]);

    const handleSubmitForm = useCallback(async (e, toUpdate) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Actualizar?",
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar"
        }).then((res) => {
            if (res.isConfirmed) {
                const keys = Object.keys(toUpdate);
                let hasEditableProps = false;
                for (const key of keys) {
                    if (key !== 'token') {
                        hasEditableProps = true;
                        break;
                    }
                }
                if (hasEditableProps) {
                    fetchUpdatePanelists(toUpdate).then((res) => {
                        if (res.status == 200) {
                            setSeeModal(prevSeeModal => ({
                                ...prevSeeModal,
                                edit: !prevSeeModal.edit,
                            }));
                            Swal.fire({
                                title: 'OK',
                                text: res.message,
                                icon: 'success'
                            });
                            fetchPanelists(pagination, columnFilters)
                        } else {
                            Swal.fire({
                                title: 'No se actualizó',
                                text: res.message,
                                icon: 'warning'
                            });
                        }
                    });
                } else {
                    Swal.fire({
                        title: 'No hay datos',
                        text: 'No hay datos para actualizar',
                        icon: 'info'
                    });
                }
            }
        });
    }, [editablePanelist]);

    //Obtener puntos actuales del panelista
    useEffect(() => { handleGetPanelistPoints(); }, [panelist.token]);

    const EnableEditButton = useMemo(() => {
        return (
            <button
                className="btn btn-primary-oo ms-3"
                onClick={handleEnableEdit}>
                {enableEdit ? 'Editando...' : "Editar"}
            </button>
        );
    }, [enableEdit, handleEnableEdit]);

    const EditPanelistForm = useMemo(() => {
        return (
            <form onSubmit={(e) => handleSubmitForm(e, editablePanelist)}>
                <div className="row form-group my-3">
                    <div className="col-12 col-md-6">
                        <label htmlFor="token">Token</label>
                        <input
                            className="form-control"
                            name="token"
                            type="text"
                            value={panelist.token}
                            disabled
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="status">Status</label>
                        <Select
                            name="status"
                            options={selectStatus.options}
                            value={selectStatus.selected}
                            onChange={handleStatusChange}
                            isDisabled={!enableEdit}
                        />
                        {
                            isErrorUpdate.status && (
                                <p className="text text-danger">{isErrorUpdate.message.errors?.status?.map(x => x)}</p>
                            )
                        }
                    </div>
                </div>
                <div className="row form-group my-3">
                    <div className="col-12 col-md-6">
                        <label htmlFor="name">Nombre</label>
                        <input
                            className="form-control"
                            name="name"
                            type="text"
                            value={editablePanelist.name ? editablePanelist.name : panelist.name}
                            onChange={handleInputChange}
                            disabled={!enableEdit}
                        />
                        {
                            isErrorUpdate.status && (
                                <p className="text text-danger">{isErrorUpdate.message.errors?.name?.map(x => x)}</p>
                            )
                        }
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="email">Email</label>
                        <input
                            className="form-control"
                            name="email"
                            type="email"
                            value={editablePanelist.email ? editablePanelist.email : panelist.email}
                            onChange={handleInputChange}
                            disabled={!enableEdit}
                        />
                        {
                            isErrorUpdate.status && (
                                <p className="text text-danger">{isErrorUpdate.message.errors?.email?.map(x => x)}</p>
                            )
                        }
                    </div>
                </div>
                <div className="row form-group my-3">
                    <div className="col-12 col-md-6">
                        <label htmlFor="dni">DNI/RUT</label>
                        <input
                            className="form-control"
                            name="dni"
                            type="text"
                            value={editablePanelist.dni ?? panelist.dni ?? ""}
                            onChange={handleInputChange}
                            disabled={!enableEdit}
                        />
                        {
                            isErrorUpdate.status && (
                                <p className="text text-danger">{isErrorUpdate.message.errors?.dni?.map(x => x)}</p>
                            )
                        }
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="phone">Teléfono celular</label>
                        <input
                            name="phone"
                            className="form-control"
                            type="text"
                            placeholder="Ejemplo: +56912341234"
                            value={editablePanelist.phone ?? panelist.phone ?? ""}
                            onChange={handleInputChange}
                            onKeyUp={handleKeyUpPhoneValidation}
                            disabled={!enableEdit}
                        />
                        {
                            isErrorUpdate.status && (
                                <p className="text text-danger">{isErrorUpdate.message.errors?.phone?.map(x => x)}</p>
                            )
                        }
                    </div>
                </div>
                <div className="row form-group my-3">
                    <div className="col-12 col-md-6">
                        <label htmlFor="doubleOptin">Tipo</label>
                        <input
                            className="form-control"
                            name="doubleOptin"
                            type="text"
                            value={PANELIST_TYPE_VALUES[panelist.type]}
                            disabled
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="gender">Género</label>
                        <input
                            className="form-control"
                            name="gender"
                            type="text"
                            value={PANELIST_GENDER_VALUES[panelist.gender]}
                            disabled
                        />
                    </div>
                </div>
                <div className="row form-group my-3">
                    <div className="col-12 col-md-6">
                        <label htmlFor="isProfiled">¿Está perfilado?</label>
                        <input
                            className="form-control"
                            name="isProfiled"
                            type="text"
                            value={PANELIST_SINO_VALUES[panelist.isProfiled]}
                            disabled
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="doubleOptin">¿Email validado?</label>
                        <input
                            className="form-control"
                            name="doubleOptin"
                            type="text"
                            value={PANELIST_SINO_VALUES[panelist.doubleOptin]}
                            disabled
                        />
                    </div>
                </div>
                <div className="row form-group my-3">
                    <div className="col-12 col-md-6">
                        <label htmlFor="registration_at">Fecha de registro</label>
                        <input
                            className="form-control"
                            name="registration_at"
                            type="text"
                            value={format(new Date(panelist.registration_at), 'dd-MM-yyyy hh:mm:ss')}
                            disabled
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="last_interaction">Última interacción</label>
                        <input
                            className="form-control"
                            name="last_interaction"
                            type="text"
                            value={format(new Date(panelist.last_interaction), 'dd-MM-yyyy hh:mm:ss')}
                            disabled
                        />
                    </div>
                </div>
                <div className="row form-group my-3">
                    <div className="col-12 col-md-6">
                        <label htmlFor="birthdate">Fecha de cumpleaños</label>
                        <input
                            name="birthdate"
                            className="form-control"
                            type="text"
                            value={format(new Date(parseISO(panelist.birthdate)), "dd 'de' MMMM", { locale: es })}
                            disabled
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="email">Puntos</label>
                        <input
                            className="form-control"
                            name="points"
                            type="text"
                            value={isLoadingGetPoints ? '...' : currentPoints}
                            disabled
                        />
                    </div>
                </div>
                <div className="row form-group my-3">
                    <div className="col-12 d-flex justify-content-end">
                        {enableEdit && (
                            <button
                                className="btn btn-primary-oo"
                            >
                                Guardar cambios
                            </button>
                        )}
                    </div>
                </div>
            </form>
        );
    }, [
        enableEdit,
        editablePanelist,
        selectStatus,
        handleInputChange,
        handleStatusChange,
        panelist,
        isErrorUpdate,
        currentPoints,
        isLoadingGetPoints
    ]);

    return (
        <ModalLayout
            closeFunction={() => {
                setSeeModal(prevSeeModal => ({
                    ...prevSeeModal,
                    edit: !prevSeeModal.edit,
                }));
                setEnableEdit(false);
            }}
            title={"Datos del panelista"}
            header={EnableEditButton}
            body={EditPanelistForm}
        />
    )
}