import { SURVEY_MESSAGE_EXAMPLE } from "../../../../../api/sio/surveyPage";
import { useSurveyPageStore } from "../../../../../store/SurveyPage/useSurveyPageStore";

export const RewardInputs = ({ handleChangeButtonText,handleChangeButtonUrl,isError,error }) => {
    const { pageData } = useSurveyPageStore((state) => state);
    return (
        <>
            <div className="form-group col-12 my-1">
                <label className="my-1 fw-bold">Texto botón</label>
                <input
                    className="form-control"
                    type="text"
                    name="button_text"
                    id="button_text"
                    onChange={ handleChangeButtonText }
                    value={pageData.button_text ?? ''}
                    placeholder={`Por ejemplo: ${SURVEY_MESSAGE_EXAMPLE.reward?.button_text}`}
                />
                {isError && (
                    <p className="text text-danger">
                        {error.data.errors?.button_text?.map((x) => x)}
                    </p>
                )}
            </div>
            <div className="form-group col-12 my-1">
                <label className="my-1 fw-bold">URL botón</label>
                <input
                    className="form-control"
                    type="text"
                    name="button_url"
                    id="button_url"
                    onChange={ handleChangeButtonUrl }
                    value={pageData.button_url ?? ''}
                    placeholder={`Por ejemplo: ${SURVEY_MESSAGE_EXAMPLE.reward?.button_url}`}
                />
                {isError && (
                    <p className="text text-danger">
                        {error.data.errors?.button_url?.map((x) => x)}
                    </p>
                )}
            </div>
        </>
    );
};
