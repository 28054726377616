export const PortionInput = ({ segmentSubset, setSegmentSubset }) => {

    const handleOnKeyUpNumberSet = (e) => {
        if (e.target.value < 1) {
            setSegmentSubset({
                ...segmentSubset,
                portion: 1,
            });
        }
        if (e.target.value > 100) {
            setSegmentSubset({
                ...segmentSubset,
                portion: 100,
            });
        }
    };

    return (
        <input
            type="number"
            className="form-control"
            min={1}
            max={100}
            placeholder="1% a 100%"
            value={segmentSubset.portion}
            onChange={(e) =>
                setSegmentSubset({
                    ...segmentSubset,
                    portion: e.target.value,
                })
            }
            onKeyUp={(e) => handleOnKeyUpNumberSet(e)}
        />
    );
};
