import { MaterialReactTable } from 'material-react-table';
import { useMemo } from 'react';
import { STATUS_SURVEY_MESSAGES } from '../../../../helper/surveyPage';
import { useSurveyPageStore } from '../../../../store/SurveyPage/useSurveyPageStore';

export const PagesList = ({seeModal, setSeeModal, setPageId, projectId}) => {
    const { surveyPages } = useSurveyPageStore((state) => state);

    const columns = useMemo(
        () => [
            {
                accessorKey: "status",
                header: "Estado",
                size: 5,
                filterVariant: "select",
                filterSelectOptions: Object.entries(STATUS_SURVEY_MESSAGES).map(
                    ([clave, valor]) => ({ text: valor, value: clave })
                ),
                Cell: ({ cell }) => {
                    return STATUS_SURVEY_MESSAGES[cell.getValue()];
                },
            },
            {
                accessorKey: "type",
                header: "Tipo",
            },
            {
                accessorKey: "title",
                header: "Titulo",
            },
            {
                header: "Acciones",
                size: 3,
                Cell: ({ cell }) => {
                    const { type, bg_position, position_y, project_id, status, title, description, button_text, button_url, id, banner_image } = cell.row.original;
                    const data = { type, project_id, status, title, description, button_text, button_url, pageId: id, banner_image, bg_position, position_y };
                    return (
                        <>
                            <button
                                className="btn btn-warning btn-sm"
                                onClick={() => {
                                    setSeeModal({
                                        ...seeModal,
                                        edit: !seeModal.edit
                                    });
                                    setPageId(cell.row.original.id)
                                }}
                            ><i className="fa-solid fa-pen-to-square"></i></button>
                            <a
                                href={`/proyecto/${ projectId }/pagina/preview?data=${ JSON.stringify(data) }`} target="_blank"
                                className="btn btn-primary btn-sm mx-2"
                            ><i className="fa-solid fa-eye"></i></a>
                        </>
                    );
                },
            },
        ],
        []
    );

    return (
        <>
            <MaterialReactTable
                columns={columns}
                data={surveyPages}
                initialState={{ density: 'compact' }}
                muiTablePaperProps={{
                    sx: {
                        boxShadow: 'none !important',
                        border: 'none'
                    },
                }}
            />
        </>
    )
}