import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import Swal from 'sweetalert2';
import { cloneSegmentation, deleteSegmentation, updateStatusSegmentationAjax } from '../../../api/sio/segmentation';
import { FormControlLabel, Switch } from '@mui/material';
import { AuthUserContext } from '../../../context/AuthUserContext';

export const Segmentation = ({ segmentation, onSelectSegmentation, getSegmentationsRefetch }) => {
    const { authUser } = useContext(AuthUserContext);
    const [ status, setStatus ] = useState(segmentation.status == 'enabled' ? true : false );
    const transformAge = (age) => {
        let ages = JSON.parse(age);
        return `Min: ${ages.min} - Max: ${ages.max}`;
    }

    const { mutate:cloneSegmentationRefetch } = useMutation(cloneSegmentation,{
        onSuccess: () => { 
            Swal.fire('Duplicar segmento', 'Se a duplicado el segmento', 'success').then( result => {
                getSegmentationsRefetch();
        })} ,
        onError: (e) => {
            Swal.fire('Crear proyecto','No se pudo generar el nuevo segmento', 'error')
        }
    });

    const { mutate:deleteSegmentationRefetch } = useMutation(deleteSegmentation,{
        onSuccess: () => { 
            Swal.fire('Eliminar segmento', 'Se a eliminado el segmento', 'success').then( result => {
                getSegmentationsRefetch();
        })} ,
        onError: (e) => {
            Swal.fire('Eliminar proyecto','No se pudo eliminar el segmento', 'error')
        }
    })

    const { mutate:updateStatusSegmentation } = useMutation(updateStatusSegmentationAjax, {
        onSuccess: () => getSegmentationsRefetch()
    })

    const handleCloneSegmentation = (e) => {
        e.preventDefault();
        Swal.fire({
            title: '¿Quieres duplicar este segmento?',
            showCancelButton: true,
            confirmButtonText: 'Duplicar',
            cancelButtonText: 'Cancelar',
            icon:'info'
          }).then((result) => {
            if(result.isConfirmed){
                cloneSegmentationRefetch(segmentation);
            }
          })
    }

    const changeSwitch = (e) => {
        setStatus(e.target.checked);
        updateStatusSegmentation({ id: segmentation.id, status: e.target.checked  ? 'enabled': 'disabled' });
    }
    const handleRemoveSegmentation = (e) => {
        e.preventDefault();
        Swal.fire({
            title: '¿Quieres eliminar este segmento?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar',
            icon:'info'
          }).then((result) => {
            if(result.isConfirmed){
                deleteSegmentationRefetch(segmentation.id);
            }
          })
    }

    return (
        <>
            <div className="accordion-item accordion-item-segmentation mt-1">
                <div
                    id={`panelsStayOpen-${segmentation.id}`}
                    data-bs-toggle="collapse" 
                    data-bs-target={`#panelsStayOpen-${segmentation.id}`}
                    aria-expanded="true" 
                    className="collapsed"
                    aria-controls={`panelsStayOpen-${segmentation.id}`}  style={{
                        display:'flex',
                        justifyContent:'space-between',
                        padding: '20px',
                        color: '#2d2d2d',
                        backgroundColor: `var(--bs-gray-${ status ? 100 : 400})`,
                        borderRadius:"10px",
                        flexWrap:'wrap'
                    }}>
                    <div>
                        <label style={{ fontWeight: "bold" }}>{ segmentation.name }</label>
                    </div>
                </div>
                <div id={`panelsStayOpen-${segmentation.id}`} className="accordion-collapse collapse" aria-labelledby={`panelsStayOpen-${segmentation.id}`}  >
                    <div className="accordion-body">
                        <div className="detail-segment">
                            {['super-admin','Project manager','commercial'].includes(authUser.roles[0].name)
                            && (
                                <div style={{
                                    display:'flex',
                                    justifyContent:'end',
                                    gap:'10px',
                                    alignItems:'center'
                                }}>
                                    <FormControlLabel
                                        onChange={ changeSwitch }
                                        control={
                                            <Switch checked={ status }   />
                                        }
                                        style={{
                                        margin: '0px'
                                    }}/>
                                    {status
                                        && <a className="text-lg" style={{ cursor:'pointer',color: "#552C75" }} href={`/proyecto/${ segmentation.project_id }/base-potencial/segmento/${ segmentation.id }`}><i className="fas fa-upload"> </i></a>
                                    }
                                    <a className="text-lg text-info"    style={{ cursor:'pointer' }} onClick={ handleCloneSegmentation }><i className="fas fa-clone"> </i></a>
                                    <a className="text-lg text-warning" style={{ cursor:'pointer' }} onClick={ onSelectSegmentation }><i className="fas fa-edit" ></i></a>
                                    <a className="text-lg text-danger"  style={{ cursor:'pointer' }} onClick={ handleRemoveSegmentation }><i className="fas fa-trash"></i></a>
                                </div>
                            )}
                            <ul>
                                <li><strong>Sexo</strong>: {JSON.parse(segmentation.gender).map(gender => gender.name).join()}</li>
                                <li><strong>Geografia</strong> : { JSON.parse(segmentation.geographyData).map( x => x.name).join() }</li>
                                <li><strong>Edades</strong> : { transformAge(segmentation.age) }</li>
                                <li><strong>Nse</strong> :
                                    <ul style={{ listStyleType: 'circle' }}>
                                            {
                                                JSON.parse(segmentation.nse).map( x => (<li key={x.id}>{`${x.name}:${x.value}`}</li>))
                                            }
                                    </ul>
                                </li>
                                {
                                    segmentation.inQuarantine == 1 && (
                                        <li><strong>Cuarentena</strong> :
                                            <ul style={{ listStyleType: 'circle' }}>
                                                    {
                                                        JSON.parse(segmentation.projectsInQuarantine).map( x => (<li key={x.id}>{`${x.name}`} (SID: {x.id})</li>)) 
                                                    }
                                            </ul>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}