import React, { useMemo } from "react";
import ReactDOM from "react-dom/client";
import { App } from "../../../App";

import {MaterialReactTable} from "material-react-table";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "react-query";
import Swal from "sweetalert2";
import copy from 'copy-to-clipboard';
import { useGetMonitores } from "../../../hooks/useMonitor";
import { deleteMonitor } from "../../../api/sio/monitor";
export const Monitores = () => {

    const { isLoading, monitores, refetch } = useGetMonitores();

    const { mutate: mDeleteMonitor } = useMutation(deleteMonitor, {
        onSuccess: () => {
            Swal.fire(
                "Eliminar monitor",
                "Se a eliminado el monitor",
                "success"
            ).then((result) => {
                // location.replace("/monitor");
                refetch()
            });
        },
        onError: (e) => {
            Swal.fire(
                "Eliminar monitor",
                "No se pudo eliminar el monitor",
                "error"
            );
        },
    });

    const handleDeletePanelExternal = (e, id) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Quieres eliminar este monitor?",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed) {
                mDeleteMonitor(id);
            }
        });
    };

    const copyToClipboard = (hash) => {
        copy(`${window.origin}/cliente/monitor/h/${hash}`);
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: "name", //access nested data with dot notation
                header: "Nombre",
            },
            {
                accessorKey: "expired_at", //access nested data with dot notation
                header: "Fecha de expiración",
            },{
              accessorKey: 'hash',
              header: 'Link monitor',
              Cell: ({ cell, row }) => {
                 return (
                    <a className="button-copy" href={`${window.origin}/cliente/monitor/h/${row.original.hash}`} onClick={(e) => copyToClipboard(row.original.hash)} target="_blank"><i className="fa-regular fa-copy"></i></a>
                 );
              }
            },{
                header: "Acciones",
                Cell: ({ cell }) => (
                    <>
                        <a
                            className="text"
                            title="Editar"
                            style={{
                                color: "var(--bs-warning)",
                                cursor: "pointer",
                                fontSize: "1.4em",
                            }}
                            href={`/monitor/${cell.row.original.id}/editar`}
                        ><i className="fa fa-pen"></i>
                        </a>
                        <a
                            className="text"
                            title="Eliminar"
                            style={{
                                color: "var(--bs-danger)",
                                cursor: "pointer",
                                fontSize: "1.4em",
                                marginLeft: "15px",
                            }}
                            onClick={(e, id) =>
                                handleDeletePanelExternal(
                                    e,
                                    cell.row.original.id
                                )
                            }
                        ><i className="fa fa-trash"></i>
                        </a>
                    </>
                ),
            },

        ],
        []
    );

    return (
        <>
        {
            isLoading ? (<Skeleton height={400}/>) :
            (
                <div className="card">
                    <div className="card-header">
                        <div className="content-head-card">
                            <label>Crear monitor</label>
                            <a className="btn btn-primary-oo" href="/monitor/crear">
                                Crear Monitor
                            </a>
                        </div>
                    </div>
                    <div className="card-body">
                        <MaterialReactTable
                            columns={columns}
                            data={monitores}
                            muiTablePaperProps={{
                                sx: {
                                    boxShadow: "none !important",
                                    border: "none",
                                },
                            }}
                        />
                    </div>
                </div>
            )
        }
            
        </>
    );
};

if (document.getElementById("jsx-list-monitor")) {
    const el = document.getElementById("jsx-list-monitor");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <Monitores {...props} />
        </App>
    );
}
