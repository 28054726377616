import React, { useEffect } from 'react';
import ReactDOM from "react-dom/client";
import { App } from "../../../App";
import { DateFilter } from './DateFilter';
import { TransactionsTotales } from './table/TransactionsTotales';
import { TransactionsProducts } from './table/TransactionsProducts';
import { DateTransactionDelivered } from './charts/DateTransactionDelivered';

import { useBoundTransactionsStore } from '../../../store/ReportsTransactionsStore/useBoundTransactionsStore';
import { DateTransactions } from './charts/DateTransactions';

export const TransactionStoreReport = () => {
    const  errors  = useBoundTransactionsStore( state => state.errors.transactionsTotales );
    return (
        <>
            <div className="card col-md-12">
                <div className="card-header">
                    <div style={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center'
                    }}>
                        <strong>Métricas canjes</strong>
                    </div>
                </div>
                <div className='card card-body'>
                    <div className="row representations-chart">
                        <DateFilter />
                    </div>
                </div>
            </div>
            {
                errors.status ? (
                    <div className="mt-2 alert alert-danger">{errors.message}</div>
                ): (
                    <>
                        <TransactionsTotales />
                        <DateTransactions />
                        <DateTransactionDelivered />
                        <TransactionsProducts />
                    </>
                )
            }
            
        </>
    )
}


if (document.getElementById("jsx-reports-transactions-store")) {
    const el = document.getElementById("jsx-reports-transactions-store");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <TransactionStoreReport {...props} />
        </App>
    );
}