import { useState } from "react";
import { useQuery } from "react-query";
import { getAnswers, getSubAnswers } from "../api/sio/answer";

export const useAnswers = (rquestion) => {
    const [answers, setAnswers] = useState([]);
    const [typeQuestion, setTypeQuestion] = useState(undefined);

    const { isLoading, isError, error } = useQuery(['getAnswers', rquestion.projectId], () => getAnswers(rquestion),{
        onSuccess: (response) => {
            const { data } = response;
            setAnswers(data);
            setTypeQuestion(['S','T'].includes(rquestion.type) ? data.model : data[0].model);
        }
    })

    return {
        isLoading,
        isError, 
        error, 
        answers, 
        setAnswers,
        typeQuestion
    }
}

export const useSubAnswers = (question) => {
    const [subAnswers, setSubAnswers] = useState([]);
    const [typeQuestion, setTypeQuestion] = useState(undefined);

    const { isLoading, isError, error } = useQuery(['getSubAnswers', question.projectId], () => getSubAnswers(question),{
        onSuccess: (response) => {
            const { data } = response;
            setSubAnswers(data);
            setTypeQuestion(data[0].model);
        }
    })

    return {
        isLoading,
        isError, 
        error, 
        subAnswers, 
        setSubAnswers,
        typeQuestion
    }
}