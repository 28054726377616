import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useMutation } from 'react-query';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { storeSegmentation } from '../../../../api/sio/segmentation';
import { useGetGeographiesAjax, useTagsGeo } from '../../../../hooks/useGeography';
import { useRefenceNse } from '../../../../hooks/useNse';
import { useSegmentations } from '../../../../hooks/useSegmentation';
import { useGetSurveys } from '../../../../hooks/useSurvey';


export const CreateSegmentation = ({ projectId, seeModal, setSeeModal   }) => {
    const [selectGender, setSelectGender] = useState({ options: [ { label: 'Mujer', value: 'F' }, { label: 'Hombre', value: 'M' } ]});
    const [segmentation, setSegmentation ] = useState({
        name: '',
        gender: [],
        geographyLevel: '',
        geographyData : [],
        age : { min: 0, max: 0 },
        inQuarantine: false,
        projectsInQuarantine : [],
        typeNse:'sin_nse',
        nse: [],
        project_id: projectId
    });

    const {isLoading:isLoadingTag, selectTagsGeo, setSelectTagsGeo } = useTagsGeo();
    const {isLoading:isLoadingGeo, setEvent, setSelectGeographies, selectGeographies } = useGetGeographiesAjax();
    const {isLoading:isLoadingNse,typesNse, setTypesNse } = useRefenceNse();
    const {isLoading:isLoadingSurveys,selectQuarantine,setSelectQuarantine,selectSurveys,setSelectSurveys } = useGetSurveys();

    const { mutate:storeSegmentationAjax, isLoading:isLoadingStoreSegmentation, isError:isErrorCreate, error:errorCreate} = useMutation(storeSegmentation,{
        onSuccess: () => { 
            Swal.fire('Crear segmentación', 'Se a generado una nueva segmentación', 'success').then( result => {
                setSeeModal({...seeModal, create: !seeModal, edit: false });
                getSegmentationsRefetch();
        })} ,
        onError: (e) => {
            Swal.fire('Crear proyecto','No se pudo generar el nuevo segmento', 'error')
        }
    })
    const  {refetch:getSegmentationsRefetch } = useSegmentations(projectId, false);

    const handleCreateSegmentation = (e) => {
        e.preventDefault();
        Swal.fire({
            title: '¿Quieres generar este segmento?',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar',
            icon:'info'
          }).then((result) => {
            storeSegmentationAjax(segmentation);
          })
    }

    return (
        <div className="modal modal-segmentation">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Crear segmento</h5>
                        <button  className="close close-modal-btn" data-dismiss="modal" aria-label="Close" onClick={ () => setSeeModal({ create:!seeModal, edit:false })} >
                            <i className="fa fa-times-circle"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={ handleCreateSegmentation } method="POST">
                            <div className="form-group">
                                <label className="my-1 fw-bold">Nombre</label>
                                <input type="text" name="name" className="form-control" id="name-input" value={ segmentation.name } onChange={ (e) => setSegmentation({ ...segmentation, name:e.target.value })}/>
                                {
                                    isErrorCreate && (
                                        <p className="text text-danger">{errorCreate.data.errors?.name?.map( x => x)}</p>
                                    )
                                }
                            </div>
                            <div className="row">
                                    <div className="form-group col-md-6">
                                        <label className="my-1 fw-bold">Género</label>
                                        <Select 
                                            key={'djasdhvashds'}
                                            isMulti={true}
                                            isClearable
                                            className="basic-multi-select"
                                            options={ selectGender.options }
                                            value = {  selectGender.selected }
                                            onChange  = { (e) => {
                                                setSelectGender({...selectGender,selected: e})
                                                setSegmentation({ ...segmentation, gender: e.map(x => ({ id:x.value, name: x.label })) })
                                            }}
                                        />
                                        {
                                            isErrorCreate && (
                                                <p className="text text-danger">{errorCreate.data.errors?.gender?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="my-1 fw-bold">Zonas geograficas</label>
                                        {
                                            isLoadingTag ? ( <Skeleton />)
                                            : (
                                                <>
                                                <Select 
                                                    className="basic-select"
                                                    options={ selectTagsGeo.options }
                                                    value = {  selectTagsGeo.selected.length > 0 ? selectTagsGeo.selected : undefined }
                                                    onChange  = { (e) => {
                                                        setSelectTagsGeo({ ...selectTagsGeo, selected: e});
                                                        setSegmentation({ ...segmentation, geographyLevel: e.value, geographyData:[] });
                                                        setSelectGeographies({ ...selectGeographies, selected:[]})
                                                        setEvent(e);
                                                    }}
                                                />
                                                {
                                                    isErrorCreate && (
                                                        <p className="text text-danger">{errorCreate.data.errors?.geographyLevel?.map( x => x)}</p>
                                                    )
                                                }
                                                </>
                                            )
                                        }
                                    </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label className="my-1 fw-bold">Seleccionar { selectTagsGeo.selected.label } </label>
                                    {
                                        isLoadingGeo ? (<Skeleton />)
                                        : (
                                            <Select 
                                                isMulti
                                                className="basic-multi-select"
                                                options={ selectGeographies.options  }
                                                value = {  selectGeographies.selected }
                                                onChange = { (e) => {
                                                    setSegmentation({...segmentation, geographyData: e.map( x => ({ id:x.value, name: x.label }))})
                                                    setSelectGeographies({...selectGeographies, selected: e})
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        isErrorCreate && (
                                            <p className="text text-danger">{errorCreate.data.errors?.geographyData?.map( x => x)}</p>
                                        )
                                    }
                                </div>
                                {
                                    isLoadingNse ? ( <Skeleton />) : (
                                        <div className="form-group col-md-12">
                                            <div className="row">
                                                <div className={`${typesNse.selectedType !== 'sin_nse' ? 'col-md-6' : 'col-md-12'}`}>
                                                <label className="my-1 fw-bold">Tipos de niveles socio económicos </label>
                                                <Select 
                                                    className="basic-select"
                                                    options={ typesNse.keys.map( x => ({label:x, value:x}) ) }
                                                    value = { { label:typesNse.selectedType, value: typesNse.selectedType} }
                                                    onChange={e => {
                                                        setTypesNse({ ...typesNse, selectedType: e.value, selected:[]});
                                                        setSegmentation({ ...segmentation, nse:[], typeNse: e.value});
                                                    }}
                                                />
                                                </div>
                                                {
                                                    typesNse.selectedType !== 'sin_nse' && (
                                                        <div className="col-md-6">
                                                            <label className="my-1 fw-bold">Seleccinar niveles socio económicos </label>
                                                            {
                                                                    <>
                                                                        <Select 
                                                                            isMulti
                                                                            isClearable
                                                                            className="basic-multi-select"
                                                                            options={ typesNse.values[typesNse.selectedType].map( x => ({value:x.value, label:x.value, id:x.id})) }
                                                                            value = {  typesNse.selected }
                                                                            onChange = { (e) => {
                                                                                setTypesNse({...typesNse, selected: e});
                                                                                setSegmentation({...segmentation, nse: e.map(x => ({ ...x, name: typesNse.selectedType})) });
                                                                            }}
                                                                        />
                                                                        {
                                                                            isErrorCreate && (
                                                                                <p className="text text-danger">{errorCreate.data.errors?.nse?.map( x => x)}</p>
                                                                            )
                                                                        }
                                                                    </>
                                                            }
                                                        </div>
                                                    )
                                                }
                                                
                                            </div>
                                        </div>
                                   )
                                }
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label className="my-1 fw-bold">Edad mínima</label>
                                    <input type="number" name="age.min" className="form-control" id="name-input" value={ segmentation.age.min } onChange={ (e) => setSegmentation({ ...segmentation, age: {...segmentation.age, min: e.target.value } })}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="my-1 fw-bold">Edad máxima</label>
                                    <input type="number" name="age.max" className="form-control" id="name-input" value={ segmentation.age.max } onChange={ (e) => setSegmentation({ ...segmentation, age: {...segmentation.age, max: e.target.value } })}/>
                                </div>
                                <div className="form-group col-md-6">
                                    {
                                        isErrorCreate && (
                                            <p className="text text-danger">{errorCreate.data.errors?.age?.map( x => x)}</p>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-2">
                                    <label className="my-1 fw-bold">Cuarentena</label>
                                    <Select 
                                        className="basic-multi-select"
                                        options={ selectQuarantine.options }
                                        defaultValue={ selectQuarantine.options.filter(x => x.value == 'N')}
                                        onChange = { (e) => {
                                            setSelectQuarantine({ ...selectQuarantine, selected: e.value});
                                            setSegmentation({ 
                                                ...segmentation, 
                                                inQuarantine: e.value == 'Y' ? true : false,
                                                projectsInQuarantine: e.value == 'Y' ? [...segmentation.projectsInQuarantine] : [] });
                                        }}
                                    />
                                    {
                                        isErrorCreate && (
                                            <p className="text text-danger">{errorCreate.data.errors?.inQuarantine?.map( x => x)}</p>
                                        )
                                    }
                                </div>
                                {
                                    segmentation.inQuarantine && (
                                        <div className="form-group col-md-10">
                                            <label className="my-1 fw-bold">Asociar encuestas </label>
                                            {
                                                isLoadingSurveys  ? ( <Skeleton /> )
                                                : (
                                                    <>
                                                    <Select 
                                                        isMulti
                                                        isClearable
                                                        className="basic-multi-select"
                                                        options={ selectSurveys.options  }
                                                        defaultValue = {  selectSurveys.selected }
                                                        onChange = { (e) => {
                                                            setSelectSurveys({...selectSurveys, selected: e})
                                                            setSegmentation({...segmentation, projectsInQuarantine: e.map( x => ({ id:x.value, name: x.label }))})
                                                        }}
                                                    />
                                                    {
                                                        isErrorCreate && (
                                                            <p className="text text-danger">{errorCreate.data.errors?.projectsInQuarantine?.map( x => x)}</p>
                                                        )
                                                    }
                                                    </>
                                                )
                                            }
                                        </div>
        
                                    )
                                }
                            </div>
                            <hr></hr>
                            <div className="form-group col-md-12">
                                {
                                    isLoadingStoreSegmentation ? (<button className="btn btn-primary-oo">Cargando...</button>)
                                    : (<button className="btn btn-primary-oo">Crear segmento</button>)
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}