import { getProductionsReport } from '../../api/reports/productions';

const initialError = { status:false, message: "" };
export const useReportsProjectStore = (set,get) => {
    return {
        status:{
            values:[
                {value:'actived', label: 'En campo'},
                {value:'expired', label: 'Cerrado'}
            ],
            selected: [
                {value:'actived', label: 'En campo'},
                {value:'expired', label: 'Cerrado'}
            ] 
        },
        setStatus:( _status ) => set( state => ({ ...state, status: { ...state.status, selected: _status }  })),
        productions: [],
        errors: initialError,
        loadingProductions: false, 
        fetchProductionsStore: async () => {
            set({ loadingProductions: true, errors: initialError});
            let _data = { ...get().preset, status: get().status.selected.map(s => s.value).join(",") };
            try{
                const response = await getProductionsReport(_data);
                if(response.status == 204){
                    set({ productions:[], errors:{ status:true, message: 'No hay datos disponibles para visualizar' }});
                }else{
                    set({ productions: response.data, errors: initialError });
                }
            }catch(error){
                set({ errors:{ status:true, message: 'No se pudo cargar los datos, intenta nuevamente' }});
            }
            set({ loadingProductions: false });
        }
    }
};