import { useEffect, useState } from "react";
import Select from "react-select";
import { useProfilesStore } from "../../../../../store/ProfileStore/useProfilesStore";
import Skeleton from "react-loading-skeleton";

export const ProfileQuestionSelect = ({ segmentSubset, setSegmentSubset }) => {
    const {
        questions,
        answers,
        setQuestionSelected,
        setAnswersSelected,
        isLoadingProfiles,
        fetchProfiles,
    } = useProfilesStore((state) => state);

    const handleQuestionsSelect = (e) => {
        setQuestionSelected(e);
        setAnswersSelected([]);
        setSegmentSubset({
            ...segmentSubset,
            profile: null
        });
    }

    const handleAnswersSelect = (e) => {
        setAnswersSelected(e);
        setSegmentSubset({
            ...segmentSubset,
            profile: {
                sid: questions?.selected?.value?.sid,
                gid: questions?.selected?.value?.gid,
                qid: questions?.selected?.value?.qid,
                type: questions?.selected?.value?.type,
                answers: e.map((answer) => answer.value.code),
                refence: questions?.selected?.value?.refence
            },
        });
    }

    useEffect(() => { if (!questions) { fetchProfiles(); } }, [questions]);

    return isLoadingProfiles ? (
        <Skeleton height={50} />
    ) : (
        <>
            <div className="row">
                <div className="form-group col-md-12">
                    <label htmlFor="profile-question">
                        Selecciona una pregunta de perfilamiento
                    </label>
                    <Select
                        key={"profilequestion"}
                        id="profile-question"
                        className="basic-select"
                        isClearable
                        options={questions.options}
                        value={questions.selected}
                        onChange={ (e) => handleQuestionsSelect(e) }
                    />
                </div>
            </div>
            {questions.selected && (
                <div className="row">
                    <div className="form-group col-md-12 my-2 my-md-0">
                        <label htmlFor="question-answers">
                            Seleccione las respuestas
                        </label>
                        <Select
                            key={"questionanswers"}
                            id="question-answers"
                            isMulti
                            isClearable
                            className="basic-multi-select"
                            options={answers.options.filter(
                                (ans) =>
                                    ans.value.qid ===
                                    questions?.selected?.value?.qid
                            )}
                            value={answers.selected}
                            onChange={ (e) => handleAnswersSelect(e) }
                        />
                    </div>
                </div>
            )}
        </>
    );
};
