import { useState } from "react";
import { ajaxFilterPotentialBase } from "../api/sio/potentialBase";
import { useEffect } from "react";
import { transformFilter } from "../helper/utils";

export const useFilterPotentialBase = (range,isFiltering) => {
    const [panelist, setPanelist] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState(null);

    const getPotentialBase = () => {
        setIsLoading(true);
        let _range = transformFilter(range);
        ajaxFilterPotentialBase(_range)
            .then((response) => {
                setPanelist(response.data);
                setIsError(false);
                setError("");
            })
            .catch((error) => {
                setIsError(true);
                setError(error.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getPotentialBase();
    }, [isFiltering]);

    return {
        panelist,
        getPotentialBase,
        isLoading,
        isError,
        error
    };
};
