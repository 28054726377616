import React, {useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getGeographiesWithLevel, getLevelsGeo, getTagGeographies } from "../api/sip/geography";

export const useTagsGeo = (country = null) => {
    const [selectTagsGeo, setSelectTagsGeo] = useState({ options: [], selected: [] });
    const response = useQuery(['tagsResponse',country], () => getTagGeographies(country), {
        onSuccess: (response) => {
            const { data } = response;
            let options = data.map( x => ({ label: x.tag, value: x.level }));
            options.push({ label: 'Zona', value: 'area'});
            setSelectTagsGeo({ ...selectTagsGeo, options });
        }
    });
    const  { isLoading, data, refetch} = response;

    return {
        isLoading,
        data,
        refetch,
        selectTagsGeo,
        setSelectTagsGeo
    }
}

export const useGetGeographiesAjax = (eValue=null) => {
    const [event, setEvent] = useState(eValue);
    const [selectGeographies, setSelectGeographies] = useState({ options: [], selected: [] });
    
    const {isLoading, refetch:getGeographies} = useQuery(['getGeographies',event],() => getGeographiesWithLevel(event), { 
        onSuccess: (response) => {
            const { data } = response;
            let options = data.map( x => ({ label: x.name, value: x.id }));
            setSelectGeographies({ ...selectGeographies, options:options });
    }, enabled: !!event});

    useEffect( () => {
        if(event == null){ return }
        getGeographies()
    },[setEvent])

    return {
        isLoading,setEvent,selectGeographies,setSelectGeographies
    }
}

export const useLevelsGeo = () => {
    const [selectLevelsGeo, setSelectLevelsGeo] = useState([]);
    const { isLoading } = useQuery(['levelsGeo'], getLevelsGeo,{ 
        onSuccess: (response) => {
            const { data } = response;
            let options = data.map( x => ({ label: `${x.tag}`, value: `${x.level}` }));
            setSelectLevelsGeo({ ...selectLevelsGeo, options, selected:options[0]})
        }
    });

    return {
        isLoading, selectLevelsGeo, setSelectLevelsGeo
    } 
}