import React, { useEffect, useState }  from "react";
import 'react-day-picker/dist/style.css';
import { format, isValid, parseISO } from "date-fns";
import Select from 'react-select';
import { useBoundProductionsReportStore } from "../../../store/ReportsProductionsStore/useBoundProductionsReportStore";

export const Filters = () => {

    const { errors, preset, representation,defaultRepresentation,setRepresentation, setPreset,statusProject,defaultStatusProject, setStatusProject } = useBoundProductionsReportStore(state => state );
    const [selected, setSelected] = useState(defaultRepresentation);
    const [range,setRange] = useState(preset);
    const [statusP, setStatusP] = useState(defaultStatusProject);

    const handleChangeInputpreset = (e,type) => {
        if(type == 'from'){
            if(isValid(parseISO(e.target.value,1))){
                setRange({ ...preset, from: parseISO(e.target.value,1)})
            }else{
                setError({ from: 'Agrega una fecha válida', ...error });
            }
        }
        if(type == 'to'){
            if(isValid(parseISO(e.target.value,1))){
                setRange({ ...preset, to: parseISO(e.target.value,1)})
            }else{
                setError({ to: 'Agrega una fecha válida', ...error });
            }
        }
    }

    const handleSearchProductionsReport = () => {
        setRepresentation(selected);
        setPreset(range);
        setStatusProject(statusP);
    }

    return (
        <>
            
            <div className={`d-flex flex-row flex-wrap gap-2`}>
                <div className={`flex-fill`}>
                    <label htmlFor="">Desde</label>
                    <input type="date" name="from_at" value={format(range.from,'yyyy-MM-dd')} className="form-control flex-fill" onChange={ (e) => handleChangeInputpreset(e,'from') }/>
                </div>
                <div className={`flex-fill`}>
                    <label htmlFor="">Hasta</label>
                    <input type="date" name="from_at"  value={format(range.to,'yyyy-MM-dd')}  className="form-control flex-fill" onChange={ (e) => handleChangeInputpreset(e,'to') }/>
                </div>
                <div className={`flex-fill`}>
                    <label htmlFor="">Representación</label>
                    <Select
                        key="selected-1"
                        defaultValue={ selected }
                        isMulti
                        name="colors"
                        options={representation}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={ (e) => {
                            setSelected(e);
                        } }
                    />
                </div>
                <div className={`flex-fill`}>
                    <label htmlFor="">Estado proyecto</label>
                    <Select
                        key="status-1"
                        defaultValue={ statusP }
                        isMulti
                        name="status"
                        options={statusProject}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={ (e) => {
                            setStatusP(e);
                        } }
                    />
                </div>
                <div className="d-flex align-items-end">
                    <button className="btn btn-sm btn-primary-oo text-light  flex-fill" onClick={ handleSearchProductionsReport }>Buscar</button>
                </div>
            </div>
            <div className="mt-3">
                { errors.status && ( <div className="alert alert-danger"><i className="fa-solid fa-triangle-exclamation"></i> { errors.message }</div> )}
            </div>
        </>
    );
};
