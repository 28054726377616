import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import { App } from '../../../App';
import { useMaterialReactTable, MaterialReactTable  } from 'material-react-table';
import { useBoundNotificationFcm } from '../../../store/NotificationFcm/useBoundNotificationFcm';
import { stepStatus } from '../../../helper/utils';


export const NotificationsFcm = () => {

    const { fetchNotifications, notifications, loading, errors } = useBoundNotificationFcm(state => state);

    const columns = useMemo( () => [
        {
            accessorKey: 'id',
            header: '#ID',
        },{
            accessorKey: 'name',
            header: 'Nombre Campaña',
        },{
            accessorKey: 'title',
            header: 'Campaña',
        },{
            accessorKey:'step',
            header:'Etapa',
            Cell:( ({ row }) => {
                let name = stepStatus(row.original);
                return (
                    <a style={{
                        backgroundColor: 'var(--bs-gray-200)',
                        padding:'5px 10px',
                        fontWeight:'bold',
                        borderRadius:'10px'

                    }}>{ name }</a>
                ) 
            })
        },{
            accessorKey:'status',
            header:'Estado',
            Cell:(({row }) => {
                let colors = {
                    send: { label: 'Enviado', color: 'var(--bs-teal)' },
                    draft: { label: 'Borrador', color: 'var(--bs-warning)' },
                    archived: { label: 'Archivada', color: 'var(--bs-danger)' },
                    progress: { label: 'En progreso', color: 'var(--bs-info)' },
                }
                return (
                    <a style={{
                        backgroundColor: colors[row.original.status].color,
                        padding:'5px 10px',
                        fontWeight:'bold',
                        borderRadius:'10px'

                    }}>{ colors[row.original.status].label }</a>
                )
            })
        },{
            header:'Acciones',
            Cell:(({row}) => {
                return (
                    <a style={{ fontSize:'14px', fontWeight:'bold', color:'var(--bs-gray-700)', cursor:'pointer' }} href={`/notificaciones/fcm/crear/${ row.original.id }`}><i className="fas fa-eye"></i></a>
                )
            })
        }
    ],[]);

    const table = useMaterialReactTable({
        columns: columns,
        data: notifications || [],
        muiTablePaperProps: {
            sx: {
                boxShadow:'none !important', border:'none'
            }
        },
        muiToolbarAlertBannerProps: errors.status ? { color: 'error', children: errors.notifications.message } : undefined,
        // enablePinning:true,
        state:{
            isLoading:loading,
            showAlertBanner: errors.notifications.status,
            density:"compact"
        },
        enablePagination:false,
        enableSorting:true,
        enableFullScreenToggle:false,
        enableDensityToggle:false,
        enableFilters:true,
        enableHiding:false,
        enableBottomToolbar:false,
        enableTopToolbar:true,
        enableColumnActions:true,
    });

    useEffect( ()  => {
        fetchNotifications();
    },[]);
    return (

        <div className="card">
            <div className="card-header">
                <div className="content-head-card">
                    <label>Notificaciones</label>
                    <a className="btn btn-primary-oo" href="/notificaciones/fcm/crear">Crear notificación</a>
                </div>
            </div>
            
            <div className="card-body">
                <MaterialReactTable key={1} table={table} />
            </div>
        </div>
    )
}

if (document.getElementById("jsx-fcm-list-notification")) {
    const el = document.getElementById("jsx-fcm-list-notification");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <NotificationsFcm {...props} />
        </App>
    );
}