import React, { useState }  from "react";
import 'react-day-picker/dist/style.css';
import { format, isValid, parseISO } from "date-fns";
import { useBoundReportsProjectStore } from "../../../store/ReportsProjectStore/useBoundReportsProjectStore";
import Select from 'react-select';

// const initialRangeState = {
//     from: null,
//     to: null,
// };

// const buttons = [
//     { value:'reset', label: 'Reiniciar' },
//     { value: 'today', label: 'Hoy' },
//     { value: 'thisWeek', label: 'Esta semana' },
//     { value: 'lastWeek', label: 'Semana pasada'},
//     { value: 'last7', label: 'Últimos 7 días' },
//     {value: 'thisMonth', label: 'Este mes' },
//     {value: 'ofQuarter', label: 'Trimestre' },
//     {value: 'ofSemester', label: 'Semestre' }

// ];
export const DateFilter = () => {

    const [error, setError] = useState({
        from: '',
        to: ''
    });
    const {preset:range, changePresetDay, changePreset, fetchProductionsStore, buttons,status,setStatus} = useBoundReportsProjectStore( state => state );
    const handleChangeInputRange = (e,type) => {
        if(type == 'from'){
            if(isValid(parseISO(e.target.value,1))){
                changePresetDay({ ...range, from: parseISO(e.target.value,1)})
            }else{
                setError({ from: 'Agrega una fecha válida', ...error });
            }
        }
        if(type == 'to'){
            if(isValid(parseISO(e.target.value,1))){
                changePresetDay({ ...range, to: parseISO(e.target.value,1)})
            }else{
                setError({ to: 'Agrega una fecha válida', ...error });
            }
        }
    }

    const handleRangePresets = (e) => {
        changePreset(e);
    }

    const handleSearchProductionsReport = () => {
        fetchProductionsStore();
    }
    return (
        <>
            
            <div className={`d-flex gap-2`}>
                    {/* <input type="date" name="from_at" value={format(range.from,'yyyy-MM-dd')} className="form-control flex-1" onChange={ (e) => handleChangeInputRange(e,'from') }/>
                    <label htmlFor="" className="text-muted text-danger">{ error.from.length ? error.from : '' }</label>
                    <input type="date" name="from_at"  value={format(range.to,'yyyy-MM-dd')}  className="form-control flex-1" onChange={ (e) => handleChangeInputRange(e,'to') }/>
                    <label htmlFor="" className="text-muted text-danger">{ error.to.length ? error.to : '' }</label> */}
                <div className={`flex-fill`}>
                    <label htmlFor="">Desde</label>
                    <input type="date" name="from_at" value={format(range.from,'yyyy-MM-dd')} className="form-control flex-fill" onChange={ (e) => handleChangeInputRange(e,'from') }/>
                </div>
                <div className={`flex-fill`}>
                    <label htmlFor="">Hasta</label>
                    <input type="date" name="from_at"  value={format(range.to,'yyyy-MM-dd')}  className="form-control flex-fill" onChange={ (e) => handleChangeInputRange(e,'to') }/>
                </div>
                    <div>
                        <label htmlFor="">Estado proyecto</label>
                        <Select
                            key="status-1"
                            defaultValue={ status.selected }
                            isMulti
                            name="status"
                            options={status.values}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={ (e) => {
                                setStatus(e);
                            } }
                        />
                    </div>
                    <div className="d-flex align-items-end">
                        <button className="btn btn-sm btn-primary-oo text-light flex-fill" onClick={ handleSearchProductionsReport }>Buscar</button>
                    </div>
                </div>
            <div className={`d-flex flex-row`}>
                <div>
                    {
                        buttons.map( (button,k) => {
                            return (<button
                                key ={k}
                                className="btn btn-sm btn btn-light text-dark m-1"
                                onClick={() => {
                                    handleRangePresets(button.value);
                                }}
                            >
                                { button.label }
                            </button>)
                        })
                    }
                </div>
            </div>
        </>
    );
};
