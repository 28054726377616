import { Alert, Skeleton, Tooltip } from "@mui/material";
import Select from 'react-select';
import { useState } from "react";
import { KpiCardTwoAreaChart } from "./KpiCardTwoAreaChart";
import { format } from "date-fns";
import { formatterNumber } from "../../../../helper/currencyFormatter";

export const TableChartCard = ({
    kpiKey,
    dateFilter,
    chartData,
    totalPoints = { validRecruitsPoints: 0, completedSurveysPoints: 0 },
    rangeTotal,
    isLoading = false,
    isError = { status: false, message: "" }
}) => {

    return (
        <div className="card border rounded shadow-sm h-100">
            <div className="card-body d-flex flex-column align-items-center">
                <span className="fw-bold text-muted mb-2">Recompensas entregadas</span>
                {isError.status && <Alert sx={{ margin: '10px' }} severity="error">{isError.message}</Alert>}
                {!(chartData.length > 0)
                    ? <Alert sx={{ margin: '10px' }} severity="error">{'No hay datos disponibles'}</Alert>
                    : <KpiCardTwoAreaChart
                        isLoading={isLoading}
                        kpiKey={kpiKey}
                        data={chartData}
                        dataKeys={['validRecruitsPoints', 'completedSurveyPoints']}
                    />
                }
                {isLoading
                    ? <Skeleton variant="rounded" width={'90%'} height={130} sx={{ margin: '10px' }} />
                    : (
                        <table className="table table-responsive small my-2">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <Tooltip title='Recompensas por reclutamiento durante periodo seleccionado' arrow>
                                            <span>Recompensa por reclutamiento ({format(dateFilter.from, 'dd-MM-yyyy') + ' al ' + format(dateFilter.to, 'dd-MM-yyyy')})</span>
                                        </Tooltip>
                                    </th>
                                    <th scope="col">{formatterNumber(rangeTotal.validRecruitsPoints || 0)} pts</th>
                                </tr>
                                <tr>
                                    <th scope="col">
                                        <Tooltip title='Recompensas por encuestas completas por reclutas durante periodo seleccionado' arrow>
                                            <span>Recompensa por encuestas completas ({format(dateFilter.from, 'dd-MM-yyyy') + ' al ' + format(dateFilter.to, 'dd-MM-yyyy')})</span>
                                        </Tooltip>
                                    </th>
                                    <th scope="col">{formatterNumber(rangeTotal.completedSurveyPoints || 0)} pts</th>
                                </tr>
                                <tr className="table-active">
                                    <th scope="col">
                                        <Tooltip title='Total recompensas durante periodo seleccionado' arrow>
                                            <span>Total recompensas recibidas en periodo ({format(dateFilter.from, 'dd-MM-yyyy') + ' al ' + format(dateFilter.to, 'dd-MM-yyyy')})</span>
                                        </Tooltip>
                                    </th>
                                    <th scope="col">{formatterNumber(rangeTotal.total || 0)} pts</th>
                                </tr>
                                <tr>
                                    <th scope="col">
                                        <Tooltip title='Total recompensas por reclutamiento (histórico)' arrow>
                                            <span>Total recompensas por reclutamiento</span>
                                        </Tooltip>
                                    </th>
                                    <th scope="col">{formatterNumber(totalPoints.validRecruitsPoints || 0)} pts</th>
                                </tr>
                                <tr>
                                    <th scope="col">
                                        <Tooltip title='Total recompensas por encuestas completas (histórico)' arrow>
                                            <span>Total recompensas por encuestas completas</span>
                                        </Tooltip>
                                    </th>
                                    <th scope="col">{formatterNumber(totalPoints.completedSurveysPoints || 0)} pts</th>
                                </tr>
                                <tr className="table-active">
                                    <th scope="col">
                                        <Tooltip title='Total de recompensas entregadas al embajador (histórico)' arrow>
                                            <span>Total recompensas recibidas</span>
                                        </Tooltip>
                                    </th>
                                    <th scope="col">{formatterNumber((totalPoints.validRecruitsPoints + totalPoints.completedSurveysPoints) || 0)} pts</th>
                                </tr>
                            </thead>
                        </table>
                    )

                }
            </div>
        </div>
    )
};