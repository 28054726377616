import { format } from "date-fns";

export const transformFilter = (range) => {
    let _d = {
        from:
            range.from == null
                ? null
                : format(new Date(range.from), "yyyy-MM-dd"),
        to: range.to == null ? null : format(new Date(range.to), "yyyy-MM-dd"),
    };
    let _data = {
        from_at: _d.from,
        to_at: _d.to,
    };
    return _data;
};


export const transformIsActionButton = {
    'disabled': false, 
    'active': true
}

export const capitalizarText = (str) =>  {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  export const stepStatus = (response) => {
    if(response.status == 'send' ){
        return 'Finalizada';
    }
    let steps = {
        1: 'Notificación',
        2: 'Selección  usuarios',
        3: 'Confirmar usuarios',
    }
    return steps[response.step];
  }
