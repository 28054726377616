import { getPanelistsOfSemester } from "../../api/reports/panelists";

const errors= { status:false, message: '' };
export const usePanelistsOfSemesterStore = (set,get) => {
    return {
        loadingSemester:false,
        errorsSemester:errors,
        responseSemester: [],
        typeChartSemester:{
            options: [{value:'barchart', label:'Barra' } , {value:'linechart', label:'Lineal' }, { value:'table', label:'Tabla' }],
            selected: {value:'barchart', label:'Barra' }
        },
        setTypeChartSemester:  (type) => set((state) => ({ ...state, typeChartSemester: { ...state.typeChartSemester, selected: type }})),
        fetchPanelistsOfSemester: async () => {
            set({ loadingSemester: true });
            set({errorsSemester:{status:false,message:''}});
            get().setErrors(false,'');
            try {
                const  {data, status } = await getPanelistsOfSemester(get().preset,get().defaultStatusProject);
                if(status == 204){
                    set({ errorsSemester: { status:true, message:'No hay data disponible para este filtro generado.'} })
                }else{
                    set({ errorsSemester: errors, responseSemester: data });
                }
            } catch (error) {
                if(error.status = 422){
                    get().setErrors(true, error.data.message);
                }
            }
            set({ loadingSemester: false });
        }
    }
};