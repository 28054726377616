import React, { useContext, useEffect, useState }  from 'react';
import Skeleton from 'react-loading-skeleton';
import Select from 'react-select';
import {Bar, BarChart, CartesianGrid, Cell, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DashboardContext } from '../../../context/DashboardContext';
import { COLORS } from '../../../helper/COLOR';
import { useNseM } from '../../../hooks/useNseDashboard';
import { AgesBarChart } from './AgesBarChart';


const CustomTooltip = ({ active, payload, label, totals }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
            backgroundColor:"#FFF",
            padding:"10px",
            borderRadius:"10px",
            // boxShadow:'1px 1px 3px 1px #7E57C2',
            boxShadow:  '#CFD8DC 0px 1px 7px 0px',
            width:'300px'
            }}>
            <p style={{ color:"#3d3d3d", textAlign:'center'}}><strong>{ label }</strong></p>
            <hr></hr>
            <p style={{ color:`${payload[0].color}`}}>{ payload[0].name }: {`${payload[0].value} (${((payload[0].value/totals)*100).toFixed(2)}%)`}</p>
        </div>
      );
    }
  
    return null;
};

const CustomBar = (props) => {
    const { x, y, width, height, fill, payload } = props;
    return (
        <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        stroke="#e2e2e2" // Color del borde
        strokeWidth={1} // Ancho del borde
        radius={[10,10,0,0]}
        />
    );
}; 

export const NseBarChart = () => {
    const {filter} = useContext(DashboardContext);
    const { isLoadingM, nses, mutate,selectTypes,setSelectTypes } = useNseM(filter,undefined);
    const handleChangeNse= (e) => {
        setSelectTypes({...selectTypes, selected: e });
        mutate({ ...filter, type: e.value});
    }

    const maxValue = Math.max(
        ...nses.map(item => Math.max(parseInt(item.total)))
    );

    useEffect( () => {
        if(nses.length == 0){ return }
        mutate({...filter, type: selectTypes.selected.value});
    },[filter])

    
    return (
        <>
            {
                isLoadingM && !nses.length ? (<Skeleton height={400}/>) : (
                    !nses.length ? (<div className="alert__not-data">No se pudo encontrar la información <strong>NSES</strong>, por favor intenta nuevamente modificando la fecha o refrescando la página</div>):(
                        <>
                            <div style={{
                                display:'flex',
                                justifyContent:'end',
                                gap: '10px',
                                fontSize:'12px',
                                alignItems:'center',
                                margin: '0.2rem'
                            }}>
                                <a><i className="fas fa-filter"></i></a>
                                <Select 
                                    className="basic-select"
                                    defaultValue = {selectTypes.selected}
                                    options= { selectTypes.options }
                                    onChange = { handleChangeNse }
                                />
                            </div>                
                            <ResponsiveContainer  width="100%" height={300}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={nses}
                                >
                                <CartesianGrid strokeDasharray="3 3" stroke='transparent' />
                                    {/* <XAxis dataKey="nses"/>
                                    <YAxis /> */}
                                    <XAxis 
                                        dataKey="nses"
                                        textAnchor= "end" 
                                        sclaeToFit="true" 
                                        verticalAnchor= "start"  
                                        angle= "-40"  
                                        style={{fontSize:'12px'}} 
                                        axisLine={false} // Elimina la línea del eje Y
                                        tickLine={false}
                                    />
                                    <YAxis
                                        type="number"
                                        domain={[0, dataMax => maxValue * 1.5]} tickCount={10} 
                                        allowDataOverflow={true}    
                                        axisLine={false} // Elimina la línea del eje Y
                                        tickLine={false} // Elimina las líneas de las marcas en el eje Y  
                                    />
                                    {/* <Tooltip cursor={{fill: 'transparent'}}/> */}
                                    <Tooltip cursor={{fill: 'transparent'}} content={ (props) => <CustomTooltip {...props} totals={nses.reduce((a,b) => a + b.total,0)} /> } />

                                    <Legend iconType='circle'/>
                                    <Bar dataKey="total" fill="rgb(33, 150, 243)" name="Nivel socio económico" barSize={60}  shape={<CustomBar />} >
                                        {nses.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % nses.length]}/>))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </>
                    )

                )
            }
        </>

    )
}