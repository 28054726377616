import React from "react";
import Dropzone from "react-dropzone";
import { useLoadExternalTokens } from "../../../../store/ExternalTokens/useLoadExternalTokens";
import Swal from "sweetalert2";
import DeleteIcon from '@mui/icons-material/Delete';

export const LoadExternalTokens = () => {
    const { file,setFile,resetFile,isValid } = useLoadExternalTokens((state) => state);

    const handleOnDropAccepted = (acceptedFiles) => {
        if (isValid(acceptedFiles[0])) {
            setFile(acceptedFiles[0]);
        }else {
            resetFile();
        }
    }

    const handleOnDropRejected = (rejectedFiles) => {
        Swal.fire('Error',"Debes subir un archivo válido de máx 5MB y extensión .csv, .xls o .xlsx",'error');
    };

    return (
    <>
        <Dropzone
            onDropAccepted={handleOnDropAccepted}
            onDropRejected={handleOnDropRejected}
            multiple={false}
            maxSize={5242880}
            accept={{
                'text/csv': ['.csv'],
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            }}
        >
            {({ getRootProps, getInputProps }) => (
                <>
                    <div className=" mt-3">
                        <label className="my-1 fw-bold">Cargar token externos</label>
                        <div className="d-flex align-items-center justify-content-center flex-column py-4 my-2" style={{ border: `2px dashed ${ file ? '#d3d3d3' : 'red' }`, }}>
                            <div className="w-100 text-center" {...getRootProps()} >
                                <input {...getInputProps()} name="tokens_file" id="tokens_file"/>
                                <p className="m-0 text-muted">Arrastra y suelta un archivo <b>.CSV / .XLSX / .XLS</b> aquí</p>
                                <p className="m-0 fw-bold" style={{ color: "#46166C", }}>o haz click para importar un archivo</p>
                                <small style={{ fontSize: "12px", }} >*La importación tiene un límite de 5MB</small>
                                <br></br>
                                <small style={{ fontSize: "12px", }} >*El archivo debe contener una columna llamada "token" o "tokens"</small>
                                <br></br>
                                <small style={{ fontSize: "12px", }} >*El archivo debe tener extensión .csv, .xls o .xlsx</small>
                            </div>
                            { file &&
                                <div className="p-3 mt-3 d-flex align-items-center flex-column flex-md-row text-center" style={{ border: "1px dashed #46166C" }}>
                                    <span>{ file.path }</span>
                                    <a className="m-0 p-" onClick={resetFile}>
                                        <DeleteIcon
                                            fontSize="medium"
                                            sx={{
                                                color: "red",
                                                marginLeft: "10px",
                                                marginBottom: "2px"
                                            }}
                                        />
                                    </a>
                                </div>
                            }
                        </div>
                    </div>
                </>
            )}
        </Dropzone>
    </>);
};