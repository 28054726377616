import { useState } from "react";
import { useQuery } from "react-query";
import { getRefenceNse, getTypesNse } from "../api/sip/nse";

export const useRefenceNse = () => {
    const [selectNse, setSelectNse] = useState({ options: [], selected: [] });
    const [typesNse, setTypesNse] = useState({
        keys: [],
        values: [],
        selectedType:'nse',
        selected:[]
    });
    const { isLoading } = useQuery(['refenceNse'], getRefenceNse,{ 
        cacheTime: 0,
        onSuccess: (response) => {
            const { data } = response;
            let options = data.map( x => ({ label: `${x.name}:${x.value}`, value: x.id }));
            setSelectNse({ ...selectNse, options, selected: options[0]});
            const types = data.reduce((nses, item) => {
                return ({...nses, [item.name]: [...(nses[item.name] || []), item]});
            }, {});
            setTypesNse({
                keys: [...Object.keys(types),'sin_nse'],
                values: types,
                selectedType:'sin_nse',
                selected:[]
            });
        }
    });

    return {
        isLoading, selectNse, setSelectNse, typesNse, setTypesNse
    }
}

export const useTypesNse = () => {
    const [selectTypes, setSelectTypes] = useState([]);
    const { isLoading } = useQuery(['typesNse'], getTypesNse,{ 
        onSuccess: (response) => {
            const { data } = response;
            let options = data.map( x => ({ label: `${x.name}`, value: `${x.name}` }));
            setSelectTypes({ ...selectTypes, options, selected:options[0]})
        }
    });

    return {
        isLoading, selectTypes, setSelectTypes
    } 
}