import { useEffect, useState } from "react";
import { useBoundPanelistsReportStore } from "../../../store/ReportsPanelistsStore/useBoundPanelistsReportStore";
import { QuarterBar } from "./bar/QuarterBar";
import Select from 'react-select';
import { QuarterLine } from "./line/QuarterLine";
import { QuarterTable } from "./table/QuarterTable";

export const DashboardPanelistsQuarter = () => {
    
    const { loadingQuarter, responseQuarter, errorsQuarter,defaultRepresentation,fetchPanelistsOfQuarter,preset,defaultStatusProject,typeChartQuarter,setTypeChartQuarter } = useBoundPanelistsReportStore( state => state );

    useEffect( () => {
        let search = defaultRepresentation.filter(f => f.value == 'quarter');
        if(search.length > 0){
            fetchPanelistsOfQuarter();
        }
    },[defaultRepresentation,preset,defaultStatusProject]);
    return (
        <div className="card mt-5">
        <div className="card-header">
            <div style={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center'
            }}>
                <label>
                    Trimestral
                </label>
                <Select
                      key="selected-2"
                      defaultValue={ typeChartQuarter.selected }
                      name="typeChartQuarter"
                      options={ typeChartQuarter.options }
                      onChange={ (e) => {
                        setTypeChartQuarter(e);
                      } }
                  />
            </div>
        </div>
        <div className="card-body">
                {
                    errorsQuarter.status && ( errorsQuarter.message )
                }
                {
                    loadingQuarter ? ( 'cargando...') : (
                        responseQuarter.length > 0 && (
                            <>
                                {typeChartQuarter.selected.value == 'barchart' && ( <QuarterBar responseQuarter={responseQuarter}/> )}
                                {typeChartQuarter.selected.value == 'linechart' && (<QuarterLine responseQuarter={responseQuarter}/>)}
                                {typeChartQuarter.selected.value == 'table' && (<QuarterTable responseQuarter={responseQuarter}/>)}
                            </>
                        )
                    )
                }
        </div>
    </div>
    )
}