import React from 'react';
import { useBoundNotificationFcm } from '../../../store/NotificationFcm/useBoundNotificationFcm';
import { capitalizarText } from '../../../helper/utils';

export const ConfirmNotificationData = () => {

    const { notification, panelists, fetchSendNotification, loading } = useBoundNotificationFcm( state => state);

    const handleSendNotification = () => {
        fetchSendNotification();
    }
    return (
        <div className="card">
            <div className="card-header">
                <div className="content-head-card">
                    <label>Mostrar proyectos</label>
                </div>
            </div>
            <div className="card-body">
                <table className="table table-bordered">
                    <thead >
                        <tr className=""  >
                            <th colSpan={2} style={{ backgroundColor: 'var(--bs-gray-200)' }}>Detalle notificación</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Nombre Campaña</th>
                            <td>{ notification.name } </td>
                        </tr>
                        <tr>
                            <th>Titulo notificación</th>
                            <td>{ notification.title } </td>
                        </tr>
                        <tr>
                            <th>Descripcion notificación</th>
                            <td>{ notification.description } </td>
                        </tr>
                        {
                            notification.isActionButton != 'disabled' && ( 
                                <>
                                <tr>
                                    <th>Titulo del boton</th>
                                    <td>{ notification.titleButton }</td>
                                </tr>
                                <tr>
                                    <th>Link del boton</th>
                                    <td>{ notification.linkButton }</td>
                                </tr>
                                </>
                            )
                        }
                    </tbody>
                    <thead >
                        <tr className="">
                            <th colSpan={2} style={{ backgroundColor: 'var(--bs-gray-200)' }}>Detalle segmentación</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Sexo</th>
                            <td >
                                <div style={{
                                    display:'flex',
                                    flexWrap:'wrap',
                                    gap:'10px'
                                }}>
                                    { panelists.gender.selected?.map( g => <span key={g.label} style={{
                                    backgroundColor:'var(--bs-gray-200)',
                                    color:'black',
                                    padding:'3px 8px',
                                    borderRadius:'10px',
                                    fontWeight:'bold',
                                    fontSize:'11px'
                                }}>{g.label}</span>) }
                                </div>
                                </td>
                        </tr>
                        <tr>
                            <th>{capitalizarText(panelists.tagGeo?.label || '')}</th>
                            <td >
                                <div style={{
                                    display:'flex',
                                    flexWrap:'wrap',
                                    gap:'10px'
                                }}>
                                    { panelists.geographies?.map( g => <span key={g.label} style={{
                                    backgroundColor:'var(--bs-gray-200)',
                                    color:'black',
                                    padding:'3px 8px',
                                    borderRadius:'10px',
                                    fontWeight:'bold',
                                    fontSize:'11px'
                                }}>{g.label}</span>) }
                                </div>
                                </td>
                        </tr>
                        <tr>
                            <th>{capitalizarText(panelists.typeNse?.label || '')}</th>
                            <td >
                                <div style={{
                                    display:'flex',
                                    flexWrap:'wrap',
                                    gap:'10px'
                                }}>
                                    { panelists.nse?.map( g => <span key={g.label} style={{
                                    backgroundColor:'var(--bs-gray-200)',
                                    color:'black',
                                    padding:'3px 8px',
                                    borderRadius:'10px',
                                    fontWeight:'bold',
                                    fontSize:'11px'
                                }}>{g.label}</span>) }
                                </div>
                                </td>
                        </tr>
                        <tr>
                            <th>Edades</th>
                            <td > 
                                <div style={{
                                    display:'flex',
                                    flexWrap:'wrap',
                                    gap:'10px'
                                }}>
                                <span style={{
                                    backgroundColor:'var(--bs-gray-200)',
                                    color:'black',
                                    padding:'3px 8px',
                                    borderRadius:'10px',
                                    fontWeight:'bold',
                                    fontSize:'11px'
                                }}>min: {panelists.ages.min}</span>
                                <span style={{
                                    backgroundColor:'var(--bs-gray-200)',
                                    color:'black',
                                    padding:'3px 8px',
                                    borderRadius:'10px',
                                    fontWeight:'bold',
                                    fontSize:'11px'
                                }}>max: {panelists.ages.max}</span>
                                </div>
                                </td>
                        </tr>
                        <tr>
                            <th>Total de base</th>
                            <td>
                                <span style={{
                                    backgroundColor:'var(--bs-gray-200)',
                                    color:'black',
                                    padding:'3px 8px',
                                    borderRadius:'10px',
                                    fontWeight:'bold',
                                    fontSize:'11px'
                                }}>
                                    { notification.totalPanelists }
                                </span></td>
                        </tr>
                    </tbody>
                </table>
                <div className="col-md-12 mt-3" style={{ display:'flex', justifyContent:'flex-end' , gap:20  }}>
                    {
                        loading ? (
                            <button className="btn btn-primary-oo" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                                Enviando notificaciones...
                            </button>
                        ) : (
                            <button className='btn btn-primary-oo' onClick={ handleSendNotification }>Publicar notificaciones</button>
                        )
                    }
                </div>
            </div>

        </div>
    )
}