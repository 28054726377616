import { useEffect, useState } from "react";
import { useBoundProductionsReportStore } from "../../../store/ReportsProductionsStore/useBoundProductionsReportStore";
import Select from 'react-select';
import { MonthBar } from "./bar/MonthBar";
import { MonthLine } from "./line/MonthLine";
import { MonthTable } from "./table/MonthTable";


export const DashboardProductionsMonth = () => {

    const { loadingMonth, responseMonth, errorsMonths,defaultRepresentation,fetchProductionOfMonth,preset,defaultStatusProject, typeChartMonth, setTypeChartMonth } = useBoundProductionsReportStore( state => state );
    useEffect( () => {
        let search = defaultRepresentation.filter(f => f.value == 'month');
        if(search.length > 0){
            fetchProductionOfMonth();
        }
    },[defaultRepresentation,preset,defaultStatusProject]);
    return (
        <div className="card mt-5">
        <div className="card-header">
            <div style={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center'
            }}>
                <label>
                    Mensual
                </label>
                <Select
                      key="selected-1"
                      defaultValue={ typeChartMonth.selected }
                      name="typeChartMonth"
                      options={ typeChartMonth.options }
                      onChange={ (e) => {
                        setTypeChartMonth(e);
                      } }
                  />
            </div>
        </div>
        <div className="card-body">
                {
                    errorsMonths.status && ( errorsMonths.message )
                }
                {loadingMonth ? ( 'cargando...') : (
                    responseMonth.length > 0 && (
                        <>
                            {typeChartMonth.selected.value == 'barchart' && ( <MonthBar responseMonth={responseMonth}/> )}
                            {typeChartMonth.selected.value == 'linechart' && (<MonthLine responseMonth={responseMonth}/> )}
                            {typeChartMonth.selected.value == 'table' && (<MonthTable responseMonth={responseMonth}/> )}
                        </>
                    )
                )}
        </div>
    </div>
    )
}