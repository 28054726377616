import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useMutation } from 'react-query';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { editSegmentation } from '../../../../api/sio/segmentation';
import { useGetGeographiesAjax, useTagsGeo } from '../../../../hooks/useGeography';
import { useRefenceNse } from '../../../../hooks/useNse';
import { useSegmentations } from '../../../../hooks/useSegmentation';
import { useGetSurveys } from '../../../../hooks/useSurvey';


export const EditSegmentation = ({ projectId, segmentation:segmentationData, seeModal, setSeeModal }) => {

    const [selectGender, setSelectGender] = useState({
        options: [ { label: 'Mujer', value: 'F' }, { label: 'Hombre', value: 'M' } ], 
        selected: JSON.parse(segmentationData.gender).map(x => ({ label: x.name, value: x.id})) 
    });

    const [status, setStatus] = useState({
        options: [ { label: 'Habilitar', value: 'enabled' }, { label: 'Deshabilitar', value: 'disabled' } ], 
        selected:  [ { label: 'Habilitar', value: 'enabled' }, { label: 'Deshabilitar', value: 'disabled' } ].filter(x => x.value == segmentationData.status)
    });

    const [segmentation, setSegmentation ] = useState({
            ...segmentationData, 
            age: JSON.parse(segmentationData.age),
            geographyData: JSON.parse(segmentationData.geographyData),
            nse: JSON.parse(segmentationData.nse),
            gender: JSON.parse(segmentationData.gender),
            projectsInQuarantine: segmentationData.inQuarantine == 0 ? [] : JSON.parse(segmentationData.projectsInQuarantine)
    });

    const { isLoading: isLoadingTag, selectTagsGeo, setSelectTagsGeo } = useTagsGeo();

    const {  isLoading: isLoadingGeo, setEvent, selectGeographies, setSelectGeographies }  = useGetGeographiesAjax({ value:segmentation.geographyLevel});

    const {isLoading:isLoadingNse,typesNse,setTypesNse} = useRefenceNse();

    const {isLoading:isLoadingSurveys,selectQuarantine,setSelectQuarantine,selectSurveys,setSelectSurveys } = useGetSurveys(!!segmentation.inQuarantine);

    const  {refetch:getSegmentationsRefetch } = useSegmentations(projectId, false);

    const { mutate:editSegmentationAjax, isLoading:isLoadingEdit, isError:isErrorEdit, error:errorEdit} = useMutation(editSegmentation,{
        onSuccess: () => { 
            Swal.fire('Editar segmentación', `Se editado el segmento ${ segmentation.name }`, 'success').then( result => {
                setSeeModal({...seeModal, create: !seeModal, edit: false });
                getSegmentationsRefetch();
        })} ,
        onError: (e) => {
            Swal.fire('Editar proyecto',`No se pudo editar el segmento ${ segmentation.name }`, 'error')
        }
    })

    const handleEditSegmentation = (e) => {
        e.preventDefault();
        Swal.fire({
            title: '¿Quieres editar este segmento?',
            showCancelButton: true,
            confirmButtonText: 'Editar',
            cancelButtonText: 'Cancelar',
            icon:'info'
          }).then((result) => {
            if(result.isConfirmed){
                editSegmentationAjax(segmentation);
            }
          })
    }
    useEffect( () => {
        if(!isLoadingNse){
            setTypesNse({...typesNse, selectedType:segmentation.typeNse})
        }
        console.log(isLoadingNse);
    },[isLoadingNse]);
    return (
        <div className="modal modal-segmentation">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Editar segmento</h5>
                        <button  className="close close-modal-btn" data-dismiss="modal" aria-label="Close" onClick={ () => {
                            setSegmentation([]);
                            setSeeModal({ create:!seeModal, edit:false });
                        }} >
                            <i className="fa fa-times-circle"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={ handleEditSegmentation } method="POST">
                            <div className="form-group">
                                <label className="my-1 fw-bold">Nombre</label>
                                <input type="text" name="name" className="form-control" id="name-input" value={ segmentation.name } onChange={ (e) => setSegmentation({ ...segmentation, name:e.target.value })}/>
                                {
                                    isErrorEdit && (
                                        <p className="text text-danger">{errorEdit.data.errors?.name?.map( x => x)}</p>
                                    )
                                }
                            </div>
                            <div className="row">
                                    <div className="form-group col-md-6">
                                        <label className="my-1 fw-bold">Género</label>
                                        <Select 
                                            key={'djasdhvashds'}
                                            isMulti={true}
                                            isClearable
                                            className="basic-multi-select"
                                            options={ selectGender.options }
                                            value = {  segmentation.gender.map( x => ({ label: x.name, value: x.id })) }
                                            onChange  = { (e) => {
                                                setSelectGender({...selectGender,selected: e})
                                                setSegmentation({ ...segmentation, gender: e.map(x => ({ id:x.value, name: x.label })) })
                                            }}
                                        />
                                        {
                                            isErrorEdit && (
                                                <p className="text text-danger">{errorEdit.data.errors?.gender?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="my-1 fw-bold">Zonas geograficas</label>
                                        {
                                            isLoadingTag ? ( <Skeleton />)
                                            : (
                                                <>
                                                <Select 
                                                    className="basic-select"
                                                    options={ selectTagsGeo.options }
                                                    value = {  selectTagsGeo.options.filter(x => segmentation.geographyLevel == x.value) }
                                                    onChange  = { (e) => {
                                                        setSelectTagsGeo({ ...selectTagsGeo, selected: e});
                                                        setSegmentation({ ...segmentation, geographyLevel: e.value, geographyData:[] });
                                                        setEvent(e);
                                                    }}
                                                />
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.geographyLevel?.map( x => x)}</p>
                                                    )
                                                }
                                                </>
                                            )
                                        }
                                    </div>
                            </div>
                            <div className="row">
                                 <div className="form-group col-md-6">
                                    <label className="my-1 fw-bold">Seleccionar { selectTagsGeo.selected.label } </label>
                                    {
                                        isLoadingGeo ? (<Skeleton />)
                                        : (
                                            <Select 
                                                isMulti
                                                isClearable
                                                className="basic-multi-select"
                                                options={ selectGeographies.options  }
                                                defaultValue = {  segmentation.geographyData.map(x => ({ label: x.name, value: x.id })) }
                                                onChange = { (e) => {
                                                    setSegmentation({...segmentation, geographyData: e.map( x => ({ id:x.value, name: x.label }))})
                                                    setSelectGeographies({...selectGeographies, selected: e})
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        isErrorEdit && (
                                            <p className="text text-danger">{errorEdit.data.errors?.geographyData?.map( x => x)}</p>
                                        )
                                    }
                                </div>
                                {
                                    isLoadingNse  ? ( <Skeleton />) : (
                                        <div className="form-group col-md-12">
                                            <div className="row">
                                                <div className={`${segmentation.typeNse !== 'sin_nse' ? 'col-md-6' : 'col-md-12'}`}>
                                                <label className="my-1 fw-bold">Tipos de niveles socio económicos </label>
                                                <Select 
                                                    className="basic-select"
                                                    options={ typesNse.keys.map( x => ({label:x, value:x}) ) }
                                                    value = { { label:typesNse.selectedType, value: typesNse.selectedType} }
                                                    onChange={e => {
                                                        setTypesNse({ ...typesNse, selectedType: e.value, selected:[]});
                                                        setSegmentation({ ...segmentation, nse:[], typeNse: e.value});
                                                    }}
                                                />
                                                </div>
                                                {
                                                    segmentation.typeNse !== 'sin_nse' && (
                                                        <div className="col-md-6">
                                                            <label className="my-1 fw-bold">Seleccinar niveles socio económicos </label>
                                                            {
                                                                    <>
                                                                        <Select 
                                                                            isMulti
                                                                            isClearable
                                                                            className="basic-multi-select"
                                                                            options={ typesNse.values[segmentation.typeNse].map( x => ({value:x.value, label:x.value, id:x.id})) }
                                                                            value = { segmentation.nse.map(x => ({ label: `${x.value}`, value:x.value, id: x.id})) }
                                                                            onChange = { (e) => {
                                                                                setTypesNse({...typesNse, selected: e});
                                                                                setSegmentation({...segmentation, nse: e.map(x => ({ ...x, name: typesNse.selectedType})) });
                                                                            }}
                                                                        />
                                                                        {
                                                                            isErrorEdit && (
                                                                                <p className="text text-danger">{errorEdit.data.errors?.nse?.map( x => x)}</p>
                                                                            )
                                                                        }
                                                                    </>
                                                            }
                                                        </div>
                                                    )
                                                }
                                                
                                            </div>
                                        </div>
                                   )
                                }
                                {/* <div className="form-group col-md-6">
                                    <label className="my-1 fw-bold">Seleccinar niveles socio económicos </label>
                                    {
                                        isLoadingNse ? ( <Skeleton />) 
                                        : ( 
                                            <>
                                                <Select 
                                                    isMulti
                                                    isClearable
                                                    className="basic-multi-select"
                                                    options={ selectNse.options  }
                                                    defaultValue = {  segmentation.nse.map(x => ({ label: `${x.name}:${x.value}`, value: x.id})) }
                                                    onChange = { (e) => {
                                                        setSelectNse({...selectNse, selected: e})
                                                        setSegmentation({...segmentation, nse: e.map( x => {
                                                            let _split = x.label.split(":");
                                                            return { id: x.value, name: _split[0], value: _split[1] }
                                                        })})
                                                    }}
                                                />
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.nse?.map( x => x)}</p>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label className="my-1 fw-bold">Edad mínima</label>
                                    <input type="number" name="age.min" className="form-control" id="name-input" value={ segmentation.age.min } onChange={ (e) => setSegmentation({ ...segmentation, age: {...segmentation.age, min: e.target.value } })}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="my-1 fw-bold">Edad máxima</label>
                                    <input type="number" name="age.max" className="form-control" id="name-input" value={ segmentation.age.max } onChange={ (e) => setSegmentation({ ...segmentation, age: {...segmentation.age, max: e.target.value } })}/>
                                </div>
                                <div className="form-group col-md-6">
                                    {
                                        isErrorEdit && (
                                            <p className="text text-danger">{errorEdit.data.errors?.age?.map( x => x)}</p>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-2">
                                    <label className="my-1 fw-bold">Cuarentena</label>
                                    <Select 
                                        className="basic-multi-select"
                                        options={ selectQuarantine.options }
                                        defaultValue={  () => {
                                            let quarantine = segmentation.inQuarantine == 1 ? 'Y' : 'N';
                                            return selectQuarantine.options.filter( x => x.value == quarantine);
                                        }}
                                        onChange = { (e) => {
                                            setSelectQuarantine({ ...selectQuarantine, selected: e.value});
                                            setSegmentation({ 
                                                ...segmentation, 
                                                inQuarantine: e.value == 'Y' ? true : false,
                                                projectsInQuarantine: e.value == 'Y' ? [...segmentation.projectsInQuarantine] : [] });
                                        }}
                                    />
                                    {
                                        isErrorEdit && (
                                            <p className="text text-danger">{errorEdit.data.errors?.inQuarantine?.map( x => x)}</p>
                                        )
                                    }
                                </div>
                                {
                                    segmentation.inQuarantine == 1 && (
                                        <div className="form-group col-md-10">
                                            <label className="my-1 fw-bold">Asociar encuestas </label>
                                            {
                                                isLoadingSurveys  ? ( <Skeleton /> )
                                                : (
                                                    <>
                                                        <Select 
                                                            isMulti
                                                            isClearable
                                                            className="basic-multi-select"
                                                            options={ selectSurveys.options  }
                                                            defaultValue = {  segmentation.projectsInQuarantine.map( x => ({ value: x.id, label: x.name }))}
                                                            onChange = { (e) => {
                                                                setSelectSurveys({...selectSurveys, selected: e})
                                                                setSegmentation({...segmentation, projectsInQuarantine: e.map( x => ({ id:x.value, name: x.label }))})
                                                            }}
                                                        />
                                                        {
                                                            isErrorEdit && (
                                                                <p className="text text-danger">{errorEdit.data.errors?.projectsInQuarantine?.map( x => x)}</p>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            {/* <div className="row">
                                <div className="form-group col-md-12">
                                    <label className="my-1 fw-bold">Estado</label>
                                    <Select 
                                        className="basic-multi-select"
                                        options={ status.options }
                                        defaultValue={ status.selected }
                                        onChange = { (e) => {
                                            setStatus({ ...status, selected: e});
                                            setSegmentation({ 
                                                ...segmentation, 
                                                status: e.value });
                                        }}
                                    />
                                    {
                                        isErrorEdit && (
                                            <p className="text text-danger">{errorEdit.data.errors?.status?.map( x => x)}</p>
                                        )
                                    }
                                </div>
                            </div> */}
                            <hr></hr>
                            <div className="form-group col-md-12">
                                {
                                    isLoadingEdit ? (<button className="btn btn-primary-oo">Cargando...</button>)
                                    : (<button className="btn btn-primary-oo">Editar segmento</button>)
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}