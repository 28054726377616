import React, { useContext, useEffect } from "react";
import { Alert } from "@mui/material";
import { AuthUserContext } from "../../../context/AuthUserContext";
export const SidebarRight = ({ id, survey_status }) => {
    const { authUser } = useContext(AuthUserContext);
    return (
        <div className="col-xl-3 col-lg-3 mt-4">
                {/* Card Body */}
                <ul className="list-group list-group-flush" id="sidebar-right-project">
                    <li className="list-group-item" id="sidebar-item-action">
                        <strong>Acciones</strong>
                    </li>
                    <li className={`list-group-item ${ !survey_status && 'disabled' }`}>
                        <a className={`link-sidebar-right`}  href={`/proyecto/${id}/dashboard`}>
                            <i className="fa fa-chart-area"></i>Dashboard
                        </a>
                    </li>
                    <li className="list-group-item">
                        <a className='link-sidebar-right' href={`/proyecto/`}>
                            <i className="fa fa-solid fa-list"></i>Ver todos los proyectos
                        </a>
                    </li>
                    <li className="list-group-item">
                        <a className='link-sidebar-right' href={`/proyecto/${id}/detalle`}>
                            <i className="fa fa-solid fa-eye"></i>Detalles  del proyecto
                        </a>
                    </li>
                    <li className={`list-group-item ${ !survey_status && 'disabled' }`}>
                        <a className={`link-sidebar-right`}  href={`/proyecto/${id}/segmentar`}>
                            <i className="fa fa-cubes"></i>Segmentar proyecto
                        </a>
                    </li>
                    {['super-admin','Project manager','commercial'].includes(authUser.roles[0].name)
                    && (
                        <>
                            <li className={`list-group-item ${ !survey_status && 'disabled' }`}>
                                <a className='link-sidebar-right' href={`/proyecto/${id}/editar`}>
                                    <i className="fa fa-edit"></i>Editar proyecto
                                </a>
                            </li>
                            <li className="list-group-item">
                                <a className='link-sidebar-right' href={`/proyecto/${id}/duplicar`}>
                                    <i className="fa fa-clone"></i>Duplicar proyecto
                                </a>
                            </li>
                            <li className={`list-group-item ${ !survey_status && 'disabled' }`}>
                                <a className={`link-sidebar-right`}  href={`/proyecto/${id}/panelistas`}>
                                    <i className="fa fa-link"></i>Panelistas
                                </a>
                            </li>
                            <li className="list-group-item">
                                <a className='link-sidebar-right' href={`/proyecto/${id}/panel-externo`}>
                                    <i className="fa fa-external-link-alt"></i>Integrar panel externo
                                </a>
                            </li>
                            <li className={`list-group-item ${ !survey_status && 'disabled' }`}>
                                <a className='link-sidebar-right' href={`/proyecto/ordenar`}>
                                    <i className="fa fa-up-down"></i>Ordenar proyectos
                                </a>
                            </li>
                        </>
                    )}
                    {['marketing','super-admin'].includes(authUser.roles[0].name)
                    && (
                        <li className={`list-group-item ${ !survey_status && 'disabled' }`}>
                            <a className={`link-sidebar-right`}  href={`/proyecto/${id}/paginas`}>
                                <i className="fa fa-message"></i>Configurar mensajes
                            </a>
                        </li>
                    )}
                </ul>
                {
                    !survey_status
                    &&
                    <Alert severity="warning" sx={{marginTop: '10px'}}>
                        <small>Los modulos deshabilitados 
                        solo están disponibles con el estudio habilitado en Limesurvey</small>
                    </Alert>
                }
        </div>
    );
};
