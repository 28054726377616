import { getPanelistsOfYear } from "../../api/reports/panelists";

const errors= { status:false, message: '' };
export const usePanelistsOfYearStore = (set,get) => {
    return {
        loadingYear:false,
        errorsYear:errors,
        responseYear: [],
        typeChartYear:{
            options: [{value:'barchart', label:'Barra' } , {value:'linechart', label:'Lineal' }, { value:'table', label:'Tabla' }],
            selected: {value:'barchart', label:'Barra' }
        },
        setTypeChartYear:  (type) => set((state) => ({ ...state, typeChartYear: { ...state.typeChartYear, selected: type }})),
        fetchPanelistsOfYear: async () => {
            set({loadingYear:true});
            set({errorsYear:{status:false,message:''}});
            get().setErrors(false,'');
            try {
                const  {data, status } = await getPanelistsOfYear(get().preset,get().defaultStatusProject);
                if(status == 204){
                    set({ errorsYear: { status:true, message:'No hay data disponible para este filtro generado.'} })
                }else{
                    set({ errorsYear: errors, responseYear: data });
                }
            } catch (error) {
                if(error.status = 422){
                    get().setErrors(true, error.data.message);
                }
            }
            set({ loadingYear: false });
        }
    }
};