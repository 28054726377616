import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "../../../App";
import { ReportsProjectsTable } from "./ReportsProjectsTable";
import { DateFilter } from "./DateFilter";
import { useBoundReportsProjectStore } from "../../../store/ReportsProjectStore/useBoundReportsProjectStore";

export const ReportProjects = () => {
    const preset = useBoundReportsProjectStore( state => state.preset );
    return (
        <div className="card">
            <div className="card-header">
                <div style={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center'
                }}>
                    <label>
                        Métricas de proyectos
                    </label>
                </div>
            </div>

            <div className="card-body">
                <DateFilter />
            </div>
            <div className="card-body">
                {
                    preset.from != null && (
                        <>
                            <ReportsProjectsTable />
                        </>

                    )
                }
            </div>
        </div>
    )
}

if (document.getElementById("jsx-reports-projects-report")) {
    const el = document.getElementById("jsx-reports-projects-report");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <ReportProjects {...props} />
        </App>
    );
}
