import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { App } from '../../../App';
import { FcmCreateNotification } from './FcmCreateNotification';
import { SelectPanelistFcm } from './SelectPanelistFcm';
import { useBoundNotificationFcm } from '../../../store/NotificationFcm/useBoundNotificationFcm';
import { ConfirmNotificationData } from './ConfirmNotificationData';



export const AccordionForm = ({id}) => {
    const { setter,fetchNotification,step, notification } = useBoundNotificationFcm( state => state);
    useEffect( () => {
        fetchNotification(id);
    },[]);
    
    if(notification.status === 'send'){
        return (
            <div style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
            }}>
                <label style={{
                backgroundColor: "#A5D6A7",
                padding:'5px 20px',
                borderRadius:8,
            }}>Acá se mostrará las métricas correspondiente a esta notificación. </label></div>
        );
    }else if(['draft', 'progress'].includes(notification.status) && parseInt(notification.step) <= 2){
        return (<Accordion notification={ notification } setter={ setter } step={ step } /> );
    }

    return (
        <ConfirmNotificationData />
    )
}


const Accordion = ({notification, setter, step }) => {
    const handleStepAccordion = (number) => {
        console.log(number, notification.step);
        if(number <= parseInt(notification.step)){
            setter({step: number});
        }
        return;
    }


    return (
        <div className="accordion accordion-flush" id="accordionCreate">
            <div className="accordion-item">
                <h2 className="accordion-header" id="accordionCreate-id">
                <button 
                    onClick={ () => handleStepAccordion(1) } 
                    className={`accordion-button ${step == 1 ? 'collapsed' : ''}`} 
                    type="button" 
                    ata-bs-toggle="collapse"
                    data-bs-target="#accordionCreate-collapseOne" 
                    aria-expanded={step == 1} 
                    aria-controls="accordionCreate-collapseOne"
                    >Información de campaña
                </button>
                </h2>
                <div id="accordionCreate-collapseOne" className={`accordion-collapse collapse ${ step == 1 ? 'show' : ''}`} aria-labelledby="accordionCreate-id">
                <div className="accordion-body">
                    <FcmCreateNotification />
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="accordionSelectPanelist-id">
                <button 
                    onClick={ () => handleStepAccordion(2) } 
                    className={`accordion-button ${step == 2 ? 'collapsed' : ''}`} 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target="#accordionSelectPanelist-collapseTwo" 
                    aria-expanded={step == 2} 
                    aria-controls="accordionSelectPanelist-collapseTwo"
                    disabled={ step >= 2 }  
                    >Seleccionar panelistas
                </button>
                </h2>
                <div id="accordionSelectPanelist-collapseTwo" className={`accordion-collapse collapse ${step == 2 ? 'show' : ''}`} aria-labelledby="accordionSelectPanelist-id">
                <div className="accordion-body" style={{ padding: 0 }}>
                    <SelectPanelistFcm />
                </div>
                </div>
            </div>
        </div>
    )
}
if (document.getElementById("jsx-fcm-create-notification")) {
    const el = document.getElementById("jsx-fcm-create-notification");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <AccordionForm {...props} />
        </App>
    );
}
