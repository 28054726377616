import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSubAnswers } from '../../../../hooks/useAnswer';
import { NumberMatrizQuestionStackedChart } from '../question/charts/NumberMatrizQuestionStackedChart';
import { UniqueMatrizQuestionPieChart } from '../question/charts/UniqueMatrizQuestionPieChart';


export const SubAnswerModal = ({ id, question, subQuestion, seeSubAnswerModal, setSeeSubAnswerModal }) => {
    const { isLoading, subAnswers, typeQuestion } = useSubAnswers({ ...subQuestion, type: question.type, projectId: id });
    return (
        <>
        {
            isLoading ? (<Skeleton height={300} />)
            :(
                <div className="modal modal-question">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{question.question.length == 0 ? question.title : question.question}</h5>
                                <button  className="close close-modal-btn" data-dismiss="modal" aria-label="Close" onClick={ () => setSeeSubAnswerModal(!seeSubAnswerModal)} >
                                    <i className="fa fa-times-circle"></i>
                                </button>
                            </div>
                            { ["uniqueMatrizQuestion"].includes(typeQuestion) && ( <UniqueMatrizQuestionPieChart data = { subAnswers } title={question.question} /> ) }
                            { ["numberMatrizQuestion"].includes(typeQuestion) && ( <NumberMatrizQuestionStackedChart data = { subAnswers } title={question.question} /> ) }
                        </div>
                    </div>
                </div>
            )
        }
        </>

    )
}