import React, { useEffect, useState } from 'react';
import { useBoundMktStore } from '../../../../store/ReportsCampaignMkt/useBoundMktStore';
import { currencyFormatter, formatterNumber } from '../../../../helper/currencyFormatter';
import Skeleton from 'react-loading-skeleton';

export const ItemsData = () => {
    const { data,totalRegister, loadings } =  useBoundMktStore( state => state );
    const { daily } = data;
    const { daily:loading } = loadings;
    const [items, setItems] = useState([]);
    useEffect( () => {
        if(daily.length  == 0){ return; }
        // let totals = daily.reduce( (previous, current) => previous + parseInt(current.total), 0);
        let totals = totalRegister;
        let profileds = daily.reduce( (previous, current) => previous + parseInt(current.profiled), 0);
        let noProfileds= daily.reduce( (previous, current) => previous + parseInt(current.notProfiled), 0);
        let totalCost = daily.reduce( (previous, current) => previous + parseInt(current.daily_cost), 0);
        setItems([
            { 'label': 'Total registrados', value: formatterNumber(totals), per:0 },
            { 'label': 'Total perfilados', value: formatterNumber(profileds), per: ((profileds/totals)*100).toFixed(2) },
            { 'label': 'Total no perfilados', value: formatterNumber(noProfileds), per:((noProfileds/totals)*100).toFixed(2) },
            { 'label': 'Costos', value: currencyFormatter({
                currency:'CLP',
                value:totalCost
            }), per:0},
            { 'label': 'Costo por registro', value: currencyFormatter({
                currency:'CLP',
                value:totalCost / totals
            }), per:0},
        ]);
    },[daily]);
    return (
            loading ? ( <Skeleton height={100}/> ): (
                <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-around', flexWrap:'wrap', gap:'20px' , marginBottom:'20px'}}>
                    {
                        items.map( (value, key) => (
                            <div className="" style={{ flex:1 }} key={ key }>
                                <div className="card-detail-project">
                                    <div className="detail-project">
                                        <label>{ value.label }:</label>
                                        <p>{ value.value }  { value.per != 0 && ( `(${value.per}%)`) }</p>
                                    </div>
                                    </div>
                                    <div>
                                </div>
                            </div>
                        ))
                    }
        </div>
    ))
}