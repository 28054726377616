import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {MaterialReactTable} from 'material-react-table';
import { getTransactions } from '../../../api/sis/transaction';
import { ExportToCsv } from 'export-to-csv';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { TransactionModal } from './modals/TransactionModal';
import { STATUS_TRANSACTIONS } from '../../../helper/status_transactions';
export const TransactionsTable = ({
    pagination,
    setPagination,
    rowCount,
    transactions,
    isLoading,
    isError,
    setIsError,
    url,
    columnFilters,
    setColumnFilters,
    csvOptions,
    handleTransactions
}) => {

    const [isExport,setIsExport] = useState(false);
    const [showTransactionModal,setShowTransactionModal] = useState(false);
    const [transactionSelect, setTransactionSelect] = useState({});
    const columns = useMemo( () =>[
        {
            accessorKey:'transaction_id',
            header: 'ID',
            size:20,
        },{
            accessorKey:'transaction_at',
            header: 'Fecha',
            size:40,
            enableColumnFilter:false,
        },{
            accessorKey:'name',
            header: 'Nombre',
        },{
            accessorKey:'email',
            header: 'Email',

        },{
            accessorKey:'dni',
            header: 'RUT/DNI',
        },{
            accessorKey:'status_transaction',
            header: 'Estado canje',
            Cell:({cell, column, table,row}) => {
                return STATUS_TRANSACTIONS[row.original.status_transaction]
            },
            filterVariant:'select',
            filterSelectOptions: Object.entries(STATUS_TRANSACTIONS).map(([clave, valor]) => ({text:valor, value:clave}))
        },{
            accessorKey:'name_product',
            header: 'Producto',
        },{
            accessorKey:'name_category_product',
            header: 'Categoria producto',
        },{
            accessorKey:'product_points',
            header: 'Puntos',
            size:30,
        },{
            accessorKey:'product_price',
            header: 'Precio',
            size:30,
        },{
            accessorKey:'codMov',
            header: 'Codigo movired',
            size:10,
        },{
            accessorKey:'companie',
            header: 'Compañia',
            size:20,
        },{
            accessorKey:'number',
            header: 'Número',
            size:20,
        },{
            header:'Acciones',
            Cell:({ cell, column, table, row }) => {
                return (
                    <div>
                        <button  className="btn btn-primary-oo" onClick={ ()  => handleViewData(row.original) } >Ver datos</button>
                    </div>
                )
            }
        }
    ],[]);

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = async () => {
        setIsExport(true);
        var _url = new URL(url);
        _url.searchParams.set('csv','generate');
        try{
            let response = await getTransactions(_url.href);
            const { data } = response;
            transactions  = data.map( x => ({...x, status_transaction: STATUS_TRANSACTIONS[x.status_transaction] }));
            csvExporter.generateCsv(transactions);
            setIsError({
                status: false,
                message: ""
            })
            setIsExport(false);
        }catch(error){
            const  { data,status } = error;
            if(status == 422){
                setIsError({
                    status:true,
                    message: data.message
                })
            }else{
                setIsError({
                    status:true,
                    message: "Error al descargar los datos"
                })
            }
            setIsExport(false);
        }
    };

    const handleViewData = (row) => {
        setTransactionSelect( row );
        setShowTransactionModal(!showTransactionModal);
    }

    return(
        <>
            {
                showTransactionModal && ( 
                    <TransactionModal 
                    transactionSelect={transactionSelect} 
                    setTransactionSelect={setTransactionSelect}
                    setShowTransactionModal={setShowTransactionModal} 
                    showTransactionModal={showTransactionModal} 
                    handleTransactions={handleTransactions}
                /> )
            }

            <MaterialReactTable 
                columns={columns} 
                data={transactions}
                manualFiltering
                manualPagination
                rowCount={rowCount} 
                onColumnFiltersChange={setColumnFilters}
                onPaginationChange={setPagination}
                initialState={{
                    density:'compact',
                    showColumnFilters: true,
                    columnVisibility:{
                        codMov:false,
                        companie:false,
                        number:false,
                        product_price:false,
                    }
                }}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [10,50,100,150,200],
                    //showFirstButton: false,
                    //showLastButton: false,
                }}
                muiToolbarAlertBannerProps={
                    isError.status
                        ? {
                            color: 'error',
                            children: isError.message,
                        }
                        : undefined
                }
                muiTablePaperProps={{
                    //customize paper styles
                    sx: {
                        boxShadow: 'none !important',
                        border: 'none'
                        },
                }}
                state={{
                    isLoading,
                    pagination,
                    columnFilters,
                    showAlertBanner: isError.status,

                }}
                renderTopToolbarCustomActions={ ({table}) => {
                    return(
                            transactions.length > 0 && (
                                <a className="btn btn-primary-oo" onClick={!isExport ? handleExportData: undefined }><i className="fas fa-download"></i> { !isExport ? 'Descargar registros' : 'Generando descarga' }</a>
                            )
                    )
                }}
                enableGlobalFilter={false}
                enableColumnActions={true}
                enableColumnFilters={true}
                enableBottomToolbar={true}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={true}
                localization={MRT_Localization_ES}
                
            /> 
        </>
    )
}
