import axios from "axios";
import { axiosApi } from "../axiosApi";

export const createMonitor = async (monitor) => { 
    try {
        const response = await axiosApi.post(`/api/monitor/crear`,monitor);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
}

export const editMonitor = async (monitor) => { 
    try {
        const response = await axiosApi.put(`/api/monitor/actualizar`,monitor);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
}

export const deleteMonitor = async(id) => {
    try {
        const response = await axiosApi.delete(`/api/monitor/eliminar/${id}`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }   
}
export const getMonitor = async(id) => {
    try{
        const response = await axios.get(`/api/monitor/${id}`);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }

}

export const  getMonitores = async () => {
    try{
        const response = await axios.get('/api/monitor');
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}