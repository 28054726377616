import {MaterialReactTable, useMaterialReactTable} from 'material-react-table';
import React, { useMemo } from 'react';
import { formatterNumber } from '../../../../helper/currencyFormatter';
import { useBoundMktStore } from '../../../../store/ReportsCampaignMkt/useBoundMktStore';

export const CampaignsTable = () => {
    const { loading, data, errors } =  useBoundMktStore( state => {
        return { 
            loading: state.loadings.campaigns, 
            data: state.data.campaigns, 
            errors: state.errors.campaigns
        }
    });
    const columns = useMemo(() => [
        {
            accessorKey: 'name',
            header: 'Nombre',
        },{
            accessorKey:'profiled',
            header: 'Perfilados',
            Cell:({ row }) => {
                const { profiled } = row.original;
                return formatterNumber( profiled )
            }
        },{
            header: 'Perfilados %',
            Cell: ({ row }) => {
                const { total, profiled } = row.original;
                return (( profiled / total)*100).toFixed(2) + '%';
            }
        },{
            accessorKey:'notProfiled',
            header:'No perfilados',
            Cell:({ row }) => {
                const { notProfiled } = row.original;
                return formatterNumber( notProfiled )
            }
        },{
            header: 'No perfilados %',
            Cell: ({ row }) => {
                const { total, notProfiled } = row.original;
                return (( notProfiled / total)*100).toFixed(2) + '%';
            }
        },{
            accessorKey:'total',
            header: 'Total',
            Cell:({ row }) => {
                const { total } = row.original;
                return formatterNumber( total )
            }
        },{
            header:'Detalle',
            Cell:({row}) => {
                return ( 
                    <div style={{ display:'flex', justifyContent:'space-around', alignItems:'center'}}>
                        <a  style={{ cursor:'pointer' }} href={`/reporte/campanhas/${row.original.id}/por-dias`} target='_blank'><i className="fa-solid fa-arrow-up-right-from-square"></i></a>
                    </div>
                )
            }
        }
    ],[]);

    const table = useMaterialReactTable({
        columns: columns,
        data:data,
        muiTablePaperProps: {
            sx: {
                boxShadow:'none !important', border:'none'
            }
        },
        muiToolbarAlertBannerProps: errors.status ? { color: 'error', children: errors.message } : undefined,
        enablePinning:true,
        initialState:{
            columnPinning: { left: [] },
            showColumnFilters:true,
        },
        state:{
            isLoading:loading,
            showAlertBanner: errors.status,
            density:"compact"
        },
        enablePagination:false,
        enableSorting:true,
        enableFullScreenToggle:false,
        enableDensityToggle:false,
        enableFilters:true,
        enableHiding:false,
        enableBottomToolbar:false,
        enableTopToolbar:true,
        enableColumnActions:true
    });

    return (
        <div>
            <MaterialReactTable table={ table } />
        </div>
    )
}