import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ajaxFilterPotentialPanelists, ajaxGetAvailablesSubset, ajaxLoadAvailables } from "../../api/sio/potentialBase";
import {
    initialStateFilter,
} from "../../helper/filterPotentialPanelist";
import { getSegmentation } from "../../api/sio/segmentation";

const initSegmentSubset = {
    type: 'lastinteraction',//swap, estado para intercambiar el filtro
    lastinteraction: {
        options: [
            {value: '3M',label: 'Últimos 3 meses'},
            {value: '6M',label: 'Últimos 6 meses'},
            {value: '12M',label: 'Últimos 12 meses'}
        ],
        selected: { value: '12M', label: 'Últimos 12 meses' }
    },
    portion: 100,
    withProfile: false,
    profile: null,
};

export const usePotentialsFilterStore = create(
    persist((set) => ({
        filter: initialStateFilter,
        segmentSubset: initSegmentSubset,
        participants: 0,
        availables: 0,
        toLoad: 0,
        batchId: "",
        isLoadingPotentials: true,
        isLoadingLoadAvailables: false,
        isLoadingSubset: false,
        isLoadingSegmentation: true,
        isError: false,
        errorMessage: "",
        isOperation: false,
        setFilter: (newFilter) => set({ filter: { ...newFilter } }),
        setBatchId: (newBatchId) => set({ batchId: newBatchId }),
        setAvailables: (newAvailables) => set({ availables: newAvailables }),
        setParticipants: (newParticipants) => set({ participants: newParticipants }),
        setSegmentSubset: (newSubset) => set({ segmentSubset: newSubset }),
        resetFilter: () => set({ filter: initialStateFilter }),
        resetSegmentSubset: () => set({ segmentSubset: initSegmentSubset }),
        resetParticipants: () => set({ participants: 0 }),
        resetAvailables: () => set({ availables: 0 }),
        resetBatchId: () => set({ batchId: "" }),
        fetchSegment: async (segmentId) => {
            try{
                const response = await getSegmentation(segmentId);
                const newFilter = {
                    id: response.id,
                    gender: JSON.parse(response.gender),
                    geographyLevel: response.geographyLevel,
                    geographyData: JSON.parse(response.geographyData),
                    age: JSON.parse(response.age),
                    inQuarantine: response.inQuarantine,
                    projectsInQuarantine: JSON.parse(response.projectsInQuarantine),
                    typeNse: response.typeNse,
                    nse: JSON.parse(response.nse),
                    project_id: response.project_id,
                };
                set({ isLoadingSegmentation: false, filter: newFilter });
            }catch(error){
                set({
                    isLoadingSegmentation: false,
                    isError: true,
                    errorMessage: error.response.data.message,
                });
            }
        },
        fetchPotentials: async (_filter) => {
            set({ isLoadingPotentials: true, isError: false, errorMessage: "" });
            // Obtenemos los panelistas según segmentos...
            try {
                const response = await ajaxFilterPotentialPanelists(_filter);
                set({
                    participants: response.status == 200 ? response.data.participants : 0,
                    availables: response.status == 200 ? response.data.availables : 0,
                    isLoadingPotentials: false,
                    batchId: response.status == 200 ? response.data.batchId : "",
                    isError: false,
                    errorMessage: ""
                });
            } catch (error) {
                set({
                    participants: 0,
                    availables: 0,
                    isLoadingPotentials: false,
                    isError: true,
                    errorMessage: error.response.data.message,
                });
            }
        },
        fetchLoadAvailables: (selected, requireExternalTokens = false) => {
            set({ isLoadingLoadAvailables: true, isError: false, errorMessage: "" });
            // Obtenemos los panelistas según segmentos...
            ajaxLoadAvailables(selected, requireExternalTokens)
                .then((response) => {
                    //seteamos el batch id, con esto el Store useBatchStore podra consultar el progreso.
                    set({ batchId: response.data.batchId, toLoad: response.data.toLoad });
                })
                .catch((error) => {
                    set({
                        isLoadingLoadAvailables: false,
                        isError: true,
                        errorMessage: error.response.data.message,
                    });
                })
                .finally(() => {
                    set({
                        isLoadingLoadAvailables: false,
                    });
                });
        },
        fetchAvailablesSubset: (selected) => {
            set({ isLoadingSubset: true, isError: false, errorMessage: "" });
            // Obtenemos los panelistas según segmentos...
            ajaxGetAvailablesSubset(selected)
                .then((response) => {
                    //seteamos el batch id, con esto el Store useBatchStore podra consultar el progreso.
                    set({ toLoad: response.data.toLoad });
                })
                .catch((error) => {
                    set({
                        isLoadingSubset: false,
                        isError: true,
                        errorMessage: error.response.data.message,
                        toLoad: 0
                    });
                })
                .finally(() => {
                    set({
                        isLoadingSubset: false,
                    });
                });
        },
    }),
    {
        name: 'potentials-filter',
        partialize: (state) => ({
            batchId: state.batchId,
            filter: state.filter,
            availables: state.batchId !== '' ? state.availables : 0,
            participants: state.batchId !== '' ? state.participants : 0,
            toLoad: state.batchId !== '' ? state.toLoad : 0,
        })
    })
);
