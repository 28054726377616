import React    from 'react';
import {Bar, BarChart, CartesianGrid, Cell, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useBoundMktStore } from '../../../../store/ReportsCampaignMkt/useBoundMktStore';
import Skeleton from 'react-loading-skeleton';


const CustomBar = (props) => {
    const { x, y, width, height, fill, payload } = props;
    return (
        <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        stroke="#e2e2e2" // Color del borde
        strokeWidth={1} // Ancho del borde
        radius={[0,10,10,0]}
        />
    );
}

const CustomTooltip = ({ active, payload, label,totals }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
            backgroundColor:"#FFF",
            padding:"10px",
            borderRadius:"10px",
            // boxShadow:'1px 1px 3px 1px #7E57C2',
            boxShadow:  '#CFD8DC 0px 1px 7px 0px',
            width:'300px'
            }}>
            <p style={{ color:"#3d3d3d", textAlign:'center'}}><strong>{ label }</strong></p>
            <hr></hr>
            <p style={{ color:`${payload[0].color}`}}>{ payload[0].name }: {`${payload[0].value} (${((payload[0].value/totals)*100).toFixed(2)}%)`}</p>
        </div>
      );
    }
  
    return null;
  };


export const AgesBarChart = ({ id }) => {

    const { loadings, errors, data } = useBoundMktStore( state => state);
    const { ages:loadingL } = loadings;
    const { ages } = data;
    
    return (
            loadingL ? ( <Skeleton height={300}/> ) : (
                ages.length > 0 && ( 
                    <ResponsiveContainer  width="100%" height={300}>
                        <BarChart
                        width={500}
                        height={300}
                        data={ages}
                        layout='vertical'
                        >
                        <CartesianGrid  stroke='none'/>
                            <XAxis dataKey="total"  type="number"
                            allowDataOverflow={true}    
                            axisLine={false} // Elimina la línea del eje Y
                            tickLine={false} 
                            />
                            <YAxis dataKey="ages"  type="category" 
                            domain={[0, dataMax => maxValue * 1.5]} tickCount={10} 
                            allowDataOverflow={true}    
                            axisLine={false} // Elimina la línea del eje Y
                            tickLine={false} 
                            
                            />
                            <Tooltip cursor={{fill: 'transparent'}} content={(props) => <CustomTooltip {...props} totals={ages.reduce((a,c)=> a  + c.total,0)}/>}/>
                            <Legend iconType='circle'/>
                            <Bar 
                                dataKey="total" 
                                fill="#42A5F5" 
                                name="Tramos de edad"
                                shape={<CustomBar />}
                                >
                                {ages.map((entry, index) => (
                                    <Cell 
                                        key={`cell-${index}`} 
                                        fill="#81D4FA"
                                        // fill={COLORS[index % ages.length]} 
                                    />))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                )
            )
    )
}