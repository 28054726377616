import { formatterNumber } from '../../../../helper/currencyFormatter';

export const MonthTable = ({ responseMonth }) => {

    return ( 
        <div className="table-responsive">
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>Mes/año</th>
                        <th>Teórico</th>
                        <th>Completas</th>
                        <th>Completas(%)</th>
                        <th>Filtradas</th>
                        <th>Parciales</th>
                        <th>Total Base</th>
                        <th>T. Respuesta(%)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        responseMonth.map((month,keyMonth) => {
                           let _t = (((parseInt(month.completed) +  parseInt(month.filtered) + parseInt(month.totalLastPartial)) / parseInt(month.totalBase))*100).toFixed(2);
                           return (
                                <tr key={keyMonth}>
                                    <td>{ month.monthName }</td>
                                    <td>{ formatterNumber(parseInt(month.sample)) }</td>
                                    <td>{ formatterNumber(parseInt(month.completed)) }</td>
                                    <td>{ ((parseInt(month.completed)/parseInt(month.sample))*100).toFixed(2) }%</td>
                                    <td>{ formatterNumber(parseInt(month.filtered)) }</td>
                                    <td>{ formatterNumber(parseInt(month.totalLastPartial)) }</td>
                                    <td>{ formatterNumber(parseInt(month.totalBase)) }</td>
                                    <td>{ _t }%</td>
                                </tr>  
                           ) 
                        })
                    }
                </tbody>
                    
            </table>
        </div>
    )
}

