import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import {MaterialReactTable} from 'material-react-table';
import { useQuotasProject } from '../../../../hooks/useProject';
import { ExportToCsv } from 'export-to-csv';
import { v4 as uuidv4 } from 'uuid';


export const QuotasTableProject = ({id, hash}) => {
    const { isLoading, quota} = useQuotasProject(id, hash);

    const columns = useMemo(() => [ 
            {
                accessorKey: 'name', //access nested data with dot notation
                header: 'Nombre',
            },{
                accessorKey: 'qlimit', //access nested data with dot notation
                header: 'Limite',
            },{
                accessorKey: 'total', //access nested data with dot notation
                header: 'Completas',
            },{
                accessorKey: 'pending', //access nested data with dot notation
                header: 'Pendientes',
            },{
                header: 'Progreso',
                Cell: ({ cell, column, row, table }) => {
                    let progress = Math.round((row.original.total/row.original.qlimit)*100);
                    progress = isNaN(progress) ? 0 : progress;
                    progress = progress == Infinity ? 100 : progress;
                    return (
                      <div className="progress">
                        <div className="progress-bar-custom" role="progressbar"  style={{ width: progress+'%' }} aria-valuenow={ progress } aria-valuemin="0" aria-valuemax='100'>{progress}%</div>
                      </div>
                    )
                }  
            }
    ],[])

    const exportQuotas = (rows) => {
        const calc  =  rows.map( row => {
            let progress = Math.round((row.original.total/row.original.qlimit)*100);
            progress = isNaN(progress) ? 0 : progress;
            progress = progress == Infinity ? 100 : progress;
            return {
                'Nombre': row.original.name, 
                'Limite':  row.original.qlimit, 
                'Completas': row.original.total,
                'Pendientes': row.original.pending,
                'Progreso': progress
            }
        })
        const csvOptions = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            headers: columns.map((c) => c.header),
            filename: uuidv4()
        };
        const csvExporter = new ExportToCsv(csvOptions); 
        csvExporter.generateCsv( calc.map( (row) => row ));
    }

    return (
        <>
        {
            isLoading ? (<Skeleton height={300}/>) : (
                <div className="col-md-12 mt-4">
                    <div className='card card-header'>
                        <h5>Quotas</h5>
                    </div>
                    <div className="card card-body">
                        <MaterialReactTable 
                            columns={columns} 
                            data={quota} 
                            initialState={{ density: 'compact' }}
                            muiTablePaperProps={{
                                sx: {
                                    boxShadow: 'none !important',
                                    border: 'none'
                                },
                            }}
                            enableColumnActions={false}
                            enableColumnFilters={true}
                            enablePagination={false}
                            enableSorting={true}
                            enableBottomToolbar={false}
                            enableTopToolbar={true}
                            muiTableBodyRowProps={{ hover: true }}
                            renderTopToolbarCustomActions= {
                                ({table}) => (
                                    <div style={{ display:'flex', gap:'5px' }}>
                                        <a 
                                            style={{ backgroundColor: 'var(--bs-cyan)'}}
                                            onClick={() => exportQuotas(table.getPrePaginationRowModel().rows)}
                                            className="btn btn-default">
                                            <i className="fas fa-download m-1"></i>Descargar
                                        </a>
            
                                    </div>
                                )
                            }
                        />
                    </div>
                </div>
            )
        }
        </>

    )
}