export const COLORS = [
    "#E57373",
    "#BA68C8",
    "#B39DDB",
    "#7986CB",
    "#90CAF9",
    "#4FC3F7",
    "#80DEEA",
    "#A5D6A7",
    "#AED581",
    "#E6EE9C",
    "#FFF176",
    "#FFE082",
    "#FFB74D",
    "#FFAB91",
    "#A1887F",
    "#546E7A"
]

export const COLORS_GENDER= {
    'F':'#F06292',
    'M': '#4FC3F7'
};