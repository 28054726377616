import React, { useContext, useEffect, useMemo, useState } from 'react';
import {ItemsResultSurveyContext} from '../../../../../context/ItemsResultSurveyContext';
import {MaterialReactTable} from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';
import { COLORS } from '../../../../../helper/COLOR';
import Skeleton from 'react-loading-skeleton';

export const DescriptionRankingQuestion = (props) => {
    const { title,descriptionData,totalAnswer } = props;
    const [loading, setLoading] = useState(true);
    const [df, setDf] = useState([]);
    const { items } = useContext(ItemsResultSurveyContext);
    const  data =  descriptionData;
    

    useEffect( () => {
        const _df = [];
        data.forEach((value,key) => {
            Object.keys(value).forEach((v,k) => {
                if(v != "answer"){
                   _df.push({total: value[v], answer: v, question: value.answer, color:COLORS[k]}) 
                }
            });
        });
        setDf(_df);
        setLoading(!loading);
    
    },[descriptionData]);

    const  columns = useMemo( () => [
        {
            header: '_',
            Cell: ({cell, row}) => {
                return (<div style={{ borderRadius: '1rem', padding:'0.5rem', width:'0.5rem', backgroundColor:row.original.color}}></div>)
            },
            size: 10,
            enableGrouping: false
        },{
            accessorKey: 'question',
            header: 'Columna',
            size:100,
        },
        {
            accessorKey: 'answer',
            header: 'Respuestas',
            size:100,
        },{
            accessorKey: 'total',
            header: 'Total',
            Cell:({ cell, row }) => {
                console.log(row);
                //let progress = Math.round((row.original.total/row.original.totalAnswer)*100);
                let progress = (row.original.total/totalAnswer)*100;
                progress = parseFloat(progress.toFixed(2));
                progress = isNaN(progress) ? 0 : progress;
                return (<label>{`${row.original.total}  (${progress}%)`}</label>)
            },
            size:100,
        },
    ],[]);

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
        filename:title
    };
    const csvExporter = new ExportToCsv(csvOptions);
    
    const mapRows = (rows) => {
        return rows.map(function(row, index){
            if(!row.getIsGrouped()){
                return ({'_': index, question: row.original.question, answer: row.original.answer, total:row.original.total });
            }
        }).filter( f =>  f != undefined);
    }

    const handleExportData = (rows) => {
        let map  = mapRows(rows);
        csvExporter.generateCsv(map);
    };

    return (
        <>
        {
            loading ? ( <Skeleton height={250}/>)
            : (
                <div style={{
                    overflow:'auto',
                    height:'100%'
                }}>
                    <MaterialReactTable 
                        columns={columns} 
                        data={df} 
                        initialState={{ density: 'compact',grouping: ['question'], expanded: true }}
                        muiTablePaperProps={{
                            sx: {
                                boxShadow: 'none !important',
                                border: 'none',
                            },
                        }}
                        enableGrouping
                        enableColumnActions={true}
                        enableColumnFilters={true}
                        enablePagination={false}
                        enableSorting={true}
                        enableBottomToolbar={false}
                        enableToolbarInternalActions={false}
                        muiTableBodyRowProps={{ hover: true }}
                        renderTopToolbarCustomActions= {
                            ({table}) => (
                                <div style={{ display:'flex', gap:'5px' }}>
                                    {/* <a 
                                        style={{ backgroundColor: 'var(--bs-cyan)'}}
                                        onClick={() => handleExportData(table.getPrePaginationRowModel().rows)}
                                        className="btn btn-default">
                                        <i className="fas fa-download m-1"></i>Descargar
                                    </a> */}
                                    {/* <a 
                                        style={{ backgroundColor: 'var(--bs-gray-200)'}}
                                        onClick={() => handleExportData(table.getPrePaginationRowModel().rows)}
                                        className="btn btn-default">
                                        <i className="fa-solid fa-up-right-from-square m-1"></i>Generar filtros
                                    </a> */}
                                </div>
                            )
                        }
                    />
        
                </div>
            )
        }
        </>


    )
}