export const PANELIST_STATUS_VALUES = {
    active: "Activo",
    inactive: "Inactivo",
};
export const PANELIST_STATUS_OPTIONS = [
    { label: "Activo", value: 'active' },
    { label: "Inactivo", value: 'inactive' },
];

export const PANELIST_SINO_VALUES = {
    1: "Sí",
    0: "No",
};
export const PANELIST_TYPE_VALUES = {
    panelist: "Panelista",
    ambassador: "Embajador",
    ambassador_candidate: "Candidato a embajador",
    pyme: "Pyme",
    pyme_candidate: "Candidato a Pyme"
};

export const PANELIST_RRSS_VALUES = {
    instagram: "Instagram",
    tiktok: "TikTok",
    facebook: "Facebook",
    x: "X",
    linkedin: "LinkedIn"
};

export const PANELIST_ACTIVITY_VALUES = {
    "3M": "0 a 3 meses",
    "6M": "3 a 6 meses",
    "12M": "6 a 12 meses",
    "older": "12 meses o más",
};

export const PANELIST_TYPE_OPTIONS = [
    { label: "Panelista", value: 'panelist' },
    { label: "Embajador", value: 'ambassador' },
    { label: "Candidato a embajador", value: 'ambassador_candidate' },
    { label: "Pyme", value: 'pyme' },
    { label: "Candidato a Pyme", value: 'pyme_candidate' },
];
export const PANELIST_GENDER_VALUES = {
    M: "Hombre",
    F: "Mujer",
};

export const POINTS_STATUS_OPTIONS = [
    { label: "Redes Sociales", value: 'rrss' },
    { label: "Otro", value: 'other' },
];