import React, { Children } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-loading-skeleton/dist/skeleton.css";
import "react-day-picker/dist/style.css";
import { AuthUserProvider } from "./providers/AuthUserProvider";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: process.env.NODE_ENV === "production",
            refetchOnWindowFocus: false,
        },
    },
});

export const App = ({ children }) => {
    return (
        <QueryClientProvider contextSharing={true} client={queryClient}>
            {/* AuthUserProvider - Data del usuario autenticado y autorizado */}
            <AuthUserProvider>
                {Children.map(children, (child) => (
                    <>{child}</>
                ))}
            </AuthUserProvider>
        </QueryClientProvider>
    );
};
