import { endOfWeek, startOfMonth, startOfWeek, sub, endOfQuarter, startOfQuarter, startOfYear } from "date-fns";

const today = new Date();
export const dateTransform = {
    'reset': { from: startOfWeek(today, { weekStartsOn: 1 }), to: endOfWeek(today, { weekStartsOn: 1 }), },
    'today': { from: today, to:today },
    'thisWeek': { from: startOfWeek(today, { weekStartsOn: 1 }), to: endOfWeek(today, { weekStartsOn: 1 }), },
    'lastWeek': { 
        from: sub(startOfWeek(today, { weekStartsOn: 1 }), { weeks: 1 }),
        to: sub(endOfWeek(today, { weekStartsOn: 1 }), { weeks: 1 })
    },
    'last7':{ from: sub(today, { days: 6 }), to: today },
    'thisMonth': { from: startOfMonth(today), to: today },
    'active': { from: sub(today, { years: 1 }), to: today },
    'semiactive': { 
        from: sub(today, { years: 2 }),
        to: sub(today, { years: 1, days: 1 })
    },
    'ofQuarter':  { from:sub(today, {months:2}), to: sub(today,{month:2}) },
    'ofSemester': { from:sub(today, {months:5}), to: sub(today,{month:5}) },
    'ofYear': { from: startOfYear(today), to: today },
}
