import { create } from "zustand";
import { addPointsToPanelist, getPanelistPoints, getPanelists, updatePanelist } from "../../api/sip/panelists";

let URL_PANELISTS = new URL(`${import.meta.env.VITE_REACT_APP_URL}/marketing/panelists`);

export const usePanelistsStore = create((set, get) => {
    return {
        panelists: [],
        panelist: {},
        token: "",
        setToken: (newToken) => set(newToken),
        setPanelist: (newPanelist) => set({ panelist: { ...newPanelist } }),
        total: 0,
        isLoadingPanelists: true,
        setIsLoadingPanelists: (newValue) => set({isLoadingPanelists: newValue}),
        isLoadingPanelist: true,
        isLoadingGetPoints: true,
        isError: { status: false, message: "" },
        isErrorUpdate: { status: false, message: "" },
        isErrorPoints: { status: false, message: "" },
        isErrorAddPoints: { status: false, message: "" },
        setIsError: (newIsError) => set({ isError: { ...newIsError } }),
        fetchPanelists: async (pagination = { pageIndex: 0, pageSize: 10 }, columnFilters = []) => {
            set({ isLoadingPanelists: true, isError: { status: false, message: "" } });
            try {
                if (URL_PANELISTS.searchParams.has('download')) {
                    URL_PANELISTS.searchParams.delete('download', 'filtered');
                }
                URL_PANELISTS.searchParams.set('perpage', `${pagination.pageSize}`);
                URL_PANELISTS.searchParams.set('page', pagination.pageIndex + 1);
                URL_PANELISTS.searchParams.set('filters', columnFilters.length > 0 ? JSON.stringify(columnFilters) : '');
                const { status, data } = await getPanelists(URL_PANELISTS);
                set({
                    isLoadingPanelists: false,
                    panelists: status === 200 ? data.data : [],
                    total: data.total
                });
            } catch (error) {
                set({
                    isLoadingPanelists: false,
                    isError: { status: true, message: error.data?.message },
                });
            }
        },
        fetchUpdatePanelists: async (toUpdate) => {
            set({ isErrorUpdate: { status: false, message: "" } });
            try {
                const { status, data } = await updatePanelist(toUpdate);
                return { status, message: data.message };
            } catch (error) {
                set({
                    isErrorUpdate: { status: true, message: error.data },
                });
            }
        },
        fetchPanelistPoints: async (token) => {
            set({ isLoadingGetPoints: true, panelist: { ...get().panelist }, isErrorPoints: { status: false, message: "" } });
            try {
                const { status, data } = await getPanelistPoints(token);
                if (status != 200) {
                    set({
                        isLoadingGetPoints: false,
                        isErrorPoints: { status: true, message: data.message },
                    });
                    return;
                }
                set({
                    isLoadingGetPoints: false,
                    panelist: status === 200 ? { ...get().panelist, points: data.points } : { ...get().panelist },
                });
                return data.points;
            } catch (error) {
                set({
                    isLoadingGetPoints: false,
                    isErrorPoints: { status: true, message: error.data?.message },
                });
            }
        },
        fetchAddPointsToPanelist: async (addPoints) => {
            set({ isErrorAddPoints: { status: false, message: "" } });
            try {
                const { status, data } = await addPointsToPanelist(addPoints);
                return { status, message: data.message };
            } catch (error) {
                set({
                    isErrorAddPoints: { status: true, message: error.data },
                });
            }
        },
    }
});

export const useDownloadPanelists = create((set) => {
    return {
        isErrorDownload: { status: false, message: "" },
        fetchStartDownloadPanelists: async (download, columnFilters) => {
            console.log("fetchStartDownloadPanelists");
            set({ isErrorDownload: { status: false, message: "" } });
            try {
                URL_PANELISTS.searchParams.set('download', download);
                URL_PANELISTS.searchParams.set('filters', columnFilters.length > 0 ? JSON.stringify(columnFilters) : '');
                const { status, data } = await getPanelists(URL_PANELISTS);
                if (status != 200) {
                    set({
                        isErrorDownload: { status: true, message: data.message },
                    });
                }
                return { status, data };
            } catch (error) {
                set({
                    isErrorDownload: { status: true, message: error.data?.message },
                });
            }
        },
    }
});