import { create } from "zustand";
import { getBatch } from "../../api/utils/batch";
import { persist } from "zustand/middleware";

export const useBatchStore = create(
    persist((set) => ({
        progress: 0,
        isError: false,
        errorMessage: "",
        isBatchStarted: false,
        setIsBatchStarted: (value) => set({ isBatchStarted: value }),
        resetProgress: () => set({ progress: 0 }),
        fetchProgress: async (batchId) => {
            set({ isError: false, errorMessage: "" });
            // Obtenemos el progreso del batch
            try {
                const data = await getBatch(batchId);
                if (data.failedJobs > 0) {
                    set({
                        isError: true,
                        errorMessage:
                            "La carga de un lote de panelistas ha fallado. Informar a TI para revisión de la base cargada.",
                    });
                }
                set({ progress: data.progress });
            } catch (error) {
                // Si hay errores, los seteamos para mostrarlos.
                set({
                    progress: 0,
                    isError: true,
                    errorMessage:
                        "Error en el proceso de carga. Informar a TI para revisión de la base.",
                });
            }
        },
    }),
    {
        name: 'batch-progress',
    }
    )
);
