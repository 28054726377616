import { create } from "zustand";
import  { useBoundCampaignReportStore } from './useBoundCampaignReportStore';
import { useBoundReportUserStore } from './useBoundReportUserStore';
import { dateTransform } from "../../helper/dateTransform";

const errorsConst = { status:false, message:'' }
export const useBoundMktStore = create( (set,get) => {
    return {
        preset:dateTransform['ofYear'],
        totalRegister: 0,
        loadings: {
            registerCost: false,
            campaign: false,
            campaigns: false,
            daily: false,
            ages: false, 
            genders: false,
            firstgeo: false,
            profiles: false,
            surveys: false,
            nses: false,
        },
        errors: {
            registerCost: errorsConst,
            campaign: errorsConst,
            campaigns: errorsConst,
            daily: errorsConst,
            ages: errorsConst,
            genders: errorsConst,
            firstgeo: errorsConst,
            profiles: errorsConst,
            surveys: errorsConst,
            nses: errorsConst
        },
        data:{
            campaign: [],
            campaigns: [],
            daily: [],
            ages: [],
            genders: [],
            firstgeo: [],
            profiles: [],
            surveys: [],
            nses: []
        },
        setPreset: (_preset) => set((state) => ({...state, preset: _preset })),
        ...useBoundCampaignReportStore(set,get),
        ...useBoundReportUserStore(set, get)
    }
})