import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "../../../App";
import { Filters } from "./Filters";
import { useBoundProductionsReportStore } from "../../../store/ReportsProductionsStore/useBoundProductionsReportStore";


export const ProductionsReport = () => {
    const { defaultRepresentation,typeChartMonth, typeChartQuarter, typeChartSemester,typeChartYear } = useBoundProductionsReportStore( state => state );
    const calcColumn  = (x,k) => {
        if(x.value == 'month' && typeChartMonth.selected.value == 'table'){
            return 'col-md-12';
        }
        if(x.value == 'quarter' && typeChartQuarter.selected.value == 'table'){
            return 'col-md-12';
        }
        if(x.value == 'semester' && typeChartSemester.selected.value == 'table'){
            return 'col-md-12';
        }
        if(x.value == 'year' && typeChartYear.selected.value == 'table'){
            return 'col-md-12';
        }
        if(k % 2=== 0){
            return 'col-md-6'
        }else if(defaultRepresentation.length == k){
            return 'col-md-12';
        }else{
            return 'col-md-6';
        }
    }
    return (
        <>
        <div className="card">
            <div className="card-header">
                <div style={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center'
                }}>
                    <label>
                        Métricas de producción
                    </label>
                </div>
            </div>

            <div className="card-body">
                <Filters />
            </div>
        </div>
        <div className="row representations-chart">
            {
                defaultRepresentation.length  ? (
                    defaultRepresentation.map( (x,k) => <div className={calcColumn(x,k+1)} key={k}>{ x.callback() } </div> )
                ):(
                    <div className="alert alert-warning">No has seleccionado una representación gráfica</div>
                )
            }
        </div>
        </>
    )
}

if (document.getElementById("jsx-productions-report")) {
    const el = document.getElementById("jsx-productions-report");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <ProductionsReport {...props} />
        </App>
    );
}
