import { axiosApi } from "../axiosApi";

export const getTotalPanel = async () => {
    try {
        const response = await axiosApi.get(`/api/dashboard/total/panel`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};


