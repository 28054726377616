export const SegmentDescription = ({ segment }) => {
    return (
        <div className="row">
            <div className="col-md-6">
                <ul>
                    <li className="fw-bold">
                        Sexo:{" "}
                        <ol>
                            {segment.gender.map((g, index) => (
                                <li key={g + index}>{g.name}</li>
                            ))}
                        </ol>
                    </li>
                    <li className="fw-bold">
                        Geografía:{" "}
                        {segment.geographyData.map((x) => x.name).join()}
                    </li>
                    <li className="fw-bold">
                        Edades: Min: {segment.age.min} - Máx: {segment.age.max}
                    </li>
                </ul>
            </div>
            <div className="col-md-6">
                <ul>
                    <li className="fw-bold">
                        Nse:{" "}
                        {segment.typeNse == "sin_nse" ? (
                            "Sin Nse"
                        ) : (
                            <ol>
                                {segment.nse.map((n, index) => (
                                    <li key={n.name + index}>
                                        {n.name}: {n.value}
                                    </li>
                                ))}
                            </ol>
                        )}
                    </li>
                    <li className="fw-bold">
                        {segment.inQuarantine == 1 ? (
                            <>
                                {"Cuarentena: "}
                                <ol>
                                    {segment.projectsInQuarantine.map(
                                        (piq, index) => (
                                            <li key={piq.name + index}>
                                                {piq.name}
                                            </li>
                                        )
                                    )}
                                </ol>
                            </>
                        ) : (
                            "Sin cuarentena"
                        )}
                    </li>
                </ul>
            </div>
        </div>
    );
};
