import Select from "react-select";

export const LastInteractionSelect = ({ segmentSubset, setSegmentSubset }) => {
    return (
        <Select
            key={"last-interaction-jdjfd"}
            className="basic-select"
            options={segmentSubset.lastinteraction.options}
            value={segmentSubset.lastinteraction.selected}
            onChange={(e) => {
                setSegmentSubset({
                    ...segmentSubset,
                    lastinteraction: {
                        ...segmentSubset.lastinteraction,
                        selected: { value: e.value, label: e.label },
                    },
                });
            }}
        />
    );
};
