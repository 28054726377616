import React, { useState } from "react";
import { useMutation } from "react-query";
import Swal from "sweetalert2";
import { editPanelExternalConfig } from "../../../../api/sio/project";
import { ParametersList } from "../form/ParametersList";

export const ConfigPanelEdit = ({
    showModal,
    setShowModal,
    panelExternal,
    projectId,
}) => {
    //Editar configuración de panel
    const {
        mutate: mEditPanelExternalConfig,
        error: errorEdit,
        isError: isErrorEdit,
    } = useMutation(editPanelExternalConfig, {
        onSuccess: () => {
            Swal.fire(
                "Editar configuración",
                "Se ha editado esta configuración",
                "success"
            ).then(() =>
                window.location.replace(`/proyecto/${projectId}/panel-externo`)
            );
        },
        onError: ({ data }) => {
            const _errors = Object.values(data.errors);
            Swal.fire(
                "Editar Panel Externo",
                `El panel no se ha editado correctamente: ${_errors.map((x) =>
                    x.map((y) => `<br> ${y}`)
                )}`,
                "error"
            );
        },
    });

    //radio button change active/inactive
    const [isChecked, setIsChecked] = useState(
        panelExternal.pivot.status === "active" ? true : false
    );
    //Form state
    const [formState, setFormState] = useState({
        project_id: projectId,
        status: panelExternal.pivot.status,
        panelexternal_id: panelExternal.id,
        parameters:
            JSON.parse(panelExternal.pivot.parameters) != null
                ? JSON.parse(panelExternal.pivot.parameters)
                : [{ id: 0, slug: "", qcode: null }],
        COMPLETED_URL: panelExternal.pivot.COMPLETED_URL,
        FILTERED_URL: panelExternal.pivot.FILTERED_URL,
        QUOTAFULL_URL: panelExternal.pivot.QUOTAFULL_URL,
    });

    //Input change handlers
    const handleInputTextChange = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...formState,
            [name]: value,
        });
    };

    const handleStatusChange = (e) => {
        setFormState({
            ...formState,
            status: e.target.value,
        });
        setIsChecked(!isChecked);
    };

    const handleParameterChange = (index, parameter) => {
        const newParameters = [...formState.parameters];
        newParameters[index] = parameter;
        setFormState({
            ...formState,
            parameters: newParameters,
        });
    };

    //Add/remove parameters handlers
    const handleAddParameter = () => {
        setFormState({
            ...formState,
            parameters: [
                ...formState.parameters,
                {
                    id: formState.parameters.length,
                    slug: "",
                    qcode: null,
                },
            ],
        });
    };

    const handleRemoveParameter = (index) => {
        const newParameters = [...formState.parameters];
        newParameters.splice(index, 1);
        setFormState({
            ...formState,
            parameters: newParameters,
        });
    };

    //OnSubmit handler
    const handleOnSubmit = (e) => {
        e.preventDefault();
        let _formState = { ...formState };
        let _p = [..._formState.parameters];
        _p = _p.filter((x) => x.slug != "");
        _formState = { ..._formState, parameters: _p };
        mEditPanelExternalConfig(_formState);
    };
    return (
        <>
            <div className="modal modal-segmentation">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Editar configuración
                            </h5>
                            <button
                                className="close close-modal-btn"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setShowModal({
                                        ...showModal,
                                        edit: false,
                                    });
                                }}
                            >
                                <i className="fa fa-times-circle"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => handleOnSubmit(e)}>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label className="my-1 fw-bold">
                                            Panel
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={panelExternal.name}
                                            disabled
                                        />
                                    </div>
                                    <ParametersList
                                        projectId={projectId}
                                        parameters={formState.parameters}
                                        onParameterChange={
                                            handleParameterChange
                                        }
                                        onAddParameter={handleAddParameter}
                                        onRemoveParameter={
                                            handleRemoveParameter
                                        }
                                        isError={isErrorEdit}
                                        error={errorEdit}
                                    />
                                </div>
                                <hr></hr>
                                <div className="row">
                                    <div className="form-group col-md-12 mb-3">
                                        <label className="my-1 fw-bold">
                                            URL COMPLETED
                                        </label>
                                        <input
                                            name="COMPLETED_URL"
                                            type={"text"}
                                            placeholder="URL 'Completas' panel externo"
                                            className="form-control"
                                            value={formState.COMPLETED_URL}
                                            onChange={(e) =>
                                                handleInputTextChange(e)
                                            }
                                        />
                                        {isErrorEdit && (
                                            <p className="text text-danger">
                                                {errorEdit.data.errors?.COMPLETED_URL?.map(
                                                    (x) => x
                                                )}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group col-md-12 mb-3">
                                        <label className="my-1 fw-bold">
                                            URL FILTERED
                                        </label>
                                        <input
                                            name="FILTERED_URL"
                                            type={"text"}
                                            placeholder="URL 'Filtradas' panel externo"
                                            className="form-control"
                                            value={formState.FILTERED_URL}
                                            onChange={(e) =>
                                                handleInputTextChange(e)
                                            }
                                        />
                                        {isErrorEdit && (
                                            <p className="text text-danger">
                                                {errorEdit.data.errors?.FILTERED_URL?.map(
                                                    (x) => x
                                                )}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group col-md-12 mb-3">
                                        <label className="my-1 fw-bold">
                                            URL QUOTA FULL
                                        </label>
                                        <input
                                            name="QUOTAFULL_URL"
                                            type={"text"}
                                            placeholder="URL 'Cuota llena' panel externo"
                                            className="form-control"
                                            value={formState.QUOTAFULL_URL}
                                            onChange={(e) =>
                                                handleInputTextChange(e)
                                            }
                                        />
                                        {isErrorEdit && (
                                            <p className="text text-danger">
                                                {errorEdit.data.errors?.QUOTAFULL_URL?.map(
                                                    (x) => x
                                                )}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-12 mb-3">
                                        <p className="fw-bold">Estado</p>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="statusActive"
                                                value={"active"}
                                                onChange={(e) =>
                                                    handleStatusChange(e)
                                                }
                                                checked={isChecked}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="statusActive"
                                            >
                                                Activo
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="statusInactive"
                                                value={"inactive"}
                                                onChange={(e) =>
                                                    handleStatusChange(e)
                                                }
                                                checked={!isChecked}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="statusInactive"
                                            >
                                                Inactivo
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="form-group col-md-12">
                                    <button
                                        type="submit"
                                        className="btn btn-primary-oo"
                                    >
                                        Guardar configuración
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
