import { getAgesMkt, getFirstgeoMkt, getGendersMkt, getNsesMkt, getProfilesMkt, getSurveysMkt } from "../../api/reports/campaigns";

const errors  = { status: false, message: '' };
export const useBoundReportUserStore = (set, get) => {
    return {
        
        fetchAges: async ({ id, preset = null}) => {
            set( {
                loadings: { ...get().loadings, ages: true  },
                errors: { ...get().errors,  ages: errors }
            })
            try{
                const { data, status } = await getAgesMkt({id, preset:get().preset });
                if(status == 204){
                    set( {
                        errors: { ...get().errors,  ages: {
                            status: true, 
                            message:'No hay datos disponible para la visualización'
                        }}
                    })
                }else{
                    set({ data: { ...get().data, ages: data }});
                }
                set({ loadings: { ...get().loadings, ages: false  } });
            }catch( error ){
                console.log(error);
            }
        },
        fetchGenders: async ({ id, preset = null}) => {
            set( {
                loadings: { ...get().loadings, genders: true  },
                errors: { ...get().errors,  genders: errors }
            })
            try{
                const { data, status } = await getGendersMkt({id, preset:get().preset });
                if(status == 204){
                    set( {
                        errors: { ...get().errors,  genders: {
                            status: true, 
                            message:'No hay datos disponible para la visualización'
                        }}
                    })
                }else{
                    set({ data: { ...get().data, genders: data }});
                }
                set({ loadings: { ...get().loadings, genders: false  } });
            }catch( error ){
                console.log(error);
            }
        },
        fetchFirstgeo: async ({ id, preset = null}) => {
            set( {
                loadings: { ...get().loadings, firstgeo: true  },
                errors: { ...get().errors,  firstgeo: errors }
            })
            try{
                const { data, status } = await getFirstgeoMkt({id, preset:get().preset });
                if(status == 204){
                    set( {
                        errors: { ...get().errors,  firstgeo: {
                            status: true, 
                            message:'No hay datos disponible para la visualización'
                        }}
                    })
                }else{
                    set({ data: { ...get().data, firstgeo: data }});
                }
                set({ loadings: { ...get().loadings, firstgeo: false  } });
            }catch( error ){
                console.log(error);
            }
        },
        fetchProfiles:async ({ id, preset = null}) => {
            set( {
                loadings: { ...get().loadings, profiles: true  },
                errors: { ...get().errors,  profiles: errors }
            })
            try{
                const { data, status } = await getProfilesMkt({id, preset:get().preset });
                if(status == 204){
                    set( {
                        errors: { ...get().errors,  profiles: {
                            status: true, 
                            message:'No hay datos disponible para la visualización'
                        }}
                    })
                }else{
                    set({ data: { ...get().data, profiles: data }});
                }
                set({ loadings: { ...get().loadings, profiles: false  } });
            }catch( error ){
                console.log(error);
            }
        },
        fetchSurveysCampaign:async ({ id, preset = null}) => {
            set( {
                loadings: { ...get().loadings, surveys: true  },
                errors: { ...get().errors,  surveys: errors }
            })
            try{
                const { data, status } = await getSurveysMkt({id, preset:get().preset });
                if(status == 204){
                    set( {
                        errors: { ...get().errors,  surveys: {
                            status: true, 
                            message:'No hay datos disponible para la visualización'
                        }}
                    })
                }else{
                    set({ data: { ...get().data, surveys: data }});
                }
                set({ loadings: { ...get().loadings, surveys: false  } });
            }catch( error ){
                console.log(error);
            }
        },
        fetchNsesCampaign:async ({ id, preset = null}) => {
            set( {
                loadings: { ...get().loadings, nses: true  },
                errors: { ...get().errors,  nses: errors }
            })
            try{
                const { data, status } = await getNsesMkt({id, preset:get().preset });
                if(status == 204){
                    set( {
                        errors: { ...get().errors,  nses: {
                            status: true, 
                            message:'No hay datos disponible para la visualización'
                        }}
                    })
                }else{
                    set({ data: { ...get().data, nses: data }});
                }
                set({ loadings: { ...get().loadings, nses: false  } });
            }catch( error ){
                console.log(error);
            }
        },
    }
}