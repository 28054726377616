import React from 'react';
import EditIcon from "@mui/icons-material/Edit";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { ListItemIcon, MenuItem } from "@mui/material";
import { useMutation } from 'react-query';
import { expireProjectPanelist, resetPanelistSurvey } from '../../../../api/sio/project';
import Swal from 'sweetalert2';



export const RenderRowActionListPanelists = ({ closeMenu, row, id, setToken, seeModal, setSeeModal,refetch  }) => {
    
    const { mutate: mResetProjectPanelistSurvey } = useMutation(resetPanelistSurvey, {
        onSuccess: () => {
            Swal.fire(
                "Reiniciar encuesta",
                "Se ha reiniciado la encuesta",
                "success"
            ).then(() => {
                refetch();
            });
        },onError: ({ data }) => {
            Swal.fire(
                "Reiniciar encuesta",
                `Ha ocurrido un error. La encuesta no se ha reiniciado`,
                "error"
            );
        },
        }
    );

    const { mutate: mExpireProjectPanelist, error: errorExpireProjectPanelist, isError: isErrorExpireProjectPanelist } = useMutation(expireProjectPanelist, {
        onSuccess: () => {
            Swal.fire(
                "Expirar Panelista",
                "Se ha expirado el link de este panelista",
                "success"
            ).then(() => {
                refetch();
            });
        },
        onError: ({ data }) => {
            Swal.fire(
                "Expirar Panelista",
                `Ha ocurrido un error. El panelista no se ha expirado: ${data.message}`,
                "error"
            );
        },
    });

    const handleResetProjectPanelistSurvey = (panelist) => {
        const _panelist = { ...panelist, project_id: id };
        Swal.fire({
            title: "¿Quieres reiniciar la encuesta de este panelista?",
            showCancelButton: true,
            confirmButtonText: "Reiniciar",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((res) => {
            if (res.isConfirmed) {
                mResetProjectPanelistSurvey(_panelist);
            } else {
                Swal.fire(
                    "Reiniciar encuesta",
                    `La encuesta no se ha reiniciado.`,
                    "warning"
                );
            }
        });
    };

    const handleExpireProjectPanelist = (panelist) => {
        const _panelist = { ...panelist, project_id: id };
        Swal.fire({
            title: "¿Quieres expirar el link de exte panelista?",
            showCancelButton: true,
            confirmButtonText: "Exprirar link",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((res) => {
            if (res.isConfirmed) {
                mExpireProjectPanelist(_panelist);
            } else {
                Swal.fire(
                    "Exprirar Panelista",
                    `El panelista no se ha expirado`,
                    "warning"
                );
            }
        });
    };

    return (
        <>
            <MenuItem
                key={0}
                onClick={() => {
                    closeMenu();
                    setToken(row.original.token);
                    setSeeModal({...seeModal, edit: !seeModal.edit });
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon><EditIcon /></ListItemIcon>
                Editar
            </MenuItem>
            <MenuItem
                key={1}
                onClick={() => {
                    closeMenu();
                    handleResetProjectPanelistSurvey(row.original);
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon><RestartAltIcon /></ListItemIcon>
                Reiniciar encuesta
            </MenuItem>
            <MenuItem
                key={2}
                onClick={() => {
                    closeMenu();
                    handleExpireProjectPanelist(row.original);
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon><DeleteForeverIcon /></ListItemIcon>
                Expirar Link
            </MenuItem>
        </>
    )
}