import { axiosApi } from "../axiosApi";

export const getGendersDahsboardAjax = async (filter) => {
    try {
        const response = await axiosApi.post(`/api/dashboard/sexo`,filter);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};


