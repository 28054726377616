import { create } from 'zustand';
import { getRefenceNse } from '../api/sip/nse';


export const useBoundNseStore = create( (set,get) => {
    return {
        types:{
            values:undefined,
            selected:{ label:'Sin nse', value:'sin_nse' },
            all:undefined
        },
        nses:{
            values:undefined,
            selected:undefined
        },
        getRefenceNse: async () => {
            try{
                const response = await getRefenceNse();
                const { data } = response;
                const types = data.reduce((nses, item) => {
                    return ({...nses, [item.name]: [...(nses[item.name] || []), item]});
                }, {});
                const typesMap = [...Object.keys(types).map((type) => ({ label:type, value:type})), { label:'Sin nse', value:'sin_nse' }];
                set({
                    types: {
                        values: typesMap,
                        selected: { label:'Sin nse', value:'sin_nse' },
                        all: types,
                    }
                })
            }catch(error){
                console.log(error);
            }
        },
        selectRefenceNse: (refence, isSetterPanelist=false) => set( (state) => {
            return {
                ...state,
                types:{
                    ...state.types,
                    selected: refence
                },
                nses: {
                    values: refence.value !== 'sin_nse'  ? 
                        state.types.all[refence.value].map( nse => ({ label:nse.value, value:nse.value }))
                        : { label:'Sin nse', value:'sin_nse' },
                    selected:undefined
                }
            }
        }),
        setter: (setter) => set(setter)
    }
})