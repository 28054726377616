import { create } from "zustand";
import { getGeographiesWithLevel, getTagGeographies } from "../../api/sip/geography";
import { useBoundNotificationFcm } from "../NotificationFcm/useBoundNotificationFcm";

export const useBoundGeographyStore = create((set,get) => {
    return {
        tagsGeography:{
            values:[],
            selected:undefined
        },
        geographies:{
            values: [],
            selected: undefined
        },
        // busco información de la notificación
        fetchTagsGeography: async () => {
            try{
                const response = await getTagGeographies();
                const { data } = response;
                const tags = [...data.map( (tag) => ({ label: tag.tag, value: tag.level }) ), { label:'Zona', value:'area' }];
                set({tagsGeography: { values: tags, selected: undefined }})
            }catch( error ){
                console.log(error);
            }
        },
        // almacenar notificación
        fetchGeography: async (level, isSetterPanelist=false) => {
            const { setterPanelist } =  useBoundNotificationFcm.getState();
            try{
                const response = await getGeographiesWithLevel(level);
                const { data } = response;
                const geo = data.map(geography => ({ label: geography.name, value: geography.id, level:geography.level }));
                set({ geographies:{ values: geo, selected:undefined }})
                if(isSetterPanelist){
                    setterPanelist({ geographies: [] });
                }
            }catch(error){
                console.log('error, fetch geography', error);
            }
        },
        // setear todos los valores
        setter: (setter) => set(setter),
        setterTagsGeography: (setter) => set( (state) => ({ ...state, tagsGeography:{ ...state.tagsGeography, ...setter }})),
        setterInput: (name, value) => set((state) => ({ ...state, notification:{ [name]: value } }))
    }
});