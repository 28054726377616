import React, { useEffect, useState } from "react";
import { updateSurveyPage } from "../../../../api/sio/surveyPage";
import { useSurveyPageStore } from "../../../../store/SurveyPage/useSurveyPageStore";
import Swal from "sweetalert2";
import { useMutation } from "react-query";
import { WelcomeInputs } from "./inputs/WelcomeInputs";
import { RewardInputs } from "./inputs/RewardInputs";
import Skeleton from "react-loading-skeleton";

export const PageEdit = ({ seeModal, setSeeModal, projectId, pageId }) => {
    const { pageData,setPageData,resetPageData,fetchSurveyPages,fetchSurveyPage } = useSurveyPageStore((state) => state);
    const [isLoading,setIsLoading] = useState(true);
    const [ image,setImage ] = useState(null);
    const isEdit = true;

    const { mutate:mUpdateSurveyPage, error:errorUpdate, isError:isErrorUpdate} = useMutation( updateSurveyPage ,{
        onSuccess: () => {
            Swal.fire('Editar página', 'Página editada con éxito', 'success')
                .then( result => {
                    setSeeModal({
                        ...seeModal,
                        edit: !seeModal.edit,
                    });
                    resetPageData();
                    //recargamos la lista de paginas del index
                    fetchSurveyPages(projectId);
                } )
        },
        onError: (e) => {
            Swal.fire('Editar página', 'No se ha editado la página', 'error')
        }
    });
    //Función para añadir la data al FormData, para enviar la imagen en el request
    const addFormData = () => {
        const formData = new FormData();
        if(pageData.type == "welcome" && image){
            formData.append('image',image);
        }
        for (const key in pageData) {
            const value = pageData[key];
            formData.append(key, value);
        }
        return formData;
    }
    //Handlers
    const handlePageTypeChange = (e) => { setPageData({ ...pageData, type: e.target.value }); };
    const handleChangeTitle = (e) => { setPageData({ ...pageData, title: e.target.value }) };
    const handleChangeDescription = (e) => { setPageData({ ...pageData, description: e.target.value }) };
    const handleChangeButtonText = (e) => { setPageData({ ...pageData, button_text: e.target.value }) };
    const handleChangeButtonUrl = (e) => { setPageData({ ...pageData, button_url: e.target.value }) };

    const handleUpdateSurveyPage = (e) => {
        e.preventDefault();
        Swal.fire({
            title: '¿Quieres editar está página?',
            showCancelButton: true,
            confirmButtonText: 'Editar',
            cancelButtonText: 'Cancelar',
            icon:'info'
        }).then((result) => {
            if (result.isConfirmed) {
                const _data = addFormData();
                mUpdateSurveyPage(_data);
            } else if (result.isDismissed || result.isDenied) {
                Swal.fire('Editar página', 'No se ha editado la página', 'warning');
            }
        })
    };

    //Effects
    useEffect(()=>{ if(pageId != '') { fetchSurveyPage(pageId).then(()=>{ setIsLoading(!isLoading); }); } },[pageId]);

    return (
        <>
            <div className="modal modal-survey-page">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Editar pantalla</h5>
                            <button
                                className="close close-modal-btn"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setSeeModal({
                                        ...seeModal,
                                        edit: !seeModal.edit,
                                    });
                                    resetPageData();
                                    localStorage.removeItem('banner_image');
                                }}
                            ><i className="fa fa-times-circle"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                        {isLoading ? (<Skeleton height={400}/>) : (
                            <form onSubmit={ handleUpdateSurveyPage } encType="multipart/form-data" method="PUT">
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label className="my-1 fw-bold">Selecciona el tipo de pantalla</label>
                                        <div className="py-2">
                                            <div className="form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="type"
                                                    id="radio-welcome"
                                                    checked={pageData.type == 'welcome'}
                                                    value={"welcome"}
                                                    onChange={handlePageTypeChange}
                                                />
                                                <label
                                                    className="form-check-label ms-2"
                                                    htmlFor="radio-welcome"
                                                >Portada de la encuesta</label>
                                            </div>
                                            <div className="form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="type"
                                                    id="radio-reward"
                                                    checked={pageData.type == 'reward'}
                                                    value={"reward"}
                                                    onChange={handlePageTypeChange}
                                                />
                                                <label
                                                    className="form-check-label ms-2"
                                                    htmlFor="radio-reward"
                                                >Recompensa</label>
                                            </div>
                                        </div>
                                        {isErrorUpdate && (
                                            <p className="text text-danger">{errorUpdate.data.errors?.type?.map( x => x)}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    {(pageData.type === 'welcome' || pageData.type === 'reward') && (
                                        <>
                                            <div className="form-group col-12 my-1">
                                                <label className="my-1 fw-bold">
                                                    {pageData.type === 'welcome'
                                                        ? "Subtítulo"
                                                        : "Título"}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    value={pageData.title}
                                                    onChange={ handleChangeTitle }
                                                />
                                                {isErrorUpdate && (
                                                    <p className="text text-danger">{errorUpdate.data.errors?.title?.map( x => x)}</p>
                                                )}
                                            </div>
                                            <div className="form-group col-12 my-1">
                                                <label className="my-1 fw-bold">
                                                    {pageData.type === 'welcome'
                                                        ? "Descripción"
                                                        : "Dato Random"}
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    type="textarea"
                                                    name="description"
                                                    id="description"
                                                    onChange={ handleChangeDescription }
                                                    value={pageData.description}
                                                />
                                                {isErrorUpdate && (
                                                    <p className="text text-danger">{errorUpdate.data.errors?.description?.map( x => x)}</p>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {pageData.type === 'welcome' && (
                                        <WelcomeInputs
                                            setImage={setImage}
                                            isError={isErrorUpdate}
                                            error={errorUpdate}
                                            isEdit={isEdit}
                                        />
                                    )}
                                    {pageData.type === 'reward' && (
                                        <RewardInputs
                                            handleChangeButtonText={handleChangeButtonText}
                                            handleChangeButtonUrl={handleChangeButtonUrl}
                                            isError={isErrorUpdate}
                                            error={errorUpdate}
                                        />
                                    )}
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <button
                                            type="submit"
                                            className="btn btn-primary-oo"
                                        >Editar pantalla
                                        </button>
                                        <a
                                            href={`/proyecto/${ projectId }/pagina/preview?data=${ JSON.stringify({...pageData,isEdit}) }`}
                                            target="_blank"
                                            className={`btn btn-primary-oo mx-2 ${ pageData.type == '' && 'd-none' }`}
                                        ><i className="fa fa-eye mx-2"></i>Previsualizar</a>
                                    </div>
                                </div>
                            </form>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
