import React from 'react';
import { CreateSegmentation } from "./CreateSegmentation";
import { EditSegmentation } from "./EditSegmentation";
export const ModalForm = ({ projectId, seeModal, setSeeModal, segmentation}) => {
    return (
        <>
        {
            seeModal.create && (
                <CreateSegmentation
                    projectId={ projectId }
                    seeModal={ seeModal }
                    setSeeModal = { setSeeModal }
                />
            )
        }
        {
            seeModal.edit && (
                <EditSegmentation
                    projectId={ projectId }
                    segmentation={ segmentation }
                    seeModal={ seeModal }
                    setSeeModal = { setSeeModal }
                />
            )
        }
        </>
    )
}