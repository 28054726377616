import React from 'react';
import {Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useBoundMktStore } from '../../../../store/ReportsCampaignMkt/useBoundMktStore';
import { currencyFormatter } from '../../../../helper/currencyFormatter';
import Skeleton from 'react-loading-skeleton';


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const _cost = currencyFormatter({
        currency:'CLP',
        value:payload[0].payload.daily_cost
      })
      const _costPerPanelist = currencyFormatter({
        currency:'CLP',
        value: payload[0].payload.daily_cost / payload[0].payload.total
      })
      return (
        <div style={{
            backgroundColor:"#FFF",
            padding:"10px",
            borderRadius:"10px",
            boxShadow:  '#CFD8DC 0px 1px 7px 0px',
            width:'300px'
            }}>
            <p style={{ color:"#3d3d3d", textAlign:'center'}}><strong>{ label }</strong></p>
            <hr></hr>
            <p style={{ color:`${payload[0].color}`}}>{ payload[0].name }: { payload[0].value }</p>
            <p style={{ color:`${payload[1].color}`}}>{ payload[1].name }: { payload[1].value }  ({ Math.round((payload[1].value/payload[0].value*100),2) }%)</p>
            <p style={{ color:`${payload[2].color}`}}>{ payload[2].name }: { payload[2].value }  ({ Math.round((payload[2].value/payload[0].value*100),2) }%)</p>
            
            <p style={{ color:"#FFA726"}}>{`Costo: ${_cost}`}</p>
            <p style={{ color:"#7E57C2"}}>{`Costo por panelista: ${_costPerPanelist}`}</p>
            
        </div>
      );
    }
  
    return null;
  };


export const RegisterLineChart = () => {

    const { loading, data } = useBoundMktStore( (state) => {
        const { loadings, errors, data } = state;
        return {
            loading: loadings.daily,
            data: data.daily,
            errors: errors.daily
        }
    });

    const maxValue = Math.max(
      ...data.map(item => Math.max(parseInt(item.total)))
    );

    return (
          loading ? ( <Skeleton height={150}/> ) : ( 
            data.length > 0 && (
              <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                  width={500}
                  height={300}
                  data={data}
                  >
                  <XAxis  
                    dataKey="registration_at" 
                      textAnchor= "end" 
                      sclaeToFit="true" 
                      verticalAnchor= "start"  
                      interval={Math.floor(data.length/15 *0.5)} 
                      angle= "-40" 
                      height={50}  
                      style={{fontSize:'12px'}} 
                      axisLine={false} // Elimina la línea del eje Y
                      tickLine={false} 
                      />
                  <YAxis 
                      type="number"
                      domain={[0, dataMax => maxValue * 1.5]} tickCount={10} 
                      axisLine={false} // Elimina la línea del eje Y
                      tickLine={false} 
                  
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend wrapperStyle={{paddingTop: "3.2rem"}} />
                      <Line legendType="circle" type="monotone" dataKey="total" stroke="rgb(33, 150, 243)"  name="Registrados" />
                      <Line legendType="circle" type="monotone" dataKey="profiled" stroke="rgb(139, 195, 74)"  name="Perfilados"  />
                      <Line legendType="circle" type="monotone" dataKey="notProfiled" stroke="#EF9A9A"  name="No perfilados"  />
                  </LineChart>
              </ResponsiveContainer>
            )
          )
    )
}