import { useEffect, useState } from "react";

export const useModal = () => {
    const [showModal, setShowModal] = useState({
        create: false,
        edit: false,
        clone: false,
        list: false,
    });

    return {
        showModal,
        setShowModal,
    };
};
