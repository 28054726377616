import React from 'react';
import { DailyProgressLineChart } from './DailyProgressLineChart';
import { ItemProgress } from './ItemsProgress';
import { PanelExternalProgressTable } from './PanelExternalProgressTable';
import { QuotasTableProject } from './../table/QuotasTableProject';

export const ListItemsDahsboard = ({id, hash }) => {
    return (
        <>
            <ItemProgress id = {id}  />
            <DailyProgressLineChart  id={id} hash={hash} />
            {
                !hash && (
                    <PanelExternalProgressTable id={ id } hash={hash}/>
                )
            }
            <QuotasTableProject id={id} hash={hash}/>
        </>
    )
}

