import React, { useMemo, useState } from 'react';
import { useQuestions } from './../../../../hooks/useQuestions';
import Skeleton from 'react-loading-skeleton';
import { AnswersModal } from '../modals/AnswersModal';
import { SubAnswerModal } from '../modals/SubAnswerModal';
import  styles from './styles.module.css';

export const QuestionsTableProject = ({id, hash }) => {
    const { isLoading, questions } = useQuestions(id, hash );
    const [question, setQuestion] = useState({ title:'xXXXX'});
    const [seeModal, setSeeModal] = useState(false);
    const [seeSubQuestionModal, setSeeSubQuestionModal] = useState(false);

    const renderTypeQuestion  = (_question) => {
        return (<div title={_question.title_type} style={{
            padding: '0.5rem',
            borderRadius: '1rem',
            background: 'var(--bs-gray-200)',
            fontWeight: 'bold',
            width:'fit-content'
        }}>{_question.title_type}</div>)
    }

    const renderTypeDashboard = ( _question ) => {
        return ( 
            <>
                {
                    [";","U","Q","X","K", "N", ";", "1", "|"].includes(_question.parentType) && (
                        <a
                        style={{ color:'var(--bs-warning)', fontSize:'15px',cursor:'no-drop'  }}
                        ><i className="fa-solid fa-circle-exclamation"></i></a>
                    )
                }
                {
                    ["L", "!", "O", "5", "G", "Y", "M", "P", "R"].includes(_question.parentType) && ( 
                        <a 
                            style={{ color:'#46166C', fontSize:'15px', cursor:'pointer' }}
                            onClick={ (e) => {
                                setQuestion({..._question});
                                setSeeModal(!seeModal);
                        }}><i className="fa-solid fa-chart-pie"></i></a>
                    )
                }
                {
                    ["S", "T"].includes(_question.parentType) && ( 
                        <a 
                            style={{ color:'#46166C', fontSize:'15px', cursor:'pointer' }}
                            onClick={ (e) => {
                                setQuestion({..._question});
                                setSeeModal(!seeModal);
                        }}><i className="fa fa-cloud"></i></a>
                    )
                }
                {
                    (["F", "E", "A", "B", "C", "H"].includes(_question.parentType) && _question.isQuestion == 'sub_question') && ( 
                        <a 
                            style={{ color:'#46166C', fontSize:'15px', cursor:'pointer' }}
                            onClick={ (e) => {
                                setQuestion(_question);
                                setSeeSubQuestionModal(!seeSubQuestionModal);
                        }}><i className="fa-solid fa-chart-pie"></i></a>
                    )
                }
                {
                    ([":"].includes(_question.parentType) && _question.isQuestion == 'sub_question') && ( 
                        <a 
                            style={{ color:'#46166C', fontSize:'15px', cursor:'pointer' }}
                            onClick={ (e) => {
                                setQuestion(_question);
                                setSeeSubQuestionModal(!seeSubQuestionModal);
                        }}><i className="fa-solid fa-chart-pie"></i></a>
                    )
                }
            </>
        )
    }

    const renderTotalQuestion = (question) => {
       
        if(["F", "E", "B", "A", ":", "C", ";", "1", "H"].includes(question.parentType) && question.isQuestion == 'question' ){
            return undefined;
        }
        return question.totalQuestion;
    }
    return (
        <>
            {
                isLoading ? (<Skeleton height={300}/>): (
                    <div className="col-md-12 mt-4">
                        <div className="card card-body">
                            <table className={`table ${styles.table_question}`}>
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>TotalQuestion</th>
                                        <th>Pregunta</th>
                                        <th>Tipo</th>
                                        <th>Dashboard</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        questions.map((question, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{question.title}</td>
                                                    <td>{renderTotalQuestion(question)}</td>
                                                    <td>{question.question.length == 0 ? "-" : question.question}</td>
                                                    <td>{ renderTypeQuestion(question) }</td>
                                                    <td>{ renderTypeDashboard( question ) }</td>
                                                </tr>
                                            )

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        { seeModal && ( <AnswersModal id={id} hash={hash} question = {question}  seeModal={seeModal} setSeeModal={ setSeeModal }  /> ) }
                        {
                            seeSubQuestionModal && ( 
                                <SubAnswerModal
                                id={id}
                                question={question}
                                subQuestion={{...question, hash:hash}} 
                                seeSubAnswerModal={ seeSubQuestionModal } 
                                setSeeSubAnswerModal={setSeeSubQuestionModal} />
                            )
                        }
                    </div>
                )
            }
        </>
    )
}