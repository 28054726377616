import { create } from "zustand";
import { getProfilesSchema } from "../../api/sip/profiles";

export const useProfilesStore = create((set) => ({
    questions: null,
    answers: null,
    isLoadingProfiles: true,
    isErrorProfiles: false,
    errorMessageProfile: "",
    setQuestionSelected: (selectedQuestion) =>
        set((state) => ({
            questions: {
                ...state.questions,
                selected: selectedQuestion,
            },
        })),
    resetQuestionSelected: () =>
        set((state) => ({
            questions: {
                ...state.questions,
                selected: null,
            },
        })),
    setAnswersSelected: (selectedAnswer) =>
        set((state) => ({
            answers: {
                ...state.answers,
                selected: selectedAnswer,
            },
        })),
    resetAnswersSelected: () =>
        set((state) => ({
            answers: {
                ...state.answers,
                selected: null,
            },
        })),
    transformQuestionsOptions: (profiles) => {
        const options = profiles
            .map((profile) =>
                profile.questions.map((question) => {
                    let options = {
                        value: {
                            sid: question.sid,
                            gid: question.gid,
                            qid: question.qid,
                            type: question.type,
                            refence: profile.refenceDB
                        },
                        label: question.question,
                        profileTitle: profile.title
                    };
                    return options;
                })
        )
        .reduce((ac, item) => ac.concat(item));
        let _options = [];
        options.forEach((opt,index,arr) => {
            if (index > 0 && opt.profileTitle == arr[index-1].profileTitle) {
                return;
            }
            _options = [
                ..._options,
                {
                    label: opt.profileTitle,
                    options: arr.filter( item => item.profileTitle === opt.profileTitle)
                }
            ]
        });
        return _options;
    },
    transformAnswersOptions: (profiles) => {
        const options = profiles
            .map((profile) =>
                profile.questions
                    .map((question) =>
                        question.answers.map((answer) => {
                            let ans = {
                                value: {
                                    qid: question.qid,
                                    code: answer.code,
                                },
                                label: answer.answer,
                            };
                            return ans;
                        })
                    )
                    .reduce((ac, item) => ac.concat(item))
            )
            .reduce((ac, item) => ac.concat(item));
        return options;
    },
    fetchProfiles: async () => {
        set({ isLoadingProfiles: true });
        try {
            const response = await getProfilesSchema();
            const profiles = response?.data;
            set((state) => (
                {
                    isLoadingProfiles: false,
                    questions: {
                        options: state.transformQuestionsOptions(profiles),
                        selected: null,
                    },
                    answers: {
                        options: state.transformAnswersOptions(profiles),
                        selected: null,
                    },
                }
            ));
        } catch (error) {
            set({
                isLoadingProfiles: false,
                isErrorProfiles: true,
                errorMessageProfile: error.response.data.message,
            });
        }
    },
}));
