import { axiosApi } from "../axiosApi";

export const getQuestionsProject = async (id,hash) => {
    var url = `/api/proyecto/${id}/preguntas`;
    if(hash){
        url = `/api/cliente/monitor/h/${hash}/p/${id}/preguntas`
    }
    try{
        const response = await axiosApi.get(`${url}`);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    } 
}


export const getSubQuestionsProject = async (question) => {
    var url = `/api/pregunta/referencia/${question.projectId}/sid/${question.sid}/gid/${question.gid}/qid/${question.qid}/tipo/${question.type}/sub-preguntas`;
    if(question.hash){
        url = `/api/cliente/monitor/h/${question.hash}/p/${question.projectId}/pregunta/referencia/${question.projectId}/sid/${question.sid}/gid/${question.gid}/qid/${question.qid}/tipo/${question.type}/sub-preguntas`
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    } 
}