import { dateTransform } from '../../helper/dateTransform';

export const useDateReportsProjectStore = (set) => {
    return {
        buttons: [
            { value:'reset', label: 'Reiniciar' },
            { value: 'today', label: 'Hoy' },
            { value: 'thisWeek', label: 'Esta semana' },
            { value: 'lastWeek', label: 'Semana pasada'},
            { value: 'last7', label: 'Últimos 7 días' },
            {value: 'thisMonth', label: 'Este mes' },
            {value: 'ofQuarter', label: 'Trimestre' },
            {value: 'ofSemester', label: 'Semestre' }
        
        ],
        preset: dateTransform['thisMonth'],
        changePresetDay:(presetSelect) => set( state => ({   
            preset: presetSelect
        })),
        changePreset: (presetSelect) => set( state => ({   
            preset: dateTransform[presetSelect]
        })),
        setMetrics: (metrics) => set( state => ({
            metrics: [...metrics]
        }))
    }
};