import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom/client";
import { App } from "../../../App";
import { CampaignsTable } from './table/CampaignsTable';
import { formatterNumber } from '../../../helper/currencyFormatter';
import { useBoundMktStore } from '../../../store/ReportsCampaignMkt/useBoundMktStore';

const ItemsReportMkt = () => {
    const { data } =  useBoundMktStore( state => state );
    const { campaigns } = data;
    const [items, setItems] = useState([]);
    useEffect( () => {
        if(campaigns.length  == 0){ return; }
        let totals = campaigns.reduce( (previous, current) => previous + parseInt(current.total), 0);
        let profileds = campaigns.reduce( (previous, current) => previous + parseInt(current.profiled), 0);
        let noProfileds= campaigns.reduce( (previous, current) => previous + parseInt(current.notProfiled), 0);
        setItems([
            { 'label': 'Total registrados', value: formatterNumber(totals), per:0 },
            { 'label': 'Total perfilados', value: formatterNumber(profileds), per: ((profileds/totals)*100).toFixed(2) },
            { 'label': 'Total no perfilados', value: formatterNumber(noProfileds), per:((noProfileds/totals)*100).toFixed(2) }
        ]);
    },[campaigns]);
    return (
        <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-around', flexWrap:'wrap', gap:'20px' , marginBottom:'20px'}}>
            {
                items.map( (value, key) => (
                    <div className="" style={{ flex:1 }} key={ key }>
                        <div className="card-detail-project">
                            <div className="detail-project">
                                    <label>{ value.label }:</label>
                                    <p>{ value.value }  { value.per != 0 && ( `(${value.per}%)`) }
                                    </p>
                            </div>
                            </div>
                            <div>

                        </div>
                    </div>
                ))
            }
        </div>
    )
}
export const CampaignsMktReport = () => {
    const { fetchCampaigns } =  useBoundMktStore( state => state);
    useEffect( () => {
        fetchCampaigns();
    },[])
    return (
        <>
        <ItemsReportMkt />
        <div className="card col-md-12">
            <div className="card-header">
                <div style={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center'
                }}>
                    <label>
                        Métricas de campañas
                    </label>
                </div>
            </div>
            <div className='card card-body'>
                <div className="row representations-chart">
                    <CampaignsTable />
                </div>
            </div>
        </div>
        </>
    )
}


if (document.getElementById("jsx-reports-mkt-campaigns")) {
    const el = document.getElementById("jsx-reports-mkt-campaigns");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <CampaignsMktReport {...props} />
        </App>
    );
}