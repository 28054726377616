import { useState } from "react";
import { useQuery } from "react-query";
import { getQuestionsProject, getSubQuestionsProject } from "../api/sio/question";

export const useQuestions = (id, hash ) => {
    const [questions, setQuestions] = useState([]);

    const { isLoading, isError, error } = useQuery(['getQuestions', id], () => getQuestionsProject(id, hash), {
        onSuccess: (response) => {
            const { data } = response;
            setQuestions(data);
        }
    })

    return {
        isLoading,
        isError, 
        error, 
        questions, 
        setQuestions
    }
}

export const useSubQuestions = (question) => {
    const [subQuestions, setSubQuestions] = useState([]);

    const { isLoading, isError, error } = useQuery(['getSubQuestions', question.projectId], () => getSubQuestionsProject(question), {
        onSuccess: (response) => {
            const { data } = response;
            setSubQuestions(data);
        }
    })

    return {
        isLoading,
        isError, 
        error, 
        subQuestions, 
        setSubQuestions
    }
}