import { useState } from "react";
import { useQuery } from "react-query";
import { getPanelExternals, getPanelExternal } from "../api/sio/panelExternal";
import { getAllPanelExternalConfig } from "../api/sio/project";

export const usePanelExternals = () => {
    const [panelExternals, setPanelExternals] = useState([]);
    const { isLoading } = useQuery(["panelExternal"], getPanelExternals, {
        onSuccess: (response) => {
            const { data } = response;
            if (data.length > 0) {
                setPanelExternals(data);
            } else {
                setPanelExternals([...panelExternals]);
            }
        },
    });
    return {
        isLoading,
        panelExternals,
        setPanelExternals,
    };
};

export const usePanelExternalsByProject = (id) => {
    const [panelExternalConfigs, setPanelExternalConfigs] = useState([]);
    const { isLoading: isLoadingPanelExternalConfigs } = useQuery(
        ["panelExternalConfigs"],
        () => getAllPanelExternalConfig(id),
        {
            onSuccess: (data) => {
                if (data.length > 0) {
                    setPanelExternalConfigs(data);
                } else {
                    setPanelExternalConfigs([...panelExternalConfigs]);
                }
            },
        }
    );
    return {
        isLoadingPanelExternalConfigs,
        panelExternalConfigs,
        setPanelExternalConfigs,
    };
};

export const usePanelExternal = (id) => {
    const [panelExternal, setPanelExternal] = useState({});
    const { isLoading } = useQuery(
        ["panelExternal", id],
        () => getPanelExternal(id),
        {
            onSuccess: (response) => {
                const { data } = response;
                setPanelExternal(data);
            },
        }
    );

    return {
        isLoading,
        panelExternal,
        setPanelExternal,
    };
};
