// React
import React from 'react';
import ReactDOM from 'react-dom/client';
// import { getSurveyStatus, getSurveyTitle } from '../../../api/sip/limesurvey';
import { App } from '../../../App';

 // Componente de terceros
// import { getProject } from '../../../api/sio/project';
import Skeleton from 'react-loading-skeleton';
import { SidebarRight } from './SidebarRight';
import { useMediaQuery } from 'react-responsive';
import { Alert, AlertTitle } from '@mui/material';
import { useCheckAttr14, useProject } from '../../../hooks/useProject';
import { useGetSurvey } from '../../../hooks/useSurvey';

const statusOptions = [
    { label: 'Modo prueba', value: 'testmode' },
    { label: 'En campo', value:'actived' },
    { label: 'Pausado', value: 'paused' },
    { label: 'Expirada', value: 'expired' },
];

const programmingOptions = [ { value: 'internal', label: 'Interna' }, { value:'external', label: 'Externa'}];

const externalLinksOptions = [ { value: 'required', label: 'Si requiere' }, { value:'noRequired', label: 'No requiere'}];

export const  DetailProject = ({ id }) => {
    const mediaQuery = useMediaQuery({ query:'(min-width:1000px)'});
    const { project, isLoading:isLoadingProject, isError:isErrorProject, error:errorProject } = useProject(id);
    const { survey, isLoading:isLoadingSurvey, isError:isErrorSurvey, error:errorSurvey } = useGetSurvey(id);
    const {checkAttr14,isLoading:isLoadingCheckAttr14} = useCheckAttr14(id);

    return (
        <>
            <div className="row">
            { !mediaQuery && !isLoadingSurvey && (<SidebarRight id={id} survey_status={survey.ls_status} />) }
            <div className="col-xl-9 col-lg-9 mt-4">
                <div className="card mb-4">
                    {/* Card Header - Dropdown */}
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <div className="content-head-card">
                            <label htmlFor="">
                                <a href="/proyecto"><strong>Mostrar proyectos</strong></a> / <a><strong>Detalle del proyecto</strong></a>
                            </label>
                        </div>
                    </div>
                    {/* Card Body */}
                    <div className="card-body">
                        { isErrorProject && <Alert severity="error" sx={{marginTop: '10px', marginBottom: '10px'}}>{errorProject}</Alert> }
                        { isErrorSurvey && <Alert severity="error" sx={{marginTop: '10px', marginBottom: '10px'}}>{errorSurvey}</Alert> }
                        {!isLoadingCheckAttr14 && !checkAttr14
                        &&   (<Alert severity="error" sx={{marginTop: '10px',marginBottom: '10px'}}>
                                <AlertTitle>Este proyecto no tiene configurado el attribute_14 (segment_status).</AlertTitle>
                                <hr></hr>Solicitar a TI ajustar la programación de la encuesta.
                            </Alert>)
                        }
                        { !survey.ls_status &&
                            <Alert severity="error" sx={{marginTop: '10px', marginBottom: '10px'}}>
                                <AlertTitle>Encuesta deshabilitada en Limesurvey</AlertTitle>
                                <hr></hr>
                                <p>La programación de este cuestionario no está habilitada en <b>Limesurvey</b></p>
                                <p>Posible motivo: ajustes en programación de cuestionario.</p>
                                <p>Consultar a TI para más información.</p>
                            </Alert>
                        }
                        {
                            isLoadingProject  ? ( <Skeleton height={400}/> )
                            : (
                                <form>
                                    <div className="mb-3 mt-3">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Nombre proyecto</label>
                                                <input type="text" className="form-control" value={ project.name } disabled />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Nickname proyecto</label>
                                                <input type="text" className="form-control" value={ project.nickname } disabled />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Cliente</label>
                                                <input type="text" className="form-control" value={ project.client } disabled />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="exampleFormControlInput2" className="form-label">Muestra requerida</label>
                                                <input type="number" className="form-control"value={ project.sample } disabled />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="" className="form-label">Tipo de programación</label>
                                                <input type="text" className="form-control" value={ programmingOptions.filter(x => x.value == project.programming)[0].label } disabled />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="" className="form-label">¿Require listado tokens o base específica ?</label>
                                                <input type="text" className="form-control" value={ externalLinksOptions.filter(x => x.value === project.externalLinks)[0].label } disabled />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Tasa de incidencia</label>
                                                <input type="number" className="form-control"  value={ project.ir } disabled />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Tiempo de duración</label>
                                                <input type="number" className="form-control"  value={ project.loi }  disabled />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Puntos</label>
                                                <input type="number" className="form-control"  value={ project.points } disabled />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Incentivos</label>
                                                <input type="text" className="form-control" value={ project.incentives } disabled />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <>
                                                    {isLoadingSurvey
                                                    ? <p className='text-success'>Cargando encuesta...</p>
                                                    : (
                                                        <>
                                                        <label htmlFor="" className="form-label">Programación asociada</label>
                                                        <input type="text" className="form-control" value={ survey.title } disabled />
                                                        </>
                                                    )
                                                    }
                                                </>
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label htmlFor="" className="form-label">Estado</label>
                                                <input type="text" className="form-control" value={ statusOptions.filter( x => x.value  == project.status)[0].label } disabled />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                <label htmlFor="" className="form-label">Pais</label>
                                                <input type="text" className="form-control" value={ project.country } disabled />
                                            </div>
                                        </div>
                                        <div className="my-3">
                                            <div className="row">
                                                <div className='col-md-6'>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="in_balancer"
                                                            checked={ project.in_balancer == 'yes' ? true : false }
                                                            disabled
                                                        />
                                                        <label className="form-check-label" htmlFor="in_balancer">
                                                        ¿Incluir en balanceador?
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )
                        }

                    </div>
                </div>
            </div>
            { mediaQuery && !isLoadingSurvey && (<SidebarRight id={id} survey_status={survey.ls_status} />) }
        </div>
    </>
    )
}
if (document.getElementById('jsx-detail-project')) {
    const el = document.getElementById("jsx-detail-project");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
          <DetailProject { ...props } />
      </App>
  )
}