import React, { useState } from "react";
import { DateRangePreset } from "./DateRangePreset";
import { Popover } from "@mui/material";
import { DayPicker } from "react-day-picker";
import { es } from "date-fns/locale";
import { useMediaQuery } from "react-responsive";

export const DateRangePicker = ({
    range,
    handleRangePresets,
    handleChangeRange,
    handleIsFiltering,
    errorMessage,
    isError,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const mediaQuery = useMediaQuery({ query: "(min-width:1000px)" });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <button
                className="btn btn-primary-oo text-light"
                onClick={handleClick}
                type="button"
            >
                Seleccionar Fecha
            </button>
            <Popover
                sx={{
                    width: "auto",
                }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <h5 className="ps-2 pt-3">Filtrar por última interacción</h5>
                <hr></hr>
                <div className={`d-flex ${!mediaQuery && "flex-column"}`}>
                    <div>
                        <DateRangePreset
                            handleRangePresets={handleRangePresets}
                        />
                    </div>
                    <div>
                        <DayPicker
                            id="date-range-picker"
                            locale={es}
                            mode="range"
                            numberOfMonths={mediaQuery ? 2 : 1}
                            selected={range}
                            onSelect={handleChangeRange}
                            modifiersStyles={{
                                selected: { backgroundColor: "#46166c" },
                            }}
                            disabled={{ after: new Date() }}
                        />
                        {isError && (
                            <div
                                className="alert alert-danger mx-2"
                                role="alert"
                            >
                                Error: {errorMessage}
                            </div>
                        )}
                    </div>
                </div>

                <button
                    className="btn btn-primary-oo text-light m-2"
                    onClick={() => {
                        handleIsFiltering();
                        handleClose();
                    }}
                    type="button"
                >
                    Filtrar
                </button>
            </Popover>
        </>
    );
};
