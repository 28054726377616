import {Line, LineChart, CartesianGrid, Cell, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatterNumber } from '../../../../helper/currencyFormatter';


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
        <div style={{
            backgroundColor:"#FFF",
            padding:"10px",
            borderRadius:"10px",
            border:"solid 1px #d3d3d3"
        }}>
            <p style={{ color:"#3d3d3d"}}>{ label }</p>
            <p style={{ color:`#C5E1A5`}}>Completas: { formatterNumber(parseInt(payload[0].payload.completed)) }</p>
            <p style={{ color:`#EF9A9A`}}>Filtradas: { formatterNumber(parseInt(payload[0].payload.filtered)) }</p>
            <p style={{ color:`#B3E5FC`}}>Parciales: { formatterNumber(parseInt(payload[0].payload.totalLastPartial)) } </p>
            <p style={{ color:`#7986CB`}}>Total base: { formatterNumber(parseInt(payload[0].payload.totalBase)) } </p>
            <p style={{ color:`#90A4AE`}}>T. Respuesta: { (((parseInt(payload[0].payload.completed) + parseInt(payload[0].payload.filtered) + parseInt(payload[0].payload.totalLastPartial)) / parseInt(payload[0].payload.totalBase))*100).toFixed(2) }% </p>

        </div>
        );
}

return null;
};
const CustomLine = (props) => {
    const { x, y, width, height, fill, payload } = props;
    return (
        <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        stroke="#e2e2e2" // Color del borde
        strokeWidth={1} // Ancho del borde
        radius={[10,10,5,5]}
        />
    );
};    
export const YearLine = ({ responseYear }) => {

    const sortedData = responseYear.sort((a, b) =>
        a.year !== b.year ? a.year - b.year : a.quarter - b.quarter
    );
    const maxValue = Math.max(
        ...sortedData.map(item => Math.max(parseInt(item.completed)+ parseInt(item.filtered)))
    );


    return ( 
        <ResponsiveContainer width="100%" height={500}>
            <LineChart
                width={500}
                height={300}
                data={responseYear}
            >
            <CartesianGrid  stroke="transparent" />
                <XAxis
                    dataKey="semesterName"
                    textAnchor= "end"
                    sclaeToFit="true"
                    verticalAnchor= "start"
                    interval={0}
                    angle= "-40"
                    height={50}
                    axisLine={false} // Elimina la línea del eje X
                    tickLine={false} // Elimina las líneas de las marcas en el eje X
                    />
                <YAxis 
                    type="number"
                    domain={[0, dataMax => maxValue]} tickCount={10} 
                    allowDataOverflow={true}    
                    axisLine={false} // Elimina la línea del eje Y
                    tickLine={false} // Elimina las líneas de las marcas en el eje Y
                />                                   
                <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip />}/>
                <Legend  wrapperStyle={{paddingTop: "3.2rem"}} />
                <Line  type="monotone"  dataKey="completed" stroke="#C5E1A5" fill="#C5E1A5" name={'Completas'} shape={<CustomLine />} legendType='circle'>
                        {sortedData.map((e,i) => (
                            <Cell key={`cell-${i}`} fill="#C5E1A5" />
                        ))}
                    </Line>
                    <Line  type="monotone"  dataKey="filtered" stroke="#EF9A9A" fill="#EF9A9A" name={'Filtradas'} shape={<CustomLine />} legendType='circle'>
                        {sortedData.map((e,i) => (
                            <Cell key={`cell-${i}`} fill="#EF9A9A" />
                        ))}
                    </Line>
                    <Line  type="monotone"  dataKey="totalLastPartial" stroke="#B3E5FC" fill="#B3E5FC" name={'Parciales'} shape={<CustomLine />} legendType='circle'>
                        {sortedData.map((e,i) => (
                            <Cell key={`cell-${i}`}  fill="#B3E5FC" />
                        ))}
                    </Line>
                    {/* <Line type="monotone"  dataKey="totalBase" stroke="#7986CB" fill="#7986CB" name={'Total base'} legendType='plainline'>
                        {sortedData.map((e, i) => (
                            <Cell key={`cell-${i}`} fill="#7986CB" />
                        ))}
                    </Line> */}
            </LineChart>
        </ResponsiveContainer>
    )
}

