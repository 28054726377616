import { axiosApi } from "../axiosApi";

export const postRecordByDateAjax = async (filter) => {
    try {
        const response = await axiosApi.post(`/api/dashboard/registrados`,filter);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getTypePanelistAjax = async (filter) => {
    try {
        const response = await axiosApi.post(`/api/dashboard/tipo-de-panelista`,filter);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};



