import React, { useContext }  from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { status } from '../../../api/data/status';
import { COLORS } from '../../../helper/COLOR';
import { useTypePanelistM } from '../../../hooks/useRegisterDashboard';
import { DashboardContext } from '../../../context/DashboardContext';
import Skeleton from 'react-loading-skeleton';


const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, payload }, totals) => {
    let progress = (payload.total/totals)*100;
    progress = parseFloat(progress.toFixed(2));
    progress = isNaN(progress) ? 0 : progress;
    return `${payload.total}  (${progress}%)`    
};

export const StatusPanelistPieChart = () => {
    const {filter, setFilter} = useContext(DashboardContext);
    const {isLoading,typePanelist} = useTypePanelistM(filter);
    return (
        <>{
            isLoading && !typePanelist.length ? ( <Skeleton height={400} />): (
                !typePanelist.length ? ( <div className="alert__not-data">No se pudo encontrar la información <strong>TIPO DE PANELISTA</strong>, por favor intenta nuevamente modificando la fecha o refrescando la página</div>)
                :(<ResponsiveContainer width="100%" height={300}>
                    <PieChart width={730} height={250}>
                        <Legend />
                        <Pie 
                            data={typePanelist} 
                            dataKey="total" 
                            nameKey="typePanelists" 
                            labelLine={true} 
                            label={(props) => renderCustomizedLabel({...props}, typePanelist.reduce((a,b) => a + b.total,0))} >
                            {status.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index * 2]} />))}
                        </Pie>
                        {/* <Tooltip  cursor={{fill: 'transparent'}}/> */}
                    </PieChart>
                </ResponsiveContainer>)
            )
        }</>

    )
}