import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getSurvey, getSurveys } from "../api/sip/limesurvey";

export const useGetSurveys = (enabled = false) => {
    const [ selectQuarantine, setSelectQuarantine ] = useState({ options: [{ value:'Y', label: 'Si'}, { value:'N', label: 'No'}], selected: null });
    const [selectSurveys, setSelectSurveys] = useState({ options: [], selected: [] });
    const {isLoading, refetch} = useQuery(['getSurveys'], () =>  getSurveys(), { 
        onSuccess: (response) => {
            const { data } = response;
            setSelectSurveys({ ...selectSurveys, options: data.map( x => ({ value: x.refenceSid, label: x.surveyls_title }))})
}, enabled});

useEffect( () => {
    if(selectQuarantine.selected == null){ return }
    if(selectQuarantine.selected == 'N'){ setSelectSurveys({ options:[], selected: []}); return;}
    refetch();
}, [selectQuarantine]);

    return {
        isLoading,selectQuarantine,setSelectQuarantine,selectSurveys,setSelectSurveys
    }
}

export const useGetSurvey = (project_id) => {
    const [survey, setSurvey] = useState({
        sid: 0,
        ls_status: true,
        title: "",
        expires: "",
        datecreated: "",
    });
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const fetchSurvey = async () => {
        setIsLoading(true)
        try {
            const { data } = await getSurvey(project_id);
            setSurvey({
                sid: data.sid,
                ls_status: data.active === 'Y',
                title: data.surveyls_title,
                expires: data.expires,
                datecreated: data.datecreated
            });
        } catch (error) {
            setIsError(true);
            setError(error.data.message);
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => { fetchSurvey(); }, [project_id]);

    return {
        isLoading,
        isError,
        error,
        survey,
    };
};