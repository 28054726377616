import { Button, Menu, MenuItem } from "@mui/material";
import { useCallback, useState } from "react";
import { useDownloadPanelists } from "../../../../store/PanelistsInsight/usePanelistsStore";
import Swal from "sweetalert2";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

export const MenuTablePanelists = ({ columnFilters, setColumnFilters, setIsLoadingPanelists }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };
    const {
        fetchStartDownloadPanelists,
    } = useDownloadPanelists((state) => state);

    const handleExportFilteredData = useCallback(async () => {
        const resp = await fetchStartDownloadPanelists('filtered', columnFilters);
        if (resp.status == 200) {
            Swal.fire({
                title: 'Generando archivo CSV',
                text: 'Recibirás un mail con el link de descarga cuando este listo',
                icon: 'success'
            });
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Ha ocurrido un error inesperado, intenta nuevamente.',
                icon: 'error'
            });
        }
    }, [columnFilters]);

    return (
        <>
            <div>
                <Button
                    key={'download-btn-key'}
                    id="basic-button"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleClick}
                    startIcon={<FileDownloadIcon />}
                    color="inherit"
                >
                    Descargar
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        sx={{
                            padding: "10px"
                        }}
                        onClick={() => handleExportFilteredData()}
                        disabled={columnFilters.length === 0}
                    >
                        <FileDownloadIcon sx={{ paddingRight: "5px" }} />
                        Descargar selección filtro
                    </MenuItem>
                </Menu>
                <Button
                    key={'reset-filters-btn-key'}
                    id="reset-filters"
                    onClick={() => {
                        setIsLoadingPanelists(true)
                        setColumnFilters([])
                    }}
                    startIcon={<RestartAltIcon />}
                    color="inherit"
                >
                    Limpiar filtros
                </Button>
            </div>
        </>
    )
}