import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { useMutation } from 'react-query';
import Swal from 'sweetalert2';

import { createRefenceLime } from '../../../api/sip/limesurvey';
import { App } from '../../../App';


export const CreateLime = () => {
    const [lime, setLime] = useState({
        platform: '',
        version: '',
        refenceDB: '',
        url: ''
    });

    const { mutate, error, isLoading:isLoadingCreate, isError:isErrorCreate, isSuccess:isSuccessCreate} = useMutation( createRefenceLime ,{
        onSuccess: () => {
            Swal.fire('Crear referencia Limesurvey', 'Referencia creada', 'success').then( result => window.location.replace('/limesurvey'));
        },
        onError: () => Swal.fire('Error al crear referencia Limesurvey', 'No se pudo crear la referencia, por favor intenta más tarde o comunicate con el administrador', 'error')
    });


    const handleCreateLime = (e) => {
        e.preventDefault();
        Swal.fire({
            title: '¿Quieres crear una referencia plataforma limesurvey?',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar',
            icon:'info'
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              mutate(lime);
            } else if (result.isDenied) {
               Swal.fire('No se pudo modificar los datos del usuario, intenta nuevamente', '', 'error') 
            }
          })
    }

    return (
        <div className="card">
        <div className="card-header">
        <div className="content-head-card">
            <label>Crear versión limesurvey</label>
        </div>
        </div>
        <div className="card-body">
            <form onSubmit={ handleCreateLime }>
                <div className="form-group">
                    <label className="my-1 fw-bold">Nombre plataforma</label>
                    <input type="text" name="platform" className="form-control" id="platform-input" value={ lime.platform } onChange={ (e) => setLime({ ...lime, platform :e.target.value })}/>
                    {
                        isErrorCreate && (
                            <p className="text text-danger">{error.response.data.errors.platform?.map( x => x)}</p>
                        )
                    }
                </div>
                <div className="form-group">
                    <label className="my-1 fw-bold">Versión plataforma</label>
                    <input type="text" name="version" className="form-control" id="version-input" value={ lime.version } onChange={ (e) => setLime({ ...lime, version :e.target.value })}/>
                    {
                        isErrorCreate && (
                            <p className="text text-danger">{error.response.data.errors.version?.map( x => x)}</p>
                        )
                    }
                </div>    
                <div className="form-group">
                    <label className="my-1 fw-bold">Referencia base de datos</label>
                    <input type="text" name="refenceDB" className="form-control" id="refenceDB-input" value={ lime.refenceDB } onChange={ (e) => setLime({ ...lime, refenceDB :e.target.value })}/>
                    {
                        isErrorCreate && (
                            <p className="text text-danger">{error.response.data.errors.refenceDB?.map( x => x)}</p>
                        )
                    }
                </div>
                <div className="form-group">
                    <label className="my-1 fw-bold">Url</label>
                    <input type="text" name="url" className="form-control" id="url-input" value={ lime.url } onChange={ (e) => setLime({ ...lime, url :e.target.value })}/>
                    {
                        isErrorCreate && (
                            <p className="text text-danger">{error.response.data.errors.url?.map( x => x)}</p>
                        )
                    }
                </div>  
                <div className="form-group mt-2">
                    {
                        isLoadingCreate ? (<button className="btn btn-primary-oo">Cargando...</button>)
                        : (<button className="btn btn-primary-oo">Crear referencia</button>)
                    }
                </div>
            </form>
        </div>
    </div>

    );
}

if (document.getElementById('jsx-create-version-lime')) {
    const el = document.getElementById("jsx-create-version-lime");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
          <CreateLime { ...props } />
      </App>
  )
}
