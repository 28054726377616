import React from "react";
//Icon = icono fa a mostrar
export const BaseCard = ({ title, quantity, icon, btn }) => {
    return (
        <div className="col-md-6 mt-3 mt-md-1 card-detail-project">
            <div className="detail-project">
                <p>
                    <i
                        className={`fa-solid ${icon}`}
                        style={{ color: "#552C75" }}
                    ></i>
                </p>
                <div>
                    <label>{title}</label>
                    <p>{quantity}</p>
                    {btn}
                </div>
            </div>
        </div>
    );
};
