// React
import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { App } from '../../../App';
import { useDropzone } from 'react-dropzone';
import styles from './style.module.css';
export const  CreateCategoryProducts = () => {

    const [ category, setCategory] = useState({
        name: '',
        description:'',
        img:'',
        metaDataImg: null
    });

    const onDrop = (file) => {
        const selectedImage = file[0];
        setCategory({ ...category, img: selectedImage.name, metaDataImg:selectedImage });
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': [],
            'image/png': []
        }, // Acepta solo archivos de tipo imagen
        multiple: false,    // Aceptar solo una imagen
      });

    return (
        <>
            <div className="row">
            <div className="col-xl-12 col-lg-12">
                <div className="card mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <div className="content-head-card">
                            <label htmlFor="">
                                <a href="/tienda/proyectos/categorias"><strong>Mostrar categorias de productos</strong></a> / <a><strong>Crear proyecto</strong></a>
                            </label>
                        </div>
                    </div>
                    {/* Card Body */}
                    <div className="card-body">
                        { category.img && (
                            <div className={styles.content_img}>
                                <img className={ `${styles.img_previous} img img-fluid` } src={URL.createObjectURL(category.metaDataImg)}/>
                            </div>
                        )}
                        <form onSubmit={  () => {} } method="POST">
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Nombre de la categoria</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="name-id" 
                                            placeholder="Nombre de la categoria" 
                                            name="name" 
                                            value={ category.name } 
                                            onChange={ (e) => setCategory({ ...category, name:e.target.value })} />
                                       
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Descripción</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        id="description-id" 
                                        placeholder="" 
                                        name="description" 
                                        value={ category.description } 
                                        onChange={ (e) => setCategory({ ...category, description:e.target.value })} />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        {/* <label htmlFor="exampleFormControlInput1" className="form-label">
                                                { category.img ? `Imagen :${ category.img }` : 'Cargar imagen'}
                                        </label> */}
                                        <div {...getRootProps()} className={ styles.dropzone } >
                                            <input  {...getInputProps()} />
                                            <label>Arrastre y suelte la imagen, o haga clic para  seleccionar</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
if (document.getElementById('jsx-create-categories-products')) {
    const el = document.getElementById("jsx-create-categories-products");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
          <CreateCategoryProducts { ...props } />
      </App>
  )
}