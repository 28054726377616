import { Alert, AlertTitle, Stack } from "@mui/material";

export const AlertStack = ({ alerts,setLoadAvailablesEnded }) => {
    return (
        <Stack spacing={2} sx={{ marginTop: "15px", marginBottom: "15px" }}>
            {alerts.isErrorBatch && (
                <Alert severity="error">
                    <AlertTitle>{alerts.errorMessageBatch}</AlertTitle>
                </Alert>
            )}
            {alerts.isError && (
                <Alert severity="error">
                    <AlertTitle>{alerts.errorMessage}</AlertTitle>
                </Alert>
            )}
            {alerts.loadAvailablesEnded.showAlert && (
                <Alert
                    sx={{
                        marginTop: 5,
                    }}
                    severity="success"
                    onClose={() => {
                        setLoadAvailablesEnded({
                            loadedQuantity: 0,
                            showAlert: false,
                        });
                    }}
                >
                    <AlertTitle>¡Panelistas cargados con éxito!</AlertTitle>
                    Se han cargado {alerts.loadAvailablesEnded.loadedQuantity}{" "}
                    panelistas.
                </Alert>
            )}
        </Stack>
    );
};
