import { axiosApi } from "./axiosApi";

export const getClientsSio  = async () => {
    try {
        const response = await axiosApi.get('/api/sio/clientes');
        return response;
    } catch (error) {
        const { response } = error;
        throw  response;
    }
}
