import React from "react";

export const DateRangePreset = ({ handleRangePresets }) => {
    return (
        <div className="d-flex flex-column py-3 px-1">
            <button
                className="btn btn-sm btn-primary-oo text-light m-1"
                onClick={() => {
                    handleRangePresets("today");
                }}
            >
                Hoy
            </button>
            <button
                className="btn btn-sm btn-primary-oo text-light m-1"
                onClick={() => {
                    handleRangePresets("thisWeek");
                }}
            >
                Esta semana
            </button>
            <button
                className="btn btn-sm btn-primary-oo text-light m-1"
                onClick={() => {
                    handleRangePresets("lastWeek");
                }}
            >
                Semana pasada
            </button>
            <button
                className="btn btn-sm btn-primary-oo text-light m-1"
                onClick={() => {
                    handleRangePresets("last7");
                }}
            >
                Últimos 7 días
            </button>
            <button
                className="btn btn-sm btn-primary-oo text-light m-1"
                onClick={() => {
                    handleRangePresets("thisMonth");
                }}
            >
                Este mes
            </button>
            <button
                className="btn btn-sm btn-primary-oo text-light m-1"
                onClick={() => {
                    handleRangePresets("active");
                }}
            >
                Activos
            </button>
            <button
                className="btn btn-sm btn-primary-oo text-light m-1"
                onClick={() => {
                    handleRangePresets("semiactive");
                }}
            >
                Semiactivos
            </button>
            <button
                className="btn btn-sm btn-primary-oo text-light m-1"
                onClick={() => {
                    handleRangePresets("reset");
                }}
            >
                Reiniciar
            </button>
        </div>
    );
};
