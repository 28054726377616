import React from 'react';

export const ButtonAction = ({ project }) => {
    return (
        <>
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            fontSize: '25px'
        }}>
            <a className="text"  title="Detalle" style={{ color: 'var(--bs-gray-500)', cursor:'pointer'}} href={`/proyecto/${project.id}/detalle`}><i className="fa fa-eye"></i></a>
        </div>
        </>
    )
}