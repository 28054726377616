// React
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { getSurveys } from '../../../api/sip/limesurvey';
import { App } from '../../../App';

 // Componente de terceros
import Select from 'react-select';
import { useMutation, useQueries } from 'react-query';
import { editProject, getProject } from '../../../api/sio/project';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import { getCountries } from '../../../api/sip/geography';
import { SidebarRight } from './SidebarRight';
import { useMediaQuery } from 'react-responsive';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Alert, AlertTitle, TextField } from '@mui/material';
import moment from 'moment';
import { useGetSurvey } from '../../../hooks/useSurvey';
import { getClientsSio } from '../../../api/Sio';

const statusOptions = [
    { label: 'Modo prueba', value: 'testmode' },
    { label: 'En campo', value:'actived' },
    { label: 'Pausado', value: 'paused' },
    { label: 'Expirada', value: 'expired' },
];

const programmingOptions = [ { value: 'internal', label: 'Interna' }, { value:'external', label: 'Externa'}];
const measuringToolOptions = [{ value:'cawi', label:'CAWI'}, {value:'tawi', label:'TAWI'}, {value:'cati', label: 'CATI'}];
const externalLinksOptions = [ { value: 'required', label: 'Si requiere' }, { value:'noRequired', label: 'No requiere'}];

export const  EditProject = ({ id }) => {

    const mediaQuery = useMediaQuery({ query:'(min-width:1000px)'});
    const { survey, isLoading:isLoadingSurvey } = useGetSurvey(id);
    const [ project, setProject] = useState({
        id: '',
        name: '',
        nickname:'',
        client:'',
        sample:'',
        programming:'internal',
        externalLinks:'noRequired',
        in_balancer: 'yes',
        ir:'',
        loi:'',
        points:'',
        incentives:'',
        refenceSurvey_id:'',
        expired_at: moment().utc(true).format("YYYY-MM-DD HH:mm"),
        measuringTool: 'cawi',
        status: 'testmode'
    });

    const [ projectResponse,surveysResponse, countriesResponse, clientResponse ] = useQueries([{
            queryKey:['project',id], queryFn : () => getProject(id),
            onSuccess: ({data}) => setProject(data)
        },{
            queryKey:['surveys'], queryFn : () => getSurveys(),
        },{
            queryKey:['countries'], queryFn: () => getCountries()
        },{
            queryKey:['getClientsSio'], queryFn: () => getClientsSio() 
        }
    ]);
    const { isLoading:isLoadingProject } = projectResponse;
    const { data:responseSurvey, isLoading } = surveysResponse;
    const { data:responseCountry, isLoading:isLoadingCountry} = countriesResponse;
    const { data:responseClient, isLoading:isLoadingClient } = clientResponse;

    const { mutate:meditProject, error:errorEdit, isError:isErrorEdit} = useMutation( editProject ,{
        onSuccess: () => {
            Swal.fire('Editar proyecto', 'Se ha editado este proyecto', 'success');
            window.location.replace(`/proyecto/${project.id}/detalle`);
        },
        onError: (e) => {
            Swal.fire('Editar proyecto','No se pudo editar este proyecto', 'error')
        }
    });

    const handleEditProject = (e) => {
        e.preventDefault();
        Swal.fire({
            title: '¿Quieres editar este proyecto?',
            showCancelButton: true,
            confirmButtonText: 'Editar',
            cancelButtonText: 'Cancelar',
            icon:'info'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                console.log('project c', project);
                meditProject(project);
            } else if (result.isDenied) {
                Swal.fire('Editar proyecto', 'No se pudo editar el proyecto', 'error')
            }
        })
    }

    const calcExpiredAt = (status) => status == 'expired' ? project.loi * 1.5 : status == 'paused' ? 5 : 0;

    return (
        <>
            <div className="row">

            { !mediaQuery && !isLoadingSurvey &&  (<SidebarRight id={id} survey_status={survey.ls_status}/>) }
            <div className="col-xl-9 col-lg-9 mt-4">
                <div className="card mb-4">
                    {/* Card Header - Dropdown */}
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <div className="content-head-card">
                            <label htmlFor="">
                                <a href="/proyecto"><strong>Mostrar proyectos</strong></a> / <a><strong>Editar proyecto</strong></a>
                            </label>
                        </div>
                    </div>
                    {/* Card Body */}
                    <div className="card-body">
                        {!survey.ls_status
                        ?   <Alert severity="error" sx={{marginTop: '10px', marginBottom: '10px'}}>
                                <AlertTitle>Encuesta deshabilitada en Limesurvey</AlertTitle>
                                <hr></hr>
                                <p>La programación de este cuestionario no está habilitada en <b>Limesurvey</b></p>
                                <p>Posible motivo: ajustes en programación de cuestionario.</p>
                                <p>Consultar a TI para más información.</p>
                            </Alert>
                            : isLoadingProject  ? ( <Skeleton height={400}/> )
                            : (
                                <form onSubmit={  handleEditProject } method="POST">
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Nombre proyecto</label>
                                                <input type="text" className="form-control" id="name-project-id" placeholder="Nombre del proyecto" name="name" value={ project.name } onChange={ (e) => setProject({ ...project, name:e.target.value })} />
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.name?.map( x => x)}</p>
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Nickname</label>
                                                <input type="text" className="form-control" id="name-project-id" placeholder="Nombre ficticio del proyecto" name="name" value={ project.nickname } onChange={ (e) => setProject({ ...project, nickname:e.target.value })} />
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.nickname?.map( x => x)}</p>
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                {
                                                    (!isLoadingClient) ? (
                                                        <>
                                                        <label htmlFor="" className="form-label">Cliente</label>
                                                        <Select 
                                                        key={3}
                                                        className="basic-select"
                                                        options= { responseClient.status == 200 ? responseClient.data.map( x => ({ value: x.nombre, label: x.nombre })) : []}
                                                        defaultValue = { () => {
                                                            return { label: project.client, value: project.client};
                                                        }}
                                                        onChange = { (e) => {
                                                            console.log(e);
                                                            setProject({ ...project, client : e.value })
                                                        }}
                                                        />
                                                        {
                                                            isErrorEdit && (
                                                                <p className="text text-danger">{errorEdit.data.errors?.client?.map( x => x)}</p>
                                                            )
                                                        }     
                                                        </>
                                                    ) : ( <div className="text text-success">Cargando clientes</div>)
                                                }
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="exampleFormControlInput2" className="form-label">Muestra requerida</label>
                                                <input type="number" className="form-control" id="sample-project-id" placeholder="N del proyecto" name="sample" value={ project.sample } onChange={ (e) => setProject({ ...project, sample:e.target.value })} />
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.sample?.map( x => x)}</p>
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="" className="form-label">Tipo de programación</label>
                                                <Select 
                                                    key={1}
                                                    className="basic-select"
                                                    options= { programmingOptions }
                                                    onChange = { (e) => {
                                                        setProject({ ...project, programming : e.value })
                                                    }}
                                                    defaultValue = { programmingOptions.filter(x => x.value == project.programming)}
                                                />
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.programming?.map( x => x)}</p>
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="" className="form-label">¿Require listado tokens o base específica ?</label>
                                                <Select 
                                                    key={2}
                                                    className="basic-select"
                                                    defaultValue = {  externalLinksOptions.filter(x => x.value === project.externalLinks)}
                                                    options= { externalLinksOptions }
                                                    onChange = { (e) => {
                                                        setProject({ ...project, externalLinks : e.value })
                                                    }}
                                                />
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.externalLinks?.map( x => x)}</p>
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-6 ">
                                                <label htmlFor="" className="form-label">Instrumento de medición</label>
                                                <Select 
                                                    className="basic-select"
                                                    defaultValue = {  measuringToolOptions.filter(x => x.value == project.measuringTool)}
                                                    options= { measuringToolOptions }
                                                    onChange = { (e) => {
                                                        setProject({ ...project, measuringTool : e.value })
                                                    }}
                                                />
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.measuringTool?.map( x => x)}</p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Tasa de incidencia</label>
                                                <input type="number" className="form-control"  id="ir-project-id" placeholder="IR" name="ir" value={ project.ir } onChange={ (e) => setProject({ ...project, ir:e.target.value })} />
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.ir?.map( x => x)}</p>
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Tiempo de duración</label>
                                                <input type="number" className="form-control"  id="duration-project-id" placeholder="Duración" name="loi" value={ project.loi }  onChange={ (e) => setProject({ ...project, loi:e.target.value })}/>
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.loi?.map( x => x)}</p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Puntos</label>
                                                <input type="number" className="form-control"  id="points-project-id" placeholder="Puntos" name="points" value={ project.points } onChange={ (e) => setProject({ ...project, points:e.target.value })}/>
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.points?.map( x => x)}</p>
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Incentivos</label>
                                                <input type="text" className="form-control" id="incentive-projects-id" placeholder="Nombre del proyecto" name="incentive" value={ project.incentives } onChange={ (e) => setProject({ ...project, incentives:e.target.value })} />
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.incentives?.map( x => x)}</p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                {
                                                    (!isLoading) ? (
                                                        <>
                                                        <label htmlFor="" className="form-label">Asociar programación</label>
                                                        <Select 
                                                        key={3}
                                                        isClearable
                                                        className="basic-select"
                                                        options= { responseSurvey.status == 200 ? responseSurvey.data.map( x => ({ value: x.refenceSid, label: x.surveyls_title })) : []}
                                                        defaultValue = { () => {
                                                            const _f = responseSurvey.data.filter(x => x.refenceSid == project.refenceSurvey_id);
                                                            return { label: _f[0].surveyls_title, value:_f[0].refenceSid};
                                                        }}
                                                        onChange = { (e) => {
                                                            setProject({ ...project, refenceSurvey_id : e.value })
                                                        }}
                                                        />
                                                        {
                                                            isErrorEdit && (
                                                                <p className="text text-danger">{errorEdit.data.errors?.refenceSurvey_id?.map( x => x)}</p>
                                                            )
                                                        }     
                                                        </>
                                                    ) : ( <div className="text text-success">Cargando encuestas</div>)
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="" className="form-label">Fecha de término</label>
                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
                                                    <DateTimePicker
                                                        ampm={false}
                                                        className="form-control"
                                                        inputFormat="YYYY-MM-DD HH:mm"
                                                        value={project.expired_at}
                                                        onChange={ (e) => setProject({ ...project, expired_at: moment(e).utc(true).format("YYYY-MM-DD HH:mm") }) }
                                                        renderInput={(params) => <TextField size="small" {...params} />}
                                                    />
                                                </LocalizationProvider>
                                                {
                                                    isErrorEdit && (
                                                        <p className="text text-danger">{errorEdit.data.errors?.expired_at?.map( x => x)}</p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="row">
                                                <div className="col-md-6">
                                                    {
                                                        !isLoadingCountry ? (
                                                            <>
                                                                <label htmlFor="" className="form-label">Pais</label>
                                                                <Select 
                                                                    key={2}
                                                                    className="basic-select"
                                                                    options= { responseCountry.status == 200 ? responseCountry.data.map( x => ({ value: x.country, label: x.country })) : []}
                                                                    defaultValue = { {value:project.country, label:project.country } }
                                                                    onChange = { (e) => {
                                                                        setProject({ ...project, country : e.value })
                                                                    }}
                                                                />
                                                                {
                                                                    isErrorEdit && (
                                                                        <p className="text text-danger">{errorEdit.data.errors?.country?.map( x => x)}</p>
                                                                    )
                                                                }
                                                            </>
                                                        ) : (<div className="text text-success">Cargando paises</div>)
                                                    }
                                                </div>
                                            <div className="col-md-6">
                                                    <label htmlFor="" className="form-label">Estado</label>
                                                    <Select 
                                                        key={2}
                                                        className="basic-select"
                                                        defaultValue = { statusOptions.filter( x => x.value  == project.status) }
                                                        options={ statusOptions }
                                                        onChange = { (e) => {
                                                            setProject({ ...project, status : e.value })
                                                        }}
                                                    />
                                                    {
                                                        isErrorEdit && (
                                                            <p className="text text-danger">{errorEdit.data.errors?.status?.map( x => x)}</p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="in_balancer"
                                                        checked={ project.in_balancer == 'yes' ? true : false }
                                                        onChange={ (e) => setProject({ ...project, in_balancer: e.target.checked ? 'yes' : 'no' }) }
                                                    />
                                                    <label className="form-check-label" htmlFor="in_balancer">
                                                    ¿Incluir en balanceador?
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        {
                                            isLoading ? (<button className="btn btn-primary-oo">Cargando...</button>)
                                            : (<button className="btn btn-primary-oo">Editar proyecto</button>)
                                        }
                                    </div>
                                </form>
                            )
                        }

                    </div>
                </div>
            </div>
            { mediaQuery && !isLoadingSurvey &&  (<SidebarRight id={id} survey_status={survey.ls_status}/>) }
        </div>
    </>
    )
}
if (document.getElementById('jsx-edit-project')) {
    const el = document.getElementById("jsx-edit-project");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
          <EditProject { ...props } />
      </App>
  )
}