import { create } from "zustand";
import { useProductionOfMonthStore } from './useProductionOfMonthStore';
import { useProductionOfQuarterStore } from './useProductionOfQuarterStore';
import { useProductionOfSemesterStore } from './useProductionOfSemesterStore';
import { useProductionOfYearStore } from './useProductionOfYearStore';

import { dateTransform } from "../../helper/dateTransform";
import { DashboardProductionsMonth } from "../../components/reports/productions/DashboardProductionsMonth";
import { DashboardProductionsQuarter } from "../../components/reports/productions/DashboardProductionsQuarter";
import { DashboardProductionsSemester } from "../../components/reports/productions/DashboardProductionsSemester";
import { DashboardProductionsYear } from "../../components/reports/productions/DashboardProductionsYear";

export const useBoundProductionsReportStore = create( (set,get) => {
    return {
        ...useProductionOfMonthStore(set,get),
        ...useProductionOfQuarterStore(set,get),
        ...useProductionOfSemesterStore(set,get),
        ...useProductionOfYearStore(set,get),
        errors:{
            status:false,
            message:''
        },
        preset:dateTransform['ofYear'],
        defaultRepresentation: [  
            { value: 'month', label: 'Mes', callback: () => <DashboardProductionsMonth/>  },
            { value: 'quarter', label: 'Trimestre',  callback: () => <DashboardProductionsQuarter/> },
            { value: 'semester', label: 'Semestre', callback: () => <DashboardProductionsSemester/> },
            { value: 'year', label: 'Año', callback: () => <DashboardProductionsYear/>  }
        ],
        defaultStatusProject:[{ value:'actived', label: 'En campo'}, { value:'expired', label: 'Cerrado' } ],
        representation:[
            { value: 'month', label: 'Mes', callback: () => <DashboardProductionsMonth/>  },
            { value: 'quarter', label: 'Trimestre',  callback: () => <DashboardProductionsQuarter/> },
            { value: 'semester', label: 'Semestre', callback: () => <DashboardProductionsSemester/> },
            { value: 'year', label: 'Año', callback: () => <DashboardProductionsYear/>  }
        ],
        statusProject:[{ value:'actived', label: 'En campo'}, { value:'expired', label: 'Cerrado' } ],
        setRepresentation: (_r) => set((state) => {
            return { ...state, defaultRepresentation: _r }
        }),
        setErrors: (status, message) => set((state) => ({...state, errors:{ status, message }})),
        setPreset: (_preset) => set((state) => ({...state, preset: _preset })),
        setStatusProject: (status) => set((state) => ({ ...state, defaultStatusProject: status })),
    }
})