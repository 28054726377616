
import { Skeleton } from "@mui/material"
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts"

export const KpiCardChart = ({ isLoading, kpiKey, chartData, baseColor = "#8884d8" }) => {
    return isLoading ?
        <Skeleton variant="rounded" width={'90%'} height={130} sx={{ margin: '10px' }} />
        : <ResponsiveContainer className="mt-2" width="100%" height={130} >
            <AreaChart key={'chart' + kpiKey} data={chartData}>
                <defs>
                    <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={baseColor} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={baseColor} stopOpacity={0} />
                    </linearGradient>
                </defs>
                <Tooltip labelFormatter={(label, payload) => `${payload[0]?.payload?.label}`} />
                <Area type="monotone" dataKey="total" stroke={baseColor}  fillOpacity={1} fill="url(#colorTotal)" />
            </AreaChart >
        </ResponsiveContainer>
}