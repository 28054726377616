import React, { useEffect, useState } from 'react';
import { STATUS_TRANSACTIONS } from '../../../../helper/status_transactions';
import Swal from 'sweetalert2';
import { updateTransaction } from '../../../../api/sis/transaction';

export const TransactionModal = ({handleTransactions,transactionSelect,setTransactionSelect, showTransactionModal, setShowTransactionModal}) => {

    const handleChangeStatus = (e) => {
        let _transactionValue = e.target.value;
        Swal.fire({
            title: `Actualizar estado`,
            html:`
                Quieres cambiar el estado a <strong style='background-color:#46166c; color:#FFF; border-radius: 1rem; padding: 0 10px;'>${STATUS_TRANSACTIONS[_transactionValue]}</strong>
            `,
            showCancelButton: true,
            confirmButtonText: 'Actualizar',
            cancelButtonText: 'Cancelar',
            icon:'info'
          }).then( async (result) => {
                if(!result.isConfirmed){ return; }
                try{
                    const response = await updateTransaction({
                        transaction_id: transactionSelect.transaction_id,
                        status: _transactionValue
                    });
                    if(response.status == 204){
                        Swal.fire('Actualizar estado','El estado del canje no pudo ser modificado', 'error');
                        return;
                    }
                    Swal.fire('Actualizar estado','El estado del canje fue modificado', 'success');
                    setTransactionSelect({...transactionSelect, status_transaction:_transactionValue});
                    handleTransactions();
                }catch(error){
                    Swal.fire('Actualizar estado','El estado del canje no pudo ser modificado', 'error');
                }
          })
    }
    return (
        <div className="modal modal-segmentation">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Canje realizado: { transactionSelect.name }
                        </h5>
                        <button  className="close close-modal-btn" data-dismiss="modal" aria-label="Close" onClick={ () => {
                            setShowTransactionModal(!showTransactionModal);
                        }} >
                            <i className="fa fa-times-circle"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Id</th>
                                    <td>{ transactionSelect.transaction_id }</td>
                                </tr>
                                <tr>
                                    <th>Nombre</th>
                                    <td>{ transactionSelect.name }</td>
                                </tr>
                                <tr>
                                    <th>RUT/DNI</th>
                                    <td>{ transactionSelect.dni }</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{ transactionSelect.email }</td>
                                </tr>
                                <tr>
                                    <th>Producto</th>
                                    <td>{ transactionSelect.name_product }</td>
                                </tr>
                                <tr>
                                    <th>Categoría producto</th>
                                    <td>{ transactionSelect.name_category_product }</td>
                                </tr>
                                <tr>
                                    <th>Codigo movired</th>
                                    <td>{ transactionSelect.codMov == 0 ? '' : transactionSelect.codMov }</td>
                                </tr>
                                <tr>
                                    <th>Compañia</th>
                                    <td>{ transactionSelect.companie }</td>
                                </tr>
                                <tr>
                                    <th>Número</th>
                                    <td>{ transactionSelect.number }</td>
                                </tr>
                                <tr>
                                    <th>Estado del premio</th>
                                    <td>
                                    <select className="form-select" style={{border:'none'}} value={ transactionSelect.status_transaction } onChange={ handleChangeStatus }>
                                        <option value="" disabled>Estado del premio</option>
                                        {
                                            Object.entries(STATUS_TRANSACTIONS).map(([clave, valor]) => {
                                                return (<option key={clave} value={clave}>{ valor }</option>)
                                            })
                                        }
                                    </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Opi puntos</th>
                                    <td>{ transactionSelect.product_points}</td>
                                </tr>
                                <tr>
                                    <th>Precio</th>
                                    <td>{ transactionSelect.product_price}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    

                </div>
            </div>
        </div>
    )
}