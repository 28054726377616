import { create } from "zustand";
import { confirmLoadPanelists, fetchNotifications, fetchSendNotifications, getNotificationFcm, progressLoadPanelists, saveNotificationFcm, searchPanelists } from "../../api/notification/fcm";
import { transformIsActionButton } from "../../helper/utils";
import { useBoundGeographyStore } from "../Geographies/useBoundGeographyStore";
import { useBoundNseStore } from "../useBoundNseStore";


const ERROR = { status: false, message: '', errors: [] };

export const useBoundNotificationFcm = create((set,get) => {
    return {
        loadings: {
            notifications: ERROR,
            panelists:false
        },
        errors:{
            notification: ERROR,
            panelists: ERROR,
            notifications: ERROR
        },
        step: 0,
        loading: false,
        errorPanelists:{ errors: [], message:'' },
        errorNotifications: { errors: [], message:'' },
        notifications:undefined,
        notification:  {
            id: undefined,
            name:'',
            title: '',
            description: '',
            isActionButton: false,
            titleButton: '',
            linkButton: '',
            segmentation:undefined,
            batchId:undefined,
            hashing: undefined,
            step:0,
            status:'draft',
            created_at: '',
            updated_at: '',
            totalPanelists: 0,
         },
         panelists:{    
            gender: {
                values: [{ label:'Mujer', value:'F' }, { label:'Hombre', value:'M' }],
                selected: undefined
            },
            tagGeo: undefined,
            geographies:undefined,
            typeNse: undefined,
            nse: undefined,
            ages:{ min: 0, max: 0}
         },
         batchProgress: undefined,         
        // busco información de la notificación
        fetchNotification: async ($id) => {
            const { fetchGeography } = useBoundGeographyStore.getState();
            const { selectRefenceNse } = useBoundNseStore.getState();
            try{
                const response = await getNotificationFcm($id);
                const { data } = response;
                const { notification, batch } = data;
                if(notification.segmentation != undefined && notification.segmentation.length > 0){
                    let segmentation = JSON.parse(notification.segmentation);
                    if(segmentation.tagGeo){
                       fetchGeography(segmentation.tagGeo, false);
                       selectRefenceNse(segmentation.typeNse, false);
                    }
                    get().setterPanelist( { ...segmentation, gender:{ ...get().panelists.gender, selected:segmentation.gender } } );
                }
                set({ 
                    notification: {...notification, isActionButton:transformIsActionButton[notification.isActionButton] }, 
                    step:notification.step, 
                    batchProgress: batch,
                });
            }catch(error){  
                throw error;
            }   
        },

        // almacenar notificación
        saveNotificationFcm: async () => {
            try{
                const response = await saveNotificationFcm({...get().notification, isActionButton: get().notification.isActionButton ? 'active' : 'disabled' });
                const { data } = response;
                const { notification } = data;
                set({ 
                        notification: {...notification, isActionButton:transformIsActionButton[notification.isActionButton] }, 
                        step:parseInt(notification.step),
                        errors : { ...get().errors, notification: ERROR } 
                });
            }catch(error){
                if(error.status == 422){
                    const { data } = error;
                    set({ errors: { ...get().errors, notification: { status: true, ...data } } });
                }
            }
        },

        // almacenar la busqueda de los panelista
        searchPanelists: async() => {
            set({ loadings: { ...get().loadings, panelists: true }});
            let panelists =  {...get().panelists, notificationId: get().notification.id};
            panelists.gender = panelists.gender.selected;
            try{
                const response = await searchPanelists(panelists);
                const { data, status } = response;
                if(status  == 204){
                    set({ loadings: { ...get().loadings, panelists: false }, errors:{ ...get().errors, panelists:{ status:true, errors:{ global:['No hay usuarios asociado a este segmento']}, message:'No hay usuarios asociado a este segmento' }}})
                    return;
                }
                set({ 
                    loadings: { ...get().loadings, panelists: false },
                    notification: data, 
                    step:data.step,
                    errors : { ...get().errors, panelists: ERROR } 
                });
            }catch(error){
                if(error.status == 422){
                    const { data } = error;
                    set({ loadings: { ...get().loadings, panelists: false }, errors: { ...get().errors, panelists: { status: true, ...data } } });
                }
            }
        },

        // confirmamos el envío de la notificación
        confirmLoadPanelists: async () => {
            let notification  = get().notification;
            try{
                const response = await confirmLoadPanelists(notification);
                const { data } = response;
                const { notification:notificationData, batch } = data;
                set({ 
                    notification: { ...notificationData}, 
                    batchProgress: { ...batch },
                    step:notificationData.step,
                    errors : { ...get().errors, panelists: ERROR } 
                });
            }catch(error){
                throw error;
                // set({ errors: { ...get().errors, panelists: { status: true, ...data } } });
            }
        },

        // generamos el progreso del bacthId
        progressLoadPanelists: async() => {
            let notification  = get().notification;
            try{
                const response = await progressLoadPanelists(notification);
                const { data  } = response;
                const { notification:notificationData, batch } = data;
                let responseNotification = notificationData  ? notificationData : notification;
                let setdata = {
                    notification: responseNotification, 
                    batchProgress: batch,
                    step:responseNotification.step,
                }
                set(setdata);
            }catch(error){
                throw error;
                console.log(error);
            }
        },

        // obtener todas las notificaciones 
        fetchNotifications: async() => {
            set({ loading: true })
            try{
                const response = await fetchNotifications();
                set({ notifications: response.data, loading:false });
            }catch(error){
                set({ loading: false })
            }
        },
        fetchSendNotification: async () => {
            let notification  = get().notification;
            set({ loading: true });
            try{
                const response = await fetchSendNotifications( notification );
                set({ notification: response.data, loading:false });
            }catch(error){
                set({ loading: false })
            }
        },
        // setear todos los valores
        setter: (setter) => set(setter),
        setterNotification: (setter) => set( (state) => ({...state, notification:{ ...state.notification, ...setter }})),
        setterPanelist: (setter) => set((state => ({ ...state, panelists:{ ...state.panelists, ...setter }}))),
        setterInput: (name, value) => set((state) => ({ ...state, notification:{ ...state.notification, [name]: value } }))
    }
});