import { axiosApi } from "../axiosApi";

export const createPanelExternal = async (panelExternal) => {
    try {
        const response = await axiosApi.post(
            `/api/panel-externo/crear`,
            panelExternal
        );
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const updatePanelExternal = async (panelExternal) => {
    try {
        const response = await axiosApi.put(
            `/api/panel-externo/actualizar`,
            panelExternal
        );
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getPanelExternals = async () => {
    try {
        const response = await axiosApi.get(`/api/panel-externo`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getPanelExternal = async (id) => {
    try {
        const response = await axiosApi.get(`/api/panel-externo/${id}`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const deletePanelExternal = async (id) => {
    try {
        const response = await axiosApi.delete(
            `/api/panel-externo/eliminar/${id}`
        );
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};
