import React, { useMemo } from "react";
import ReactDOM from "react-dom/client";
import { App } from "../../../App";

import {MaterialReactTable} from "material-react-table";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "react-query";
import Swal from "sweetalert2";
import copy from 'copy-to-clipboard';
import { useGetMonitores } from "../../../hooks/useMonitor";
import { deleteMonitor } from "../../../api/sio/monitor";
export const CategoriesProducts = () => {

    const { isLoading, monitores, refetch } = useGetMonitores();

    // const { mutate: mDeleteMonitor } = useMutation(deleteMonitor, {
    //     onSuccess: () => {
    //         Swal.fire(
    //             "Eliminar monitor",
    //             "Se a eliminado el monitor",
    //             "success"
    //         ).then((result) => {
    //             // location.replace("/monitor");
    //             refetch()
    //         });
    //     },
    //     onError: (e) => {
    //         Swal.fire(
    //             "Eliminar monitor",
    //             "No se pudo eliminar el monitor",
    //             "error"
    //         );
    //     },
    // });

    // const handleDeletePanelExternal = (e, id) => {
    //     e.preventDefault();
    //     Swal.fire({
    //         title: "¿Quieres eliminar este monitor?",
    //         showCancelButton: true,
    //         confirmButtonText: "Eliminar",
    //         cancelButtonText: "Cancelar",
    //         icon: "info",
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             mDeleteMonitor(id);
    //         }
    //     });
    // };

    const columns = useMemo(
        () => [
            {
                accessorKey: "name", //access nested data with dot notation
                header: "Nombre",
            },{
                accessorKey: "created_at", //access nested data with dot notation
                header: "Fecha de creación",
            },{
                header: "Acciones",
                Cell: ({ cell }) => (
                    <>
                        <a
                            className="text"
                            title="Editar"
                            style={{
                                color: "var(--bs-warning)",
                                cursor: "pointer",
                                fontSize: "1.4em",
                            }}
                            href={`/panel-externo/${cell.row.original.id}/editar`}
                        >
                            <i className="fa fa-pen"></i>
                        </a>
                    </>
                ),
            }
        ],
        []
    );

    return (
        <>
        {
            isLoading ? (<Skeleton height={400}/>) :
            (
                <div className="card">
                    <div className="card-header">
                        <div className="content-head-card">
                            <label>Crear categoria de producto</label>
                            <a className="btn btn-primary-oo" href="/tienda/productos/categorias/crear">
                                Crear categoria
                            </a>
                        </div>
                    </div>
                    <div className="card-body">
                        <MaterialReactTable
                            columns={columns}
                            data={[
                                {
                                    id: 1,
                                    name:'my first category',
                                    created_at: '2023-09-15'
                                }
                            ]}
                            muiTablePaperProps={{
                                sx: {
                                    boxShadow: "none !important",
                                    border: "none",
                                },
                            }}
                        />
                    </div>
                </div>
            )
        }
            
        </>
    );
};

if (document.getElementById("jsx-list-categories-products")) {
    const el = document.getElementById("jsx-list-categories-products");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <CategoriesProducts {...props} />
        </App>
    );
}
