// React
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { getSurveys } from '../../../api/sip/limesurvey';
import { App } from '../../../App';

 // Componente de terceros
import Select from 'react-select';
import { useMutation, useQuery } from 'react-query';
import { createProject } from '../../../api/sio/project';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/de';
import moment from 'moment';
import { getClientsSio } from '../../../api/Sio';

const programmingOptions = [ { value: 'internal', label: 'Interna' }, { value:'external', label: 'Externa'}];
const measuringToolOptions = [{ value:'cawi', label:'CAWI'}, {value:'tawi', label:'TAWI'}, {value:'cati', label: 'CATI'}];
const externalLinksOptions = [ { value: 'required', label: 'Si requiere' }, { value:'noRequired', label: 'No requiere'}];
export const  CreateProject = () => {

    const [ project, setProject] = useState({
        name: '',
        nickname:'',
        client: '',
        sample:'',
        programming:'internal',
        externalLinks:'noRequired',
        in_balancer: 'yes',
        ir:'',
        loi:'',
        points:'',
        incentives:'',
        refenceSurvey_id:'',
        status: 'testmode',
        expired_at: moment().utc(true).format("YYYY-MM-DD HH:mm"),
        measuringTool: 'cawi'
    });
    const { data:responseSurvey, isLoading } = useQuery(['surveys'], getSurveys);
    const { data:responseClients, isLoading:isLoadingClient } = useQuery('clientsSio', getClientsSio );

    const { mutate:mCreateProject, error:erroCreate, isError:isErrorCreate} = useMutation( createProject ,{
        onSuccess: () => { Swal.fire('Crear proyecto', 'Se a generado un nuevo proyecto', 'success').then( result => window.location.replace('/proyecto')) },
        onError: (e) => {
            Swal.fire('Crear proyecto','No se pudo generar el nuevo proyecto', 'error')
        }
    });

    const handleCreateProject = (e) => {
        e.preventDefault();
        Swal.fire({
            title: '¿Quieres generar este proyecto?',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar',
            icon:'info'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                console.log(project);
                mCreateProject(project);
            } else if (result.isDenied) {
                Swal.fire('Crear proyecto', 'No se pudo generar el nuevo proyecto', 'error') 
            }
        })
    }
    return (
        <>
            <div className="row">
            <div className="col-xl-12 col-lg-12">
                <div className="card mb-4">
                    {/* Card Header - Dropdown */}
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <div className="content-head-card">
                            <label htmlFor="">
                                <a href="/proyecto"><strong>Mostrar proyectos</strong></a> / <a><strong>Crear proyecto</strong></a>
                            </label>
                        </div>
                    </div>
                    {/* Card Body */}
                    <div className="card-body">
                        <form onSubmit={  handleCreateProject } method="POST">
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Nombre proyecto</label>
                                        <input type="text" className="form-control" id="name-project-id" placeholder="Nombre del proyecto" name="name" value={ project.name } onChange={ (e) => setProject({ ...project, name:e.target.value })} />
                                        {
                                            isErrorCreate && (
                                                <p className="text text-danger">{erroCreate.data.errors?.name?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Nickname</label>
                                        <input type="text" className="form-control" id="name-project-id" placeholder="Nombre ficticio del proyecto" name="name" value={ project.nickname } onChange={ (e) => setProject({ ...project, nickname:e.target.value })} />
                                        {
                                            isErrorCreate && (
                                                <p className="text text-danger">{erroCreate.data.errors?.nickname?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        {
                                            (!isLoadingClient) ? (
                                                <>
                                                <label htmlFor="" className="form-label">Cliente</label>
                                                <Select
                                                isClearable
                                                className="basic-select"
                                                options= { responseClients.status == 200 ? responseClients.data.map( x => ({ value: x.nombre, label: x.nombre })) : []}
                                                onChange = { (e) => {
                                                    setProject({...project, client: e.value})
                                                }}
                                                />
                                                {
                                                    isErrorCreate && (
                                                        <p className="text text-danger">{erroCreate.data.errors?.client?.map( x => x)}</p>
                                                    )
                                                }
                                                </>
                                            ) : ( <div className="text text-success">Cargando clientes</div>)
                                        }
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="exampleFormControlInput2" className="form-label">Muestra requerida</label>
                                        <input type="number" className="form-control" id="sample-project-id" placeholder="N del proyecto" name="sample" value={ project.sample } onChange={ (e) => setProject({ ...project, sample:e.target.value })} />
                                        {
                                            isErrorCreate && (
                                                <p className="text text-danger">{erroCreate.data.errors?.sample?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="" className="form-label">Tipo de programación</label>
                                        <Select
                                            className="basic-select"
                                            defaultValue = {  programmingOptions.filter(x => x.value == project.programming)}
                                            options= { programmingOptions }
                                            onChange = { (e) => {
                                                setProject({ ...project, programming : e.value })
                                            }}
                                        />
                                        {
                                            isErrorCreate && (
                                                <p className="text text-danger">{erroCreate.data.errors?.programming?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="" className="form-label">¿Require listado tokens o base específica ?</label>
                                        <Select 
                                            className="basic-select"
                                            defaultValue = {  externalLinksOptions.filter(x => x.value == project.externalLinks)}
                                            options= { externalLinksOptions }
                                            onChange = { (e) => {
                                                setProject({ ...project, externalLinks : e.value })
                                            }}
                                        />
                                        {
                                            isErrorCreate && (
                                                <p className="text text-danger">{erroCreate.data.errors?.externalLinks?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                    <div className="col-md-6 ">
                                        <label htmlFor="" className="form-label">Instrumento de medición</label>
                                        <Select 
                                            className="basic-select"
                                            defaultValue = {  measuringToolOptions.filter(x => x.value == project.measuringTool)}
                                            options= { measuringToolOptions }
                                            onChange = { (e) => {
                                                setProject({ ...project, measuringTool : e.value })
                                            }}
                                        />
                                        {
                                            isErrorCreate && (
                                                <p className="text text-danger">{erroCreate.data.errors?.measuringTool?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Tasa de incidencia</label>
                                        <input type="number" className="form-control"  id="ir-project-id" placeholder="IR" name="ir" value={ project.ir } onChange={ (e) => setProject({ ...project, ir:e.target.value })} />
                                        {
                                            isErrorCreate && (
                                                <p className="text text-danger">{erroCreate.data.errors?.ir?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Tiempo de duración</label>
                                        <input type="number" className="form-control"  id="duration-project-id" placeholder="Duración" name="loi" value={ project.loi }  onChange={ (e) => setProject({ ...project, loi:e.target.value })}/>
                                        {
                                            isErrorCreate && (
                                                <p className="text text-danger">{erroCreate.data.errors?.loi?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Puntos</label>
                                        <input type="number" className="form-control"  id="points-project-id" placeholder="Puntos" name="points" value={ project.points } onChange={ (e) => setProject({ ...project, points:e.target.value })}/>
                                        {
                                            isErrorCreate && (
                                                <p className="text text-danger">{erroCreate.data.errors?.points?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Incentivos</label>
                                        <input type="text" className="form-control" id="incentive-projects-id" placeholder="Nombre del proyecto" name="incentive" value={ project.incentives } onChange={ (e) => setProject({ ...project, incentives:e.target.value })} />
                                        {
                                            isErrorCreate && (
                                                <p className="text text-danger">{erroCreate.data.errors?.incentives?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        {
                                            (!isLoading) ? (
                                                <>
                                                <label htmlFor="" className="form-label">Asociar programación</label>
                                                <Select
                                                isClearable
                                                className="basic-select"
                                                options= { responseSurvey.status == 200 ? responseSurvey.data.map( x => ({ value: x.refenceSid, label: x.surveyls_title })) : []}
                                                onChange = { (e) => {
                                                    setProject({...project, refenceSurvey_id: e.value})
                                                }}
                                                />
                                                {
                                                    isErrorCreate && (
                                                        <p className="text text-danger">{erroCreate.data.errors?.refenceSurvey_id?.map( x => x)}</p>
                                                    )
                                                }
                                                </>
                                            ) : ( <div className="text text-success">Cargando encuestas</div>)
                                        }
                                    </div>
                                    <div className="col-md-6">
                                    <label htmlFor="" className="form-label">Fecha de término</label>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
                                        <DateTimePicker
                                            ampm={false}
                                            className="form-control"
                                            inputFormat="YYYY-MM-DD HH:mm"
                                            value={project.expired_at}
                                            onChange={ (e) => {
                                                setProject({...project, expired_at: moment(e).utc(true).format('YYYY-MM-DD HH:mm:ss')}) 
                                            }}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                        />
                                    </LocalizationProvider>
                                        {
                                            isErrorCreate && (
                                                    <p className="text text-danger">{erroCreate.data.errors?.expired_at?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className='col-md-6'>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="in_balancer"
                                                checked={ project.in_balancer == 'yes' ? true : false }
                                                onChange={ (e) => setProject({ ...project, in_balancer: e.target.checked ? 'yes' : 'no' }) }
                                            />
                                            <label className="form-check-label" htmlFor="in_balancer">
                                            ¿Incluir en balanceador?
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                            </div>

                            <div className="form-group mt-2">
                                {
                                    isLoading ? (<button className="btn btn-primary-oo">Cargando...</button>)
                                    : (<button className="btn btn-primary-oo">Crear proyecto</button>)
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
if (document.getElementById('jsx-create-project')) {
    const el = document.getElementById("jsx-create-project");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
          <CreateProject { ...props } />
      </App>
  )
}