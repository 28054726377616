import { useMemo } from "react";
import { ModalLayout } from "../../../ui/modal/ModalLayout";
import { AmbassadorResumeModalBody } from "./AmbassadorResumeModalBody";

export const AmbassadorResume = ({ setSeeModal, panelist }) => {

    const AmbassadorResumeHeader = useMemo(() => {
        return (
            <div>
                <h3>Resumen Embajador</h3>
                <h6 className="text-muted"><b>Nombre: </b>{panelist?.name}</h6>
                <h6 className="text-muted"><b>Email: </b>{panelist?.email}</h6>
                <h6 className="text-muted"><b>Token: </b>{panelist?.token}</h6>
            </div>
        )
    }, [panelist]);

    return (
        <ModalLayout
            modalClass="modal-survey-page"
            closeFunction={() => {
                setSeeModal(prevSeeModal => ({
                    ...prevSeeModal,
                    ambassadorResume: !prevSeeModal.ambassadorResume,
                }));
            }}
            header={AmbassadorResumeHeader}
            body={<AmbassadorResumeModalBody panelist={panelist} />}
        />
    )
}