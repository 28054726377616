import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useGetGeographiesAjax, useTagsGeo } from '../../../../hooks/useGeography';
import { PLATFORM_MAIL_OPTIONS } from '../../../../helper/friend-points';
import { useFriendPointStore, useFriendPointsStore } from '../../../../store/FriendPointsStore/useFriendPointsStore';


export const CreateFriendPointModal = ({ seeModal, setSeeModal }) => {
    const { friendPoint,setFriendPoint,createFriendPoint, isError, error, resetFriendPoint } = useFriendPointStore((state) => state);
    const { fetchFriendPoints } = useFriendPointsStore((state) => state);
    const [ platformMailSelect,setPlatformMailSelect ] = useState({ value: "MAILJET", label: "Mailjet" });
    const [ selectGender, setSelectGender ] = useState(
        { options: [ { label: 'Mujer', value: 'F' }, { label: 'Hombre', value: 'M' } ] }
    );
    const { isLoading:isLoadingTag, selectTagsGeo, setSelectTagsGeo } = useTagsGeo();
    const { isLoading:isLoadingGeo, setEvent, setSelectGeographies, selectGeographies } = useGetGeographiesAjax();

    const handleCreateFriendPoint = (e) => {
        e.preventDefault();
        Swal.fire({
            title: '¿Quieres crear esta distribución de puntos de referidos?',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar',
            icon:'info'
        }).then((result) => {
            if(result.isConfirmed){
                createFriendPoint(friendPoint).then((resp) => {
                    //resp => es el response.status del fetch
                    if(resp == 200) {
                        Swal.fire("Creado","La distribución de puntos se ha creado con éxito","success")
                        .then(() => {
                            resetFriendPoint();
                            setSeeModal({ create: !seeModal.create, edit: false });
                            fetchFriendPoints();
                        });
                    }else {
                        Swal.fire("Error al crear","No se ha creado la distribución de puntos",'error');
                    }
                });
            }else {
                Swal.fire("Error al crear","No se ha creado la distribución de puntos",'error');
            }
        })
    }

    useEffect(()=>{
        console.log(friendPoint)
    },[friendPoint])

    return (
        <div className="modal modal-segmentation">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Crear distribución de puntos</h5>
                        <button  className="close close-modal-btn" data-dismiss="modal" aria-label="Close"
                            onClick={ () => {
                                setSeeModal({ create: !seeModal.create, edit: false });
                                resetFriendPoint();
                            }} >
                            <i className="fa fa-times-circle"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={ handleCreateFriendPoint } method="POST">
                            <div className='row' >
                                <div className='form-group col-12 col-md-6'>
                                    <label className="my-1 fw-bold">Descripción</label>
                                    <input type="text" name="description" className="form-control" id="description-input"
                                        onChange={ (e) => setFriendPoint({ ...friendPoint, description: e.target.value })}
                                        placeholder="Escribe una descripción"
                                        value={friendPoint.description}
                                    />
                                    {
                                        isError && (
                                            <p className="text text-danger">{error?.data?.errors?.description?.map( x => x)}</p>
                                        )
                                    }
                                </div>
                                <div className='form-group col-12 col-md-6'>
                                    <label className="my-1 fw-bold">Puntos</label>
                                    <input type="number" name="points" className="form-control" id="points-input"
                                        onChange={ (e) => setFriendPoint({ ...friendPoint, points: e.target.value })}
                                        value={friendPoint.points}
                                        min={0}
                                    />
                                    {
                                        isError && (
                                            <p className="text text-danger">{error?.data?.errors?.points?.map( x => x)}</p>
                                        )
                                    }
                                </div>
                            </div>
                            <div className='row' >
                                <div className='form-group col-12 col-md-6'>
                                    <label className="my-1 fw-bold">Plataforma de mailing</label>
                                    <Select
                                        className="basic-select"
                                        options={ PLATFORM_MAIL_OPTIONS }
                                        value = { platformMailSelect }
                                        onChange = { (e) => {
                                            setPlatformMailSelect({ value: e.value, label: e.label});
                                            setFriendPoint({...friendPoint,
                                                platformMail: { ...friendPoint.platformMail, platformMail: e.value }
                                            });
                                        }}
                                    />
                                    {
                                        isError && (
                                            <p className="text text-danger">{error?.data?.errors['platformMail.platformMail']?.map( x => x)}</p>
                                        )
                                    }
                                </div>
                                <div className='form-group col-12 col-md-6'>
                                    <label className="my-1 fw-bold">Id de plantilla</label>
                                    <input defaultValue={5812562} type="number" name="templateId" className="form-control" id="templateId-input"
                                        onChange={ (e) => {
                                            setFriendPoint({...friendPoint,
                                                platformMail: { ...friendPoint.platformMail, idPlatformMail: e.target.value }
                                            });
                                        }}
                                    />
                                    {
                                        isError && (
                                            <p className="text text-danger">
                                                {error?.data?.errors['platformMail.idPlatformMail']?.map( x => x)}
                                            </p>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row">
                                    <div className="form-group col-md-6">
                                        <label className="my-1 fw-bold">Género</label>
                                        <Select
                                            key={'djasdhvashds'}
                                            isMulti={true}
                                            isClearable
                                            className="basic-multi-select"
                                            options={ selectGender.options }
                                            value = { selectGender.selected }
                                            onChange  = { (e) => {
                                                setSelectGender({...selectGender,selected: e})
                                                setFriendPoint({ ...friendPoint, payload: {
                                                    ...friendPoint.payload,
                                                    gender: e.map(x => ({ id: x.value, name: x.label }))}
                                                })
                                            }}
                                        />
                                        {
                                            isError && (
                                                <p className="text text-danger">{error?.data?.errors['payload.gender']?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="my-1 fw-bold">Zonas geograficas</label>
                                        {
                                            isLoadingTag ? ( <Skeleton />)
                                            : (
                                                <>
                                                <Select
                                                    className="basic-select"
                                                    options={ selectTagsGeo.options }
                                                    value = {  selectTagsGeo.selected.length > 0 ? selectTagsGeo.selected : undefined }
                                                    onChange  = { (e) => {
                                                        setSelectTagsGeo({ ...selectTagsGeo, selected: e});
                                                        setFriendPoint({ ...friendPoint, payload: {
                                                                ...friendPoint.payload,
                                                                geographyLevel: e.value,
                                                                geographyData:[]
                                                            }
                                                        });
                                                        setSelectGeographies({ ...selectGeographies, selected:[]})
                                                        setEvent(e);
                                                    }}
                                                />
                                                {
                                                    isError && (
                                                        <p className="text text-danger">{error?.data?.errors['payload.geographyLevel']?.map( x => x)}</p>
                                                    )
                                                }
                                                </>
                                            )
                                        }
                                    </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label className="my-1 fw-bold">Seleccionar { selectTagsGeo.selected.label } </label>
                                    {
                                        isLoadingGeo ? (<Skeleton />)
                                        : (
                                            <>
                                            <Select
                                                isMulti
                                                className="basic-multi-select"
                                                options={ selectGeographies.options  }
                                                value = {  selectGeographies.selected }
                                                onChange = { (e) => {
                                                    setFriendPoint({...friendPoint, payload: {
                                                            ...friendPoint.payload,
                                                            geographyData: e.map( x => ({ id: x.value, name: x.label }))
                                                        }
                                                    })
                                                    setSelectGeographies({...selectGeographies, selected: e})
                                                }}
                                            />
                                            {
                                                isError && (
                                                    <p className="text text-danger">
                                                        {error?.data?.errors['payload.geographyData']?.map( x => x)}
                                                    </p>
                                                )
                                            }
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label className="my-1 fw-bold">Edad mínima</label>
                                    <input type="number" name="agemin" className="form-control" id="agemin"
                                        onChange={ (e) => setFriendPoint({ ...friendPoint, payload: {
                                                ...friendPoint.payload,
                                                age: { ...friendPoint.payload.age, min: e.target.value }
                                            }
                                    })}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="my-1 fw-bold">Edad máxima</label>
                                    <input type="number" name="agemax" className="form-control" id="agemax"
                                        onChange={ (e) => setFriendPoint({ ...friendPoint, payload: {
                                                ...friendPoint.payload,
                                                age: { ...friendPoint.payload.age, max: e.target.value }
                                            }
                                    })}/>
                                </div>
                                <div className='col-12'>
                                    { isError && (
                                        <ul className='text-danger m-0 p-1'>
                                            { error?.data?.errors['payload.age']?.map( (x,i) => <li key={x+i}>{x}</li> ) }
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="form-group col-md-12">
                                <button
                                    type='submit'
                                    className="btn btn-primary-oo"
                                >
                                    Crear puntos
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}