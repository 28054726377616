import React, { useContext, useMemo }  from 'react';
import { MaterialReactTable } from 'material-react-table';


export const RegisterTable = ({ registers }) => {
    const columns = useMemo( () =>[
        {
            accessorKey:'date',
            header: 'Fecha registro',
        },{
            accessorKey:'registered',
            header:'Registrados'
        },{
            accessorKey:'profiled',
            header:'Perfilados',
        },{
            header:'Tasa de perfilados',
            Cell:({ cell, column, table, row }) => {
                let _calc = row.original.registered > 0 ? Math.round((row.original.profiled  / row.original.registered)*100) : "-";
                return `${ _calc }%`
            }
        }
    ],[])
    return (
        <>
            <MaterialReactTable
                columns={columns} 
                data={registers} 
                muiTablePaperProps={{
                    //customize paper styles
                    sx: {
                        boxShadow: 'none !important',
                        border: 'none',
                    },
                }}
                enableColumnActions={false}
                enableColumnFilters={true}
                enablePagination={false}
                enableSorting={false}
                enableBottomToolbar={false}
                enableTopToolbar={true}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableFilters={false}
                enableHiding={false}
            />
        </>

    )
}