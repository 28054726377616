import { axiosApi } from "../axiosApi";

export const acceptAmbassadorCandidate = async (candidate) => {
    try {
        const response = await axiosApi.put('/api/marketing/ambassadors/accept-ambassador-candidate', candidate);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const rejectAmbassadorCandidate = async (candidate) => {
    try {
        const response = await axiosApi.put('/api/marketing/ambassadors/reject-ambassador-candidate', candidate);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const cancelAmbassador = async (ambassador) => {
    try {
        const response = await axiosApi.put('/api/marketing/ambassadors/cancel-ambassador', ambassador);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getAmbassadorValidRecruits = async (filter) => {
    try {
        const response = await axiosApi.post('/api/marketing/ambassadors/ambassador/valid-recruits', filter);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};
export const getAmbassadorRecruitsCompletedSurveys = async (filter) => {
    try {
        const response = await axiosApi.post('/api/marketing/ambassadors/ambassador/recruits-completed-surveys', filter);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};
export const getDeliveredRewards = async (filter) => {
    try {
        const response = await axiosApi.post('/api/marketing/ambassadors/ambassador/delivered-rewards', filter);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};