(function($) {
  "use strict"; // Start of use strict

  // var media = window.matchMedia("(min-width: 768px)");
  // if(!media.match){
  //   $("body").addClass("sidebar-toggled");
  //   $(".sidebar").addClass("toggled");
  //   $('.sidebar .collapse').collapse('hide');
  // }
  // // Toggle the side navigation
  $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $('.sidebar .collapse').collapse('hide');
    };
  });

  if ($(window).width() < 480 && !$(".sidebar").hasClass("toggled")) {
    $("body").addClass("sidebar-toggled");
    $(".sidebar").addClass("toggled");
    $('.sidebar .collapse').collapse('hide');
  };

  // Close any open menu accordions when window is resized below 768px
  $(window).resize(function() {
    if ($(window).width() < 768) {
      $('.sidebar .collapse').collapse('hide');
      $("body").removeClass("sidebar-toggled");
      $(".sidebar").removeClass("toggled");
    };
    
    // Toggle the side navigation when window is resized below 480px
    if ($(window).width() < 640 && !$(".sidebar").hasClass("toggled")) {
      $("body").addClass("sidebar-toggled");
      $(".sidebar").addClass("toggled");
      $('.sidebar .collapse').collapse('hide');
    };
  });

  $(window).resize(function() {
    console.log('resize');
  });

  // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
  $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function(e) {
    if ($(window).width() > 768) {
      var e0 = e.originalEvent,
        delta = e0.wheelDelta || -e0.detail;
      this.scrollTop += (delta < 0 ? 1 : -1) * 30;
      e.preventDefault();
    }
  });

  // Scroll to top button appear
  $(document).on('scroll', function() {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
      $('.scroll-to-top').fadeIn();
    } else {
      $('.scroll-to-top').fadeOut();
    }
  });

  /*Hay un error con el JQuery easing, comentar las lineas de abajo mantiene el scroll-to-top pero sin efecto easing */
  // Smooth scrolling using jQuery easing
  // $(document).on('click', 'a.scroll-to-top', function(e) {
  //   var $anchor = $(this);
  //   $('html, body').stop().animate({
  //     scrollTop: ($($anchor.attr('href')).offset().top)
  //   }, 1000, 'easeInOutExpo');
  //   e.preventDefault();
  // });

})(jQuery); // End of use strict
