import { getTransactionDelivered, getTransactionsProducts, getTransactionsProductsForDate, getTransactionsTotales } from "../../api/reports/transactionStore";

const ERRORS = { status: false, message: '' }
export const useBoundFetchTransactionStore = (set, get) => {
    return {
        // Obtenemos los totales de los canjes realizados
        fetchTotales: async () => {
            set( {
                loadings: { ...get().loadings, transactionsTotales: true  },
                errors: { ...get().errors,  transactionsTotales: ERRORS }
            });
            try{
                const { data, status } = await getTransactionsTotales(get().preset);
                if(status == 204){
                    set( {
                        errors: { ...get().errors,  transactionsTotales: { status: true, message: 'No hay datos disponibles para la visulización'} },
                        data: { ...get().data, transactionsTotales: [] }
                    })
                }else{
                    set({ data: { ...get().data, transactionsTotales: data } });
                }
            set({ loadings: { ...get().loadings, transactionsTotales: false  }})
            }catch(error){
                console.log(error);
            }
        },
        // Obtenemos los productos canjeados.
        fetchTransactionsProducts: async () => {
            set( {
                loadings: { ...get().loadings, products: true  },
                errors: { ...get().errors,  products: ERRORS }
            });
            try{
                const { data, status } = await getTransactionsProducts(get().preset);
                if(status == 204){
                    set( {
                        errors: { ...get().errors,  products: { status: true, message: 'No hay datos disponibles para la visulización'} },
                        data: { ...get().data, products: [] }
                    })
                }else{
                    set({ data: { ...get().data, products: data } });
                }
            set({ loadings: { ...get().loadings, products: false  }})
            }catch(error){
                console.log(error);
            }
        },
        // Obtenemos los productos canjeados.
        fetchTransactionsProductsForDate: async () => {
            set( {
                loadings: { ...get().loadings, productsForDate: true  },
                errors: { ...get().errors,  productsForDate: ERRORS }
            });
            try{
                const { data, status } = await getTransactionsProductsForDate(get().preset);
                if(status == 204){
                    set( {
                        errors: { ...get().errors,  productsForDate: { status: true, message: 'No hay datos disponibles para la visulización'} },
                        data: { ...get().data, productsForDate: [] }
                    })
                }else{
                    set({ data: { ...get().data, productsForDate: data } });
                }
            set({ loadings: { ...get().loadings, productsForDate: false  }})
            }catch(error){
                console.log(error);
            }
        },

        // Obtenemos los productos entregados.
        fetchTransactionDelivered: async() => {
            set( {
                loadings: { ...get().loadings, transactionDelivered: true  },
                errors: { ...get().errors,  transactionDelivered: ERRORS }
            });
            try{
                const { data, status } = await getTransactionDelivered(get().preset);
                if(status == 204){
                    set( {
                        errors: { ...get().errors,  transactionDelivered: { status: true, message: 'No hay datos disponibles para la visulización'} },
                        data: { ...get().data, transactionDelivered: [] }
                    })
                }else{
                    set({ data: { ...get().data, transactionDelivered: data } });
                }
            set({ loadings: { ...get().loadings, transactionDelivered: false  }})
            }catch(error){
                console.log(error);
            }
        }


    }
}