export const STATUS_TRANSACTIONS = {
    'accepted': 'En proceso',
    'process': 'Proceso de compra',
    'rejected': 'Rechazado',
    'delivered': 'Entregado',
    // 'underReview': 'En revisión',
}

export const CURRENCY = {
    'CL' : 'CLP',
    'PE' : 'PEN',
    'CO' : 'COP'
}