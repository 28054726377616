import React, { useState } from 'react';
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud'
import { COLORS } from '../../../../../helper/COLOR';
import styles from './styles.module.css';
export const TextQuestion = ({ data }) => {
    const [words, setWords] = useState(data.words.length == 0 ? [] : data.words);
    console.log(words);
    //Generamos rotación de las palabras
    const getRotationDegree = () =>  {
        const rand = Math.random();
        const degree = rand > 0.5 ? 60 : -60;
        return rand * degree;
    }
    const colors = ['#143059', '#2F6B9A', '#82a6c2'];
    // Aplicamos la escala de las palabras
    const fontScale = scaleLog({
        domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
        range: [15, 100],
      });
    const fontSizeSetter = (datum) => fontScale(datum.value);
    const fixedValueGenerator = () => 0.5;

    return (
        <div className={styles.wordcloud}>
            {
                words.length > 0 ? (
                    <Wordcloud
                        words={words}
                        width={700}
                        height={700}
                        fontSize={fontSizeSetter}
                        font={'Impact'}
                        padding={2}
                        spiral={'archimedean'}
                        rotate={getRotationDegree}
                        random={fixedValueGenerator}
                    >
                        {(cloudWords) =>
                            cloudWords.map((w, i) => (
                            <Text
                                key={w.text}
                                fill={COLORS[i % COLORS.length]}
                                textAnchor={'middle'}
                                transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                                fontSize={w.size*0.8}
                                fontFamily={w.font}
                            >
                                {w.text}
                            </Text>
                            ))
                        }
                    </Wordcloud>
                ): (<h4>Sin variables de texto para la nuve de palabra</h4>)
            }
        
        </div>
    )
}