import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import { App } from "../../../../App";
import { BaseCard } from "./ui/BaseCard";
import { Alert, LinearProgress } from "@mui/material";
import { useBatchStore } from "../../../../store/utils/useBatchStore";
import { deleteOperationFromCache } from "../../../../api/sio/potentialBase";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import { useGetSurvey } from "../../../../hooks/useSurvey";
import { SidebarRight } from "../SidebarRight";
import { SegmentDescription } from "./ui/SegmentDescription";
import { AlertStack } from "./ui/AlertStack";
import { LoadingAvailables } from "./ui/LoadingAvailables";
import { LastInteractionSelect } from "./ui/LastInteractionSelect";
import { PortionInput } from "./ui/PortionInput";
import { TypeSubsetSelect } from "./ui/TypeSubsetSelect";
import { ProfileQuestionSelect } from "./ui/ProfileQuestionSelect";
import { useProfilesStore } from "../../../../store/ProfileStore/useProfilesStore";
import { usePotentialsFilterStore } from '../../../../store/Potentials/usePotentialsFilterStore';
import { useProject } from "../../../../hooks/useProject";
import { LoadExternalTokens } from "../externaltokens/LoadExternalTokens";
import { useLoadExternalTokens } from "../../../../store/ExternalTokens/useLoadExternalTokens";


export const PotentitalBaseProject = ({ id,segmentid }) => {
    const mediaQuery = useMediaQuery({ query:'(min-width:1000px)'});
    const [ loadAvailablesEnded, setLoadAvailablesEnded ] = useState({
        showAlert: false,
        loadedQuantity: 0
    });
    const intervalRef = useRef(5000);
    const intervalSubsetRef = useRef(1000);
    const { project } = useProject(id);
    const {
        participants,
        availables,
        segmentSubset,
        setSegmentSubset,
        toLoad,
        batchId,
        isLoadingPotentials,
        resetBatchId,
        fetchPotentials,
        fetchLoadAvailables,
        filter,
        isError,
        errorMessage,
        isLoadingLoadAvailables,
        fetchSegment,
        isLoadingSegmentation,
        isLoadingSubset,
        fetchAvailablesSubset,
        resetSegmentSubset
    } = usePotentialsFilterStore((state) => state);

    const {
        resetQuestionSelected,
        resetAnswersSelected
    } = useProfilesStore((state) => state);

    const { file, addFileToFormData } = useLoadExternalTokens((state) => state);

    const { survey, isLoading:isLoadingSurvey, isError:isErrorSurvey, error:errorSurvey } = useGetSurvey(id);

    const {
        progress,
        resetProgress,
        fetchProgress,
        isBatchStarted,
        setIsBatchStarted,
        isError: isErrorBatch,
        errorMessage: errorMessageBatch,
    } = useBatchStore((state) => state);

    const transformSegmentSubset = () => {
        let selected = {
            type: segmentSubset.type,
            projectId: id,
            segmentId: segmentid,
            lastinteraction: segmentSubset.lastinteraction.selected.value,
            portion: segmentSubset.portion,
            withProfile: segmentSubset.withProfile,
            profile: segmentSubset.profile
        };
        return selected;
    }

    const handleLoadAvailables = () => {
        //Esto inicia la carga de panelistas disponibles al estudio (lime_tokens)
        let selected = transformSegmentSubset();
        setIsBatchStarted(true);
        if (project.externalLinks == 'required' && file){
            selected.externalLinks = project.externalLinks;
            selected = addFileToFormData(file,selected);
            fetchLoadAvailables(selected,true);
        }else {
            fetchLoadAvailables(selected);
        }
    }

    const handleGetAvailablesSubset = () => {
        let selected = transformSegmentSubset();
        //Esto consulta la cantidad disponible a cargar segun selección
        fetchAvailablesSubset(selected);
    }

    const handleDeleteOperationFromCache = () => {
        //Eliminamos la cache de la operacion y la base de panelistas guardada.
        deleteOperationFromCache(id,segmentid);
    }

    const handleEndLoadAvailables = () => {
        //reiniciamos los estados y refrescamos la data
        clearInterval(intervalRef.current);
        clearInterval(intervalSubsetRef.current);
        setLoadAvailablesEnded({loadedQuantity: toLoad,showAlert: true});
        handleDeleteOperationFromCache();
        resetSegmentSubset();
        resetQuestionSelected();
        resetAnswersSelected();
        resetBatchId();
        resetProgress();
        setIsBatchStarted(false);
        fetchPotentials(filter);
    }

    const handleUpdateStoreOptions = () => {
        usePotentialsFilterStore.persist.setOptions({
            name: 'potentials-filter-'+id+'-'+segmentid
        });
        usePotentialsFilterStore.persist.rehydrate();
        localStorage.removeItem('potentials-filter');
        useBatchStore.persist.setOptions({
            name: 'batch-progress-'+id+'-'+segmentid
        });
        useBatchStore.persist.rehydrate();
        localStorage.removeItem('batch-progress');
    }

    useEffect(() => {
        //Se cambian las options del persist de datos (zustand), para que la key en local storage sea única por proyecto y segmento.
        handleUpdateStoreOptions();
        //Buscamos la data del segmento y seteamos el filter.
        fetchSegment(segmentid);
    }, [id,segmentid]);

    //Seteamos un nuevo nombre del storage y rehidratamos para actualizar
    useEffect(() => {
        //El filter ya debe estar seteado para ejecutar fetchPotentials, para ello validamos que el id no sea 0.
        if(!isLoadingSegmentation && filter.id !== 0){
            //Obtenemos la data si hay panelistas disponibles o los cargados del segmento.
            fetchPotentials(filter);
        }
    }, [isLoadingSegmentation]);

    //Obtenemos el progreso de la carga
    useEffect(() => {
        if (batchId == "") { return; }
        if (intervalRef.current != undefined) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            if (progress >= 0 && progress <= 100) {
                fetchProgress(batchId);
            }
        }, 5000);
        if (progress == 100) {
            handleEndLoadAvailables();
        }
    }, [progress,batchId]);

    //Obtenemos la cantidad de la subselección
    useEffect(() => {
        if(segmentSubset.withProfile && !segmentSubset.profile){ return; }
        if(!isLoadingPotentials) {
            if (intervalSubsetRef.current) {
                clearInterval(intervalSubsetRef.current);
            }
            intervalSubsetRef.current = setTimeout(() => {
                handleGetAvailablesSubset();
            }, segmentSubset.type == 'lastinteraction' ? 100 : 500);
        }
    }, [
        segmentSubset.type,
        segmentSubset.lastinteraction,
        segmentSubset.portion,
        segmentSubset.profile,
        segmentSubset.withProfile,
        isLoadingPotentials
    ]);

    useEffect(() => { if(isError && isBatchStarted){ setIsBatchStarted(false); } },[ isError ]);

    return (
        <>
        <div className="row">
            { !mediaQuery && !isLoadingSurvey && (<SidebarRight id={id} survey_status={survey.ls_status} />) }
            <div className="col-lg-9 mt-4">
                <div className="card">
                    <div className="card-header">
                        <div className="content-head-card">
                            <label>Cargar base potencial</label>
                        </div>
                    </div>
                    <div className="card-body">
                        {isErrorSurvey && <Alert severity="error" sx={{marginTop: '10px', marginBottom: '10px'}}>{errorSurvey}</Alert> }
                        {isLoadingPotentials ? <Skeleton height={500} />
                        : (
                        <>
                            <div className="card border rounded shadow">
                                <div className="card-header fw-bold h6">Segmento seleccionado </div>
                                <div className="card-body">
                                    <SegmentDescription segment={filter} />
                                </div>
                            </div>
                            <div className="card border rounded shadow mt-3 mt-md-4">
                                <div className="card-header fw-bold h6">Base potencial del segmento</div>
                                <div className="card-body">
                                    <div className="row">
                                        <BaseCard title={"Base Cargada"} quantity={participants} icon={"fa-database"} />
                                        <BaseCard
                                            title={"Base Disponible"}
                                            quantity={availables}
                                            icon={"fa-upload"}
                                        />
                                    </div>
                                </div>
                            </div>
                            {availables > 0 && (
                                <div className="card border rounded shadow mt-3 mt-md-4">
                                <div className="card-header fw-bold h6">{isBatchStarted ? 'Cargando...' : 'Confirmar carga de base'}</div>
                                {isBatchStarted &&
                                    <LinearProgress
                                        variant="indeterminate"
                                        sx={{
                                            backgroundColor: "#552C75",
                                            "& .MuiLinearProgress-bar": {backgroundColor: "#F3F3F3"}
                                        }}
                                    />
                                }
                                <div className="card-body">
                                    <div className="row">
                                    { isBatchStarted ? (
                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                            {isBatchStarted &&
                                                <LoadingAvailables toLoad={toLoad} availables={availables} progress={progress} />
                                            }
                                        </div>
                                    )
                                    : (
                                        <form method="POST">
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                <TypeSubsetSelect segmentSubset={segmentSubset} setSegmentSubset={setSegmentSubset} />
                                                </div>
                                                <div className="form-group col-md-6 my-2 my-md-0">
                                                    <label>{ segmentSubset.type == 'lastinteraction' ? 'Última actividad' : 'Cantidad (%)'}</label>
                                                    {segmentSubset.type == 'lastinteraction'
                                                        ? <LastInteractionSelect
                                                            segmentSubset={segmentSubset}
                                                            setSegmentSubset={setSegmentSubset}
                                                        />
                                                        : <PortionInput
                                                            segmentSubset={segmentSubset}
                                                            setSegmentSubset={setSegmentSubset}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="row my-2">
                                                <div className="form-group col-md-12">
                                                    <input
                                                        id="withProfile"
                                                        name="withProfile"
                                                        className="me-2"
                                                        type="checkbox"
                                                        value={segmentSubset.withProfile}
                                                        checked={segmentSubset.withProfile}
                                                        onChange={() => {
                                                                setSegmentSubset({
                                                                    ...segmentSubset,
                                                                    withProfile: !segmentSubset.withProfile,
                                                                    profile: null
                                                                })
                                                                if(segmentSubset.withProfile){
                                                                    resetQuestionSelected();
                                                                    resetAnswersSelected();
                                                                }
                                                            }
                                                        }
                                                    />
                                                    <label htmlFor="withProfile">¿Usar perfilamiento?</label>
                                                </div>
                                            </div>
                                            {segmentSubset.withProfile &&
                                                <ProfileQuestionSelect
                                                    segmentSubset={segmentSubset}
                                                    setSegmentSubset={setSegmentSubset}
                                                />
                                            }
                                            {availables > 0 && batchId == "" && !isLoadingLoadAvailables
                                            && (
                                                <div className="row d-flex justify-content-center align-items-center">
                                                    <div className="col-12">
                                                        { !isLoadingSubset ?
                                                        (<Alert severity="info" sx={{marginTop: '10px'}}>
                                                            Esta selección tiene {toLoad} panelistas
                                                        </Alert>)
                                                        : (
                                                        <Skeleton sx={{marginTop: '10px'}} height={40} />
                                                        )}

                                                        { project.externalLinks == 'required' && <LoadExternalTokens /> }

                                                        <button
                                                            type="submit"
                                                            className={`btn btn-primary-oo mt-3 ${ !mediaQuery
                                                                ? 'w-100'
                                                                : 'float-md-end' }`
                                                            }
                                                            onClick={ () => { handleLoadAvailables() }  }
                                                            disabled={(isLoadingSubset || toLoad == 0) || (project.externalLinks == 'required' && !file)}>
                                                            Cargar Panelistas
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </form>
                                    )}
                                    </div>
                                </div>
                            </div>
                            )}
                        </>
                        )}
                        <AlertStack
                            alerts={{isErrorBatch,errorMessageBatch,isError,errorMessage,loadAvailablesEnded}}
                            setLoadAvailablesEnded={setLoadAvailablesEnded}
                        />
                    </div>
                </div>
            </div>
            { mediaQuery && !isLoadingSurvey && (<SidebarRight id={id} survey_status={survey.ls_status} />) }
        </div>
        </>
    );
};

if (document.getElementById("jsx-project-potential-base")) {
    const el = document.getElementById("jsx-project-potential-base");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <PotentitalBaseProject {...props} />
        </App>
    );
}
