import React, { useContext }  from 'react';
import Skeleton from 'react-loading-skeleton';
import {Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DashboardContext } from '../../../context/DashboardContext';
import { useRegistersM } from '../../../hooks/useRegisterDashboard';
import { TotalRegister } from './TotalRegister';
import { RegisterTable } from '../../ui/table/RegisterTable';


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
            backgroundColor:"#FFF",
            padding:"10px",
            borderRadius:"10px",
            border:"solid 1px #d3d3d3"
        }}>
            <p style={{ color:"#3d3d3d"}}>{ label }</p>
            <p style={{ color:`${payload[0].color}`}}>{ payload[0].name }: { payload[0].value }</p>
            <p style={{ color:`${payload[1].color}`}}>{ payload[1].name }: { payload[1].value }  ({ Math.round((payload[1].value/payload[0].value*100),2) }%)</p>
        </div>
      );
    }
  
    return null;
  };
export const RegisterLineChart = () => {
    const {filter, setFilter} = useContext(DashboardContext);
    const {isLoading,registers} = useRegistersM(filter);
    return (
        <>
            {
                isLoading && !registers.length ? ( <Skeleton height={400} />) : (
                    !registers.length ? (<div className="alert__not-data">No se pudo encontrar la información <strong>REGISTROS</strong>, por favor intenta nuevamente modificando la fecha o refrescando la página</div>)
                    :(<>
                        <TotalRegister registers={ registers }/>
                        <RegisterTable registers={ registers } />
                        <div style={{ marginTop:'2rem'}}>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                width={500}
                                height={300}
                                data={registers}
                                >
                                <XAxis  
                                    dataKey="date" 
                                    textAnchor= "end" 
                                    sclaeToFit="true" 
                                    verticalAnchor= "start"  
                                    interval={Math.floor(registers.length/15 *0.5)} 
                                    angle= "-40"  
                                    height={50}  
                                    style={{fontSize:'12px'}} 
                                    axisLine={false} // Elimina la línea del eje Y
                                    tickLine={false} // Elimina las líneas de las marcas en el eje Y 
                                />
                                <YAxis 
                                    axisLine={false} // Elimina la línea del eje Y
                                    tickLine={false} // Elimina las líneas de las marcas en el eje Y 
                                />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend wrapperStyle={{paddingTop: "3.2rem"}} />
                                    <Line legendType="circle" type="monotone" dataKey="registered" stroke="rgb(33, 150, 243)"  name="Registrados" />
                                    <Line legendType="circle" type="monotone" dataKey="profiled" stroke="rgb(139, 195, 74)"  name="Perfilados"  />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </>)
                )
            }
        </>

    )
}