import React from 'react';
import { useBoundProfile } from '../../store/Profiles/useBoundProfiles';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDebounceFetchProfileData } from '../../hooks/useDebounceFetchProfileData';


export const OrderQuestion = () => {
    const { dataSelect, setter,fetchSearchData } = useBoundProfile( state => state );
    const { fetchData } = useDebounceFetchProfileData();
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleOnDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        let items = reorder(
            dataSelect,
            result.source.index,
            result.destination.index
        );
        items = items.map((item, key) => {
            return { ...item, order: key };
        });
        setter({ dataSelect: items });
        fetchData();
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        display: "flex",
        justifyContent: "space-between",
        fontSize: "12px",
        fontWeight: "bold",
        alignItems: "center",
        textAlign: "left",
        margin: "5px",
        padding: "5px",
        borderRadius: "10px",
        border: "solid 1px var(--bs-gray-300)",
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        // change background colour if dragging
        background: isDragging ? "var(--bs-gray-200)" : "var(--bs-gray-100)",
        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const removeItem = (question) => {
        let filter = dataSelect.filter( x => x.value !== question.value);
        setter({ dataSelect: filter });
        fetchSearchData()
    }

    return (
        <>
        <DragDropContext
            onDragEnd={(result) =>
                handleOnDragEnd(result)
            }
        >
            <div id="container-order">
                <Droppable droppableId="listOrder">
                    {(droppableProvided) => (
                        <ul
                            style={{
                                width: "100%",
                                textAlign: "center",
                                listStyle: "none",
                                padding: "0px",
                                border:'dashed 1px black',
                                borderRadius: '8px'
                            }}
                            ref={
                                droppableProvided.innerRef
                            }
                            {...droppableProvided.droppableProps}
                        >
                            {
                                dataSelect.length == 0  ? ( <div>Sin datos seleccionados</div> ): (
                                    dataSelect.map(
                                        (data, index) => (
                                            <Draggable
                                                key={data.value}
                                                draggableId={String(
                                                    data.value
                                                )}
                                                index={index}
                                            >
                                                {(
                                                    draggableProvided,
                                                    snapshot
                                                ) => (
                                                    <li
                                                        ref={
                                                            draggableProvided.innerRef
                                                        }
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            draggableProvided
                                                                .draggableProps
                                                                .style
                                                        )}
                                                    >
                                                        <span>
                                                            <span className="badge btn-primary-oo me-2">
                                                                {index +
                                                                    1}
                                                            </span>
                                                            {
                                                                data.label
                                                            }
                                                        </span>
                                                        <a onClick={ () =>   removeItem(data) }>
                                                        <i
                                                            className="fas fa-x"
                                                            style={{
                                                                cursor:'pointer',
                                                                padding:'5px',
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        ></i>

                                                        </a>
                                                    </li>
                                                )}
                                            </Draggable>
                                        )
                                    )
                                )
                            }
                            {
                                droppableProvided.placeholder
                            }
                        </ul>
                    )}
                </Droppable>
            </div>
    </DragDropContext>
    </>
    )
}