import { create } from "zustand";
import { usePanelistsOfMonthStore } from './usePanelistsOfMonthStore';
import { usePanelistsOfQuarterStore } from './usePanelistsOfQuarterStore';
import { usePanelistsOfSemesterStore } from './usePanelistsOfSemesterStore';
import { usePanelistsOfYearStore } from './usePanelistsOfYearStore';

import { dateTransform } from "../../helper/dateTransform";
import { DashboardPanelistsMonth } from "../../components/reports/panelists/DashboardPanelistsMonth";
import { DashboardPanelistsQuarter } from "../../components/reports/panelists/DashboardPanelistsQuarter";
import { DashboardPanelistsSemester } from "../../components/reports/panelists/DashboardPanelistsSemester";
import { DashboardPanelistsYear } from "../../components/reports/panelists/DashboardPanelistsYear";

export const useBoundPanelistsReportStore = create( (set,get) => {
    return {
        ...usePanelistsOfMonthStore(set,get),
        ...usePanelistsOfQuarterStore(set,get),
        ...usePanelistsOfSemesterStore(set,get),
        ...usePanelistsOfYearStore(set,get),
        errors:{
            status:false,
            message:''
        },
        preset:dateTransform['ofYear'],
        defaultRepresentation: [  
            { value: 'month', label: 'Mes', callback: () => <DashboardPanelistsMonth/>  },
            { value: 'quarter', label: 'Trimestre',  callback: () => <DashboardPanelistsQuarter/> },
            { value: 'semester', label: 'Semestre', callback: () => <DashboardPanelistsSemester/> },
            { value: 'year', label: 'Año', callback: () => <DashboardPanelistsYear/>  }
        ],
        defaultStatusProject:[{ value:'actived', label: 'En campo'}, { value:'expired', label: 'Cerrado' } ],
        representation:[
            { value: 'month', label: 'Mes', callback: () => <DashboardPanelistsMonth/>  },
            { value: 'quarter', label: 'Trimestre',  callback: () => <DashboardPanelistsQuarter/> },
            { value: 'semester', label: 'Semestre', callback: () => <DashboardPanelistsSemester/> },
            { value: 'year', label: 'Año', callback: () => <DashboardPanelistsYear/>  }
        ],
        statusProject:[{ value:'actived', label: 'En campo'}, { value:'expired', label: 'Cerrado' } ],
        setRepresentation: (_r) => set((state) => {
            return { ...state, defaultRepresentation: _r }
        }),
        setErrors: (status, message) => set((state) => ({...state, errors:{ status, message }})),
        setPreset: (_preset) => set((state) => ({...state, preset: _preset })),
        setStatusProject: (status) => set((state) => ({ ...state, defaultStatusProject: status })),
    }
})