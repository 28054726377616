import { axiosApi } from "../axiosApi";

export const getGeographiesWithLevel = async (filter) => {
    try {
        const response = await axiosApi.post(`/api/dashboard/referencia-geografica`,filter);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};