import { formatterNumber } from '../../../../helper/currencyFormatter';

export const YearTable = ({ responseYear }) => {

    return ( 
        <div className="table-responsive">
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>Año</th>
                        <th>Teórico</th>
                        <th>Completas</th>
                        <th>Completas(%)</th>
                        <th>Filtradas</th>
                        <th>Parciales</th>
                        <th>Total Base</th>
                        <th>T. Respuesta(%)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        responseYear.map((year,keyYear) => {
                        let _t = (((parseInt(year.completed) +  parseInt(year.filtered) + parseInt(year.totalLastPartial)) / parseInt(year.totalBase))*100).toFixed(2);
                        return (
                                <tr key={keyYear}>
                                    <td>{ year.year }</td>
                                    <td>{ formatterNumber(parseInt(year.sample)) }</td>
                                    <td>{ formatterNumber(parseInt(year.completed)) }</td>
                                    <td>{ ((parseInt(year.completed)/parseInt(year.sample))*100).toFixed(2) }%</td>
                                    <td>{ formatterNumber(parseInt(year.filtered)) }</td>
                                    <td>{ formatterNumber(parseInt(year.totalLastPartial)) }</td>
                                    <td>{ formatterNumber(parseInt(year.totalBase)) }</td>
                                    <td>{ _t }%</td>
                                </tr>  
                        ) 
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

