import { format } from "date-fns";
import { axiosApi } from "../axiosApi";

// Obtengo los totales de los canjes
export const getTransactionsTotales = async (preset = null) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/canjes/totales`);
    if(preset != null){
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

// Obtenemos los productos canjeados. 
export const getTransactionsProducts = async (preset = null) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/canjes/productos`);
    if(preset != null){
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

// Obtenemos los productos canjeados por fecha. 
export const getTransactionsProductsForDate = async (preset = null) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/canjes`);
    if(preset != null){
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}


// Obtenemos datos de los canjes entregado
export const getTransactionDelivered = async ( preset = null) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/canjes/entregados`);
    if(preset != null){
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}