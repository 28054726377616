import { useState } from "react";
import { useQuery } from "react-query";
import { getMonitor, getMonitores } from "../api/sio/monitor";

export const useGetMonitores = () => {
    const [monitores, setMonitores] = useState([]);

    const response = useQuery(['getMonitores'], getMonitores,{ 
        onSuccess: (response) => {
            const { data } = response;
            setMonitores(data);
        }
    });

    return {
        ...response, 
        monitores, setMonitores
    }
}

export const useGetMonitor = (id) => {
    const [monitor, setMonitor] = useState([]);

    const response = useQuery(['getMonitor', id],() =>  getMonitor(id),{ 
        onSuccess: (response) => {
            const { data } = response;
            setMonitor(data);
        }
    });

    return {
        ...response, 
        monitor, setMonitor
    }
}