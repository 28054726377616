import React, { useMemo } from 'react';
import { App } from '../../../App';
import ReactDOM from 'react-dom/client';
import { getVersionsLime } from '../../../api/sip/limesurvey';

import {MaterialReactTable} from 'material-react-table';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import { ButtonAction } from './form/ButtonAction';


export const VersionLime = () => {
    const { status, isLoading, data } = useQuery('version-lime', getVersionsLime);
    const columns = useMemo(
        () => [
          {
            accessorKey: 'platform', //access nested data with dot notation
            header: 'Plataforma',
          },
          {
            accessorKey: 'version',
            header: 'Versión',
          },
          {
            accessorKey: 'refenceDB',
            header: 'Referencia DB',
          },
          {
            accessorKey: 'status',
            header: 'Estado',
          },

          {
            header: 'Acciones',
            Cell: ( { cell, row }) => <ButtonAction  versionLime = { cell.row.original } /> 
          },
        ],
        [],
      );
    return (
        <>
            {
                isLoading ? ( <Skeleton  height={400}/>)
                :(
                    <div className="card">
                        <div className="card-header">
                        <div className="content-head-card">
                            <label>Versiones limesurvey</label>
                            <a className="btn btn-primary-oo" href="/limesurvey/crear">Crear versión</a>
                        </div>
                        </div>
                        <div className="card-body">
                            <MaterialReactTable 
                                columns={columns} 
                                data={data} 
                                muiTablePaperProps={{
                                    //customize paper styles
                                    sx: {
                                        boxShadow: 'none !important',
                                        border: 'none'
                                    },
                                }}
                                />
                        </div>
                    </div>
                )
            }
        </>
    )
}
if (document.getElementById('jsx-version-lime')) {
    const el = document.getElementById("jsx-version-lime");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <VersionLime { ...props } />
        </App>
    )
}
