import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';

import { useQuery } from 'react-query';
import {EditUserForm} from './form/EditUserForm';
import { ListPermissions } from './form/ListPermissions';
import { getUser } from '../../../api/sio/user';
import Skeleton from 'react-loading-skeleton';
import { App } from '../../../App';

export const EditUser = ({ id }) =>  {
    const [ user, setUser ] = useState(null);
    const { isLoading, isError } = useQuery( ['user'], () => getUser(id),{ onSuccess:  ( data  ) => setUser(data) });

    return (
        <>
        <div className="row">
            <div className="col-xl-6 col-lg-6 mt-2">
                <div className="card">
                    <div className='card-header'>
                        <label>
                            Editar usuario
                        </label>
                    </div>
                    <div className='card-body'>
                        {
                            isLoading || isError ?( <Skeleton height={400}/> ) : (
                                <EditUserForm
                                    setUser = { setUser }
                                    user={ user }
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 mt-2">
                <div className="card">
                    <div className='card-header'>Gestionar Permisos</div>
                    <div className='card-body'>
                        {
                            isLoading || isError ?( <Skeleton height={400}/> ) : (
                                <ListPermissions  user={ user } setUser={ setUser }/>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
if (document.getElementById('jsx-edit-user')) {
    const el = document.getElementById("jsx-edit-user");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
          <EditUser { ...props } />
      </App>
  )
}