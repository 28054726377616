import Skeleton from "react-loading-skeleton";
import { useBoundReportsProjectStore } from "../../../store/ReportsProjectStore/useBoundReportsProjectStore";
import { useEffect, useMemo, useState } from "react";
import {MaterialReactTable} from "material-react-table";
import { projectStatus } from "../../../helper/projectTransform";
import { v4 as uuidv4 } from 'uuid';
import { ExportToCsv } from "export-to-csv";
import { format } from "date-fns";

export const ReportsProjectsTable = () => {

    const [isExport, setIsExport] = useState(false);
    const columns = useMemo( () => [
        {
            header:'Dashboard',
            Cell:({row})=> {
                return <div className="text text-center"><a style={{ cursor:'pointer'}} href={`/proyecto/${row.original.id}/dashboard`} target="_blank"><i className="fa-solid fa-arrow-up-right-from-square"></i></a></div>
            },
            size:10
        },
        {  
            header:'Codigo oo', 
            accessorFn: (row) => {
                if(row.name == null){ return; }
                let _split = row.name.split(" ");
                return _split[0];
            },
        },
        {  
            accessorKey: 'name', 
            header:'Nombre', 
            Cell:({row}) => {
                return ( <a title={`${ row.original.name }`} href={`/proyecto/${row.original.id}/dashboard`} target="_blank">{ row.original.name.slice(0,50) + '...' }</a>)
            } ,
        },
        {  
            accessorKey: 'client', 
            header:'Cliente', 
        },
        { accessorKey: 'status', header:'Estado', Cell:({row}) => (<strong>{projectStatus[row.original.status]}</strong>) },
        { accessorKey: 'sample', header: 'Teorico.', Cell: ({ row }) => {
            return parseInt(row.original.sample,10) || 0;
        }},
        {  accessorKey: 'completed', header: 'Completas', Cell: ({ row }) => {
            return parseInt(row.original.completed,10) || 0;
        }},
        { accessorFn: (row) => { 
            let completed = parseInt(row.completed,10) || 0;
            let teorico = parseInt(row.sample,10) || 0;
            if(teorico == 0) return 0;
            return ((completed/teorico)*100).toFixed(2) + '%';
        }, header:'Completas %', Cell: ({ row }) => {
            let completed = parseInt(row.original.completed,10) || 0;
            let teorico = parseInt(row.original.sample,10) || 0;
            if(teorico == 0) return 0 + '%';
            return ((completed/teorico)*100).toFixed(2) + '%';
        }},
        { accessorFn: (row) => { 
            let completed =  parseInt(row.completed, 10) || 0;
            let filtered =  parseInt(row.filtered, 10) || 0;
            let parcial =  parseInt(row.totalLastPartial, 10) || 0;
            let totalBase =  parseInt(row.totalBase, 10) || 0;
            return (((completed+filtered+parcial)/totalBase)*100 || 0 ).toFixed(2) + '%';
        },header: 'Tasa Respuesta', Cell: ({ row }) => {
            let completed =  parseInt(row.original.completed, 10) || 0;
            let filtered =  parseInt(row.original.filtered, 10) || 0;
            let parcial =  parseInt(row.original.totalLastPartial, 10) || 0;
            let totalBase =  parseInt(row.original.totalBase, 10) || 0;
            return (((completed+filtered+parcial)/totalBase)*100 || 0 ).toFixed(2) + '%';
        }},
        { accessorKey:'ir', header: 'IR T.', Cell: ({ row }) => {
            return (parseInt(row.original.ir,10) || 0) + '%';
        }},
        { accessorFn: (row) => { 
            let completed =  parseInt(row.completed, 10) || 0;
            let filtered =  parseInt(row.filtered, 10) || 0;
            return ((completed/(completed+filtered))*100 || 0).toFixed(2) + '%';
        },header: 'IR', Cell: ({ row }) => {
            let completed =  parseInt(row.original.completed, 10) || 0;
            let filtered =  parseInt(row.original.filtered, 10) || 0;
            return ((completed/(completed+filtered))*100 || 0).toFixed(2) + '%';
        }},
        { accessorKey: 'filtered',header: 'Filtradas', Cell: ({ row }) => {
            return parseInt(row.original.filtered,10) || 0;
        }},
        { accessorKey: 'totalLastPartial', header: 'Parciales', Cell: ({ row }) => {
            return parseInt(row.original.totalLastPartial,10) || 0;
        }},
        { accessorKey: 'totalBase', header: 'Total base', Cell: ({ row }) => {
            return parseInt(row.original.totalBase,10) || 0;
        }},
    ],[])
    
    const handleExportData = () => {
        console.log(statusProject, preset);
        let _s = statusProject.selected.map(m => m.value).toString();
        let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/proyectos`);
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
        url.searchParams.set('status', _s);
        url.searchParams.set('download', 'ok');
        window.open(url,'_blank');
    }
    
    // const handleExportData = () => {
    //     setIsExport(true);
    //     const productionsMap = productions.map((production) => {
    //         const completed = parseInt(production.completed, 10) || 0;
    //         const filtered = parseInt(production.filtered, 10) || 0;
    //         const parcial = parseInt(production.parcial, 10) || 0;
    //         const totalBase = parseInt(production.totalBase, 10) || 0;
    //         const ir = ((completed / (completed + filtered)) * 100 || 0).toFixed(2) + '%';
    //         const responseRate = (((completed + filtered + parcial) / totalBase) * 100 || 0).toFixed(2) + '%';
    //         const ct = parseInt(production.sample,10) || 0;
    //         return {
    //             'Id': production.id,
    //             'Nombre': production.name,
    //             'Completas': completed,
    //             'Completas teorico': ct,
    //             'Filtradas': filtered,
    //             'Parciales': parcial,
    //             'IR': ir,
    //             'Total de base': totalBase,
    //             'Tasa respuesta': responseRate,
    //         };
    //     });
    //     const csvExporter = new ExportToCsv({
    //         filename: uuidv4(),
    //         fieldSeparator: ',',
    //         quoteStrings: '"',
    //         decimalSeparator: '.',
    //         showLabels: true,
    //         useBom: true,
    //         headers: Object.keys(productionsMap[0])
    //     }); 
    //     csvExporter.generateCsv(productionsMap);
    //     setIsExport(false);
    // }
  
    const {
        preset, 
        status:statusProject,
        productions,
        errors,
        loadingProductions,
        fetchProductionsStore
    }= useBoundReportsProjectStore( state => state);
    useEffect( () => {
        fetchProductionsStore()
    },[])

    
    return (
        <>
            <ItemsMetrics />
            {
                <MaterialReactTable
                    isClearable
                    columns={columns} 
                    data={productions}
                    muiToolbarAlertBannerProps={ errors.status ? { color: 'error', children: errors.message } : undefined }
                    muiTablePaperProps={{ sx: { boxShadow: 'none !important', border: 'none' } }}
                    enablePinning
                    initialState={{ 
                        columnPinning: { left: ['Dashboard','Codigo oo','name','client'] },
                        pagination: { pageSize: 100 }
                    }}
                    state={{
                        isLoading:loadingProductions,
                        showAlertBanner: errors.status,
                        density:"compact"
                    }}
                    enableColumnActions={false}
                    enableColumnFilters={true}
                    // enablePagination={false}
                    enableSorting={true}
                    // enableBottomToolbar={false}
                    // enableTopToolbar={true}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    // enableFilters={false}
                    enableHiding={false}
                    renderTopToolbarCustomActions={ ({table}) => {
                        return(
                                productions.length > 0 && (
                                    <a className="btn btn-primary-oo" onClick={!isExport ? handleExportData: undefined }><i className="fas fa-download"></i> { !isExport ? 'Descargar datos' : 'Generando descarga' }</a>
                                )
                        )
                    }}
                /> 
            }
        </>
    )
}

const ItemsMetrics = () => {
    const {
        productions,
        loadingProductions,
    }= useBoundReportsProjectStore( state => state);
    if(productions.length == 0){
       return; 
    }

    const completed = productions.reduce(function(a, e) {
        let value =  parseInt(e.completed, 10) || 0;
        return a + value;
    }, 0);

    const completedT= productions.reduce(function(a, e) {
        let value =  parseInt(e.sample, 10) || 0;
        return a + value;
    }, 0);
    const irT= productions.reduce(function(a, e) {
        let value =  parseInt(e.ir, 10) || 0;
        return a + value;
    }, 0);

    const filtered = productions.reduce(function(a, e) {
        let value =  parseInt(e.filtered, 10) || 0;
        return a + value;
    }, 0);

    const parcial = productions.reduce(function(a, e) {
        let value =  parseInt(e.totalLastPartial, 10) || 0;
        return a + value;
    }, 0);

    const totalBase = productions.reduce(function(a, e) {
        let value =  parseInt(e.totalBase, 10) || 0;
        return a + value;
    }, 0);

    return (
        <>
            {
                loadingProductions ? ( <Skeleton height={50} /> ) :(
                    <div className="table-responsive">
                        <table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th>Teorico</th>
                                    <th>Completas</th>
                                    <th>Completas %</th>
                                    <th>Tasa de respuesta</th>
                                    <th>Ir T.</th>
                                    <th>IR</th>
                                    <th>Filtradas</th>
                                    <th>Parciales</th>
                                    <th>Total base</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{ completedT }</th>
                                    <th>{ completed }</th>
                                    <th>{ ((completed/completedT)*100).toFixed(2) }%</th>
                                    <th>{ (((completed +parcial+filtered)/parseInt(totalBase))*100 || 0).toFixed(2) + '%' }</th>
                                    <th>{ (irT/productions.length).toFixed(2) }%</th>
                                    <th>{ ((completed/(completed+filtered))*100).toFixed(2) }%</th>
                                    <th>{ filtered }</th>
                                    <th>{ parcial }</th>
                                    <th>{ totalBase }</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }
        </>
    )
}