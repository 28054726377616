export const status =[
    {
        name: 'active',
        value: 40000,
    },{
        name: 'semiactive',
        value: 80000
    },
    {
        name: 'inactive',
        value: 70000
    }
]