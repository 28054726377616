import React from 'react'; 


const  vars = ['firstname','email','token','dni','birthdate', 'registration_at', 'age'];
export const VarInput = ({
    name,
    textInput,
    handleChange
}) => {
    return (
        <ul className="navbar-nav mx-3">
            <div className="topbar-divider d-none d-sm-block"></div>
            <li className="nav-item dropdown no-arrow">
                <a className="nav-link dropdown-toggle form-label" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-address-book"></i>
                </a>
                <div className="dropdown-menu" aria-labelledby="userDropdown">
                    {
                        vars.map(item => (
                            <a key={ item } className="dropdown-item" href="#" style={{ width:'none' }} onClick={ () => {
                                handleChange({ name: name, value: textInput, selected:`[[${item}]]`  })
                            }}>
                                {item}
                            </a>
                        ))
                    }
                </div>
            </li>   
        </ul>

    )
}