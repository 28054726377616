import React, { useContext, useMemo } from 'react';
import {MaterialReactTable} from 'material-react-table';
import {ItemsResultSurveyContext} from '../../../../../context/ItemsResultSurveyContext';
import { ExportToCsv } from 'export-to-csv';
import { Label } from 'recharts';

export const DescriptionMultipleOptionsQuestion = (props) => {
    const { children } = props.payload[0].payload;
    const data = children.map((v) => ({...v.props.payload, fill: v.props.fill}));
    const { items } = useContext(ItemsResultSurveyContext);
    const  columns = useMemo( () => [
        {
            header: '_',
            Cell: ({cell, row}) => {
                return (<div style={{ borderRadius: '1rem', padding:'0.5rem', width:'0.5rem', backgroundColor:row.original.fill}}></div>)
            },
            size: 10,
        },{
            accessorKey: 'question',
            header: 'Respuesta',
            size: '80'
        },{
            accessorKey: 'code',
            header: 'Código',
        },{
            accessorKey: 'total',
            header: 'Total',
            Cell:({ cell, row }) => {
                //let progress = Math.round((row.original.total/row.original.totalAnswer)*100);
                let progress = (row.original.total/row.original.totalAnswer)*100;
                progress = parseFloat(progress.toFixed(2));
                progress = isNaN(progress) ? 0 : progress;
                return (<label>{`${row.original.total}  (${progress}%)`}</label>)
            }
        },
    ],[]);

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
        filename: props.title
    };
    const csvExporter = new ExportToCsv(csvOptions);
    
    const mapRows = (rows) => {
        return rows.map(function(row, index){
            return ({'_': index, answer: row.original.question, code: row.original.code, total:row.original.total })
        });
    }

    const handleExportData = (rows) => {
        csvExporter.generateCsv(mapRows(rows));
    };

    return (
        <div style={{
            overflow:'auto',
            height:'100%'
        }}>
            <MaterialReactTable 
                columns={columns} 
                data={data} 
                initialState={{ density: 'compact' }}
                muiTablePaperProps={{
                    sx: {
                        boxShadow: 'none !important',
                        border: 'none',
                    },
                }}
                enableColumnActions={false}
                enableColumnFilters={true}
                enablePagination={false}
                enableSorting={true}
                enableBottomToolbar={false}
                enableToolbarInternalActions={false}
                muiTableBodyRowProps={{ hover: true }}
                renderTopToolbarCustomActions= {
                    ({table}) => (
                        <div style={{ display:'flex', gap:'5px' }}>
                            {/* <a 
                                style={{ backgroundColor: 'var(--bs-cyan)'}}
                                onClick={() => handleExportData(table.getPrePaginationRowModel().rows)}
                                className="btn btn-default">
                                <i className="fas fa-download m-1"></i>Descargar
                            </a> */}
                            {/* <a 
                                style={{ backgroundColor: 'var(--bs-gray-200)'}}
                                onClick={() => handleExportData(table.getPrePaginationRowModel().rows)}
                                className="btn btn-default">
                                <i className="fa-solid fa-up-right-from-square m-1"></i>Generar filtros
                            </a> */}
                        </div>
                    )
                }
            />

        </div>

    )
}