import axios from "axios";
import { axiosApi } from "../axiosApi"

export const getSegmentations = async(projectId) => {
    try {
        const   {data} = await axiosApi.get(`/api/segmentacion/listar/${projectId}`);
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
}

export const getSegmentation = async(id) => {
    try {
        const   {data} = await axiosApi.get(`/api/segmentacion/${id}`);
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
}

export const storeSegmentation = async (segmentation) => {
    try {
        const   {data} = await axiosApi.post('/api/segmentacion/guardar', segmentation);
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
}


export const editSegmentation = async(segmentation) => {
    try{
        const { data } = await axiosApi.put(`/api/segmentacion/${ segmentation.id }/editar`, segmentation);
        return data;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

export const cloneSegmentation = async (segmentation) => {
    try {
        const   {data} = await axiosApi.post('/api/segmentacion/duplicar', segmentation);
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }    
}

export const deleteSegmentation = async (id) => {
    try {
        const   {data} = await axiosApi.delete(`/api/segmentacion/eliminar/${id}`);
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }    
}

export const updateStatusSegmentationAjax = async (segmentation) => {
    try {
        const   {data} = await axiosApi.put(`/api/segmentacion/actualizar/estado/${segmentation.id}`, segmentation);
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }    
}