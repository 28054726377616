import React from 'react';
import { VarInput } from '../VarInput';
import { useBoundNotificationFcm } from '../../../store/NotificationFcm/useBoundNotificationFcm';



export const FcmCreateNotification = () => {

    const { notification,setterInput, saveNotificationFcm, errors } = useBoundNotificationFcm(state => state);
    const { name,title,description,isActionButton,titleButton,linkButton } = notification;
    const { notification:nErrors } = errors;
    const { errors:eNotification } = nErrors;
    const handleChange = ({ name, value, selected }) => {
        setterInput(name, value + selected);
    }

    const handleSaveNotification = (e) => {
        e.preventDefault();
        saveNotificationFcm();
    }

    return (
        <div>
            <form onSubmit={ handleSaveNotification }>
                <div className="col-md-12 mb-3">
                    <div style={{ display:'flex', alignItems:'center' }}>
                        <label htmlFor="forTitle" className="form-label"><strong>Nombre de la campaña</strong></label>
                        <VarInput name='name' textInput={ name } handleChange={ handleChange } />
                    </div>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="titleId" 
                        placeholder="Titulo de la notificación" 
                        name="name" 
                        value={ name ?? '' } 
                        onChange={ ({ target }) => {
                            const{ value, name:nameInput, type } = target;
                            setterInput(nameInput, value);
                        }  }/>
                        {
                            eNotification?.name && ( 
                                <label className='mt-2 text text-danger'>{ eNotification?.name[0] } </label>
                            )
                        }
                </div>
                <div className="col-md-12 mb-3">
                    <div style={{ display:'flex', alignItems:'center' }}>
                        <label htmlFor="forTitle" className="form-label"><strong>Titulo</strong></label>
                        <VarInput name='title' textInput={ title } handleChange={ handleChange } />
                    </div>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="titleId" 
                        placeholder="Titulo de la notificación" 
                        name="title" 
                        value={ title ?? '' } 
                        onChange={ ({ target }) => {
                            const{ value, name, type } = target;
                            setterInput(name, value);
                        }  }/>
                        {
                            eNotification?.title && ( 
                                <label className='mt-2 text text-danger'>{ eNotification?.title[0] } </label>
                            )
                        }
                </div>
                <div className="col-md-12 mb-3">
                    <div style={{ display:'flex', alignItems:'center' }}>
                        <label htmlFor="forTitle" className="form-label"><strong>Descripción</strong></label>
                        <VarInput name='description' textInput={ description } handleChange={ handleChange } />
                    </div>
                    <textarea 
                        className="form-control" 
                        id="descriptionId" 
                        rows="7" 
                        name='description'
                        value={ description ?? '' }
                        onChange={ ({ target }) => {
                            const{ value, name, type } = target;
                            setterInput(name, value);
                        }} 
                        style={{ resize:'none' }}>
                    </textarea>
                    {
                        eNotification?.description && ( 
                            <label className='mt-2 text text-danger'>{ eNotification?.description[0] } </label>
                        )
                    }
                </div>
                <div className="form-check form-switch col-md-12 mb-3">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        name='isActionButton'
                        role="switch" 
                        id="isActionButtonId"
                        checked={ isActionButton }
                        onChange={ ({ target }) => {
                            const{ value, name, type, checked } = target;
                            setterInput(name, type === 'checkbox' ? checked  : value);
                        }  }/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">¿La notificación debe contener un boton?</label>
                </div>

                {
                    (isActionButton)  && ( 
                        <>
                        <div className="col-md-12 mb-3">
                            <label htmlFor="forTitle" className="form-label"><strong>Titulo del boton</strong></label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="titleButtonId" 
                                placeholder="Titulo del boton" 
                                name="titleButton" 
                                value={ titleButton ?? '' } 
                                onChange={ ({ target }) => {
                                    const{ value, name, type } = target;
                                    setterInput(name, value);
                                }  }/>
                                {
                                    eNotification?.titleButton && ( 
                                     <label className='mt-2 text text-danger'>{ eNotification?.titleButton[0] } </label>
                                    )
                                }
                        </div>
                        <div className="col-md-12 mb-3">
                            <div style={{ display:'flex', alignItems:'center' }}>
                                <label htmlFor="forTitle" className="form-label"><strong>Enlace del boton</strong></label>
                                <VarInput name='linkButton' textInput={ linkButton } handleChange={ handleChange } />
                            </div>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="linkButtonId" 
                                placeholder="Enlace del boton" 
                                name="linkButton" 
                                value={ linkButton ?? '' } 
                                onChange={ ({ target }) => {
                                    const{ value, name, type } = target;
                                    setterInput(name, value);
                                }}/>
                                {
                                    eNotification?.linkButton && ( 
                                     <label className='mt-2 text text-danger'>{ eNotification?.linkButton[0] } </label>
                                    )
                                }
                        </div>
                        </>
                    )
                }
                <div className="col-md-12 mb-3" style={{ textAlign:'right' }}>
                    {
                        notification.status == 'draft' && (
                            <button className='btn btn-primary-oo'>Guardar</button>
                        )
                    }
                </div>
                
            </form>
        </div>
    )
}