import { Alert } from "@mui/material";
import Select from 'react-select';
import { useState } from "react";
import { KpiCardChart } from "./KpiCardChart";

export const KpiCard = ({
    kpiKey,
    title,
    filterTotal,
    todayTotal,
    chartData,
    total,
    baseColor = "#8884d8",
    isLoading = false,
    isError = { status: false, message: "" }
}) => {
    return <div key={'chart-card-' + kpiKey} className="card border rounded shadow-sm">
        <div className="card-body d-flex flex-column align-items-center pt-3 p-0">
            <span className="fw-bold text-muted mb-2">{title}</span>
            {/* <FilterSelect key={'select-' + kpiKey} /> */}
            {isError.status
                ? <Alert sx={{ margin: '10px' }} severity="error">{isError?.message}</Alert>
                : <KpiCardChart
                    isLoading={isLoading}
                    kpiKey={kpiKey}
                    chartData={chartData}
                    baseColor={baseColor}
                />
            }
            <div className="d-flex align-items-center my-3">
                <div className="d-flex flex-column text-muted text-center">
                    <span className="fs-4 fw-lighter">{todayTotal}</span>
                    <span className="small" style={{ marginTop: '-5px' }}>Hoy</span>
                </div>
                <div className="vr mx-2"></div>
                <div className="d-flex flex-column text-muted text-center">
                    <span className="fs-4 fw-lighter">{filterTotal}</span>
                    <span className="small" style={{ marginTop: '-5px' }}>Periodo</span>
                </div>
                <div className="vr mx-2"></div>
                <div className="d-flex flex-column text-muted text-center">
                    <span className="fs-4 fw-lighter">{total}</span>
                    <span className="small" style={{ marginTop: '-5px' }}>Total</span>
                </div>
            </div>
        </div>
    </div>
};