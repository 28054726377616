import React from 'react';
import {Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useDailyProject } from '../../../../hooks/useProject';
import Skeleton from 'react-loading-skeleton';
import { DailyProgressTable } from './DailyProgressTable';

export const DailyProgressLineChart = ({ id, hash }) => {

    const { isLoading, daily } = useDailyProject(id, hash);
    return ( 
        <>
            {
                isLoading ? ( <Skeleton height={300}/> ) :(
                    <div className="col-md-12 mt-4">
                        <div className='card card-header'>
                            <h5>Progreso diario</h5>
                        </div>
                        <div className="card card-body">
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={500}
                                    height={300}
                                    data = {daily}
                                >
                                    <XAxis dataKey="datestamp" textAnchor= "end" sclaeToFit="fale" verticalAnchor= "start"  interval={Math.floor(daily.length/15 *0.5)} angle= "-40" style={{fontSize:'12px'}} />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend wrapperStyle={{paddingTop: "3.2rem"}} />
                                        <Line legendType="circle" type="monotone" dataKey="completed"  stroke="rgb(139, 195, 74)" name="Completas" />
                                        <Line legendType="circle" type="monotone" dataKey="filtered" stroke="rgb(255, 152, 0)"  name="Filtradas"  />
                                        <Line legendType="circle" type="monotone" dataKey="parcial"  stroke="rgb(33, 150, 243)" name="Parciales" />
                                </LineChart>
                            </ResponsiveContainer>
                            <DailyProgressTable daily={daily} />
                        </div>
                    </div>
                ) 
            }
        </>

    )
}

