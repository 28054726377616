import React, { useMemo } from "react";
import ReactDOM from "react-dom/client";
import { App } from "../../../App";

import {MaterialReactTable} from "material-react-table";
import Skeleton from "react-loading-skeleton";
import { deletePanelExternal } from "../../../api/sio/panelExternal";
import { usePanelExternals } from "../../../hooks/usePanelExternal";
import { useMutation } from "react-query";
import Swal from "sweetalert2";

export const PanelExternal = () => {
    const { isLoading, panelExternals } = usePanelExternals();

    const { mutate: mdeletePanelExternal } = useMutation(deletePanelExternal, {
        onSuccess: () => {
            Swal.fire(
                "Eliminar panel externo",
                "Se a eliminado el panel",
                "success"
            ).then((result) => {
                location.replace("/panel-externo");
            });
        },
        onError: (e) => {
            Swal.fire(
                "Eliminar panel externo",
                "No se pudo eliminar el panel",
                "error"
            );
        },
    });

    const handleDeletePanelExternal = (e, id) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Quieres eliminar este panel externo?",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed) {
                mdeletePanelExternal(id);
            }
        });
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "name", //access nested data with dot notation
                header: "Nombre",
            },
            {
                accessorKey: "slug", //access nested data with dot notation
                header: "Slug",
            },
            {
                header: "Acciones",
                Cell: ({ cell }) => (
                    <>
                        <a
                            className="text"
                            title="Editar"
                            style={{
                                color: "var(--bs-warning)",
                                cursor: "pointer",
                                fontSize: "1.4em",
                            }}
                            href={`/panel-externo/${cell.row.original.id}/editar`}
                        >
                            <i className="fa fa-pen"></i>
                        </a>
                        <a
                            className="text"
                            title="Eliminar"
                            style={{
                                color: "var(--bs-danger)",
                                cursor: "pointer",
                                fontSize: "1.4em",
                                marginLeft: "15px",
                            }}
                            onClick={(e, id) =>
                                handleDeletePanelExternal(
                                    e,
                                    cell.row.original.id
                                )
                            }
                        >
                            <i className="fa fa-trash"></i>
                        </a>
                    </>
                ),
            },
        ],
        []
    );

    return (
        <>
            {isLoading ? (
                <Skeleton height={400} />
            ) : (
                <div className="card">
                    <div className="card-header">
                        <div className="content-head-card">
                            <label>Listar paneles</label>
                            <a
                                className="btn btn-primary-oo"
                                href="/panel-externo/crear"
                            >
                                Crear panel
                            </a>
                        </div>
                    </div>
                    <div className="card-body">
                        <MaterialReactTable
                            columns={columns}
                            data={panelExternals}
                            muiTablePaperProps={{
                                //customize paper styles
                                sx: {
                                    boxShadow: "none !important",
                                    border: "none",
                                },
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

if (document.getElementById("jsx-list-panelexternal")) {
    const el = document.getElementById("jsx-list-panelexternal");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <PanelExternal {...props} />
        </App>
    );
}
