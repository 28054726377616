import React, { useMemo, useState } from "react";
import ReactDOM from "react-dom/client";
import {MaterialReactTable} from "material-react-table";
import Skeleton from "@mui/material/Skeleton";
import { App } from "../../../App";
import { ConfigPanelCreate } from "./modals/ConfigPanelCreate";
import { useModal } from "../../../hooks/useModal";
import { ConfigPanelEdit } from "./modals/ConfigPanelEdit";
import { useMutation } from "react-query";
import { deletePanelExternalConfig } from "../../../api/sio/project";
import moment from "moment/moment";
import "moment/locale/es";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Alert, AlertTitle, ListItemIcon, MenuItem } from "@mui/material";
import { usePanelExternalsByProject } from "../../../hooks/usePanelExternal";
import { useMediaQuery } from "react-responsive";
import { SidebarRight } from "./SidebarRight";
import { useProject } from "../../../hooks/useProject";
import { panelConfigStatus } from "../../../helper/panelExternalTransform";
import { useGetSurvey } from "../../../hooks/useSurvey";

export const PanelExternal = ({ id }) => {
    const mediaQuery = useMediaQuery({ query: "(min-width:1000px)" });
    const { survey, isLoading:isLoadingSurvey, isError:isErrorSurvey, error:errorSurvey } = useGetSurvey(id);
    const { showModal, setShowModal } = useModal();
    const [panelExternal, setPanelExternal] = useState({});
    const { project } = useProject(id);
    const { panelExternalConfigs, isLoadingPanelExternalConfigs } =
        usePanelExternalsByProject(id);

    //Eliminar configuración de panel externo
    const {
        mutate: mDeletePanelExternalConfig,
        error: erroDelete,
        isError: isErrorDelete,
    } = useMutation(deletePanelExternalConfig, {
        onSuccess: () => {
            Swal.fire(
                "Eliminar configuración",
                "Se ha eliminado esta configuración",
                "success"
            ).then(() =>
                window.location.replace(`/proyecto/${id}/panel-externo`)
            );
        },
        onError: (res) => {
            Swal.fire(
                "Eliminar configuración",
                `El panel no se ha eliminado correctamente: ${res.data.error}`,
                "error"
            );
        },
    });
    const handleDeletePanelExternalConfig = (sioPanelExternal) => {
        Swal.fire({
            title: "¿Quieres eliminar este panel externo?",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((res) => {
            if (res.isConfirmed) {
                mDeletePanelExternalConfig(sioPanelExternal);
            } else {
                Swal.fire(
                    "Eliminar configuración",
                    `El panel no se ha eliminado`,
                    "error"
                );
            }
        });
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "pivot.status", //access nested data with dot notation
                header: "Estado",
                Cell: ({ cell }) => {
                    return <strong>{panelConfigStatus[cell.getValue()]}</strong>;
                },
                size: 5,
            },
            {
                accessorKey: "name",
                header: "Panel",
            },
            {
                accessorKey: "pivot.created_at",
                header: "Fecha de creación",
                Cell: ({ cell }) =>
                    moment(cell.getValue()).locale("es").fromNow(),
            },
        ],
        []
    );
    return (
        <>
            {showModal.edit && (
                <ConfigPanelEdit
                    showModal={showModal}
                    setShowModal={setShowModal}
                    panelExternal={panelExternal}
                    projectId={id}
                />
            )}
            {showModal.create && (
                <ConfigPanelCreate
                    showModal={showModal}
                    setShowModal={setShowModal}
                    projectId={id}
                />
            )}
            <div className="row">
                {!mediaQuery && !isLoadingSurvey && <SidebarRight id={id} survey_status={survey.ls_status} />}
                <div className="col-xl-9 col-lg-9 mt-4">
                    <h4>Proyecto: {project.name}</h4>
                    <div className="card">
                        <div className="card-header">
                            <div className="content-head-card">
                                <label>Paneles integrados</label>
                                <a
                                    className="btn btn-primary-oo"
                                    onClick={() =>
                                        setShowModal({
                                            ...showModal,
                                            create: true,
                                        })
                                    }
                                >
                                    Configurar panel
                                </a>
                            </div>
                        </div>
                        <div className="card-body">
                            { isErrorSurvey && <Alert severity="error" sx={{marginTop: '10px', marginBottom: '10px'}}>{errorSurvey}</Alert> }
                            { !survey.ls_status &&
                                <Alert severity="error" sx={{marginTop: '10px', marginBottom: '10px'}}>
                                    <AlertTitle>Encuesta deshabilitada en Limesurvey</AlertTitle>
                                    <hr></hr>
                                    <p>La programación de este cuestionario no está habilitada en <b>Limesurvey</b></p>
                                    <p>Posible motivo: ajustes en programación de cuestionario.</p>
                                    <p>Consultar a TI para más información.</p>
                                </Alert>
                            }
                            { isLoadingPanelExternalConfigs ? (
                                <Skeleton height={400} />
                            ) : (
                                <MaterialReactTable
                                    columns={columns}
                                    data={panelExternalConfigs}
                                    initialState={{ density: "compact" }}
                                    muiTablePaperProps={{
                                        //customize paper styles
                                        sx: {
                                            boxShadow: "none !important",
                                            border: "none",
                                        },
                                    }}
                                    enableRowActions
                                    positionActionsColumn="last"
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            header: "Acciones", //change header text
                                        },
                                    }}
                                    renderRowActionMenuItems={({
                                        closeMenu,
                                        row,
                                    }) => [
                                        <MenuItem
                                            key={0}
                                            onClick={() => {
                                                // View profile logic...
                                                closeMenu();
                                                setPanelExternal(row.original);
                                                setShowModal({
                                                    ...showModal,
                                                    edit: true,
                                                });
                                            }}
                                            sx={{ m: 0 }}
                                        >
                                            <ListItemIcon>
                                                <EditIcon />
                                            </ListItemIcon>
                                            Editar
                                        </MenuItem>,
                                        <MenuItem
                                            key={1}
                                            onClick={() => {
                                                // Send email logic...
                                                closeMenu();
                                                window.location.replace(
                                                    `/proyecto/${id}/panelistas`
                                                );
                                            }}
                                            sx={{ m: 0 }}
                                        >
                                            <ListItemIcon>
                                                <RemoveRedEyeIcon />
                                            </ListItemIcon>
                                            Panelistas
                                        </MenuItem>,
                                        <MenuItem
                                            key={2}
                                            onClick={() => {
                                                // Send email logic...
                                                closeMenu();
                                                handleDeletePanelExternalConfig(
                                                    row.original
                                                );
                                            }}
                                            sx={{ m: 0 }}
                                        >
                                            <ListItemIcon>
                                                <DeleteForeverIcon />
                                            </ListItemIcon>
                                            Eliminar
                                        </MenuItem>,
                                    ]}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {mediaQuery && !isLoadingSurvey && <SidebarRight id={id} survey_status={survey.ls_status} />}
            </div>
        </>
    );
};

if (document.getElementById("jsx-panel-external")) {
    const el = document.getElementById("jsx-panel-external");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <PanelExternal {...props} />
        </App>
    );
}
