import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {  getPermissionsUser, updatePermissionsUser } from '../../../../api/sio/user';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';

export const ListPermissions = ({ user, setUser }) => {
    const [ permissions, setPermissions ] = useState(null);

    const { isLoading:isLoadingPermissions, error:errorPermissions, isError:isErrorPermissions, isSuccess:isSuccessPermissions } = useQuery( ['permissions'], () => getPermissionsUser(), { 
        onSuccess: (data) => {
            setPermissions(data);
        },
        cacheTime:0

    } );

    const selectCheckbox = (e) => {
        let search = user.permissions;
        if(!e.target.checked){
            setUser({ ...user, permissions: search.filter( p => p.id != e.target.value)}); // Hacemos un barrido de este objeto, con esto solo dejamos quienes están en check.
        }else{
            setUser({ 
                ...user, 
                permissions:[...search, ...permissions.filter( p => p.id == e.target.value)] 
            });
        }
    }

    const { mutate, isError:isErrorPermissionsUpdate, isSuccess:isSuccessPermissionsUpdate } = useMutation(updatePermissionsUser,{
        onSuccess: () => Swal.fire('Permisos modificado', '', 'success'),
        onError: () =>  Swal.fire('No se pudo modificar los permisos de usuario, intenta nuevamente', '', 'error')
    })
    const handleUpdatePermissionsUser = (e) => {
        e.preventDefault();
        Swal.fire({
            title: '¿Quieres modificar los permisos para el usuario?',
            showCancelButton: true,
            confirmButtonText: 'Editar',
            cancelButtonText: 'Cancelar',
            icon:'info'
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                mutate({ permissions: user.permissions, id: user.id });
            } else if (result.isDenied) {
              Swal.fire('No se pudo modificar los permisos de usuario, intenta nuevamente', '', 'error')
            }
          })
    }

    return (
        <>
        {
             isLoadingPermissions || isErrorPermissions ? ( <Skeleton height={400}/>) 
             : (
                <form  onSubmit={ handleUpdatePermissionsUser }>
                    {
                        permissions.map( permission => {
                            if(user.permissions?.length > 0){
                                var search = user.permissions?.filter( u => u.id == permission.id);
                                search = search.length > 0  ? true : false;
                            }
                            return (
                                <div className="form-check" key={ permission.id }>
                                    <input 
                                        key={ permission.id }
                                        className="form-check-input" 
                                        type="checkbox" value={permission.id} 
                                        id="flexCheckChecked" 
                                        onChange={ selectCheckbox } 
                                        checked={search} />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        { permission.name }
                                    </label>
                                </div>

                            );
                        })
                    }
                        <div className="form-group mt-2">
                            <button className="btn btn-primary-oo">Gestionar permisos</button>
                        </div>
                </form>
             )
        }
        </>
    )
}