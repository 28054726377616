import { getProductionOfQuarter } from "../../api/reports/productions";

const errors= { status:false, message: '' };
export const useProductionOfQuarterStore = (set,get) => {
    return {
        loadingQuarter:false,
        errorsQuarter:errors,
        responseQuarter: [],
        typeChartQuarter:{
            options: [{value:'barchart', label:'Barra' } , {value:'linechart', label:'Lineal' }, { value:'table', label:'Tabla' }],
            selected: {value:'barchart', label:'Barra' }
        },
        setTypeChartQuarter:  (type) => set((state) => ({ ...state, typeChartQuarter: { ...state.typeChartQuarter, selected: type }})),
        fetchProductionOfQuarter: async () => {
            set({ loadingQuarter: true });
            set({errorsQuarter:{status:false,message:''}});
            get().setErrors(false,'');
            try {
                const  {data, status } = await getProductionOfQuarter(get().preset,get().defaultStatusProject);
                if(status == 204){
                    set({ errorsQuarter: { status:true, message:'No hay data disponible para este filtro generado.'} })
                }else{
                    set({ errorsQuarter: errors, responseQuarter: data });
                }
            } catch (error) {
                if(error.status = 422){
                    get().setErrors(true, error.data.message);
                }
            }
            set({ loadingQuarter: false });
        }
    }
};