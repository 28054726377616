import { create } from "zustand";
import {
    acceptAmbassadorCandidate,
    cancelAmbassador,
    getAmbassadorRecruitsCompletedSurveys,
    getAmbassadorValidRecruits,
    getDeliveredRewards,
    rejectAmbassadorCandidate
} from "../../api/sip/ambassador";

export const useAmbassadorStore = create((set, get) => {
    return {
        validRecruits: {
            chart: [],
            export: [],
            total: 0,
            rangeTotal: 0,
            todayTotal: 0,
        },
        completedSurveys: {
            chart: [],
            export: [],
            total: 0,
            rangeTotal: 0,
            todayTotal: 0,
        },
        deliveredRewards: {
            chart: [],
            export: [],
            total: 0,
            rangeTotal: 0,
            todayTotal: 0,
        },
        isLoading: {
            validRecruits: true,
            completedSurveys: true,
            deliveredRewards: true,
        },
        isError: {
            validRecruits: { status: false, message: "" },
            completedSurveys: { status: false, message: "" },
            deliveredRewards: { status: false, message: "" }
        },
        fetchAcceptAmbassadorCandidate: async (candidate) => {
            try {
                const { status, data } = await acceptAmbassadorCandidate(candidate);
                return { status, message: data.message };
            } catch (error) {
                throw { errors: error.data };
            }
        },
        fetchRejectAmbassadorCandidate: async (candidate) => {
            try {
                const { status, data } = await rejectAmbassadorCandidate(candidate);
                return { status, message: data.message };
            } catch (error) {
                throw { errors: error.data };
            }
        },
        fetchCancelAmbassador: async (ambassador) => {
            try {
                const { status, data } = await cancelAmbassador(ambassador);
                return { status, message: data.message };
            } catch (error) {
                throw { errors: error.data };
            }
        },
        fetchAmbassadorValidRecruits: async (filter) => {
            set({
                isLoading: { ...get().isLoading, validRecruits: true },
                isError: { ...get().isError, validRecruits: { status: false, message: "" } }
            })
            try {
                const { status, data } = await getAmbassadorValidRecruits(filter);
                if (status === 200 && data.chartData.length >= 1) {
                    set({
                        isLoading: { ...get().isLoading, validRecruits: false },
                        validRecruits: {
                            chart: data.chartData,
                            export: data.exportableData,
                            total: data.total,
                            rangeTotal: data.rangeTotal,
                            todayTotal: data.todayTotal
                        },
                        isError: { ...get().isError, validRecruits: { status: false, message: "" } }
                    })
                } else {
                    set({
                        isLoading: { ...get().isLoading, validRecruits: false },
                        validRecruits: {
                            chart: [],
                            export: [],
                            total: data.total ?? 0,
                            rangeTotal: 0,
                            todayTotal: 0
                        },
                        isError: { ...get().isError, validRecruits: { status: true, message: "No hay datos disponibles" } }
                    })
                }
            } catch (error) {
                set({
                    isError: { ...get().isError, validRecruits: { status: true, message: "No hay datos disponibles" } },
                    isLoading: { ...get().isLoading, validRecruits: false },
                    validRecruits: {
                        chart: [],
                        export: [],
                        total: 0,
                        rangeTotal: 0,
                        todayTotal: 0
                    }
                });
            }
        },
        fetchAmbassadorRecruitsCompletedSurveys: async (filter) => {
            set({
                isLoading: { ...get().isLoading, completedSurveys: true },
                isError: { ...get().isError, completedSurveys: { status: false, message: "" } }
            })
            try {
                const { status, data } = await getAmbassadorRecruitsCompletedSurveys(filter);
                if (status === 200 && data.chartData.length >= 1) {
                    set({
                        isLoading: { ...get().isLoading, completedSurveys: false },
                        completedSurveys: {
                            chart: data.chartData,
                            export: data.exportableData,
                            total: data.total,
                            rangeTotal: data.rangeTotal,
                            todayTotal: data.todayTotal
                        },
                        isError: { ...get().isError, completedSurveys: { status: false, message: "" } }
                    })
                } else {
                    set({
                        isLoading: { ...get().isLoading, completedSurveys: false },
                        completedSurveys: {
                            chart: [],
                            export: [],
                            total: data.total ?? 0,
                            rangeTotal: 0,
                            todayTotal: 0
                        },
                        isError: { ...get().isError, completedSurveys: { status: true, message: "No hay datos disponibles" } }
                    })
                }
            } catch (error) {
                set({
                    isError: { ...get().isError, completedSurveys: { status: true, message: "No hay datos disponibles" } },//error.data?.message
                    isLoading: { ...get().isLoading, completedSurveys: false },
                    completedSurveys: {
                        chart: [],
                        export: [],
                        total: 0,
                        rangeTotal: 0,
                        todayTotal: 0
                    }
                });
            }
        },
        fetchAmbassadorDeliveredRewards: async (filter) => {
            set({
                isLoading: { ...get().isLoading, deliveredRewards: true },
                isError: { ...get().isError, deliveredRewards: { status: false, message: "" } }
            })
            try {
                const { status, data } = await getDeliveredRewards(filter);
                if (status === 200) {
                    set({
                        isLoading: { ...get().isLoading, deliveredRewards: false },
                        deliveredRewards: {
                            chart: data.chartData,
                            export: data.exportableData,
                            rangeTotal: data.rangeTotal,
                            totalPoints: data.totalDeliveredPoints
                        },
                        isError: { ...get().isError, deliveredRewards: { status: false, message: "" } }
                    })
                } else {
                    set({
                        isLoading: { ...get().isLoading, deliveredRewards: false },
                        deliveredRewards: {
                            chart: [],
                            export: [],
                            totalPoints: data.totalDeliveredPoints ?? 0,
                            rangeTotal: {
                                completedSurveyPoints: 0,
                                total: 0,
                                validRecruitsPoints: 0
                            }
                        },
                        isError: { ...get().isError, deliveredRewards: { status: true, message: "No hay datos disponibles" } }
                    })
                }
            } catch (error) {
                set({
                    isError: { ...get().isError, deliveredRewards: { status: true, message: error.data?.errors } },
                    isLoading: { ...get().isLoading, deliveredRewards: false },
                    deliveredRewards: {
                        chart: [],
                        export: [],
                        totalPoints: 0,
                        rangeTotal: {
                            completedSurveyPoints: 0,
                            total: 0,
                            validRecruitsPoints: 0
                        }
                    }
                });
            }
        },
    }
});