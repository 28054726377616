import React from "react";
import ReactDOM from "react-dom/client";
import Skeleton from "@mui/material/Skeleton";

import { App } from "../../../App";
import { useGetProjects, useGetProjectsOrder } from "../../../hooks/useProject";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Swal from "sweetalert2";
import { setProjectsOrder } from "../../../api/sio/project";
import { useMutation } from "react-query";

export const OrderProject = () => {
    // const { isLoading, data } = useQuery('projects', getProjects);
    const {
        projects,
        setProjects,
        isLoading: isLoadingProject,
        isError: isErrorProjects,
        error: errorProjects,
        refetch: projectsRefetch,
    } = useGetProjectsOrder();

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleOnDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        let items = reorder(
            projects,
            result.source.index,
            result.destination.index
        );
        items = items.map((item, key) => {
            return { ...item, order: key };
        });
        setProjects(items);
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        display: "flex",
        justifyContent: "space-between",
        fontSize: "16px",
        fontWeight: "bold",
        alignItems: "center",
        textAlign: "left",
        margin: "10px",
        padding: "20px",
        borderRadius: "10px",
        border: "solid 1px var(--bs-gray-300)",
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        // change background colour if dragging
        background: isDragging ? "var(--bs-gray-200)" : "var(--bs-gray-100)",

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    // const handleUpdateOrder = async () => {
    //     const response = await setProjectsOrder(projects);
    //     const { data, status } = response;
    //     if (status == 200) {
    //         setProjects(data);
    //         Swal({ title: "¡Prioridad actualizada!", icon: "success" });
    //     } else {
    //         Swal({
    //             title: "Error al actualizar la prioridad de los proyectos",
    //             icon: "error",
    //         });
    //     }
    // };

    const { mutate: mUpdateOrderProject } = useMutation(setProjectsOrder, {
        onSuccess: () => {
            Swal.fire(
                "Ordenar Proyectos",
                "¡Orden actualizado!",
                "success"
            ).then(() => {
                projectsRefetch();
            });
        },
        onError: (res) => {
            Swal.fire(
                "Ordenar Proyectos",
                `${res.data.error ? res.data.error : ""}`,
                "error"
            ).then(() => {
                projectsRefetch();
            });
        },
    });

    const handleUpdateProjectOrder = () => {
        Swal.fire({
            title: "¿Actualizar prioridad de los proyectos?",
            showCancelButton: true,
            confirmButtonText: "Actualizar",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed) {
                mUpdateOrderProject(projects);
            }
        });
    };

    return (
        <>
            {isLoadingProject ? (
                <Skeleton height={400} />
            ) : (
                <div className="card">
                    <div className="card-header">
                        <div className="content-head-card">
                            <label>Ordenar prioridades</label>
                            <a
                                className="btn btn-primary-oo text-white"
                                onClick={handleUpdateProjectOrder}
                            >
                                <i className="fas fa-sync-alt m-1"></i>
                                Actualizar Orden
                            </a>
                        </div>
                    </div>
                    <div className="card-body">
                        {projects.length == 0 ? (
                            <Skeleton height={300} />
                        ) : (
                            <>
                                <DragDropContext
                                    onDragEnd={(result) =>
                                        handleOnDragEnd(result)
                                    }
                                >
                                    <div id="container-order">
                                        <Droppable droppableId="listOrder">
                                            {(droppableProvided) => (
                                                <ul
                                                    style={{
                                                        width: "100%",
                                                        textAlign: "center",
                                                        listStyle: "none",
                                                        padding: "0px",
                                                    }}
                                                    ref={
                                                        droppableProvided.innerRef
                                                    }
                                                    {...droppableProvided.droppableProps}
                                                >
                                                    {projects.map(
                                                        (project, index) => (
                                                            <Draggable
                                                                key={project.id}
                                                                draggableId={String(
                                                                    project.id
                                                                )}
                                                                index={index}
                                                            >
                                                                {(
                                                                    draggableProvided,
                                                                    snapshot
                                                                ) => (
                                                                    <li
                                                                        ref={
                                                                            draggableProvided.innerRef
                                                                        }
                                                                        {...draggableProvided.draggableProps}
                                                                        {...draggableProvided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            draggableProvided
                                                                                .draggableProps
                                                                                .style
                                                                        )}
                                                                    >
                                                                        <span>
                                                                            <span className="badge btn-primary-oo me-2">
                                                                                {index +
                                                                                    1}
                                                                            </span>
                                                                            {
                                                                                project.name
                                                                            }
                                                                        </span>
                                                                        <i
                                                                            className="fas fa-arrows-alt"
                                                                            style={{
                                                                                fontSize:
                                                                                    "22px",
                                                                            }}
                                                                        ></i>
                                                                    </li>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    )}
                                                    {
                                                        droppableProvided.placeholder
                                                    }
                                                </ul>
                                            )}
                                        </Droppable>
                                    </div>
                                </DragDropContext>
                            </>
                        )}
                    </div>
                    <div className="card-footer py-3 d-flex flex-row align-items-center justify-content-end">
                        <a
                            className="btn btn-primary-oo text-white"
                            onClick={handleUpdateProjectOrder}
                        >
                            <i className="fas fa-sync-alt m-1"></i>Actualizar
                            Orden
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

if (document.getElementById("jsx-projects-order")) {
    const el = document.getElementById("jsx-projects-order");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <OrderProject {...props} />
        </App>
    );
}
