import React, { useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from "material-react-table/locales/es";

import { STATUS_OPTIONS } from '../../../../helper/friend-points';
import { useFriendPointStore } from '../../../../store/FriendPointsStore/useFriendPointsStore';

export const FriendPointsTable = ({ friendPoints, seeModal, setSeeModal }) => {
    const { setFriendPoint } = useFriendPointStore((state) => state);
    const columns = useMemo( () =>[
        {
            accessorKey: 'status',
            header: 'Estado',
            Cell: ({ cell }) => { return <strong>{STATUS_OPTIONS.find(x => x.value == cell.getValue()).label}</strong> },
        },{
            accessorKey:'description',
            header: 'Descripción',
        },{
            accessorKey:'points',
            header: 'Puntos',
            enableColumnFilter:false,
        },{
            accessorKey:'platformMail',
            header: 'Plataforma/Plantilla',
            Cell: ({cell}) => { return JSON.parse(cell.getValue()).platformMail + "/" +JSON.parse(cell.getValue()).idPlatformMail }
        },{
            header:'Acciones',
            Cell:({ cell }) => {
                return (
                    <div>
                        <button  className="btn btn-warning" onClick={()=> {
                            setSeeModal({ ...seeModal,edit: true });
                            const _friendPoint = {
                                ...cell.row.original,
                                payload: JSON.parse(cell.row.original.payload),
                                platformMail: JSON.parse(cell.row.original.platformMail)
                            };
                            setFriendPoint(_friendPoint);
                        }}>
                            <i className='fa fa-edit'></i>
                        </button>
                    </div>
                )
            }
        }
    ],[]);

    return(
        <>
            <MaterialReactTable
                localization={MRT_Localization_ES}
                columns={columns}
                data={friendPoints}
                initialState={{ density: "compact" }}
            />
        </>
    )
}
