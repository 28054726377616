import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { getAgesDahsboardAjax } from "../api/dashboard/age";

export const useAgesM = (filter) => {
    const [ages, setAges] = useState([]);
    const filterRef = useRef(filter);
    const { isLoading, mutate } = useMutation(getAgesDahsboardAjax,{ 
        onSuccess : (response) => {
            const { data } = response;
            setAges(data || []);
    },onError:( { response }) => setAges([]) });
    useEffect( () => {
        setAges([]);
        mutate(filter);
    },[filter]);
    return {
        ages, setAges,isLoading
    }
}
