export const STATUS_SURVEY_MESSAGES = {
    enabled: "Habilitado",
    disabled: "Deshabilitado",
};

export const BG_POSITION_OPTIONS = [
    { value: "top",label: "Arriba" },
    { value: "bottom",label: "Abajo" },
    { value: "center",label: "Centro" },
    { value: "percent",label: "Personalizado" },
];