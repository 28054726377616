import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { useMutation } from "react-query";
import Swal from "sweetalert2";
import { createPanelExternal } from "../../../api/sio/panelExternal";
import { App } from "../../../App";

export const PanelExternal = () => {
    const [panelExternal, setPanelExternal] = useState({
        id: 0,
        name: "",
    });

    const {
        mutate: mCreatePanelExternal,
        isLoading,
        error,
        isError,
    } = useMutation(createPanelExternal, {
        onSuccess: () => {
            Swal.fire("Crear Panel Externo", "Panel creado", "success").then(
                (result) => location.replace("/panel-externo")
            );
        },
    });

    const handleCreatePanelExternal = (e) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Quieres agregar un nuevo panel externo?",
            showCancelButton: true,
            confirmButtonText: "Crear",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                mCreatePanelExternal(panelExternal);
            } else if (result.isDenied) {
                Swal.fire(
                    "No se pudo agregar el panel, intenta nuevamente.",
                    "",
                    "error"
                );
            }
        });
    };

    return (
        <div className="card">
            <div className="card-header">
                <div className="content-head-card">
                    <label>Registrar panel</label>
                </div>
            </div>
            <div className="card-body">
                <form onSubmit={handleCreatePanelExternal}>
                    <div className="form-group">
                        <label className="my-1 fw-bold">Nombre panel</label>
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="name-input"
                            value={panelExternal.name}
                            onChange={(e) =>
                                setPanelExternal({
                                    ...panelExternal,
                                    name: e.target.value,
                                })
                            }
                        />
                        {isError && (
                            <p className="text text-danger">
                                {error?.data.message}
                            </p>
                        )}
                    </div>
                    <div className="form-group mt-2">
                        {isLoading ? (
                            <button className="btn btn-primary-oo">
                                Cargando...
                            </button>
                        ) : (
                            <button className="btn btn-primary-oo">
                                Crear panel
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

if (document.getElementById("jsx-panelexternal")) {
    const el = document.getElementById("jsx-panelexternal");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <PanelExternal {...props} />
        </App>
    );
}
