import { create } from "zustand";
import { dateTransform } from "../../helper/dateTransform";
import { useBoundFetchTransactionStore } from './useBoundFetchTransactionStore';

const ERRORS = { status: false, message: '' }
export const  useBoundTransactionsStore = create( (set, get) => {
    return {
        loadings: {
            transactionsTotales: false,
            products: false,
            transactionDelivered: false,
            productsForDate: false
        },
        errors: {
            transactionsTotales: ERRORS,
            products: ERRORS,
            transactionDelivered: ERRORS,
            productsForDate: ERRORS
        },
        data:{
            transactionsTotales: [],
            products: [],
            transactionDelivered: [],
            productsForDate: []
        },
        preset: dateTransform['thisMonth'],
        setPreset: (_preset) => set((state) => ({...state, preset: _preset })),
        ...useBoundFetchTransactionStore( set, get )
    }
})