import { useState } from "react";
import { useQuery } from "react-query";
import { getTotalPanel } from "../api/dashboard/panel";

export const useGetTotalPanel = (enabled=true) => {
    const [panel, setPanel] = useState([]);
    const query = useQuery(['getTotalPanel'],() => getTotalPanel(),{ 
        enabled:enabled,
        onSuccess: (response) => {
            const { data } = response;
            setPanel(data);
        }
    });

    return {
        ...query,
        panel, 
        setPanel
    }
}
