import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { usePanelExternalProject } from '../../../../hooks/useProject';
import {MaterialReactTable} from 'material-react-table';

export const PanelExternalProgressTable = ({id, hash}) => {


    const columns=  useMemo( () => [
        {
            accessorKey:'panel',
            header: 'Panel',
        },{
            accessorKey: 'completed',
            header: 'Completas'
        },{
            accessorKey: 'filtered',
            header: 'Filtradas'
        },{
            accessorKey: 'parcial',
            header: 'Parciales'
        },{
            header:'IR',
            Cell:({ row }) => {
                let ir = row.original.completed > 0
                ? Math.round((row.original.completed / (row.original.completed + row.original.filtered))*100) :  "0";
                return `${ ir }%`
            }
        },{
            header: 'Descargar base',
            Cell:({ row }) => {
                return (<div>
                    <a href={`${import.meta.env.VITE_REACT_APP_URL}/proyecto/${id}/exportar/metricas/panel/${row.original.panel}`} style={{ backgroundColor: 'var(--bs-cyan)'}} className="btn btn-default">
                        <i className="fas fa-download"></i>
                    </a>
                </div>)
            }
        }
    ],[]);

    const { isLoading, panel } = usePanelExternalProject(id, hash);
    return ( 
        <>
        {
            isLoading ? (<Skeleton height={300} />):(
                panel.length  > 0 && (
                    <div className="col-md-12 mt-4">
                        <div className='card card-header'>
                            <h5 htmlFor="">Paneles</h5>
                        </div>
                        <div className="card card-body">
                        <MaterialReactTable 
                            columns={columns} 
                            data={panel} 
                            muiTablePaperProps={{
                                //customize paper styles
                                sx: {
                                    boxShadow: 'none !important',
                                    border: 'none',
                                },
                            }}
                            enableColumnActions={false}
                            enableColumnFilters={true}
                            enablePagination={true}
                            enableSorting={true}
                            enableBottomToolbar={true}
                            enableTopToolbar={true}
                            enableFullScreenToggle={true}
                            enableDensityToggle={false}
                            enableFilters={false}
                            enableHiding={false}
                            
                        />
                        </div>
                    </div>
                )
            )
        }</>

    )
}

