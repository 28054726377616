import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { getGendersDahsboardAjax } from "../api/dashboard/gender";

export const useGendersM = (filter) => {
    const [genders, setGenders] = useState([]);
    const filterRef = useRef(filter);
    const { isLoading, mutate, error, isError } = useMutation(getGendersDahsboardAjax,{ 
        onSuccess : (response) => {
            const { data } = response;
            setGenders(data || []);
    }, onError:({ response}) => setGenders([])});
    useEffect( () => {
        mutate(filter);
        setGenders([])
    },[filter]);
    return {
        genders, setGenders,isLoading
    }
}
