import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {Bar, BarChart, CartesianGrid, Cell, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useBoundMktStore } from '../../../../store/ReportsCampaignMkt/useBoundMktStore';


export const NseBarChart = () => {
    const { loading, nses, totalRegister } = useBoundMktStore ((state) => {
        const { loadings, data, errors } = state;
        return {
            loading: loadings.nses,
            nses: data.nses,
            errors: errors.nses,
            totalRegister: state.totalRegister

        }
    })
    return (
        loading ? (<Skeleton height={400}/>) : (
            Object.keys(nses).length > 0 && (
                <div className="row">
                        {
                            Object.keys(nses).map((nse, key) => {
                                return (
                                <div key={key} className={`${Object.keys(nses).length == 1 ? 'col-md-12' : 'col-md-6' }`}>
                                        <RenderChart nse = { nses[nse] } type={nse} totals = {totalRegister}/>
                                </div>)
                            
                            })
                        }
                </div>
        )))
}

const CustomTooltip = ({ active, payload, label, totals }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
            backgroundColor:"#FFF",
            padding:"10px",
            borderRadius:"10px",
            // boxShadow:'1px 1px 3px 1px #7E57C2',
            boxShadow:  '#CFD8DC 0px 1px 7px 0px',
            width:'300px'
            }}>
            <p style={{ color:"#3d3d3d", textAlign:'center'}}><strong>{ label }</strong></p>
            <hr></hr>
            <p style={{ color:`${payload[0].color}`}}>{ payload[0].name }: {`${payload[0].value} (${((payload[0].value/totals)*100).toFixed(2)}%)`}</p>
        </div>
      );
    }
  
    return null;
};

const CustomBar = (props) => {
    const { x, y, width, height, fill, payload } = props;
    return (
        <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        stroke="#e2e2e2" // Color del borde
        strokeWidth={1} // Ancho del borde
        radius={[10,10,0,0]}
        />
    );
}; 

const RenderChart = ({nse, type, totals}) => {
    const maxValue = Math.max(
        ...nse.map(item => Math.max(parseInt(item.total)))
    );
    return ( 
        <ResponsiveContainer  width="100%" height={300}>
            <BarChart
                width={500}
                height={300}
                data={nse}
            >
            <CartesianGrid stroke='transparent' />
                <XAxis 
                    dataKey="nses"
                    textAnchor= "end" 
                    sclaeToFit="true" 
                    verticalAnchor= "start"  
                    angle= "-40"  
                    style={{fontSize:'12px'}} 
                    axisLine={false} // Elimina la línea del eje Y
                    tickLine={false}
                />
                <YAxis
                    type="number"
                    domain={[0, dataMax => maxValue * 1.5]} tickCount={10} 
                    allowDataOverflow={true}    
                    axisLine={false} // Elimina la línea del eje Y
                    tickLine={false} // Elimina las líneas de las marcas en el eje Y  
                />
                <Tooltip cursor={{fill: 'transparent'}} content={ (props) => <CustomTooltip {...props} totals={totals} /> } />
                <Legend iconType='circle'/>
                <Bar dataKey="total" fill="#81D4FA" name={`Nivel socio económico ${type.toUpperCase()}`}  barSize={40} shape={<CustomBar />} >
                    {nse.map((entry, index) => (<Cell key={`cell-${index}`} fill="#81D4FA"/>))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}