import { formatterNumber } from '../../../../helper/currencyFormatter';

export const YearTable = ({ responseYear }) => {

    return ( 
        <div className="table-responsive">
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>Año</th>
                        <th>Panelistas</th>
                        <th>Completas</th>
                        <th style={{ width:'250px' }}>Tasa de completas</th>
                        <th>Filtradas</th>
                        <th>Parciales</th>
                        <th>Tasa base total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        responseYear.map((year,keyYear) => {
                            const _sum = ((parseInt(year.completed) + parseInt(year.filtered) + parseInt(year.partial)) /  (parseInt(year.completed_total) + parseInt(year.filtered_total) + parseInt(year.totalLastPartial))*100).toFixed(2);
                            return (
                                <tr key={keyYear}>
                                    <td>{ year.year }</td>
                                    <td>{ formatterNumber(parseInt(year.completed)) }</td>
                                    <td>{ formatterNumber(parseInt(year.completed_total)) }</td>
                                    <td>{ ((parseInt(year.completed) / parseInt(year.completed_total))*100).toFixed(2)}%</td>
                                    <td>{ formatterNumber(parseInt(year.filtered)) }</td>
                                    <td>{ formatterNumber(parseInt(year.partial)) }</td>
                                    <td>{ _sum }%</td>
                                </tr>  
                           ) 
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

