import moment from "moment";
import { DashboardContext } from "../context/DashboardContext";
import React, { useState } from 'react';
import { format, startOfMonth } from "date-fns";
import { enGB, es } from "date-fns/locale";


export const DashboardProvider = ({ children }) => {
    const [filter, setFilter ] = useState({
        date: {
            from: startOfMonth(new Date(),'yyyy-MM-dd'),
            to: new Date()
        },
    })
    return <DashboardContext.Provider value={{ filter, setFilter }}>{ children }</DashboardContext.Provider>
}