import { formatterNumber } from '../../../../helper/currencyFormatter';

export const QuarterTable = ({ responseQuarter }) => {

    return ( 
        <div className="table-responsive">
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>Trimestre/año</th>
                        <th>Teórico</th>
                        <th>Completas</th>
                        <th>Completas(%)</th>
                        <th>Filtradas</th>
                        <th>Parciales</th>
                        <th>Total Base</th>
                        <th>T. Respuesta(%)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        responseQuarter.map((quarter,keyquarter) => {
                        let _t = (((parseInt(quarter.completed) +  parseInt(quarter.filtered) + parseInt(quarter.totalLastPartial)) / parseInt(quarter.totalBase))*100).toFixed(2);
                        return (
                                <tr key={keyquarter}>
                                    <td>{ quarter.quarterName }</td>
                                    <td>{ formatterNumber(parseInt(quarter.sample)) }</td>
                                    <td>{ formatterNumber(parseInt(quarter.completed)) }</td>
                                    <td>{ ((parseInt(quarter.completed)/parseInt(quarter.sample))*100).toFixed(2) }%</td>
                                    <td>{ formatterNumber(parseInt(quarter.filtered)) }</td>
                                    <td>{ formatterNumber(parseInt(quarter.totalLastPartial)) }</td>
                                    <td>{ formatterNumber(parseInt(quarter.totalBase)) }</td>
                                    <td>{ _t }%</td>
                                </tr>  
                        ) 
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

