
import { axiosApi } from '../axiosApi';

export const getUsers = async () => {
    const { data } = await axiosApi.get('/api/usuario/listar');
    return data;
}

export const getUser =  async (id) => {
    const { data } = await axiosApi.get(`/api/usuario/${ id }`);
    return data;
}

export const resetPassword =  async (id) => {
    const { data } = await axiosApi.get(`/api/usuario/reestablecer-credenciales/${ id }`);
    return data;
}

export const getRolesUser =  async () => {
    const { data } = await axiosApi.get(`/api/usuario/roles`);
    return data;
}

export const getPermissionsUser = async () => {
    const { data } = await axiosApi.get('/api/usuario/permisos');
    return data;
}

export const createUser = async (user) => {
    const { data } = await axiosApi.post(`/api/usuario/crear`, user);
    return data;
}


export const updateUser = async (user) => {
    const { data } = await axiosApi.put(`/api/usuario/actualizar`, user);
    return data;
}

export const updatePermissionsUser = async (permissions) => {
    const { data } = await axiosApi.put('/api/usuario/actualizar/permisos', permissions);
    return data;
}