import React from 'react';
import { useBoundTransactionsStore } from '../../../../store/ReportsTransactionsStore/useBoundTransactionsStore';
import Skeleton from 'react-loading-skeleton';
import { CURRENCY, STATUS_TRANSACTIONS } from '../../../../helper/status_transactions';
import { currencyFormatter, formatterNumber } from '../../../../helper/currencyFormatter';

export const TransactionsTotales = () => {

    const { data, loading, totalPoints, totalPrices, errors} = useBoundTransactionsStore( state => {
        const { loadings, data, errors:errorsState } = state;
        return {
            loading: loadings.transactionsTotales,
            data: data.transactionsTotales,
            errors: errorsState.transactionsTotales,
            totalPoints : data.transactionsTotales.reduce( (a,b) => a + b.total_points, 0),
            totalPrices : data.transactionsTotales.reduce( (a,b) => a + b.total_prices, 0),
        }
    });

    if(loading){
        return <Skeleton height={300} />
    }else{
        if(data.length == 0){ return <div className="mt-2 alert alert-danger">{errors.message}</div> }
    }
    return (
        <div className="mt-3 card">
            <div className="card-header"><strong>Resúmen</strong></div>
            <div className="card-body table-responsive">
            <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Estado</th>
                            <th>Total de puntos</th>
                            <th>Total de puntos %</th>
                            <th>Total precio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map( (response, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{ STATUS_TRANSACTIONS[response.status] }</td>
                                        <td>{ formatterNumber(response.total_points) }</td>
                                        <td>{ ((response.total_points / totalPoints)*100).toFixed(2) }%</td>
                                        <td>{ 
                                            currencyFormatter({
                                                value: response.total_prices,
                                                currency: CURRENCY[response.country]
                                            })
                                        }</td>
                                    </tr>
                                )
                            })
                        }
                        <tr style={{ border: 'none' }}>
                            <td style={{ border:'none' }}></td>
                            <td style={{ border:'none', backgroundColor:'#FFE0B2', color:'#F57C00', textAlign:'right' }}><strong>{ formatterNumber(totalPoints) }</strong></td>
                            <td style={{ border:'none' }}></td>
                            <td style={{ border:'none', backgroundColor:'#C8E6C9', color:'#689F38', textAlign:'right' }}><strong>{ currencyFormatter({
                                                value: totalPrices,
                                                currency: CURRENCY[data[0].country]
                                            }) 
                                            }</strong></td>
                        </tr>
                    </tbody>
            </table>
            </div>
        </div>
    )
}