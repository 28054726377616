import { format } from "date-fns";
import { axiosApi } from "../axiosApi";

// Todas las campañas
export const getCampaigns = async (preset = null ) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/campanhas`);
    if(preset != null){
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

// Registro diario de la campaña
export const getCampaignsForDay = async (preset = null, id ) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/reporte/campanhas/${id}/por-dias`);
    if(preset != null){
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}

// Obtiene información de la campaña
export const getCampaign = async (id) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/campanha/${id}`);
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
}


// Registra el costo  diario por camapaña
export const  registerCostCampaign = async (payload) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/campanha/registrar/costo`);
    try{
        const response = await axiosApi.post('/api/campanha/registrar/costo', payload);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }
};


//  Obtenemos información de las edades, según fecha de registro y campaña
export const getAgesMkt = async({id, preset}) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/campanha/${id}/edades`);
    if(preset != null){
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    } 
}

// Obtenemos información de la variable sexo, según fecha y campaña
export const getGendersMkt = async({id, preset}) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/campanha/${id}/sexo`);
    if(preset != null){
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }  
}

// Obtenemos información de la variable firstgeo, según fecha y campaña
export const getFirstgeoMkt = async({id, preset}) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/campanha/${id}/geo/1`);
    if(preset != null){
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }  
}

// Obtenemos los datos de los usuarios que respondieron un perfil. 
export const getProfilesMkt = async({id, preset}) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/campanha/${id}/perfiles`);
    if(preset != null){
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }  
}

// Obtenemos las encuestas que respondieron los usuarios que se registraron por medio de esta campaña
export const getSurveysMkt = async({id, preset}) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/campanha/${id}/encuestas`);
    if(preset != null){
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }  
}

// Obtenemos los nses que ha respondido un usuario
export const getNsesMkt = async({id, preset}) => {
    let url =  new URL(`${import.meta.env.VITE_REACT_APP_URL}/campanha/${id}/nses`);
    if(preset != null){
        url.searchParams.set('from_at', format(preset.from,'yyyy-MM-dd'));
        url.searchParams.set('to_at', format(preset.to,'yyyy-MM-dd'));
    }
    try{
        const response = await axiosApi.get(url);
        return response;
    }catch(error){
        const { response } = error;
        throw response;
    }  
}

    