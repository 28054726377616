import React, { useContext } from 'react';
import { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { App } from '../../../App';
import { ItemsResultSurveyContext } from '../../../context/ItemsResultSurveyContext';
import { ItemsResultSurveyProvider } from '../../../providers/ItemsResultSurveyProvider';
import { ListItemsDahsboard } from './dashboard/Index';
import { QuestionsTableProject } from './table/QuestionsTableProject';
export const DashboardProject = ({id,hash = null }) => {
    const {
        setStatePro,statePro
    } = useContext(ItemsResultSurveyContext);
    // const { project } = useProject(id);
    const [state, setState] = useState({
        dashboard: true,
        questions: false
    })

    const handleChangePage = (dashboard, questions) => {
        setState({ ...state, dashboard, questions });
        setStatePro(!statePro);
    };
    return (
        <>

            <div className="project__menu-data">
                <a
                    className={`btn  ${state.dashboard && "btn-primary-oo"}`}
                    onClick={() => handleChangePage(true, false)}
                >
                    Información general
                </a>
                <a
                    className={`btn ${state.questions && "btn-primary-oo"}`}
                    onClick={() => handleChangePage(false, true)}
                >
                    Listado de preguntas
                </a>
            </div>
            <div className="row">
                {
                    state.dashboard && (<ListItemsDahsboard id={id} hash={ hash } />)
                }
                {
                    state.questions && (<QuestionsTableProject id={id} hash={ hash } />)
                }
                
            </div>
        </>
    );
};

if (document.getElementById("jsx-dashboard-project")) {
    const el = document.getElementById("jsx-dashboard-project");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <ItemsResultSurveyProvider>
                <DashboardProject {...props} />
            </ItemsResultSurveyProvider>
        </App>
    );
}
