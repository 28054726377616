import React, { useState } from "react";
import { ParameterItem } from "./ParameterItem";

export const ParametersList = ({
    projectId,
    parameters,
    onParameterChange,
    onAddParameter,
    onRemoveParameter,
}) => {
    const [isChecked, setIsChecked] = useState(
        !["", null].includes(parameters[0].slug)
    );

    const handleRadioChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <>
            <div className="form-group col-md-6">
                <label className="my-1 fw-bold">Añadir parámetros</label>
                <div className="py-2">
                    <div className="form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radio-si"
                            id="radio-si"
                            checked={isChecked}
                            onChange={handleRadioChange}
                        />
                        <label
                            className="form-check-label ms-2"
                            htmlFor="radio-si"
                        >
                            Si
                        </label>
                    </div>
                    <div className="form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radio-no"
                            id="radio-no"
                            checked={!isChecked}
                            onChange={handleRadioChange}
                        />
                        <label
                            className="form-check-label ms-2"
                            htmlFor="radio-no"
                        >
                            No
                        </label>
                    </div>
                </div>
            </div>
            {isChecked && (
                <div className="form-group mt-3">
                    <div className="card border bg-primary-oo-100 rounded ">
                        <div className="card-body">
                            <div className="d-flex justify-content-between mb-2">
                                <h6 className="fw-bold align-self-center">
                                    Parámetros
                                </h6>
                                <input
                                    type="button"
                                    className="btn btn-primary-oo"
                                    onClick={onAddParameter}
                                    defaultValue="Añadir otro"
                                />
                            </div>
                            {parameters.map((parameter, index) => (
                                <ParameterItem
                                    key={parameter.id}
                                    index={index}
                                    projectId={projectId}
                                    parameter={parameter}
                                    onParameterChange={onParameterChange}
                                    onRemoveParameter={onRemoveParameter}
                                    showRemoveButton={parameters.length > 1}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
