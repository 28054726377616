import { axiosApi } from "../axiosApi";

export const getSurveyPages = async (projectId) => {
    try {
        const response = await axiosApi.get(`/api/proyecto/${ projectId }/paginas/listar`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getSurveyPage = async (pageId) => {
    try {
        const response = await axiosApi.get(`/api/proyecto/pagina/${ pageId }/mostrar`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const createSurveyPage = async (pageData) => {
    try {
        const response = await axiosApi.post(`/api/proyecto/pagina/guardar`, pageData);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const updateSurveyPage = async (pageData) => {
    try {
        const response = await axiosApi.post(`/api/proyecto/pagina/actualizar`, pageData);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

/* CONSTANTES */
export const SURVEY_MESSAGE_EXAMPLE = {
    welcome: {
        title: "Un mundo de opiniones que importan",
        description: "Tus respuestas deben ser en base a tu experiencia personal y lo más específicas posibles.",
     },
    reward: {
        title: "¡Eres increíble!",
        description: 'La flor más grande del mundo es la "Rafflesia arnoldii"',
        button_text: '¡Seguir contestando!',
        button_url: 'https://balancer.opinandoonline.com/load/ejemplo',
     },
};