// React
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { App } from "../../../../App";

// Componente de terceros
import { SidebarRight } from "../SidebarRight";
import { useMediaQuery } from "react-responsive";
import { useProject } from "../../../../hooks/useProject";
import { PagesList } from "./PagesList";
import { useGetSurvey } from "../../../../hooks/useSurvey";
import { Alert,AlertTitle } from "@mui/material";
import { PageCreate } from "./PageCreate";
import { PageEdit } from "./PageEdit";
import { useSurveyPageStore } from "../../../../store/SurveyPage/useSurveyPageStore";
import Skeleton from "react-loading-skeleton";

export const SurveyPages = ({ id }) => {
    const [ seeModal, setSeeModal ] = useState({create:false, edit:false});
    const [ pageId,setPageId ] = useState(null);
    const mediaQuery = useMediaQuery({ query: "(min-width:1000px)" });
    const { project } = useProject(id);
    const { survey, isLoading:isLoadingSurvey, isError:isErrorSurvey, error:errorSurvey } = useGetSurvey(id);
    const { surveyPages,resetPageData,fetchSurveyPages, isLoadingList } = useSurveyPageStore((state) => state);

    useEffect(()=>{
        if (surveyPages.length == 0) {
            fetchSurveyPages(id);
        }
        localStorage.removeItem('banner_image');
    },[surveyPages]);

    return (
        <>
            {seeModal.create && (
                <PageCreate
                    seeModal={seeModal}
                    setSeeModal={setSeeModal}
                    projectId={id}
                />
            )}

            {seeModal.edit && (
                <PageEdit
                    pageId={pageId}
                    seeModal={seeModal}
                    setSeeModal={setSeeModal}
                    projectId={id}
                />
            )}
            <div className="row">
                {!mediaQuery && !isLoadingSurvey && <SidebarRight id={id} survey_status={survey.ls_status} />}
                <div className="col-xl-9 col-lg-9 mt-4">
                    <h4>Proyecto: {project.name}</h4>
                    <div className="card mb-4">
                        <div className="card-header">
                            <div className="content-head-card">
                                <label>Páginas</label>
                                <a
                                    className="btn btn-primary-oo"
                                    onClick = { () => {
                                        setSeeModal({ ...seeModal, create: !seeModal.create });
                                        resetPageData();
                                        }}
                                >
                                    Crear página
                                </a>
                            </div>
                        </div>
                        {/* Card Body */}
                        <div className="card-body">
                            { isErrorSurvey && <Alert severity="error" sx={{marginTop: '10px', marginBottom: '10px'}}>{errorSurvey}</Alert> }
                            { !survey.ls_status &&
                                <Alert severity="error" sx={{marginTop: '10px', marginBottom: '10px'}}>
                                    <AlertTitle>Encuesta deshabilitada en Limesurvey</AlertTitle>
                                    <hr></hr>
                                    <p>La programación de este cuestionario no está habilitada en <b>Limesurvey</b></p>
                                    <p>Posible motivo: ajustes en programación de cuestionario.</p>
                                    <p>Consultar a TI para más información.</p>
                                </Alert>
                            }
                            {isLoadingList ? <Skeleton height={400} />
                            : <PagesList projectId={id} seeModal={seeModal} setSeeModal={setSeeModal} setPageId={setPageId}  /> }
                        </div>
                    </div>
                </div>
                {mediaQuery && !isLoadingSurvey && <SidebarRight id={id} survey_status={survey.ls_status} />}
            </div>
        </>
    );
};

if (document.getElementById("jsx-survey-messages")) {
    const el = document.getElementById("jsx-survey-messages");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <SurveyPages {...props} />
        </App>
    );
}