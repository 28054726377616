import { useEffect } from "react";
import { useBoundPanelistsReportStore } from "../../../store/ReportsPanelistsStore/useBoundPanelistsReportStore";
import {Rectangle } from 'recharts';
import Select from 'react-select';
import { YearBar } from "./bar/YearBar";
import { YearLine } from "./line/YearLine";
import { YearTable } from "./table/YearTable";

export const DashboardPanelistsYear = () => {
    const { loadingYear, responseYear, errorsYear,defaultRepresentation,fetchPanelistsOfYear,preset,defaultStatusProject,typeChartYear,setTypeChartYear } = useBoundPanelistsReportStore( state => state );

    useEffect( () => {
        let search = defaultRepresentation.filter(f => f.value == 'year');
        if(search.length > 0){
            fetchPanelistsOfYear();
        }
    },[defaultRepresentation,preset,defaultStatusProject]);
    return (
        <div className="card mt-5">
        <div className="card-header">
            <div style={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center'
            }}>
                <label>
                    Por año
                </label>
                <Select
                      key="selected-2"
                      defaultValue={ typeChartYear.selected }
                      name="typeChartYear"
                      options={ typeChartYear.options }
                      onChange={ (e) => {
                        setTypeChartYear(e);
                      } }
                  />
            </div>
        </div>
        <div className="card-body">
                {
                    errorsYear.status && ( errorsYear.message )
                }
                {
                    loadingYear ? ( 'cargando...') : (
                        responseYear.length > 0 && (
                            <>
                            {typeChartYear.selected.value == 'barchart' && ( <YearBar responseYear={responseYear}/> )}
                            {typeChartYear.selected.value == 'linechart' && (<YearLine responseYear={responseYear}/>)}
                            {typeChartYear.selected.value == 'table' && (<YearTable responseYear={responseYear}/>)}
                        </>
                        )
                    )
                }
        </div>
    </div>
    )
}