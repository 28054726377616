import React  from 'react';
import ReactDOM from 'react-dom/client';
import { App } from '../../App';
import { FiltereLeftAside } from './FiltereLeftAside';
import { useBoundProfile } from '../../store/Profiles/useBoundProfiles';
import { SearchData } from './SearchData';
import Skeleton from 'react-loading-skeleton';

export const DashboardProfiles = () => {

    const { responseData, loading } = useBoundProfile(state => state);
    return (
        <div className="content__profiles_dashboard" style={{ gridTemplateColumns: `1fr  500px` }}>
            {
                responseData.data.length > 0 ? ( 
                    <SearchData />
                ) : (
                    <div style={{  backgroundColor:'white', borderRadius:'8px', display:'flex', justifyContent:'center', alignItems:'center' }}>
                        {
                            (loading) ? ( 
                                <Skeleton width={'auto'} baseColor='red'/>
                            ) : (
                                <label>Seleccionar preguntas para la visualización de datos</label>
                            )
                        }
                    </div>
                )
            }
            <div  className={`question__content`} style={{ height:'50vh'}} >
                <FiltereLeftAside />
            </div>
        </div>
    )
}
if (document.getElementById('jsx-dashboard-profiles')) {
    const el = document.getElementById("jsx-dashboard-profiles");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
      <App>
        <DashboardProfiles { ...props } />
      </App>
  )
}