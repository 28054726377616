import 'react-day-picker/dist/style.css';
import React, { useEffect, useState }  from "react";
import { format, isValid, parseISO } from "date-fns";
import { useBoundMktStore } from "../../../store/ReportsCampaignMkt/useBoundMktStore";

export const DateFilter = ({ campaign_id }) => {

    const [error, setError] = useState({ from: '', to: '' });
    const { preset,
        fetchCampaignsForDay,
        setPreset,
        fetchCampaign,
        fetchAges,
        fetchGenders,
        fetchFirstgeo,
        fetchProfiles,
        fetchSurveysCampaign,
        fetchNsesCampaign  } = useBoundMktStore( state => state );
    const handleChangeInputRange = (e,type) => {
        if(type == 'from'){
            if(isValid(parseISO(e.target.value,1))){
                setPreset({ ...preset, from: parseISO(e.target.value,1)})
            }else{
                setError({ from: 'Agrega una fecha válida', ...error });
            }
        }
        if(type == 'to'){
            if(isValid(parseISO(e.target.value,1))){
                setPreset({ ...preset, to: parseISO(e.target.value,1)})
            }else{
                setError({ to: 'Agrega una fecha válida', ...error });
            }
        }
    }

    const handleCampaigns = () => {
        fetchCampaignsForDay(null, campaign_id);
        fetchAges({ id: campaign_id, preset:null });
        fetchGenders({ id: campaign_id, preset:null });
        fetchGenders({ id: campaign_id, preset:null });
        fetchFirstgeo({ id: campaign_id, preset:null });
        fetchProfiles({ id: campaign_id, preset:null });
        fetchSurveysCampaign({ id: campaign_id, preset:null });
        fetchNsesCampaign({ id: campaign_id, preset:null });

    }

    useEffect( () => {
        fetchCampaign( campaign_id );
    },[])
    return (
        <>
            <div className={`d-flex gap-2`}>
            <div className={`flex-fill`}>
                <label htmlFor="">Desde</label>
                <input type="date" name="from_at" value={ format(preset.from,'yyyy-MM-dd') } className="form-control flex-fill" onChange={ (e) => handleChangeInputRange(e,'from') }/>
            </div>
            <div className={`flex-fill`}>
                <label htmlFor="">Hasta</label>
                <input type="date" name="to_at"  value={ format(preset.to,'yyyy-MM-dd') }  className="form-control flex-fill" onChange={ (e) => handleChangeInputRange(e,'to') }/>
            </div>
            <div className="d-flex align-items-end">
                <button className="btn btn-sm btn-primary-oo text-light flex-fill" onClick={ handleCampaigns  }>Buscar</button>
            </div>
                </div>
        </>
    );
};
