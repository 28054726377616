export function currencyFormatter({ currency, value}) {
    const formatter = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency
    }) 
    return formatter.format(value)
  }

export const  formatterNumber = (numero) => {
    let numeroFormateado = parseFloat(numero).toFixed(2);
    return numeroFormateado.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.00$/, "");;
  }