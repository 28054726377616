import React from 'react';
import { useQuery } from "react-query";
import Swal from 'sweetalert2';
import { resetPassword } from "../../../../api/sio/user";

export const ButtonAction = ({ user }) => {
    const { status, isLoading, refetch } = useQuery(['reset-password', { id: user.id }],() => resetPassword(user.id), { enabled:false,
        onSuccess: () => Swal.fire('Reestablecer contraseña', 'Se envio un correo  de forma exitosa', 'success'),
        onError: () => Swal.fire('Reestablecer contraseña', 'No se pudo enviar el correo, intenta nuevamente o comunicate con el administrador', 'error')
     });
    return (
        <>
            <div className="content-button-action">
                <a  className="btn btn-warning" href={`editar/${ user.id }`}>Editar</a>
                {
                    isLoading ? ( <p>Enviando mail...</p>) : (
                        <a  className="btn btn-info" onClick={ refetch }>Password</a>
                    )
                }
            </div>
        </>
    );
}