import React, { useContext }  from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { COLORS, COLORS_GENDER } from '../../../helper/COLOR';
import { useGendersM } from '../../../hooks/useGenderDashboard';
import { DashboardContext } from '../../../context/DashboardContext';
import Skeleton from 'react-loading-skeleton';


const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, payload }, totals) => {
    let progress = (payload.total/totals)*100;
    progress = parseFloat(progress.toFixed(2));
    progress = isNaN(progress) ? 0 : progress;
    return `${payload.total}  (${progress}%)`    
};

export const GenderPieChart = () => {
    const {filter} = useContext(DashboardContext);
    const { isLoading, genders } = useGendersM(filter);


    return (
        <>        {
            isLoading && !genders.length ? (<Skeleton height={400}/>) :(
                !genders.length  ? ( <div className="alert__not-data">No se pudo encontrar la informacion <strong>SEXO</strong>, por favor intenta nuevamente modificando la fecha o refrescando la página</div>)
                :(<ResponsiveContainer width="100%" height={300}>
                    <PieChart width={730} height={250}>
                        <Legend payload={ 
                            genders.map( (v,k) => {
                                return ({ ...v, name: v.panelist_gender, value:v.panelist_gender, color:COLORS[k] })
                            })
                        }/>
                        <Pie 
                            data={genders} 
                            dataKey="total" 
                            nameKey="gender" 
                            labelLine={true} 
                            label={(props) => renderCustomizedLabel({...props}, genders.reduce((a,b) => a + b.total,0))} >
                            {genders.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index]} />))}
                        </Pie>
                        {/* <Tooltip content={<CustomTooltip />} cursor={{fill: 'transparent'}}/> */}
                    </PieChart>
                </ResponsiveContainer>)
            )
        }
        </>
    )
}