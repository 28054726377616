import React, { useState } from "react";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { updatePanelistsExpiredAtByPanel } from "../../../../api/sio/project";
import Swal from "sweetalert2";
import { usePanels } from "../../../../hooks/useProject";
import de from "date-fns/locale/de";

export const UpdateExpiredAtModal = ({ seeModal, setSeeModal, projectId, refetchPanelists }) => {
    const [formData,setFormData] = useState({
        project_id: projectId,
        expired_at: new Date(),
        panels: []
    });
    const [errors,setErrors] = useState({ status: false, message: [] });
    const { panels }= usePanels(projectId);
    const handleUpdateExpiredAtByPanel = async () => {
        setErrors({
            status:false,
            message: ""
        });
        try{
            let response = await updatePanelistsExpiredAtByPanel(formData);
            const { message,status } = response;
            if(status == 200){
                Swal.fire({
                    title: `Fecha de expiración actualizada`,
                    icon: "success",
                }).then((result) => {
                    result.isConfirmed && setSeeModal({ ...seeModal, updateExpiredAt: false });
                });
                setSeeModal({ ...seeModal, updateExpiredAt: false });
                refetchPanelists();
                return;
            }
        }catch(error){
            const  { data,status } = error;
            if(status == 422){
                setErrors({
                    status: true,
                    message: data.errors
                })
            }else{
                setErrors({
                    status: true,
                    message: "Algo ocurrio al intentar actualizar los datos, intenta nuevamente."
                })
            }
        }
    }

    //OnSubmit handler
    const handleOnSubmit = (e) => {
        e.preventDefault();
        Swal.fire({
            title: `¿Actualizar la fecha de expiración de los paneles seleccionados?`,
            showCancelButton: true,
            confirmButtonText: "Actualizar",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((result) => {
            result.isConfirmed
                ? handleUpdateExpiredAtByPanel()
                : Swal.fire(
                    "Actualizar Fecha",
                    "No se ha modificado la fecha de expiración",
                    "warning"
                );
        });
    };

    return (
        <>
            <div className="modal modal-segmentation">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Actualizar fecha de expiración</h5>
                            <button
                                className="close close-modal-btn"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setSeeModal({
                                        ...seeModal,
                                        updateExpiredAt: !seeModal.updateExpiredAt,
                                    });
                                }}
                            >
                                <i className="fa fa-times-circle"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => handleOnSubmit(e)}>
                                <div className="row mb-3">
                                    <div className="form-group col-md-6">
                                        <label className="my-1 fw-bold">
                                            Seleccionar un panel
                                        </label>
                                        <Select
                                            key={2}
                                            isClearable
                                            isMulti
                                            placeholder='Modificar fecha de expiración'
                                            options = { panels }
                                            value={ formData.panels }
                                            onChange = { e => {
                                                setFormData({
                                                    ...formData,
                                                    panels: e.some( x => x.value == 'ALL' )
                                                    ? [e.find( x => x.value == 'ALL' )]
                                                    : e
                                                })
                                            } }
                                        />
                                        {
                                            errors.status && (
                                                <p className="text text-danger py-2">{errors.message?.panels?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="my-1 fw-bold">
                                            Fecha de expiración
                                        </label>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            adapterLocale={de}
                                        >
                                            <DateTimePicker
                                                ampm={false}
                                                className="form-control"
                                                inputFormat="dd/MM/yyyy HH:mm"
                                                value={formData.expired_at}
                                                onChange={ (e) => {
                                                    setFormData({...formData, expired_at: e})
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        size="small"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        {
                                            errors.status && (
                                                <p className="text text-danger py-2">{errors.message?.expired_at?.map( x => x)}</p>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                </div>
                                <hr />
                                <div className="form-group col-md-12">
                                    <button
                                        type="submit"
                                        className="btn btn-primary-oo"
                                    >
                                        Actualizar fecha
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
