import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';

import { App } from '../../../App';
import { FriendPointsTable } from './table/FriendPointsTable';
import { useFriendPointsStore } from '../../../store/FriendPointsStore/useFriendPointsStore';
import Skeleton from 'react-loading-skeleton';
import { Alert } from '@mui/material';
import { CreateFriendPointModal } from './modal/CreateFriendPointModal';
import { EditFriendPointModal } from './modal/EditFriendPointModal';

export const FriendPoints = () => {
    const [ mount,setMount ] = useState(null);
    const [ seeModal,setSeeModal ] = useState({
        create: false,
        edit: false
    });
    const { friendPoints,fetchFriendPoints,isLoading,isError,error } = useFriendPointsStore((state) => state);
    useEffect(()=>{ if(!mount){ fetchFriendPoints(); } setMount(true); },[mount]);
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12">
                <div className="card mb-4">
                    <div className="card-header">
                        <div className="content-head-card">
                            <label>Distribución de puntos por referidos</label>
                            <button className="btn btn-primary-oo" onClick={
                                () => setSeeModal({ ...seeModal, create: !seeModal.create })
                            }>Crear distribución</button>
                        </div>
                    </div>
                    <div className="card-body">
                        { seeModal.create && (
                            <CreateFriendPointModal
                                seeModal={seeModal}
                                setSeeModal={setSeeModal}
                            />
                        )}
                        { seeModal.edit && (
                            <EditFriendPointModal
                                seeModal={seeModal}
                                setSeeModal={setSeeModal}
                            />
                        )}
                        { isLoading ? <Skeleton height={200} />
                        : (
                            <FriendPointsTable
                                friendPoints={friendPoints}
                                seeModal={seeModal}
                                setSeeModal={setSeeModal}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

if (document.getElementById('jsx-friend-points')) {
    const el = document.getElementById("jsx-friend-points");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <FriendPoints { ...props } />
        </App>
    )
}