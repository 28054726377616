import React, { useEffect } from 'react';
import Select, { components } from 'react-select';
import { useBoundProfile } from '../../store/Profiles/useBoundProfiles';
import { useBoundGeographyStore } from '../../store/Geographies/useBoundGeographyStore';
import { useBoundNseStore } from '../../store/useBoundNseStore';
import { useDebounceFetchProfileData } from '../../hooks/useDebounceFetchProfileData';

import { OrderQuestion } from './OrderQuestion';
export const FiltereLeftAside = () => {

    const { fetchQuestions, questions, setter, dataSelect } = useBoundProfile(state => state);
    const { fetchTagsGeography } = useBoundGeographyStore( state => state );
    const { getRefenceNse } = useBoundNseStore( state => state );
    const { fetchData } = useDebounceFetchProfileData();

    const Group = (props) => {
        return (
            <div style={{
                border:'3px dotted #46166c',
                borderRadius:'8px',
                margin:'5px',
                padding:'10px',
                backgroundColor:'var(--bs-gray-100)'
            }}>
                <components.Group { ...props } />
            </div>
        )
    }
    const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            <SearchIcon />
        </components.DropdownIndicator>
        )
    )
    }

    const SearchIcon = () => (
        <svg
            width="22"
            height="22"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="38" cy="40" r="20.5" stroke="currentColor" strokeWidth="7" />
            <path
                d="M76.0872 84.4699C78.056 86.4061 81.2217 86.3797 83.158 84.4109C85.0943 82.442 85.0679 79.2763 83.099 77.34L76.0872 84.4699ZM50.4199 59.2273L76.0872 84.4699L83.099 77.34L57.4317 52.0974L50.4199 59.2273Z"
                fill="currentColor"
            />
        </svg>
    )

    useEffect( () => {
        fetchQuestions();
        fetchTagsGeography();
        getRefenceNse();
        return () => null;
    },[]);

    return (
        <>
            { /** contenedor preguntas */}
            <div className="form-group col-md-12">
                <label className="my-1 fw-bold">Buscar preguntas </label>
                <div className="question__content_filter">
                    <div className="question__content_filter_item">
                        <Select
                        isMulti
                        className='basic-multi-select'
                        components={{ Group, DropdownIndicator }}
                        options={ questions }
                        value={ dataSelect || [] }
                        placeholder='Buscar preguntas'
                        onChange ={ (e) => {
                            const order = e.map( (i,k) => ({ ...i, order: k}));
                            setter({ dataSelect: order })
                            fetchData();                            
                        }}
                    />
                    </div>
                </div>
            </div>
            <div className="form-group col-md-12">
                <label className="my-1 fw-bold">Ordernar preguntas </label>
                <OrderQuestion />
            </div>
        </>
    )
} 