import { ItemsResultSurveyContext } from "../context/ItemsResultSurveyContext";
import React, { useEffect, useRef, useState } from 'react';
import { useItemsProject } from "../hooks/useProject";

export const ItemsResultSurveyProvider = ({ children }) => {
    const { id, hash } = children.props;
    const  { isLoading,items,refetch  } = useItemsProject(id, hash);
    const [ statePro, setStatePro] = useState(false);
    const refState = useRef(statePro);

    useEffect(() => {
        if(refState.current === statePro){
            refetch();
        }
    },[statePro]);

    return (
        <>
        {
            <ItemsResultSurveyContext.Provider value={{ items, isLoading, setStatePro,statePro, hash}}>{ children }</ItemsResultSurveyContext.Provider>
        }
        </>
    )
}